/**
 * [2024-02-15.0840 by Brian]
 *    Valid taxonomy codes. Started as CSV from Emily.
 */
export const TaxonomyCodeReference = {
  '193200000X': {
    code: '193200000X',
    displayName: 'Multi-Specialty Group',
  },
  '193400000X': {
    code: '193400000X',
    displayName: 'Single Specialty Group',
  },
  '207K00000X': {
    code: '207K00000X',
    displayName: 'Allergy & Immunology Physician',
  },
  '207KA0200X': {
    code: '207KA0200X',
    displayName: 'Allergy Physician',
  },
  '207KI0005X': {
    code: '207KI0005X',
    displayName:
      'Clinical & Laboratory Immunology (Allergy & Immunology) Physician',
  },
  '207L00000X': {
    code: '207L00000X',
    displayName: 'Anesthesiology Physician',
  },
  '207LA0401X': {
    code: '207LA0401X',
    displayName: 'Addiction Medicine (Anesthesiology) Physician',
  },
  '207LC0200X': {
    code: '207LC0200X',
    displayName: 'Critical Care Medicine (Anesthesiology) Physician',
  },
  '207LH0002X': {
    code: '207LH0002X',
    displayName: 'Hospice and Palliative Medicine (Anesthesiology) Physician',
  },
  '207LP2900X': {
    code: '207LP2900X',
    displayName: 'Pain Medicine (Anesthesiology) Physician',
  },
  '207LP3000X': {
    code: '207LP3000X',
    displayName: 'Pediatric Anesthesiology Physician',
  },
  '208U00000X': {
    code: '208U00000X',
    displayName: 'Clinical Pharmacology Physician',
  },
  '208C00000X': {
    code: '208C00000X',
    displayName: 'Colon & Rectal Surgery Physician',
  },
  '207N00000X': {
    code: '207N00000X',
    displayName: 'Dermatology Physician',
  },
  '207NI0002X': {
    code: '207NI0002X',
    displayName: 'Clinical & Laboratory Dermatological Immunology Physician',
  },
  '207ND0900X': {
    code: '207ND0900X',
    displayName: 'Dermatopathology Physician',
  },
  '207ND0101X': {
    code: '207ND0101X',
    displayName: 'MOHS-Micrographic Surgery Physician',
  },
  '207NP0225X': {
    code: '207NP0225X',
    displayName: 'Pediatric Dermatology Physician',
  },
  '207NS0135X': {
    code: '207NS0135X',
    displayName: 'Procedural Dermatology Physician',
  },
  '204R00000X': {
    code: '204R00000X',
    displayName: 'Electrodiagnostic Medicine Physician',
  },
  '207P00000X': {
    code: '207P00000X',
    displayName: 'Emergency Medicine Physician',
  },
  '207PE0004X': {
    code: '207PE0004X',
    displayName: 'Emergency Medical Services (Emergency Medicine) Physician',
  },
  '207PH0002X': {
    code: '207PH0002X',
    displayName:
      'Hospice and Palliative Medicine (Emergency Medicine) Physician',
  },
  '207PT0002X': {
    code: '207PT0002X',
    displayName: 'Medical Toxicology (Emergency Medicine) Physician',
  },
  '207PP0204X': {
    code: '207PP0204X',
    displayName: 'Pediatric Emergency Medicine (Emergency Medicine) Physician',
  },
  '207PS0010X': {
    code: '207PS0010X',
    displayName: 'Sports Medicine (Emergency Medicine) Physician',
  },
  '207PE0005X': {
    code: '207PE0005X',
    displayName:
      'Undersea and Hyperbaric Medicine (Emergency Medicine) Physician',
  },
  '207Q00000X': {
    code: '207Q00000X',
    displayName: 'Family Medicine Physician',
  },
  '207QA0401X': {
    code: '207QA0401X',
    displayName: 'Addiction Medicine (Family Medicine) Physician',
  },
  '207QA0000X': {
    code: '207QA0000X',
    displayName: 'Adolescent Medicine (Family Medicine) Physician',
  },
  '207QA0505X': {
    code: '207QA0505X',
    displayName: 'Adult Medicine Physician',
  },
  '207QG0300X': {
    code: '207QG0300X',
    displayName: 'Geriatric Medicine (Family Medicine) Physician',
  },
  '207QH0002X': {
    code: '207QH0002X',
    displayName: 'Hospice and Palliative Medicine (Family Medicine) Physician',
  },
  '207QB0002X': {
    code: '207QB0002X',
    displayName: 'Obesity Medicine (Family Medicine) Physician',
  },
  '207QS1201X': {
    code: '207QS1201X',
    displayName: 'Sleep Medicine (Family Medicine) Physician',
  },
  '207QS0010X': {
    code: '207QS0010X',
    displayName: 'Sports Medicine (Family Medicine) Physician',
  },
  '208D00000X': {
    code: '208D00000X',
    displayName: 'General Practice Physician',
  },
  '208M00000X': {
    code: '208M00000X',
    displayName: 'Hospitalist Physician',
  },
  '202C00000X': {
    code: '202C00000X',
    displayName: 'Independent Medical Examiner Physician',
  },
  '202D00000X': {
    code: '202D00000X',
    displayName: 'Integrative Medicine Physician',
  },
  '207R00000X': {
    code: '207R00000X',
    displayName: 'Internal Medicine Physician',
  },
  '207RA0401X': {
    code: '207RA0401X',
    displayName: 'Addiction Medicine (Internal Medicine) Physician',
  },
  '207RA0000X': {
    code: '207RA0000X',
    displayName: 'Adolescent Medicine (Internal Medicine) Physician',
  },
  '207RA0002X': {
    code: '207RA0002X',
    displayName: 'Adult Congenital Heart Disease Physician',
  },
  '207RA0001X': {
    code: '207RA0001X',
    displayName: 'Advanced Heart Failure and Transplant Cardiology Physician',
  },
  '207RA0201X': {
    code: '207RA0201X',
    displayName: 'Allergy & Immunology (Internal Medicine) Physician',
  },
  '207RC0000X': {
    code: '207RC0000X',
    displayName: 'Cardiovascular Disease Physician',
  },
  '207RI0001X': {
    code: '207RI0001X',
    displayName:
      'Clinical & Laboratory Immunology (Internal Medicine) Physician',
  },
  '207RC0001X': {
    code: '207RC0001X',
    displayName: 'Clinical Cardiac Electrophysiology Physician',
  },
  '207RC0200X': {
    code: '207RC0200X',
    displayName: 'Critical Care Medicine (Internal Medicine) Physician',
  },
  '207RE0101X': {
    code: '207RE0101X',
    displayName: 'Endocrinology, Diabetes & Metabolism Physician',
  },
  '207RG0100X': {
    code: '207RG0100X',
    displayName: 'Gastroenterology Physician',
  },
  '207RG0300X': {
    code: '207RG0300X',
    displayName: 'Geriatric Medicine (Internal Medicine) Physician',
  },
  '207RH0000X': {
    code: '207RH0000X',
    displayName: 'Hematology (Internal Medicine) Physician',
  },
  '207RH0003X': {
    code: '207RH0003X',
    displayName: 'Hematology & Oncology Physician',
  },
  '207RI0008X': {
    code: '207RI0008X',
    displayName: 'Hepatology Physician',
  },
  '207RH0002X': {
    code: '207RH0002X',
    displayName:
      'Hospice and Palliative Medicine (Internal Medicine) Physician',
  },
  '207RH0005X': {
    code: '207RH0005X',
    displayName: 'Hypertension Specialist Physician',
  },
  '207RI0200X': {
    code: '207RI0200X',
    displayName: 'Infectious Disease Physician',
  },
  '207RI0011X': {
    code: '207RI0011X',
    displayName: 'Interventional Cardiology Physician',
  },
  '207RM1200X': {
    code: '207RM1200X',
    displayName: 'Magnetic Resonance Imaging (MRI) Internal Medicine Physician',
  },
  '207RX0202X': {
    code: '207RX0202X',
    displayName: 'Medical Oncology Physician',
  },
  '207RN0300X': {
    code: '207RN0300X',
    displayName: 'Nephrology Physician',
  },
  '207RB0002X': {
    code: '207RB0002X',
    displayName: 'Obesity Medicine (Internal Medicine) Physician',
  },
  '207RP1001X': {
    code: '207RP1001X',
    displayName: 'Pulmonary Disease Physician',
  },
  '207RR0500X': {
    code: '207RR0500X',
    displayName: 'Rheumatology Physician',
  },
  '207RS0012X': {
    code: '207RS0012X',
    displayName: 'Sleep Medicine (Internal Medicine) Physician',
  },
  '207RS0010X': {
    code: '207RS0010X',
    displayName: 'Sports Medicine (Internal Medicine) Physician',
  },
  '207RT0003X': {
    code: '207RT0003X',
    displayName: 'Transplant Hepatology Physician',
  },
  '209800000X': {
    code: '209800000X',
    displayName: 'Legal Medicine (M.D./D.O.) Physician',
  },
  '207SG0202X': {
    code: '207SG0202X',
    displayName: 'Clinical Biochemical Genetics Physician',
  },
  '207SC0300X': {
    code: '207SC0300X',
    displayName: 'Clinical Cytogenetics Physician',
  },
  '207SG0201X': {
    code: '207SG0201X',
    displayName: 'Clinical Genetics (M.D.) Physician',
  },
  '207SG0203X': {
    code: '207SG0203X',
    displayName: 'Clinical Molecular Genetics Physician',
  },
  '207SG0207X': {
    code: '207SG0207X',
    displayName: 'Medical Biochemical Genetics',
  },
  '207SM0001X': {
    code: '207SM0001X',
    displayName: 'Molecular Genetic Pathology (Medical Genetics) Physician',
  },
  '207SG0205X': {
    code: '207SG0205X',
    displayName: 'Ph.D. Medical Genetics Physician',
  },
  '207T00000X': {
    code: '207T00000X',
    displayName: 'Neurological Surgery Physician',
  },
  '204D00000X': {
    code: '204D00000X',
    displayName: 'Neuromusculoskeletal Medicine & OMM Physician',
  },
  '204C00000X': {
    code: '204C00000X',
    displayName: 'Sports Medicine (Neuromusculoskeletal Medicine) Physician',
  },
  '207U00000X': {
    code: '207U00000X',
    displayName: 'Nuclear Medicine Physician',
  },
  '207UN0903X': {
    code: '207UN0903X',
    displayName: 'In Vivo & In Vitro Nuclear Medicine Physician',
  },
  '207UN0901X': {
    code: '207UN0901X',
    displayName: 'Nuclear Cardiology Physician',
  },
  '207UN0902X': {
    code: '207UN0902X',
    displayName: 'Nuclear Imaging & Therapy Physician',
  },
  '207V00000X': {
    code: '207V00000X',
    displayName: 'Obstetrics & Gynecology Physician',
  },
  '207VC0300X': {
    code: '207VC0300X',
    displayName: 'Complex Family Planning Physician',
  },
  '207VC0200X': {
    code: '207VC0200X',
    displayName: 'Critical Care Medicine (Obstetrics & Gynecology) Physician',
  },
  '207VF0040X': {
    code: '207VF0040X',
    displayName:
      'Female Pelvic Medicine and Reconstructive Surgery (Obstetrics & Gynecology) Physician',
  },
  '207VX0201X': {
    code: '207VX0201X',
    displayName: 'Gynecologic Oncology Physician',
  },
  '207VG0400X': {
    code: '207VG0400X',
    displayName: 'Gynecology Physician',
  },
  '207VH0002X': {
    code: '207VH0002X',
    displayName:
      'Hospice and Palliative Medicine (Obstetrics & Gynecology) Physician',
  },
  '207VM0101X': {
    code: '207VM0101X',
    displayName: 'Maternal & Fetal Medicine Physician',
  },
  '207VB0002X': {
    code: '207VB0002X',
    displayName: 'Obesity Medicine (Obstetrics & Gynecology) Physician',
  },
  '207VX0000X': {
    code: '207VX0000X',
    displayName: 'Obstetrics Physician',
  },
  '207VE0102X': {
    code: '207VE0102X',
    displayName: 'Reproductive Endocrinology Physician',
  },
  '207W00000X': {
    code: '207W00000X',
    displayName: 'Ophthalmology Physician',
  },
  '207WX0120X': {
    code: '207WX0120X',
    displayName: 'Cornea and External Diseases Specialist Physician',
  },
  '207WX0009X': {
    code: '207WX0009X',
    displayName: 'Glaucoma Specialist (Ophthalmology) Physician',
  },
  '207WX0109X': {
    code: '207WX0109X',
    displayName: 'Neuro-ophthalmology Physician',
  },
  '207WX0200X': {
    code: '207WX0200X',
    displayName: 'Ophthalmic Plastic and Reconstructive Surgery Physician',
  },
  '207WX0110X': {
    code: '207WX0110X',
    displayName:
      'Pediatric Ophthalmology and Strabismus Specialist Physician Physician',
  },
  '207WX0107X': {
    code: '207WX0107X',
    displayName: 'Retina Specialist (Ophthalmology) Physician',
  },
  '207WX0108X': {
    code: '207WX0108X',
    displayName:
      'Uveitis and Ocular Inflammatory Disease (Ophthalmology) Physician',
  },
  '204E00000X': {
    code: '204E00000X',
    displayName: 'Oral & Maxillofacial Surgery (D.M.D.)',
  },
  '207X00000X': {
    code: '207X00000X',
    displayName: 'Orthopaedic Surgery Physician',
  },
  '207XS0114X': {
    code: '207XS0114X',
    displayName: 'Adult Reconstructive Orthopaedic Surgery Physician',
  },
  '207XX0004X': {
    code: '207XX0004X',
    displayName: 'Orthopaedic Foot and Ankle Surgery Physician',
  },
  '207XS0106X': {
    code: '207XS0106X',
    displayName: 'Orthopaedic Hand Surgery Physician',
  },
  '207XS0117X': {
    code: '207XS0117X',
    displayName: 'Orthopaedic Surgery of the Spine Physician',
  },
  '207XX0801X': {
    code: '207XX0801X',
    displayName: 'Orthopaedic Trauma Physician',
  },
  '207XP3100X': {
    code: '207XP3100X',
    displayName: 'Pediatric Orthopaedic Surgery Physician',
  },
  '207XX0005X': {
    code: '207XX0005X',
    displayName: 'Sports Medicine (Orthopaedic Surgery) Physician',
  },
  '207Y00000X': {
    code: '207Y00000X',
    displayName: 'Otolaryngology Physician',
  },
  '207YS0123X': {
    code: '207YS0123X',
    displayName: 'Facial Plastic Surgery Physician',
  },
  '207YX0602X': {
    code: '207YX0602X',
    displayName: 'Otolaryngic Allergy Physician',
  },
  '207YX0905X': {
    code: '207YX0905X',
    displayName: 'Otolaryngology/Facial Plastic Surgery Physician',
  },
  '207YX0901X': {
    code: '207YX0901X',
    displayName: 'Otology & Neurotology Physician',
  },
  '207YP0228X': {
    code: '207YP0228X',
    displayName: 'Pediatric Otolaryngology Physician',
  },
  '207YX0007X': {
    code: '207YX0007X',
    displayName:
      'Plastic Surgery within the Head & Neck (Otolaryngology) Physician',
  },
  '207YS0012X': {
    code: '207YS0012X',
    displayName: 'Sleep Medicine (Otolaryngology) Physician',
  },
  '208VP0014X': {
    code: '208VP0014X',
    displayName: 'Interventional Pain Medicine Physician',
  },
  '208VP0000X': {
    code: '208VP0000X',
    displayName: 'Pain Medicine Physician',
  },
  '207ZP0101X': {
    code: '207ZP0101X',
    displayName: 'Anatomic Pathology Physician',
  },
  '207ZP0102X': {
    code: '207ZP0102X',
    displayName: 'Anatomic Pathology & Clinical Pathology Physician',
  },
  '207ZB0001X': {
    code: '207ZB0001X',
    displayName: 'Blood Banking & Transfusion Medicine Physician',
  },
  '207ZP0104X': {
    code: '207ZP0104X',
    displayName: 'Chemical Pathology Physician',
  },
  '207ZC0008X': {
    code: '207ZC0008X',
    displayName: 'Clinical Informatics (Pathology) Physician',
  },
  '207ZC0006X': {
    code: '207ZC0006X',
    displayName: 'Clinical Pathology Physician',
  },
  '207ZP0105X': {
    code: '207ZP0105X',
    displayName: 'Clinical Pathology/Laboratory Medicine Physician',
  },
  '207ZC0500X': {
    code: '207ZC0500X',
    displayName: 'Cytopathology Physician',
  },
  '207ZD0900X': {
    code: '207ZD0900X',
    displayName: 'Dermatopathology (Pathology) Physician',
  },
  '207ZF0201X': {
    code: '207ZF0201X',
    displayName: 'Forensic Pathology Physician',
  },
  '207ZH0000X': {
    code: '207ZH0000X',
    displayName: 'Hematology (Pathology) Physician',
  },
  '207ZI0100X': {
    code: '207ZI0100X',
    displayName: 'Immunopathology Physician',
  },
  '207ZM0300X': {
    code: '207ZM0300X',
    displayName: 'Medical Microbiology Physician',
  },
  '207ZP0007X': {
    code: '207ZP0007X',
    displayName: 'Molecular Genetic Pathology (Pathology) Physician',
  },
  '207ZN0500X': {
    code: '207ZN0500X',
    displayName: 'Neuropathology Physician',
  },
  '207ZP0213X': {
    code: '207ZP0213X',
    displayName: 'Pediatric Pathology Physician',
  },
  '208000000X': {
    code: '208000000X',
    displayName: 'Pediatrics Physician',
  },
  '2080A0000X': {
    code: '2080A0000X',
    displayName: 'Pediatric Adolescent Medicine Physician',
  },
  '2080C0008X': {
    code: '2080C0008X',
    displayName: 'Child Abuse Pediatrics Physician',
  },
  '2080I0007X': {
    code: '2080I0007X',
    displayName: 'Pediatric Clinical & Laboratory Immunology Physician',
  },
  '2080P0006X': {
    code: '2080P0006X',
    displayName: 'Developmental - Behavioral Pediatrics Physician',
  },
  '2080H0002X': {
    code: '2080H0002X',
    displayName: 'Pediatric Hospice and Palliative Medicine Physician',
  },
  '2080T0002X': {
    code: '2080T0002X',
    displayName: 'Pediatric Medical Toxicology Physician',
  },
  '2080N0001X': {
    code: '2080N0001X',
    displayName: 'Neonatal-Perinatal Medicine Physician',
  },
  '2080P0008X': {
    code: '2080P0008X',
    displayName: 'Pediatric Neurodevelopmental Disabilities Physician',
  },
  '2080B0002X': {
    code: '2080B0002X',
    displayName: 'Pediatric Obesity Medicine Physician',
  },
  '2080P0201X': {
    code: '2080P0201X',
    displayName: 'Pediatric Allergy/Immunology Physician',
  },
  '2080P0202X': {
    code: '2080P0202X',
    displayName: 'Pediatric Cardiology Physician',
  },
  '2080P0203X': {
    code: '2080P0203X',
    displayName: 'Pediatric Critical Care Medicine Physician',
  },
  '2080P0204X': {
    code: '2080P0204X',
    displayName: 'Pediatric Emergency Medicine (Pediatrics) Physician',
  },
  '2080P0205X': {
    code: '2080P0205X',
    displayName: 'Pediatric Endocrinology Physician',
  },
  '2080P0206X': {
    code: '2080P0206X',
    displayName: 'Pediatric Gastroenterology Physician',
  },
  '2080P0207X': {
    code: '2080P0207X',
    displayName: 'Pediatric Hematology & Oncology Physician',
  },
  '2080P0208X': {
    code: '2080P0208X',
    displayName: 'Pediatric Infectious Diseases Physician',
  },
  '2080P0210X': {
    code: '2080P0210X',
    displayName: 'Pediatric Nephrology Physician',
  },
  '2080P0214X': {
    code: '2080P0214X',
    displayName: 'Pediatric Pulmonology Physician',
  },
  '2080P0216X': {
    code: '2080P0216X',
    displayName: 'Pediatric Rheumatology Physician',
  },
  '2080T0004X': {
    code: '2080T0004X',
    displayName: 'Pediatric Transplant Hepatology Physician',
  },
  '2080S0012X': {
    code: '2080S0012X',
    displayName: 'Pediatric Sleep Medicine Physician',
  },
  '2080S0010X': {
    code: '2080S0010X',
    displayName: 'Pediatric Sports Medicine Physician',
  },
  '202K00000X': {
    code: '202K00000X',
    displayName: 'Phlebology Physician',
  },
  '208100000X': {
    code: '208100000X',
    displayName: 'Physical Medicine & Rehabilitation Physician',
  },
  '2081P0301X': {
    code: '2081P0301X',
    displayName:
      'Brain Injury Medicine (Physical Medicine & Rehabilitation) Physician',
  },
  '2081H0002X': {
    code: '2081H0002X',
    displayName:
      'Hospice and Palliative Medicine (Physical Medicine & Rehabilitation) Physician',
  },
  '2081N0008X': {
    code: '2081N0008X',
    displayName:
      'Neuromuscular Medicine (Physical Medicine & Rehabilitation) Physician',
  },
  '2081P2900X': {
    code: '2081P2900X',
    displayName: 'Pain Medicine (Physical Medicine & Rehabilitation) Physician',
  },
  '2081P0010X': {
    code: '2081P0010X',
    displayName: 'Pediatric Rehabilitation Medicine Physician',
  },
  '2081P0004X': {
    code: '2081P0004X',
    displayName: 'Spinal Cord Injury Medicine Physician',
  },
  '2081S0010X': {
    code: '2081S0010X',
    displayName:
      'Sports Medicine (Physical Medicine & Rehabilitation) Physician',
  },
  '208200000X': {
    code: '208200000X',
    displayName: 'Plastic Surgery Physician',
  },
  '2082S0099X': {
    code: '2082S0099X',
    displayName:
      'Plastic Surgery Within the Head and Neck (Plastic Surgery) Physician',
  },
  '2082S0105X': {
    code: '2082S0105X',
    displayName: 'Surgery of the Hand (Plastic Surgery) Physician',
  },
  '2083A0300X': {
    code: '2083A0300X',
    displayName: 'Addiction Medicine (Preventive Medicine) Physician',
  },
  '2083A0100X': {
    code: '2083A0100X',
    displayName: 'Aerospace Medicine Physician',
  },
  '2083C0008X': {
    code: '2083C0008X',
    displayName: 'Clinical Informatics Physician',
  },
  '2083T0002X': {
    code: '2083T0002X',
    displayName: 'Medical Toxicology (Preventive Medicine) Physician',
  },
  '2083B0002X': {
    code: '2083B0002X',
    displayName: 'Obesity Medicine (Preventive Medicine) Physician',
  },
  '2083X0100X': {
    code: '2083X0100X',
    displayName: 'Occupational Medicine Physician',
  },
  '2083P0500X': {
    code: '2083P0500X',
    displayName:
      'Preventive Medicine/Occupational Environmental Medicine Physician',
  },
  '2083P0901X': {
    code: '2083P0901X',
    displayName: 'Public Health & General Preventive Medicine Physician',
  },
  '2083S0010X': {
    code: '2083S0010X',
    displayName: 'Sports Medicine (Preventive Medicine) Physician',
  },
  '2083P0011X': {
    code: '2083P0011X',
    displayName:
      'Undersea and Hyperbaric Medicine (Preventive Medicine) Physician',
  },
  '2084A0401X': {
    code: '2084A0401X',
    displayName: 'Addiction Medicine (Psychiatry & Neurology) Physician',
  },
  '2084P0802X': {
    code: '2084P0802X',
    displayName: 'Addiction Psychiatry Physician',
  },
  '2084B0040X': {
    code: '2084B0040X',
    displayName: 'Behavioral Neurology & Neuropsychiatry Physician',
  },
  '2084P0301X': {
    code: '2084P0301X',
    displayName: 'Brain Injury Medicine (Psychiatry & Neurology) Physician',
  },
  '2084P0804X': {
    code: '2084P0804X',
    displayName: 'Child & Adolescent Psychiatry Physician',
  },
  '2084N0600X': {
    code: '2084N0600X',
    displayName: 'Clinical Neurophysiology Physician',
  },
  '2084D0003X': {
    code: '2084D0003X',
    displayName: 'Diagnostic Neuroimaging (Psychiatry & Neurology) Physician',
  },
  '2084E0001X': {
    code: '2084E0001X',
    displayName: 'Epilepsy Physician',
  },
  '2084F0202X': {
    code: '2084F0202X',
    displayName: 'Forensic Psychiatry Physician',
  },
  '2084P0805X': {
    code: '2084P0805X',
    displayName: 'Geriatric Psychiatry Physician',
  },
  '2084H0002X': {
    code: '2084H0002X',
    displayName:
      'Hospice and Palliative Medicine (Psychiatry & Neurology) Physician',
  },
  '2084A2900X': {
    code: '2084A2900X',
    displayName: 'Neurocritical Care Physician',
  },
  '2084P0005X': {
    code: '2084P0005X',
    displayName: 'Neurodevelopmental Disabilities Physician',
  },
  '2084N0400X': {
    code: '2084N0400X',
    displayName: 'Neurology Physician',
  },
  '2084N0402X': {
    code: '2084N0402X',
    displayName:
      'Neurology with Special Qualifications in Child Neurology Physician',
  },
  '2084N0008X': {
    code: '2084N0008X',
    displayName: 'Neuromuscular Medicine (Psychiatry & Neurology) Physician',
  },
  '2084B0002X': {
    code: '2084B0002X',
    displayName: 'Obesity Medicine (Psychiatry & Neurology) Physician',
  },
  '2084P2900X': {
    code: '2084P2900X',
    displayName: 'Pain Medicine (Psychiatry & Neurology) Physician',
  },
  '2084P0800X': {
    code: '2084P0800X',
    displayName: 'Psychiatry Physician',
  },
  '2084P0015X': {
    code: '2084P0015X',
    displayName: 'Psychosomatic Medicine Physician',
  },
  '2084S0012X': {
    code: '2084S0012X',
    displayName: 'Sleep Medicine (Psychiatry & Neurology) Physician',
  },
  '2084S0010X': {
    code: '2084S0010X',
    displayName: 'Sports Medicine (Psychiatry & Neurology) Physician',
  },
  '2084V0102X': {
    code: '2084V0102X',
    displayName: 'Vascular Neurology Physician',
  },
  '2085B0100X': {
    code: '2085B0100X',
    displayName: 'Body Imaging Physician',
  },
  '2085D0003X': {
    code: '2085D0003X',
    displayName: 'Diagnostic Neuroimaging (Radiology) Physician',
  },
  '2085R0202X': {
    code: '2085R0202X',
    displayName: 'Diagnostic Radiology Physician',
  },
  '2085U0001X': {
    code: '2085U0001X',
    displayName: 'Diagnostic Ultrasound Physician',
  },
  '2085H0002X': {
    code: '2085H0002X',
    displayName: 'Hospice and Palliative Medicine (Radiology) Physician',
  },
  '2085N0700X': {
    code: '2085N0700X',
    displayName: 'Neuroradiology Physician',
  },
  '2085N0904X': {
    code: '2085N0904X',
    displayName: 'Nuclear Radiology Physician',
  },
  '2085P0229X': {
    code: '2085P0229X',
    displayName: 'Pediatric Radiology Physician',
  },
  '2085R0001X': {
    code: '2085R0001X',
    displayName: 'Radiation Oncology Physician',
  },
  '2085R0205X': {
    code: '2085R0205X',
    displayName: 'Radiological Physics Physician',
  },
  '2085R0203X': {
    code: '2085R0203X',
    displayName: 'Therapeutic Radiology Physician',
  },
  '2085R0204X': {
    code: '2085R0204X',
    displayName: 'Vascular & Interventional Radiology Physician',
  },
  '208600000X': {
    code: '208600000X',
    displayName: 'Surgery Physician',
  },
  '2086H0002X': {
    code: '2086H0002X',
    displayName: 'Hospice and Palliative Medicine (Surgery) Physician',
  },
  '2086S0120X': {
    code: '2086S0120X',
    displayName: 'Pediatric Surgery Physician',
  },
  '2086S0122X': {
    code: '2086S0122X',
    displayName: 'Plastic and Reconstructive Surgery Physician',
  },
  '2086S0105X': {
    code: '2086S0105X',
    displayName: 'Surgery of the Hand (Surgery) Physician',
  },
  '2086S0102X': {
    code: '2086S0102X',
    displayName: 'Surgical Critical Care Physician',
  },
  '2086X0206X': {
    code: '2086X0206X',
    displayName: 'Surgical Oncology Physician',
  },
  '2086S0127X': {
    code: '2086S0127X',
    displayName: 'Trauma Surgery Physician',
  },
  '2086S0129X': {
    code: '2086S0129X',
    displayName: 'Vascular Surgery Physician',
  },
  '208G00000X': {
    code: '208G00000X',
    displayName: 'Thoracic Surgery (Cardiothoracic Vascular Surgery) Physician',
  },
  '204F00000X': {
    code: '204F00000X',
    displayName: 'Transplant Surgery Physician',
  },
  '208800000X': {
    code: '208800000X',
    displayName: 'Urology Physician',
  },
  '2088F0040X': {
    code: '2088F0040X',
    displayName:
      'Female Pelvic Medicine and Reconstructive Surgery (Urology) Physician',
  },
  '2088P0231X': {
    code: '2088P0231X',
    displayName: 'Pediatric Urology Physician',
  },
  '106E00000X': {
    code: '106E00000X',
    displayName: 'Assistant Behavior Analyst',
  },
  '106S00000X': {
    code: '106S00000X',
    displayName: 'Behavior Technician',
  },
  '103K00000X': {
    code: '103K00000X',
    displayName: 'Behavioral Analyst',
  },
  '103G00000X': {
    code: '103G00000X',
    displayName: 'Clinical Neuropsychologist',
  },
  '103GC0700X': {
    code: '103GC0700X',
    displayName: 'Deactivated - Clinical Neuropsychologist',
  },
  '101Y00000X': {
    code: '101Y00000X',
    displayName: 'Counselor',
  },
  '101YA0400X': {
    code: '101YA0400X',
    displayName: 'Addiction (Substance Use Disorder) Counselor',
  },
  '101YM0800X': {
    code: '101YM0800X',
    displayName: 'Mental Health Counselor',
  },
  '101YP1600X': {
    code: '101YP1600X',
    displayName: 'Pastoral Counselor',
  },
  '101YP2500X': {
    code: '101YP2500X',
    displayName: 'Professional Counselor',
  },
  '101YS0200X': {
    code: '101YS0200X',
    displayName: 'School Counselor',
  },
  '101200000X': {
    code: '101200000X',
    displayName: 'Drama Therapist',
  },
  '106H00000X': {
    code: '106H00000X',
    displayName: 'Marriage & Family Therapist',
  },
  '102X00000X': {
    code: '102X00000X',
    displayName: 'Poetry Therapist',
  },
  '102L00000X': {
    code: '102L00000X',
    displayName: 'Psychoanalyst',
  },
  '103T00000X': {
    code: '103T00000X',
    displayName: 'Psychologist',
  },
  '103TA0400X': {
    code: '103TA0400X',
    displayName: 'Addiction (Substance Use Disorder) Psychologist ',
  },
  '103TA0700X': {
    code: '103TA0700X',
    displayName: 'Adult Development & Aging Psychologist',
  },
  '103TC0700X': {
    code: '103TC0700X',
    displayName: 'Clinical Psychologist',
  },
  '103TC2200X': {
    code: '103TC2200X',
    displayName: 'Clinical Child & Adolescent Psychologist',
  },
  '103TB0200X': {
    code: '103TB0200X',
    displayName: 'Cognitive & Behavioral Psychologist',
  },
  '103TC1900X': {
    code: '103TC1900X',
    displayName: 'Counseling Psychologist',
  },
  '103TE1000X': {
    code: '103TE1000X',
    displayName: 'Deactivated - Psychologist',
  },
  '103TE1100X': {
    code: '103TE1100X',
    displayName: 'Exercise & Sports Psychologist',
  },
  '103TF0000X': {
    code: '103TF0000X',
    displayName: 'Family Psychologist',
  },
  '103TF0200X': {
    code: '103TF0200X',
    displayName: 'Forensic Psychologist',
  },
  '103TP2701X': {
    code: '103TP2701X',
    displayName: 'Group Psychotherapy Psychologist',
  },
  '103TH0004X': {
    code: '103TH0004X',
    displayName: 'Health Psychologist',
  },
  '103TH0100X': {
    code: '103TH0100X',
    displayName: 'Health Service Psychologist',
  },
  '103TM1700X': {
    code: '103TM1700X',
    displayName: 'Deactivated - Psychologist Men & Masculinity',
  },
  '103TM1800X': {
    code: '103TM1800X',
    displayName: 'Intellectual & Developmental Disabilities Psychologist',
  },
  '103TP0016X': {
    code: '103TP0016X',
    displayName: 'Prescribing (Medical) Psychologist',
  },
  '103TP0814X': {
    code: '103TP0814X',
    displayName: 'Psychoanalysis Psychologist',
  },
  '103TP2700X': {
    code: '103TP2700X',
    displayName: 'Deactivated - Psychologist Psychotherapy',
  },
  '103TR0400X': {
    code: '103TR0400X',
    displayName: 'Rehabilitation Psychologist',
  },
  '103TS0200X': {
    code: '103TS0200X',
    displayName: 'School Psychologist',
  },
  '103TW0100X': {
    code: '103TW0100X',
    displayName: 'Deactivated - Psychotherapy Women',
  },
  '104100000X': {
    code: '104100000X',
    displayName: 'Social Worker',
  },
  '1041C0700X': {
    code: '1041C0700X',
    displayName: 'Clinical Social Worker',
  },
  '1041S0200X': {
    code: '1041S0200X',
    displayName: 'School Social Worker',
  },
  '111N00000X': {
    code: '111N00000X',
    displayName: 'Chiropractor',
  },
  '111NI0013X': {
    code: '111NI0013X',
    displayName: 'Independent Medical Examiner Chiropractor',
  },
  '111NI0900X': {
    code: '111NI0900X',
    displayName: 'Internist Chiropractor',
  },
  '111NN0400X': {
    code: '111NN0400X',
    displayName: 'Neurology Chiropractor',
  },
  '111NN1001X': {
    code: '111NN1001X',
    displayName: 'Nutrition Chiropractor',
  },
  '111NX0100X': {
    code: '111NX0100X',
    displayName: 'Occupational Health Chiropractor',
  },
  '111NX0800X': {
    code: '111NX0800X',
    displayName: 'Orthopedic Chiropractor',
  },
  '111NP0017X': {
    code: '111NP0017X',
    displayName: 'Pediatric Chiropractor',
  },
  '111NR0200X': {
    code: '111NR0200X',
    displayName: 'Radiology Chiropractor',
  },
  '111NR0400X': {
    code: '111NR0400X',
    displayName: 'Rehabilitation Chiropractor',
  },
  '111NS0005X': {
    code: '111NS0005X',
    displayName: 'Sports Physician Chiropractor',
  },
  '111NT0100X': {
    code: '111NT0100X',
    displayName: 'Thermography Chiropractor',
  },
  '125K00000X': {
    code: '125K00000X',
    displayName: 'Advanced Practice Dental Therapist',
  },
  '126800000X': {
    code: '126800000X',
    displayName: 'Dental Assistant',
  },
  '124Q00000X': {
    code: '124Q00000X',
    displayName: 'Dental Hygienist',
  },
  '126900000X': {
    code: '126900000X',
    displayName: 'Dental Laboratory Technician',
  },
  '125J00000X': {
    code: '125J00000X',
    displayName: 'Dental Therapist',
  },
  '122300000X': {
    code: '122300000X',
    displayName: 'Dentist',
  },
  '1223D0001X': {
    code: '1223D0001X',
    displayName: 'Public Health Dentist',
  },
  '1223D0004X': {
    code: '1223D0004X',
    displayName: 'Dentist Anesthesiologist',
  },
  '1223E0200X': {
    code: '1223E0200X',
    displayName: 'Endodontist',
  },
  '1223G0001X': {
    code: '1223G0001X',
    displayName: 'General Practice Dentistry',
  },
  '1223P0106X': {
    code: '1223P0106X',
    displayName: 'Oral and Maxillofacial Pathology Dentist',
  },
  '1223X0008X': {
    code: '1223X0008X',
    displayName: 'Oral and Maxillofacial Radiology Dentist',
  },
  '1223S0112X': {
    code: '1223S0112X',
    displayName: 'Oral and Maxillofacial Surgery (Dentist)',
  },
  '1223X2210X': {
    code: '1223X2210X',
    displayName: 'Orofacial Pain Dentist',
  },
  '1223X0400X': {
    code: '1223X0400X',
    displayName: 'Orthodontics and Dentofacial Orthopedic Dentist',
  },
  '1223P0221X': {
    code: '1223P0221X',
    displayName: 'Pediatric Dentist',
  },
  '1223P0300X': {
    code: '1223P0300X',
    displayName: 'Periodontist',
  },
  '1223P0700X': {
    code: '1223P0700X',
    displayName: 'Prosthodontist',
  },
  '122400000X': {
    code: '122400000X',
    displayName: 'Denturist',
  },
  '125Q00000X': {
    code: '125Q00000X',
    displayName: 'Oral Medicinist',
  },
  '132700000X': {
    code: '132700000X',
    displayName: 'Dietary Manager',
  },
  '136A00000X': {
    code: '136A00000X',
    displayName: 'Registered Dietetic Technician',
  },
  '133V00000X': {
    code: '133V00000X',
    displayName: 'Registered Dietitian',
  },
  '133VN1101X': {
    code: '133VN1101X',
    displayName: 'Gerontological Nutrition Registered Dietitian',
  },
  '133VN1006X': {
    code: '133VN1006X',
    displayName: 'Metabolic Nutrition Registered Dietitian',
  },
  '133VN1201X': {
    code: '133VN1201X',
    displayName: 'Obesity and Weight Management Nutrition Registered Dietitian',
  },
  '133VN1301X': {
    code: '133VN1301X',
    displayName: 'Oncology Nutrition Registered Dietitian',
  },
  '133VN1004X': {
    code: '133VN1004X',
    displayName: 'Pediatric Nutrition Registered Dietitian',
  },
  '133VN1401X': {
    code: '133VN1401X',
    displayName: 'Pediatric Critical Care Nutrition Registered Dietitian',
  },
  '133VN1005X': {
    code: '133VN1005X',
    displayName: 'Renal Nutrition Registered Dietitian',
  },
  '133VN1501X': {
    code: '133VN1501X',
    displayName: 'Sports Dietetics Nutrition Registered Dietitian',
  },
  '133N00000X': {
    code: '133N00000X',
    displayName: 'Nutritionist',
  },
  '133NN1002X': {
    code: '133NN1002X',
    displayName: 'Nutrition Education Nutritionist',
  },
  '146N00000X': {
    code: '146N00000X',
    displayName: 'Basic Emergency Medical Technician',
  },
  '146M00000X': {
    code: '146M00000X',
    displayName: 'Intermediate Emergency Medical Technician',
  },
  '146L00000X': {
    code: '146L00000X',
    displayName: 'Paramedic',
  },
  '146D00000X': {
    code: '146D00000X',
    displayName: 'Personal Emergency Response Attendant',
  },
  '152W00000X': {
    code: '152W00000X',
    displayName: 'Optometrist',
  },
  '152WC0802X': {
    code: '152WC0802X',
    displayName: 'Corneal and Contact Management Optometrist',
  },
  '152WL0500X': {
    code: '152WL0500X',
    displayName: 'Low Vision Rehabilitation Optometrist',
  },
  '152WX0102X': {
    code: '152WX0102X',
    displayName: 'Occupational Vision Optometrist',
  },
  '152WP0200X': {
    code: '152WP0200X',
    displayName: 'Pediatric Optometrist',
  },
  '152WS0006X': {
    code: '152WS0006X',
    displayName: 'Sports Vision Optometrist',
  },
  '152WV0400X': {
    code: '152WV0400X',
    displayName: 'Vision Therapy Optometrist',
  },
  '156F00000X': {
    code: '156F00000X',
    displayName: 'Technician/Technologist',
  },
  '156FC0800X': {
    code: '156FC0800X',
    displayName: 'Contact Lens Technician/Technologist',
  },
  '156FC0801X': {
    code: '156FC0801X',
    displayName: 'Contact Lens Fitter',
  },
  '156FX1700X': {
    code: '156FX1700X',
    displayName: 'Ocularist',
  },
  '156FX1100X': {
    code: '156FX1100X',
    displayName: 'Ophthalmic Technician/Technologist',
  },
  '156FX1101X': {
    code: '156FX1101X',
    displayName: 'Ophthalmic Assistant ',
  },
  '156FX1800X': {
    code: '156FX1800X',
    displayName: 'Optician',
  },
  '156FX1201X': {
    code: '156FX1201X',
    displayName: 'Optometric Assistant Technician',
  },
  '156FX1202X': {
    code: '156FX1202X',
    displayName: 'Optometric Technician',
  },
  '156FX1900X': {
    code: '156FX1900X',
    displayName: 'Orthoptist',
  },
  '164W00000X': {
    code: '164W00000X',
    displayName: 'Licensed Practical Nurse',
  },
  '167G00000X': {
    code: '167G00000X',
    displayName: 'Licensed Psychiatric Technician',
  },
  '164X00000X': {
    code: '164X00000X',
    displayName: 'Licensed Vocational Nurse',
  },
  '163W00000X': {
    code: '163W00000X',
    displayName: 'Registered Nurse',
  },
  '163WA0400X': {
    code: '163WA0400X',
    displayName: 'Addiction (Substance Use Disorder) Registered Nurse',
  },
  '163WA2000X': {
    code: '163WA2000X',
    displayName: 'Administrator Registered Nurse',
  },
  '163WP2201X': {
    code: '163WP2201X',
    displayName: 'Ambulatory Care Registered Nurse',
  },
  '163WC3500X': {
    code: '163WC3500X',
    displayName: 'Cardiac Rehabilitation Registered Nurse',
  },
  '163WC0400X': {
    code: '163WC0400X',
    displayName: 'Case Management Registered Nurse',
  },
  '163WC1400X': {
    code: '163WC1400X',
    displayName: 'College Health Registered Nurse',
  },
  '163WC1500X': {
    code: '163WC1500X',
    displayName: 'Community Health Registered Nurse',
  },
  '163WC2100X': {
    code: '163WC2100X',
    displayName: 'Continence Care Registered Nurse',
  },
  '163WC1600X': {
    code: '163WC1600X',
    displayName: 'Continuing Education/Staff Development Registered Nurse',
  },
  '163WC0200X': {
    code: '163WC0200X',
    displayName: 'Critical Care Medicine Registered Nurse',
  },
  '163WD0400X': {
    code: '163WD0400X',
    displayName: 'Diabetes Educator Registered Nurse',
  },
  '163WD1100X': {
    code: '163WD1100X',
    displayName: 'Peritoneal Dialysis Registered Nurse',
  },
  '163WE0003X': {
    code: '163WE0003X',
    displayName: 'Emergency Registered Nurse',
  },
  '163WE0900X': {
    code: '163WE0900X',
    displayName: 'Enterostomal Therapy Registered Nurse',
  },
  '163WF0300X': {
    code: '163WF0300X',
    displayName: 'Flight Registered Nurse',
  },
  '163WG0100X': {
    code: '163WG0100X',
    displayName: 'Gastroenterology Registered Nurse',
  },
  '163WG0000X': {
    code: '163WG0000X',
    displayName: 'General Practice Registered Nurse',
  },
  '163WG0600X': {
    code: '163WG0600X',
    displayName: 'Gerontology Registered Nurse',
  },
  '163WH0500X': {
    code: '163WH0500X',
    displayName: 'Hemodialysis Registered Nurse',
  },
  '163WH0200X': {
    code: '163WH0200X',
    displayName: 'Home Health Registered Nurse',
  },
  '163WH1000X': {
    code: '163WH1000X',
    displayName: 'Hospice Registered Nurse',
  },
  '163WI0600X': {
    code: '163WI0600X',
    displayName: 'Infection Control Registered Nurse',
  },
  '163WI0500X': {
    code: '163WI0500X',
    displayName: 'Infusion Therapy Registered Nurse',
  },
  '163WL0100X': {
    code: '163WL0100X',
    displayName: 'Lactation Consultant (Registered Nurse)',
  },
  '163WM0102X': {
    code: '163WM0102X',
    displayName: 'Maternal Newborn Registered Nurse',
  },
  '163WM0705X': {
    code: '163WM0705X',
    displayName: 'Medical-Surgical Registered Nurse',
  },
  '163WN0002X': {
    code: '163WN0002X',
    displayName: 'Neonatal Intensive Care Registered Nurse',
  },
  '163WN0003X': {
    code: '163WN0003X',
    displayName: 'Low-Risk Neonatal Registered Nurse',
  },
  '163WN0300X': {
    code: '163WN0300X',
    displayName: 'Nephrology Registered Nurse',
  },
  '163WN0800X': {
    code: '163WN0800X',
    displayName: 'Neuroscience Registered Nurse',
  },
  '163WM1400X': {
    code: '163WM1400X',
    displayName: 'Nurse Massage Therapist (NMT)',
  },
  '163WN1003X': {
    code: '163WN1003X',
    displayName: 'Nutrition Support Registered Nurse',
  },
  '163WX0002X': {
    code: '163WX0002X',
    displayName: 'High-Risk Obstetric Registered Nurse',
  },
  '163WX0003X': {
    code: '163WX0003X',
    displayName: 'Inpatient Obstetric Registered Nurse',
  },
  '163WX0106X': {
    code: '163WX0106X',
    displayName: 'Occupational Health Registered Nurse',
  },
  '163WX0200X': {
    code: '163WX0200X',
    displayName: 'Oncology Registered Nurse',
  },
  '163WX1100X': {
    code: '163WX1100X',
    displayName: 'Ophthalmic Registered Nurse',
  },
  '163WX0800X': {
    code: '163WX0800X',
    displayName: 'Orthopedic Registered Nurse',
  },
  '163WX1500X': {
    code: '163WX1500X',
    displayName: 'Ostomy Care Registered Nurse',
  },
  '163WX0601X': {
    code: '163WX0601X',
    displayName: 'Otorhinolaryngology & Head-Neck Registered Nurse',
  },
  '163WP0000X': {
    code: '163WP0000X',
    displayName: 'Pain Management Registered Nurse',
  },
  '163WP0218X': {
    code: '163WP0218X',
    displayName: 'Pediatric Oncology Registered Nurse',
  },
  '163WP0200X': {
    code: '163WP0200X',
    displayName: 'Pediatric Registered Nurse',
  },
  '163WP1700X': {
    code: '163WP1700X',
    displayName: 'Perinatal Registered Nurse',
  },
  '163WS0121X': {
    code: '163WS0121X',
    displayName: 'Plastic Surgery Registered Nurse',
  },
  '163WP0808X': {
    code: '163WP0808X',
    displayName: 'Psychiatric/Mental Health Registered Nurse',
  },
  '163WP0809X': {
    code: '163WP0809X',
    displayName: 'Adult Psychiatric/Mental Health Registered Nurse',
  },
  '163WP0807X': {
    code: '163WP0807X',
    displayName:
      'Child & Adolescent Psychiatric/Mental Health Registered Nurse',
  },
  '163WR0006X': {
    code: '163WR0006X',
    displayName: 'Registered Nurse First Assistant',
  },
  '163WR0400X': {
    code: '163WR0400X',
    displayName: 'Rehabilitation Registered Nurse',
  },
  '163WR1000X': {
    code: '163WR1000X',
    displayName: 'Reproductive Endocrinology/Infertility Registered Nurse',
  },
  '163WS0200X': {
    code: '163WS0200X',
    displayName: 'School Registered Nurse',
  },
  '163WU0100X': {
    code: '163WU0100X',
    displayName: 'Urology Registered Nurse',
  },
  '163WW0101X': {
    code: '163WW0101X',
    displayName: "Ambulatory Women's Health Care Registered Nurse",
  },
  '163WW0000X': {
    code: '163WW0000X',
    displayName: 'Wound Care Registered Nurse',
  },
  '372600000X': {
    code: '372600000X',
    displayName: 'Adult Companion',
  },
  '372500000X': {
    code: '372500000X',
    displayName: 'Chore Provider',
  },
  '373H00000X': {
    code: '373H00000X',
    displayName: 'Day Training/Habilitation Specialist',
  },
  '374J00000X': {
    code: '374J00000X',
    displayName: 'Doula',
  },
  '374U00000X': {
    code: '374U00000X',
    displayName: 'Home Health Aide',
  },
  '376J00000X': {
    code: '376J00000X',
    displayName: 'Homemaker',
  },
  '376K00000X': {
    code: '376K00000X',
    displayName: "Nurse's Aide",
  },
  '376G00000X': {
    code: '376G00000X',
    displayName: 'Nursing Home Administrator',
  },
  '374T00000X': {
    code: '374T00000X',
    displayName: 'Religious Nonmedical Nursing Personnel',
  },
  '374K00000X': {
    code: '374K00000X',
    displayName: 'Religious Nonmedical Practitioner',
  },
  '374700000X': {
    code: '374700000X',
    displayName: 'Technician',
  },
  '3747A0650X': {
    code: '3747A0650X',
    displayName: 'Attendant Care Provider',
  },
  '3747P1801X': {
    code: '3747P1801X',
    displayName: 'Personal Care Attendant',
  },
  '171100000X': {
    code: '171100000X',
    displayName: 'Acupuncturist',
  },
  '171M00000X': {
    code: '171M00000X',
    displayName: 'Case Manager/Care Coordinator',
  },
  '174V00000X': {
    code: '174V00000X',
    displayName: 'Clinical Ethicist',
  },
  '172V00000X': {
    code: '172V00000X',
    displayName: 'Community Health Worker',
  },
  '171W00000X': {
    code: '171W00000X',
    displayName: 'Contractor',
  },
  '171WH0202X': {
    code: '171WH0202X',
    displayName: 'Home Modifications Contractor',
  },
  '171WV0202X': {
    code: '171WV0202X',
    displayName: 'Vehicle Modifications Contractor',
  },
  '172A00000X': {
    code: '172A00000X',
    displayName: 'Driver',
  },
  '176P00000X': {
    code: '176P00000X',
    displayName: 'Funeral Director',
  },
  '170300000X': {
    code: '170300000X',
    displayName: 'Genetic Counselor (M.S.)',
  },
  '171400000X': {
    code: '171400000X',
    displayName: 'Health & Wellness Coach',
  },
  '174H00000X': {
    code: '174H00000X',
    displayName: 'Health Educator',
  },
  '175L00000X': {
    code: '175L00000X',
    displayName: 'Homeopath',
  },
  '171R00000X': {
    code: '171R00000X',
    displayName: 'Interpreter',
  },
  '174N00000X': {
    code: '174N00000X',
    displayName: 'Lactation Consultant (Non-RN)',
  },
  '175M00000X': {
    code: '175M00000X',
    displayName: 'Lay Midwife',
  },
  '173000000X': {
    code: '173000000X',
    displayName: 'Legal Medicine',
  },
  '172M00000X': {
    code: '172M00000X',
    displayName: 'Mechanotherapist',
  },
  '176B00000X': {
    code: '176B00000X',
    displayName: 'Midwife',
  },
  '171000000X': {
    code: '171000000X',
    displayName: 'Military Health Care Provider',
  },
  '1710I1002X': {
    code: '1710I1002X',
    displayName: 'Independent Duty Corpsman',
  },
  '1710I1003X': {
    code: '1710I1003X',
    displayName: 'Independent Duty Medical Technicians',
  },
  '172P00000X': {
    code: '172P00000X',
    displayName: 'Naprapath',
  },
  '175F00000X': {
    code: '175F00000X',
    displayName: 'Naturopath',
  },
  '175T00000X': {
    code: '175T00000X',
    displayName: 'Peer Specialist',
  },
  '170100000X': {
    code: '170100000X',
    displayName: 'Ph.D. Medical Genetics',
  },
  '405300000X': {
    code: '405300000X',
    displayName: 'Prevention Professional',
  },
  '173C00000X': {
    code: '173C00000X',
    displayName: 'Reflexologist',
  },
  '173F00000X': {
    code: '173F00000X',
    displayName: 'Sleep Specialist (PhD)',
  },
  '174400000X': {
    code: '174400000X',
    displayName: 'Specialist',
  },
  '1744G0900X': {
    code: '1744G0900X',
    displayName: 'Graphics Designer',
  },
  '1744P3200X': {
    code: '1744P3200X',
    displayName: ' Prosthetics Case Management',
  },
  '1744R1103X': {
    code: '1744R1103X',
    displayName: 'Research Study Abstracter/Coder',
  },
  '1744R1102X': {
    code: '1744R1102X',
    displayName: 'Research Study Specialist',
  },
  '174M00000X': {
    code: '174M00000X',
    displayName: 'Veterinarian',
  },
  '174MM1900X': {
    code: '174MM1900X',
    displayName: 'Medical Research Veterinarian',
  },
  '183500000X': {
    code: '183500000X',
    displayName: 'Pharmacist',
  },
  '1835P2201X': {
    code: '1835P2201X',
    displayName: 'Ambulatory Care Pharmacist',
  },
  '1835C0206X': {
    code: '1835C0206X',
    displayName: 'Cardiology Pharmacist',
  },
  '1835C0207X': {
    code: '1835C0207X',
    displayName: 'Compounded Sterile Preparations Pharmacist',
  },
  '1835C0205X': {
    code: '1835C0205X',
    displayName: 'Critical Care Pharmacist',
  },
  '1835E0208X': {
    code: '1835E0208X',
    displayName: 'Emergency Medicine Pharmacist',
  },
  '1835G0000X': {
    code: '1835G0000X',
    displayName: 'Deactivated - Pharmacist',
  },
  '1835G0303X': {
    code: '1835G0303X',
    displayName: 'Geriatric Pharmacist',
  },
  '1835I0206X': {
    code: '1835I0206X',
    displayName: 'Infectious Diseases Pharmacist',
  },
  '1835N0905X': {
    code: '1835N0905X',
    displayName: 'Nuclear Pharmacist',
  },
  '1835N1003X': {
    code: '1835N1003X',
    displayName: 'Nutrition Support Pharmacist',
  },
  '1835X0200X': {
    code: '1835X0200X',
    displayName: 'Oncology Pharmacist',
  },
  '1835P0200X': {
    code: '1835P0200X',
    displayName: 'Pediatric Pharmacist',
  },
  '1835P0018X': {
    code: '1835P0018X',
    displayName: 'Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist',
  },
  '1835P1200X': {
    code: '1835P1200X',
    displayName: 'Pharmacotherapy Pharmacist',
  },
  '1835P1300X': {
    code: '1835P1300X',
    displayName: 'Psychiatric Pharmacist',
  },
  '1835S0206X': {
    code: '1835S0206X',
    displayName: 'Solid Organ Transplant Pharmacist',
  },
  '183700000X': {
    code: '183700000X',
    displayName: 'Pharmacy Technician',
  },
  '367A00000X': {
    code: '367A00000X',
    displayName: 'Advanced Practice Midwife',
  },
  '367H00000X': {
    code: '367H00000X',
    displayName: 'Anesthesiologist Assistant',
  },
  '364S00000X': {
    code: '364S00000X',
    displayName: 'Clinical Nurse Specialist',
  },
  '364SA2100X': {
    code: '364SA2100X',
    displayName: 'Acute Care Clinical Nurse Specialist',
  },
  '364SA2200X': {
    code: '364SA2200X',
    displayName: 'Adult Health Clinical Nurse Specialist',
  },
  '364SC2300X': {
    code: '364SC2300X',
    displayName: 'Chronic Care Clinical Nurse Specialist',
  },
  '364SC1501X': {
    code: '364SC1501X',
    displayName: 'Community Health/Public Health Clinical Nurse Specialist',
  },
  '364SC0200X': {
    code: '364SC0200X',
    displayName: 'Critical Care Medicine Clinical Nurse Specialist',
  },
  '364SE0003X': {
    code: '364SE0003X',
    displayName: 'Emergency Clinical Nurse Specialist',
  },
  '364SE1400X': {
    code: '364SE1400X',
    displayName: 'Ethics Clinical Nurse Specialist',
  },
  '364SF0001X': {
    code: '364SF0001X',
    displayName: 'Family Health Clinical Nurse Specialist',
  },
  '364SG0600X': {
    code: '364SG0600X',
    displayName: 'Gerontology Clinical Nurse Specialist',
  },
  '364SH1100X': {
    code: '364SH1100X',
    displayName: 'Holistic Clinical Nurse Specialist',
  },
  '364SH0200X': {
    code: '364SH0200X',
    displayName: 'Home Health Clinical Nurse Specialist',
  },
  '364SI0800X': {
    code: '364SI0800X',
    displayName: 'Informatics Clinical Nurse Specialist',
  },
  '364SL0600X': {
    code: '364SL0600X',
    displayName: 'Long-Term Care Clinical Nurse Specialist',
  },
  '364SM0705X': {
    code: '364SM0705X',
    displayName: 'Medical-Surgical Clinical Nurse Specialist',
  },
  '364SN0000X': {
    code: '364SN0000X',
    displayName: 'Neonatal Clinical Nurse Specialist',
  },
  '364SN0800X': {
    code: '364SN0800X',
    displayName: 'Neuroscience Clinical Nurse Specialist',
  },
  '364SX0106X': {
    code: '364SX0106X',
    displayName: 'Occupational Health Clinical Nurse Specialist',
  },
  '364SX0200X': {
    code: '364SX0200X',
    displayName: 'Oncology Clinical Nurse Specialist',
  },
  '364SX0204X': {
    code: '364SX0204X',
    displayName: 'Pediatric Oncology Clinical Nurse Specialist',
  },
  '364SP0200X': {
    code: '364SP0200X',
    displayName: 'Pediatric Clinical Nurse Specialist',
  },
  '364SP1700X': {
    code: '364SP1700X',
    displayName: 'Perinatal Clinical Nurse Specialist',
  },
  '364SP2800X': {
    code: '364SP2800X',
    displayName: 'Perioperative Clinical Nurse Specialist',
  },
  '364SP0808X': {
    code: '364SP0808X',
    displayName: 'Psychiatric/Mental Health Clinical Nurse Specialist',
  },
  '364SP0809X': {
    code: '364SP0809X',
    displayName: 'Adult Psychiatric/Mental Health Clinical Nurse Specialist',
  },
  '364SP0807X': {
    code: '364SP0807X',
    displayName:
      'Child & Adolescent Psychiatric/Mental Health Clinical Nurse Specialist',
  },
  '364SP0810X': {
    code: '364SP0810X',
    displayName:
      'Child & Family Psychiatric/Mental Health Clinical Nurse Specialist',
  },
  '364SP0811X': {
    code: '364SP0811X',
    displayName:
      'Chronically Ill Psychiatric/Mental Health Clinical Nurse Specialist',
  },
  '364SP0812X': {
    code: '364SP0812X',
    displayName:
      'Community Psychiatric/Mental Health Clinical Nurse Specialist',
  },
  '364SP0813X': {
    code: '364SP0813X',
    displayName:
      'Geropsychiatric Psychiatric/Mental Health Clinical Nurse Specialist',
  },
  '364SR0400X': {
    code: '364SR0400X',
    displayName: 'Rehabilitation Clinical Nurse Specialist',
  },
  '364SS0200X': {
    code: '364SS0200X',
    displayName: 'School Clinical Nurse Specialist',
  },
  '364ST0500X': {
    code: '364ST0500X',
    displayName: 'Transplantation Clinical Nurse Specialist',
  },
  '364SW0102X': {
    code: '364SW0102X',
    displayName: "Women's Health Clinical Nurse Specialist",
  },
  '367500000X': {
    code: '367500000X',
    displayName: 'Certified Registered Nurse Anesthetist',
  },
  '363L00000X': {
    code: '363L00000X',
    displayName: 'Nurse Practitioner',
  },
  '363LA2100X': {
    code: '363LA2100X',
    displayName: 'Acute Care Nurse Practitioner',
  },
  '363LA2200X': {
    code: '363LA2200X',
    displayName: 'Adult Health Nurse Practitioner',
  },
  '363LC1500X': {
    code: '363LC1500X',
    displayName: 'Community Health Nurse Practitioner',
  },
  '363LC0200X': {
    code: '363LC0200X',
    displayName: 'Critical Care Medicine Nurse Practitioner',
  },
  '363LF0000X': {
    code: '363LF0000X',
    displayName: 'Family Nurse Practitioner',
  },
  '363LG0600X': {
    code: '363LG0600X',
    displayName: 'Gerontology Nurse Practitioner',
  },
  '363LN0000X': {
    code: '363LN0000X',
    displayName: 'Neonatal Nurse Practitioner',
  },
  '363LN0005X': {
    code: '363LN0005X',
    displayName: 'Critical Care Neonatal Nurse Practitioner',
  },
  '363LX0001X': {
    code: '363LX0001X',
    displayName: 'Obstetrics & Gynecology Nurse Practitioner',
  },
  '363LX0106X': {
    code: '363LX0106X',
    displayName: 'Occupational Health Nurse Practitioner',
  },
  '363LP0200X': {
    code: '363LP0200X',
    displayName: 'Pediatric Nurse Practitioner',
  },
  '363LP0222X': {
    code: '363LP0222X',
    displayName: 'Critical Care Pediatric Nurse Practitioner',
  },
  '363LP1700X': {
    code: '363LP1700X',
    displayName: 'Perinatal Nurse Practitioner',
  },
  '363LP2300X': {
    code: '363LP2300X',
    displayName: 'Primary Care Nurse Practitioner',
  },
  '363LP0808X': {
    code: '363LP0808X',
    displayName: 'Psychiatric/Mental Health Nurse Practitioner',
  },
  '363LS0200X': {
    code: '363LS0200X',
    displayName: 'School Nurse Practitioner',
  },
  '363LW0102X': {
    code: '363LW0102X',
    displayName: "Women's Health Nurse Practitioner",
  },
  '363A00000X': {
    code: '363A00000X',
    displayName: 'Physician Assistant',
  },
  '363AM0700X': {
    code: '363AM0700X',
    displayName: 'Medical Physician Assistant',
  },
  '363AS0400X': {
    code: '363AS0400X',
    displayName: 'Surgical Physician Assistant',
  },
  '211D00000X': {
    code: '211D00000X',
    displayName: 'Podiatric Assistant',
  },
  '213E00000X': {
    code: '213E00000X',
    displayName: 'Podiatrist',
  },
  '213ES0103X': {
    code: '213ES0103X',
    displayName: 'Foot & Ankle Surgery Podiatrist',
  },
  '213ES0131X': {
    code: '213ES0131X',
    displayName: 'Foot Surgery Podiatrist',
  },
  '213EG0000X': {
    code: '213EG0000X',
    displayName: 'Deactivated - Podiatrist',
  },
  '213EP1101X': {
    code: '213EP1101X',
    displayName: 'Primary Podiatric Medicine Podiatrist',
  },
  '213EP0504X': {
    code: '213EP0504X',
    displayName: 'Public Medicine Podiatrist',
  },
  '213ER0200X': {
    code: '213ER0200X',
    displayName: 'Radiology Podiatrist',
  },
  '213ES0000X': {
    code: '213ES0000X',
    displayName: 'Sports Medicine Podiatrist',
  },
  '229N00000X': {
    code: '229N00000X',
    displayName: 'Anaplastologist',
  },
  '221700000X': {
    code: '221700000X',
    displayName: 'Art Therapist',
  },
  '224Y00000X': {
    code: '224Y00000X',
    displayName: 'Clinical Exercise Physiologist',
  },
  '225600000X': {
    code: '225600000X',
    displayName: 'Dance Therapist',
  },
  '222Q00000X': {
    code: '222Q00000X',
    displayName: 'Developmental Therapist',
  },
  '226300000X': {
    code: '226300000X',
    displayName: 'Kinesiotherapist',
  },
  '225700000X': {
    code: '225700000X',
    displayName: 'Massage Therapist',
  },
  '224900000X': {
    code: '224900000X',
    displayName: 'Mastectomy Fitter',
  },
  '225A00000X': {
    code: '225A00000X',
    displayName: 'Music Therapist',
  },
  '225X00000X': {
    code: '225X00000X',
    displayName: 'Occupational Therapist',
  },
  '225XR0403X': {
    code: '225XR0403X',
    displayName: 'Driving and Community Mobility Occupational Therapist',
  },
  '225XE0001X': {
    code: '225XE0001X',
    displayName: 'Environmental Modification Occupational Therapist',
  },
  '225XE1200X': {
    code: '225XE1200X',
    displayName: 'Ergonomics Occupational Therapist',
  },
  '225XF0002X': {
    code: '225XF0002X',
    displayName: 'Feeding, Eating & Swallowing Occupational Therapist',
  },
  '225XG0600X': {
    code: '225XG0600X',
    displayName: 'Gerontology Occupational Therapist',
  },
  '225XH1200X': {
    code: '225XH1200X',
    displayName: 'Hand Occupational Therapist',
  },
  '225XH1300X': {
    code: '225XH1300X',
    displayName: 'Human Factors Occupational Therapist',
  },
  '225XL0004X': {
    code: '225XL0004X',
    displayName: 'Low Vision Occupational Therapist',
  },
  '225XM0800X': {
    code: '225XM0800X',
    displayName: 'Mental Health Occupational Therapist',
  },
  '225XN1300X': {
    code: '225XN1300X',
    displayName: 'Neurorehabilitation Occupational Therapist',
  },
  '225XP0200X': {
    code: '225XP0200X',
    displayName: 'Pediatric Occupational Therapist',
  },
  '225XP0019X': {
    code: '225XP0019X',
    displayName: 'Physical Rehabilitation Occupational Therapist',
  },
  '224Z00000X': {
    code: '224Z00000X',
    displayName: 'Occupational Therapy Assistant',
  },
  '224ZR0403X': {
    code: '224ZR0403X',
    displayName:
      'Driving and Community Mobility Occupational Therapy Assistant',
  },
  '224ZE0001X': {
    code: '224ZE0001X',
    displayName: 'Environmental Modification Occupational Therapy Assistant',
  },
  '224ZF0002X': {
    code: '224ZF0002X',
    displayName: 'Feeding, Eating & Swallowing Occupational Therapy Assistant',
  },
  '224ZL0004X': {
    code: '224ZL0004X',
    displayName: 'Low Vision Occupational Therapy Assistant',
  },
  '225000000X': {
    code: '225000000X',
    displayName: 'Orthotic Fitter',
  },
  '222Z00000X': {
    code: '222Z00000X',
    displayName: 'Orthotist',
  },
  '224L00000X': {
    code: '224L00000X',
    displayName: 'Pedorthist',
  },
  '225100000X': {
    code: '225100000X',
    displayName: 'Physical Therapist',
  },
  '2251C2600X': {
    code: '2251C2600X',
    displayName: 'Cardiopulmonary Physical Therapist',
  },
  '2251E1300X': {
    code: '2251E1300X',
    displayName: 'Clinical Electrophysiology Physical Therapist',
  },
  '2251E1200X': {
    code: '2251E1200X',
    displayName: 'Ergonomics Physical Therapist',
  },
  '2251G0304X': {
    code: '2251G0304X',
    displayName: 'Geriatric Physical Therapist',
  },
  '2251H1200X': {
    code: '2251H1200X',
    displayName: 'Hand Physical Therapist',
  },
  '2251H1300X': {
    code: '2251H1300X',
    displayName: 'Human Factors Physical Therapist',
  },
  '2251N0400X': {
    code: '2251N0400X',
    displayName: 'Neurology Physical Therapist',
  },
  '2251X0800X': {
    code: '2251X0800X',
    displayName: 'Orthopedic Physical Therapist',
  },
  '2251P0200X': {
    code: '2251P0200X',
    displayName: 'Pediatric Physical Therapist',
  },
  '2251S0007X': {
    code: '2251S0007X',
    displayName: 'Sports Physical Therapist',
  },
  '225200000X': {
    code: '225200000X',
    displayName: 'Physical Therapy Assistant',
  },
  '224P00000X': {
    code: '224P00000X',
    displayName: 'Prosthetist',
  },
  '225B00000X': {
    code: '225B00000X',
    displayName: 'Pulmonary Function Technologist',
  },
  '225800000X': {
    code: '225800000X',
    displayName: 'Recreation Therapist',
  },
  '226000000X': {
    code: '226000000X',
    displayName: 'Recreational Therapist Assistant',
  },
  '225C00000X': {
    code: '225C00000X',
    displayName: 'Rehabilitation Counselor',
  },
  '225CA2400X': {
    code: '225CA2400X',
    displayName: 'Assistive Technology Practitioner Rehabilitation Counselor',
  },
  '225CA2500X': {
    code: '225CA2500X',
    displayName: 'Assistive Technology Supplier Rehabilitation Counselor',
  },
  '225CX0006X': {
    code: '225CX0006X',
    displayName: 'Orientation and Mobility Training Rehabilitation Counselor',
  },
  '225400000X': {
    code: '225400000X',
    displayName: 'Rehabilitation Practitioner',
  },
  '227800000X': {
    code: '227800000X',
    displayName: 'Certified Respiratory Therapist',
  },
  '2278C0205X': {
    code: '2278C0205X',
    displayName: 'Critical Care Certified Respiratory Therapist',
  },
  '2278E1000X': {
    code: '2278E1000X',
    displayName: 'Educational Certified Respiratory Therapist',
  },
  '2278E0002X': {
    code: '2278E0002X',
    displayName: 'Emergency Care Certified Respiratory Therapist',
  },
  '2278G1100X': {
    code: '2278G1100X',
    displayName: 'General Care Certified Respiratory Therapist',
  },
  '2278G0305X': {
    code: '2278G0305X',
    displayName: 'Geriatric Care Certified Respiratory Therapist',
  },
  '2278H0200X': {
    code: '2278H0200X',
    displayName: 'Home Health Certified Respiratory Therapist',
  },
  '2278P3900X': {
    code: '2278P3900X',
    displayName: 'Neonatal/Pediatric Certified Respiratory Therapist',
  },
  '2278P3800X': {
    code: '2278P3800X',
    displayName: 'Palliative/Hospice Certified Respiratory Therapist',
  },
  '2278P4000X': {
    code: '2278P4000X',
    displayName: 'Patient Transport Certified Respiratory Therapist',
  },
  '2278P1004X': {
    code: '2278P1004X',
    displayName: 'Pulmonary Diagnostics Certified Respiratory Therapist',
  },
  '2278P1006X': {
    code: '2278P1006X',
    displayName:
      'Pulmonary Function Technologist Certified Respiratory Therapist',
  },
  '2278P1005X': {
    code: '2278P1005X',
    displayName: 'Pulmonary Rehabilitation Certified Respiratory Therapist',
  },
  '2278S1500X': {
    code: '2278S1500X',
    displayName: 'SNF/Subacute Care Certified Respiratory Therapist',
  },
  '227900000X': {
    code: '227900000X',
    displayName: 'Registered Respiratory Therapist',
  },
  '2279C0205X': {
    code: '2279C0205X',
    displayName: 'Critical Care Registered Respiratory Therapist',
  },
  '2279E1000X': {
    code: '2279E1000X',
    displayName: 'Educational Registered Respiratory Therapist',
  },
  '2279E0002X': {
    code: '2279E0002X',
    displayName: 'Emergency Care Registered Respiratory Therapist',
  },
  '2279G1100X': {
    code: '2279G1100X',
    displayName: 'General Care Registered Respiratory Therapist',
  },
  '2279G0305X': {
    code: '2279G0305X',
    displayName: 'Geriatric Care Registered Respiratory Therapist',
  },
  '2279H0200X': {
    code: '2279H0200X',
    displayName: 'Home Health Registered Respiratory Therapist',
  },
  '2279P3900X': {
    code: '2279P3900X',
    displayName: 'Neonatal/Pediatric Registered Respiratory Therapist',
  },
  '2279P3800X': {
    code: '2279P3800X',
    displayName: 'Palliative/Hospice Registered Respiratory Therapist',
  },
  '2279P4000X': {
    code: '2279P4000X',
    displayName: 'Patient Transport Registered Respiratory Therapist',
  },
  '2279P1004X': {
    code: '2279P1004X',
    displayName: 'Pulmonary Diagnostics Registered Respiratory Therapist',
  },
  '2279P1006X': {
    code: '2279P1006X',
    displayName:
      'Pulmonary Function Technologist Registered Respiratory Therapist',
  },
  '2279P1005X': {
    code: '2279P1005X',
    displayName: 'Pulmonary Rehabilitation Registered Respiratory Therapist',
  },
  '2279S1500X': {
    code: '2279S1500X',
    displayName: 'SNF/Subacute Care Registered Respiratory Therapist',
  },
  '225500000X': {
    code: '225500000X',
    displayName:
      'Respiratory/Developmental/Rehabilitative Specialist/Technologist',
  },
  '2255A2300X': {
    code: '2255A2300X',
    displayName: 'Athletic Trainer',
  },
  '2255R0406X': {
    code: '2255R0406X',
    displayName: 'Blind Rehabilitation Specialist/Technologist',
  },
  '231H00000X': {
    code: '231H00000X',
    displayName: 'Audiologist',
  },
  '231HA2400X': {
    code: '231HA2400X',
    displayName: 'Assistive Technology Practitioner Audiologist',
  },
  '231HA2500X': {
    code: '231HA2500X',
    displayName: 'Assistive Technology Supplier Audiologist',
  },
  '237600000X': {
    code: '237600000X',
    displayName: 'Audiologist-Hearing Aid Fitter',
  },
  '237700000X': {
    code: '237700000X',
    displayName: 'Hearing Instrument Specialist',
  },
  '235500000X': {
    code: '235500000X',
    displayName: 'Speech/Language/Hearing Specialist/Technologist',
  },
  '2355A2700X': {
    code: '2355A2700X',
    displayName: 'Audiology Assistant',
  },
  '2355S0801X': {
    code: '2355S0801X',
    displayName: 'Speech-Language Assistant',
  },
  '235Z00000X': {
    code: '235Z00000X',
    displayName: 'Speech-Language Pathologist',
  },
  '390200000X': {
    code: '390200000X',
    displayName:
      'Student in an Organized Health Care Education/Training Program',
  },
  '242T00000X': {
    code: '242T00000X',
    displayName: 'Perfusionist',
  },
  '247100000X': {
    code: '247100000X',
    displayName: 'Radiologic Technologist',
  },
  '2471B0102X': {
    code: '2471B0102X',
    displayName: 'Bone Densitometry Radiologic Technologist',
  },
  '2471C1106X': {
    code: '2471C1106X',
    displayName: 'Cardiac-Interventional Technology Radiologic Technologist',
  },
  '2471C1101X': {
    code: '2471C1101X',
    displayName:
      'Cardiovascular-Interventional Technology Radiologic Technologist',
  },
  '2471C3401X': {
    code: '2471C3401X',
    displayName: 'Computed Tomography Radiologic Technologist',
  },
  '2471M1202X': {
    code: '2471M1202X',
    displayName: 'Magnetic Resonance Imaging Radiologic Technologist',
  },
  '2471M2300X': {
    code: '2471M2300X',
    displayName: 'Mammography Radiologic Technologist',
  },
  '2471N0900X': {
    code: '2471N0900X',
    displayName: 'Nuclear Medicine Technology Radiologic Technologist',
  },
  '2471Q0001X': {
    code: '2471Q0001X',
    displayName: 'Quality Management Radiologic Technologist',
  },
  '2471R0002X': {
    code: '2471R0002X',
    displayName: 'Radiation Therapy Radiologic Technologist',
  },
  '2471C3402X': {
    code: '2471C3402X',
    displayName: 'Radiography Radiologic Technologist',
  },
  '2471S1302X': {
    code: '2471S1302X',
    displayName: 'Sonography Radiologic Technologist',
  },
  '2471V0105X': {
    code: '2471V0105X',
    displayName: 'Vascular Sonography Radiologic Technologist',
  },
  '2471V0106X': {
    code: '2471V0106X',
    displayName: 'Vascular-Interventional Technology Radiologic Technologist',
  },
  '243U00000X': {
    code: '243U00000X',
    displayName: 'Radiology Practitioner Assistant',
  },
  '246X00000X': {
    code: '246X00000X',
    displayName: 'Cardiovascular Specialist/Technologist',
  },
  '246XC2901X': {
    code: '246XC2901X',
    displayName: 'Cardiovascular Invasive Specialist/Technologist',
  },
  '246XS1301X': {
    code: '246XS1301X',
    displayName: 'Sonography Specialist/Technologist',
  },
  '246XC2903X': {
    code: '246XC2903X',
    displayName: 'Vascular Specialist/Technologist',
  },
  '246Y00000X': {
    code: '246Y00000X',
    displayName: 'Health Information Specialist/Technologist',
  },
  '246YC3301X': {
    code: '246YC3301X',
    displayName: 'Hospital Based Coding Specialist',
  },
  '246YC3302X': {
    code: '246YC3302X',
    displayName: 'Physician Office Based Coding Specialist',
  },
  '246YR1600X': {
    code: '246YR1600X',
    displayName: ' Registered Record Administrator',
  },
  '246Z00000X': {
    code: '246Z00000X',
    displayName: 'Other Specialist/Technologist',
  },
  '246ZA2600X': {
    code: '246ZA2600X',
    displayName: 'Medical Art Specialist/Technologist',
  },
  '246ZB0500X': {
    code: '246ZB0500X',
    displayName: 'Biochemist',
  },
  '246ZB0301X': {
    code: '246ZB0301X',
    displayName: 'Biomedical Engineer',
  },
  '246ZB0302X': {
    code: '246ZB0302X',
    displayName: 'Biomedical Photographer',
  },
  '246ZB0600X': {
    code: '246ZB0600X',
    displayName: 'Biostatiscian',
  },
  '246ZE0500X': {
    code: '246ZE0500X',
    displayName: 'EEG Specialist/Technologist',
  },
  '246ZE0600X': {
    code: '246ZE0600X',
    displayName: 'Electroneurodiagnostic Specialist/Technologist',
  },
  '246ZG1000X': {
    code: '246ZG1000X',
    displayName: 'Medical Geneticist (PhD) Specialist/Technologist',
  },
  '246ZG0701X': {
    code: '246ZG0701X',
    displayName: 'Graphics Methods Specialist/Technologist',
  },
  '246ZI1000X': {
    code: '246ZI1000X',
    displayName: 'Medical Illustrator',
  },
  '246ZN0300X': {
    code: '246ZN0300X',
    displayName: 'Nephrology Specialist/Technologist',
  },
  '246ZX2200X': {
    code: '246ZX2200X',
    displayName: 'Orthopedic Assistant',
  },
  '246ZC0007X': {
    code: '246ZC0007X',
    displayName: 'Surgical Assistant',
  },
  '246ZS0410X': {
    code: '246ZS0410X',
    displayName: 'Surgical Technologist',
  },
  '246Q00000X': {
    code: '246Q00000X',
    displayName: 'Pathology Specialist/Technologist',
  },
  '246QB0000X': {
    code: '246QB0000X',
    displayName: 'Blood Banking Specialist/Technologist',
  },
  '246QC1000X': {
    code: '246QC1000X',
    displayName: 'Chemistry Pathology Specialist/Technologist',
  },
  '246QC2700X': {
    code: '246QC2700X',
    displayName: 'Cytotechnology Specialist/Technologist',
  },
  '246QH0401X': {
    code: '246QH0401X',
    displayName: 'Hemapheresis Practitioner',
  },
  '246QH0000X': {
    code: '246QH0000X',
    displayName: 'Hematology Specialist/Technologist',
  },
  '246QH0600X': {
    code: '246QH0600X',
    displayName: 'Histology Specialist/Technologist',
  },
  '246QI0000X': {
    code: '246QI0000X',
    displayName: 'Immunology Pathology Specialist/Technologist',
  },
  '246QL0900X': {
    code: '246QL0900X',
    displayName: 'Laboratory Management Specialist/Technologist',
  },
  '246QL0901X': {
    code: '246QL0901X',
    displayName: 'Diplomate Laboratory Management Specialist/Technologist',
  },
  '246QM0706X': {
    code: '246QM0706X',
    displayName: 'Medical Technologist',
  },
  '246QM0900X': {
    code: '246QM0900X',
    displayName: 'Microbiology Specialist/Technologist',
  },
  '246W00000X': {
    code: '246W00000X',
    displayName: 'Cardiology Technician',
  },
  '247000000X': {
    code: '247000000X',
    displayName: 'Health Information Technician',
  },
  '2470A2800X': {
    code: '2470A2800X',
    displayName: 'Assistant Health Information Record Technician',
  },
  '247200000X': {
    code: '247200000X',
    displayName: 'Other Technician',
  },
  '2472B0301X': {
    code: '2472B0301X',
    displayName: 'Biomedical Engineering Technician',
  },
  '2472D0500X': {
    code: '2472D0500X',
    displayName: 'Darkroom Technician',
  },
  '2472E0500X': {
    code: '2472E0500X',
    displayName: 'EEG Technician',
  },
  '2472R0900X': {
    code: '2472R0900X',
    displayName: 'Renal Dialysis Technician',
  },
  '2472V0600X': {
    code: '2472V0600X',
    displayName: 'Veterinary Technician',
  },
  '246R00000X': {
    code: '246R00000X',
    displayName: 'Pathology Technician',
  },
  '247ZC0005X': {
    code: '247ZC0005X',
    displayName: 'Clinical Laboratory Director (Non-physician)',
  },
  '246RH0600X': {
    code: '246RH0600X',
    displayName: 'Histology Technician',
  },
  '246RM2200X': {
    code: '246RM2200X',
    displayName: 'Medical Laboratory Technician',
  },
  '246RP1900X': {
    code: '246RP1900X',
    displayName: 'Phlebotomy Technician',
  },
  '251B00000X': {
    code: '251B00000X',
    displayName: 'Case Management Agency',
  },
  '251S00000X': {
    code: '251S00000X',
    displayName: 'Community/Behavioral Health Agency',
  },
  '251C00000X': {
    code: '251C00000X',
    displayName: 'Developmentally Disabled Services Day Training Agency',
  },
  '252Y00000X': {
    code: '252Y00000X',
    displayName: 'Early Intervention Provider Agency',
  },
  '253J00000X': {
    code: '253J00000X',
    displayName: 'Foster Care Agency',
  },
  '251E00000X': {
    code: '251E00000X',
    displayName: 'Home Health Agency',
  },
  '251F00000X': {
    code: '251F00000X',
    displayName: 'Home Infusion Agency',
  },
  '251G00000X': {
    code: '251G00000X',
    displayName: 'Community Based Hospice Care Agency',
  },
  '253Z00000X': {
    code: '253Z00000X',
    displayName: 'In Home Supportive Care Agency',
  },
  '251300000X': {
    code: '251300000X',
    displayName: 'Local Education Agency (LEA)',
  },
  '251J00000X': {
    code: '251J00000X',
    displayName: 'Nursing Care Agency',
  },
  '251T00000X': {
    code: '251T00000X',
    displayName: 'PACE Provider Organization',
  },
  '251K00000X': {
    code: '251K00000X',
    displayName: 'Public Health or Welfare Agency',
  },
  '251X00000X': {
    code: '251X00000X',
    displayName: 'Supports Brokerage Agency',
  },
  '251V00000X': {
    code: '251V00000X',
    displayName: 'Voluntary or Charitable Agency',
  },
  '261Q00000X': {
    code: '261Q00000X',
    displayName: 'Clinic/Center',
  },
  '261QM0855X': {
    code: '261QM0855X',
    displayName: 'Adolescent and Children Mental Health Clinic/Center',
  },
  '261QA0600X': {
    code: '261QA0600X',
    displayName: 'Adult Day Care Clinic/Center',
  },
  '261QM0850X': {
    code: '261QM0850X',
    displayName: 'Adult Mental Health Clinic/Center',
  },
  '261QA0005X': {
    code: '261QA0005X',
    displayName: 'Ambulatory Family Planning Facility',
  },
  '261QA0006X': {
    code: '261QA0006X',
    displayName: 'Ambulatory Fertility Facility',
  },
  '261QA1903X': {
    code: '261QA1903X',
    displayName: 'Ambulatory Surgical Clinic/Center',
  },
  '261QA0900X': {
    code: '261QA0900X',
    displayName: 'Amputee Clinic/Center',
  },
  '261QA3000X': {
    code: '261QA3000X',
    displayName: 'Augmentative Communication Clinic/Center',
  },
  '261QB0400X': {
    code: '261QB0400X',
    displayName: 'Birthing Clinic/Center',
  },
  '261QC1500X': {
    code: '261QC1500X',
    displayName: 'Community Health Clinic/Center',
  },
  '261QC1800X': {
    code: '261QC1800X',
    displayName: 'Corporate Health Clinic/Center',
  },
  '261QC0050X': {
    code: '261QC0050X',
    displayName: 'Critical Access Hospital Clinic/Center',
  },
  '261QD0000X': {
    code: '261QD0000X',
    displayName: 'Dental Clinic/Center',
  },
  '261QD1600X': {
    code: '261QD1600X',
    displayName: 'Developmental Disabilities Clinic/Center',
  },
  '261QE0002X': {
    code: '261QE0002X',
    displayName: 'Emergency Care Clinic/Center',
  },
  '261QE0700X': {
    code: '261QE0700X',
    displayName: 'End-Stage Renal Disease (ESRD) Treatment Clinic/Center',
  },
  '261QE0800X': {
    code: '261QE0800X',
    displayName: 'Endoscopy Clinic/Center',
  },
  '261QF0050X': {
    code: '261QF0050X',
    displayName: 'Non-Surgical Family Planning Clinic/Center',
  },
  '261QF0400X': {
    code: '261QF0400X',
    displayName: 'Federally Qualified Health Center (FQHC)',
  },
  '261QG0250X': {
    code: '261QG0250X',
    displayName: 'Genetics Clinic/Center',
  },
  '261QH0100X': {
    code: '261QH0100X',
    displayName: 'Health Service Clinic/Center',
  },
  '261QH0700X': {
    code: '261QH0700X',
    displayName: 'Hearing and Speech Clinic/Center',
  },
  '261QI0500X': {
    code: '261QI0500X',
    displayName: 'Infusion Therapy Clinic/Center',
  },
  '261QL0400X': {
    code: '261QL0400X',
    displayName: 'Lithotripsy Clinic/Center',
  },
  '261QM1200X': {
    code: '261QM1200X',
    displayName: 'Magnetic Resonance Imaging (MRI) Clinic/Center',
  },
  '261QM2500X': {
    code: '261QM2500X',
    displayName: 'Medical Specialty Clinic/Center',
  },
  '261QM3000X': {
    code: '261QM3000X',
    displayName: 'Medically Fragile Infants and Children Day Care',
  },
  '261QM0801X': {
    code: '261QM0801X',
    displayName:
      'Mental Health Clinic/Center (Including Community Mental Health Center)',
  },
  '261QM2800X': {
    code: '261QM2800X',
    displayName: 'Methadone Clinic',
  },
  '261QM1000X': {
    code: '261QM1000X',
    displayName: 'Migrant Health Clinic/Center',
  },
  '261QM1103X': {
    code: '261QM1103X',
    displayName:
      'Military Ambulatory Procedure Visits Operational (Transportable) Clinic/Center',
  },
  '261QM1101X': {
    code: '261QM1101X',
    displayName:
      'Military and U.S. Coast Guard Ambulatory Procedure Clinic/Center',
  },
  '261QM1102X': {
    code: '261QM1102X',
    displayName:
      'Military Outpatient Operational (Transportable) Component Clinic/Center',
  },
  '261QM1100X': {
    code: '261QM1100X',
    displayName: 'Military/U.S. Coast Guard Outpatient Clinic/Center',
  },
  '261QM1300X': {
    code: '261QM1300X',
    displayName: 'Multi-Specialty Clinic/Center',
  },
  '261QX0100X': {
    code: '261QX0100X',
    displayName: 'Occupational Medicine Clinic/Center',
  },
  '261QX0200X': {
    code: '261QX0200X',
    displayName: 'Oncology Clinic/Center',
  },
  '261QX0203X': {
    code: '261QX0203X',
    displayName: 'Radiation Oncology Clinic/Center',
  },
  '261QS0132X': {
    code: '261QS0132X',
    displayName: 'Ophthalmologic Surgery Clinic/Center',
  },
  '261QS0112X': {
    code: '261QS0112X',
    displayName: 'Oral and Maxillofacial Surgery Clinic/Center',
  },
  '261QP3300X': {
    code: '261QP3300X',
    displayName: 'Pain Clinic/Center',
  },
  '261QP2000X': {
    code: '261QP2000X',
    displayName: 'Physical Therapy Clinic/Center',
  },
  '261QP1100X': {
    code: '261QP1100X',
    displayName: 'Podiatric Clinic/Center',
  },
  '261QP2300X': {
    code: '261QP2300X',
    displayName: 'Primary Care Clinic/Center',
  },
  '261QP2400X': {
    code: '261QP2400X',
    displayName: 'Prison Health Clinic/Center',
  },
  '261QP0904X': {
    code: '261QP0904X',
    displayName: 'Federal Public Health Clinic/Center',
  },
  '261QP0905X': {
    code: '261QP0905X',
    displayName: 'State or Local Public Health Clinic/Center',
  },
  '261QR0200X': {
    code: '261QR0200X',
    displayName: 'Radiology Clinic/Center',
  },
  '261QR0206X': {
    code: '261QR0206X',
    displayName: 'Mammography Clinic/Center',
  },
  '261QR0208X': {
    code: '261QR0208X',
    displayName: 'Mobile Radiology Clinic/Center',
  },
  '261QR0207X': {
    code: '261QR0207X',
    displayName: 'Mobile Mammography Clinic/Center',
  },
  '261QR0800X': {
    code: '261QR0800X',
    displayName: 'Recovery Care Clinic/Center',
  },
  '261QR0400X': {
    code: '261QR0400X',
    displayName: 'Rehabilitation Clinic/Center',
  },
  '261QR0404X': {
    code: '261QR0404X',
    displayName: 'Cardiac Rehabilitation Clinic/Center',
  },
  '261QR0401X': {
    code: '261QR0401X',
    displayName: 'Comprehensive Outpatient Rehabilitation Facility (CORF)',
  },
  '261QR0405X': {
    code: '261QR0405X',
    displayName: 'Substance Use Disorder Rehabilitation Clinic/Center',
  },
  '261QR1100X': {
    code: '261QR1100X',
    displayName: 'Research Clinic/Center',
  },
  '261QR1300X': {
    code: '261QR1300X',
    displayName: 'Rural Health Clinic/Center',
  },
  '261QS1200X': {
    code: '261QS1200X',
    displayName: 'Sleep Disorder Diagnostic Clinic/Center',
  },
  '261QS1000X': {
    code: '261QS1000X',
    displayName: 'Student Health Clinic/Center',
  },
  '261QU0200X': {
    code: '261QU0200X',
    displayName: 'Urgent Care Clinic/Center',
  },
  '261QV0200X': {
    code: '261QV0200X',
    displayName: 'VA Clinic/Center',
  },
  '273100000X': {
    code: '273100000X',
    displayName: 'Epilepsy Hospital Unit',
  },
  '275N00000X': {
    code: '275N00000X',
    displayName: 'Medicare Defined Swing Bed Hospital Unit',
  },
  '273R00000X': {
    code: '273R00000X',
    displayName: 'Psychiatric Hospital Unit',
  },
  '273Y00000X': {
    code: '273Y00000X',
    displayName: 'Rehabilitation Hospital Unit',
  },
  '276400000X': {
    code: '276400000X',
    displayName: 'Substance Use Disorder Rehabilitation Hospital Unit',
  },
  '287300000X': {
    code: '287300000X',
    displayName: 'Deactivated - Christian Science Sanitorium',
  },
  '281P00000X': {
    code: '281P00000X',
    displayName: 'Chronic Disease Hospital',
  },
  '281PC2000X': {
    code: '281PC2000X',
    displayName: "Children's Chronic Disease Hospital",
  },
  '282N00000X': {
    code: '282N00000X',
    displayName: 'General Acute Care Hospital',
  },
  '282NC2000X': {
    code: '282NC2000X',
    displayName: "Children's Hospital",
  },
  '282NC0060X': {
    code: '282NC0060X',
    displayName: 'Critical Access Hospital',
  },
  '282NR1301X': {
    code: '282NR1301X',
    displayName: 'Rural Acute Care Hospital',
  },
  '282NW0100X': {
    code: '282NW0100X',
    displayName: "Women's Hospital",
  },
  '282E00000X': {
    code: '282E00000X',
    displayName: 'Long Term Care Hospital',
  },
  '286500000X': {
    code: '286500000X',
    displayName: 'Military Hospital',
  },
  '2865C1500X': {
    code: '2865C1500X',
    displayName: 'Deactivated - Military Hospital',
  },
  '2865M2000X': {
    code: '2865M2000X',
    displayName: 'Military General Acute Care Hospital',
  },
  '2865X1600X': {
    code: '2865X1600X',
    displayName:
      'Operational (Transportable) Military General Acute Care Hospital',
  },
  '283Q00000X': {
    code: '283Q00000X',
    displayName: 'Psychiatric Hospital',
  },
  '283X00000X': {
    code: '283X00000X',
    displayName: 'Rehabilitation Hospital',
  },
  '283XC2000X': {
    code: '283XC2000X',
    displayName: "Children's Rehabilitation Hospital",
  },
  '282J00000X': {
    code: '282J00000X',
    displayName: 'Religious Nonmedical Health Care Institution',
  },
  '284300000X': {
    code: '284300000X',
    displayName: 'Special Hospital',
  },
  '291U00000X': {
    code: '291U00000X',
    displayName: 'Clinical Medical Laboratory',
  },
  '292200000X': {
    code: '292200000X',
    displayName: 'Dental Laboratory',
  },
  '291900000X': {
    code: '291900000X',
    displayName: 'Military Clinical Medical Laboratory',
  },
  '293D00000X': {
    code: '293D00000X',
    displayName: 'Physiological Laboratory',
  },
  '302F00000X': {
    code: '302F00000X',
    displayName: 'Exclusive Provider Organization',
  },
  '302R00000X': {
    code: '302R00000X',
    displayName: 'Health Maintenance Organization',
  },
  '305S00000X': {
    code: '305S00000X',
    displayName: 'Point of Service',
  },
  '305R00000X': {
    code: '305R00000X',
    displayName: 'Preferred Provider Organization',
  },
  '311500000X': {
    code: '311500000X',
    displayName: 'Alzheimer Center (Dementia Center)',
  },
  '310400000X': {
    code: '310400000X',
    displayName: 'Assisted Living Facility',
  },
  '3104A0630X': {
    code: '3104A0630X',
    displayName: 'Assisted Living Facility (Behavioral Disturbances)',
  },
  '3104A0625X': {
    code: '3104A0625X',
    displayName: 'Assisted Living Facility (Mental Illness)',
  },
  '317400000X': {
    code: '317400000X',
    displayName: 'Deactivated - Christian Science Facility',
  },
  '311Z00000X': {
    code: '311Z00000X',
    displayName: 'Custodial Care Facility',
  },
  '311ZA0620X': {
    code: '311ZA0620X',
    displayName: 'Adult Care Home Facility',
  },
  '315D00000X': {
    code: '315D00000X',
    displayName: 'Inpatient Hospice',
  },
  '315P00000X': {
    code: '315P00000X',
    displayName: 'Intellectual Disabilities Intermediate Care Facility',
  },
  '310500000X': {
    code: '310500000X',
    displayName: 'Mental Illness Intermediate Care Facility',
  },
  '313M00000X': {
    code: '313M00000X',
    displayName: 'Nursing Facility/Intermediate Care Facility',
  },
  '314000000X': {
    code: '314000000X',
    displayName: 'Skilled Nursing Facility',
  },
  '3140N1450X': {
    code: '3140N1450X',
    displayName: 'Pediatric Skilled Nursing Facility',
  },
  '177F00000X': {
    code: '177F00000X',
    displayName: 'Lodging Provider',
  },
  '174200000X': {
    code: '174200000X',
    displayName: 'Meals Provider',
  },
  '320800000X': {
    code: '320800000X',
    displayName:
      'Mental Illness Community Based Residential Treatment Facility',
  },
  '320900000X': {
    code: '320900000X',
    displayName:
      'Intellectual and/or Developmental Disabilities Community Based Residential Treatment Facility',
  },
  '323P00000X': {
    code: '323P00000X',
    displayName: 'Psychiatric Residential Treatment Facility',
  },
  '322D00000X': {
    code: '322D00000X',
    displayName:
      "Emotionally Disturbed Childrens' Residential Treatment Facility",
  },
  '320600000X': {
    code: '320600000X',
    displayName:
      'Intellectual and/or Developmental Disabilities Residential Treatment Facility',
  },
  '320700000X': {
    code: '320700000X',
    displayName: 'Physical Disabilities Residential Treatment Facility',
  },
  '324500000X': {
    code: '324500000X',
    displayName: 'Substance Abuse Rehabilitation Facility',
  },
  '3245S0500X': {
    code: '3245S0500X',
    displayName: "Children's Substance Abuse Rehabilitation Facility",
  },
  '385H00000X': {
    code: '385H00000X',
    displayName: 'Respite Care',
  },
  '385HR2050X': {
    code: '385HR2050X',
    displayName: 'Respite Care Camp',
  },
  '385HR2055X': {
    code: '385HR2055X',
    displayName: 'Child Mental Illness Respite Care',
  },
  '385HR2060X': {
    code: '385HR2060X',
    displayName:
      'Child Intellectual and/or Developmental Disabilities Respite Care',
  },
  '385HR2065X': {
    code: '385HR2065X',
    displayName: 'Child Physical Disabilities Respite Care',
  },
  '331L00000X': {
    code: '331L00000X',
    displayName: 'Blood Bank',
  },
  '332100000X': {
    code: '332100000X',
    displayName: 'Department of Veterans Affairs (VA) Pharmacy',
  },
  '332B00000X': {
    code: '332B00000X',
    displayName: 'Durable Medical Equipment & Medical Supplies',
  },
  '332BC3200X': {
    code: '332BC3200X',
    displayName: 'Customized Equipment (DME)',
  },
  '332BD1200X': {
    code: '332BD1200X',
    displayName: 'Dialysis Equipment & Supplies (DME)',
  },
  '332BN1400X': {
    code: '332BN1400X',
    displayName: 'Nursing Facility Supplies (DME)',
  },
  '332BX2000X': {
    code: '332BX2000X',
    displayName: 'Oxygen Equipment & Supplies (DME)',
  },
  '332BP3500X': {
    code: '332BP3500X',
    displayName: 'Parenteral & Enteral Nutrition Supplies (DME)',
  },
  '333300000X': {
    code: '333300000X',
    displayName: 'Emergency Response System Companies',
  },
  '332G00000X': {
    code: '332G00000X',
    displayName: 'Eye Bank',
  },
  '332H00000X': {
    code: '332H00000X',
    displayName: 'Eyewear Supplier',
  },
  '332S00000X': {
    code: '332S00000X',
    displayName: 'Hearing  Aid Equipment',
  },
  '332U00000X': {
    code: '332U00000X',
    displayName: 'Home Delivered Meals',
  },
  '332800000X': {
    code: '332800000X',
    displayName:
      'Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy',
  },
  '335G00000X': {
    code: '335G00000X',
    displayName: 'Medical Foods Supplier',
  },
  '332000000X': {
    code: '332000000X',
    displayName: 'Military/U.S. Coast Guard Pharmacy',
  },
  '332900000X': {
    code: '332900000X',
    displayName: 'Non-Pharmacy Dispensing Site',
  },
  '335U00000X': {
    code: '335U00000X',
    displayName: 'Organ Procurement Organization',
  },
  '333600000X': {
    code: '333600000X',
    displayName: 'Pharmacy',
  },
  '3336C0002X': {
    code: '3336C0002X',
    displayName: 'Clinic Pharmacy',
  },
  '3336C0003X': {
    code: '3336C0003X',
    displayName: 'Community/Retail Pharmacy',
  },
  '3336C0004X': {
    code: '3336C0004X',
    displayName: 'Compounding Pharmacy',
  },
  '3336H0001X': {
    code: '3336H0001X',
    displayName: 'Home Infusion Therapy Pharmacy',
  },
  '3336I0012X': {
    code: '3336I0012X',
    displayName: 'Institutional Pharmacy',
  },
  '3336L0003X': {
    code: '3336L0003X',
    displayName: 'Long Term Care Pharmacy',
  },
  '3336M0002X': {
    code: '3336M0002X',
    displayName: 'Mail Order Pharmacy',
  },
  '3336M0003X': {
    code: '3336M0003X',
    displayName: 'Managed Care Organization Pharmacy',
  },
  '3336N0007X': {
    code: '3336N0007X',
    displayName: 'Nuclear Pharmacy',
  },
  '3336S0011X': {
    code: '3336S0011X',
    displayName: 'Specialty Pharmacy',
  },
  '335V00000X': {
    code: '335V00000X',
    displayName:
      'Portable X-ray and/or Other Portable Diagnostic Imaging Supplier',
  },
  '335E00000X': {
    code: '335E00000X',
    displayName: 'Prosthetic/Orthotic Supplier',
  },
  '344800000X': {
    code: '344800000X',
    displayName: 'Air Carrier',
  },
  '341600000X': {
    code: '341600000X',
    displayName: 'Ambulance',
  },
  '3416A0800X': {
    code: '3416A0800X',
    displayName: 'Air Ambulance',
  },
  '3416L0300X': {
    code: '3416L0300X',
    displayName: 'Land Ambulance',
  },
  '3416S0300X': {
    code: '3416S0300X',
    displayName: 'Water Ambulance',
  },
  '347B00000X': {
    code: '347B00000X',
    displayName: 'Bus',
  },
  '341800000X': {
    code: '341800000X',
    displayName: 'Military/U.S. Coast Guard Transport, ',
  },
  '3418M1120X': {
    code: '3418M1120X',
    displayName: 'Military or U.S. Coast Guard Air Transport Ambulance',
  },
  '3418M1110X': {
    code: '3418M1110X',
    displayName: 'Military or U.S. Coast Guard Ground Transport Ambulance',
  },
  '3418M1130X': {
    code: '3418M1130X',
    displayName: 'Military or U.S. Coast Guard Water Transport Ambulance',
  },
  '343900000X': {
    code: '343900000X',
    displayName: 'Non-emergency Medical Transport (VAN)',
  },
  '347C00000X': {
    code: '347C00000X',
    displayName: 'Private Vehicle',
  },
  '343800000X': {
    code: '343800000X',
    displayName: 'Secured Medical Transport (VAN)',
  },
  '344600000X': {
    code: '344600000X',
    displayName: 'Taxi',
  },
  '347D00000X': {
    code: '347D00000X',
    displayName: 'Train',
  },
  '347E00000X': {
    code: '347E00000X',
    displayName: 'Transportation Broker',
  },
  '342000000X': {
    code: '342000000X',
    displayName: 'Transportation Network Company',
  },
};
