import { Patient } from '@chiroup/core/types/Patient.type';
import React from 'react';

export const PatientContext = React.createContext<{
  patient: Patient;
  updatePatientCache: (patient: Partial<Patient>) => void;
  refetch: () => void;
}>({
  patient: {} as Patient,
  updatePatientCache: () => {},
  refetch: () => {},
});
