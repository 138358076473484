import { useQuery } from 'react-query';
import supplementService from '../services/supplement.service';
import { CustomizationListItem } from '../components/clinical-skills/CustomizationListItem.type';
import { useContext } from 'react';
import { MeContext } from '../contexts/me.context';

type Props = {
  refetchOnMount?: boolean;
  omitOnline?: boolean;
  ids?: number[];
  enabled?: boolean;
  getReferenceCodes?: boolean;
};
const useSupplements = ({
  refetchOnMount = true,
  omitOnline = false,
  ids = [],
  enabled = true,
  getReferenceCodes = false,
}: Props = {}) => {
  const meContext = useContext(MeContext);
  const { data, isFetching } = useQuery<{
    data: CustomizationListItem[];
    itemTaxHash: { [key: string]: boolean };
  }>(
    ['supplements', ids],
    () =>
      supplementService.list(
        meContext.me.selectedClinic?.ID || -1,
        omitOnline,
        getReferenceCodes,
        ids,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount,
      enabled,
    },
  );

  return { isFetching, data };
};

export default useSupplements;
