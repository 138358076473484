import { Currency } from '@chiroup/components';

type Props = {
  total?: string | number;
  paid?: string | number;
};

const SuperbillInvoiceTotals: React.FC<Props> = ({ total, paid }) => {
  return (
    <div className="avoid-page-break w-full ">
      <div className="pl-6  py-2 pr-2 border rounded-md text-xs mt-2 min-w-full">
        <div className="flex items-center mb-1">
          <span>Total charges:</span>
          <span className="flex-grow border-b border-dotted border-gray-300  pt-3"></span>
          <Currency value={total} />
        </div>
        <div className="flex items-center mb-1">
          <span>Total paid:</span>
          <span className="flex-grow border-b border-dotted border-gray-300  pt-3"></span>
          <Currency value={paid} />
        </div>
      </div>
    </div>
  );
};

export default SuperbillInvoiceTotals;
