import React from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import EndOfList from '../EndOfList';
import LoadingPage from '../LoadingPage';

type Props = {
  isFetching: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      {
        data: any[];
        lastKey?: string;
        skip?: number;
      },
      unknown
    >
  >;
  hasNextPage?: boolean;
  className?: string;
  clientSide?: boolean;
  children?: React.ReactNode;
  hideEndOfList?: boolean;
};

const List: React.FC<Props> = ({
  children,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  className = 'relative z-0 divide-y divide-gray-300 dark:divide-darkGray-700 border-b border-gray-300 dark:border-darkGray-700',
  clientSide = false,
  hideEndOfList = false,
}) => {
  return isFetching && !isFetchingNextPage ? (
    <LoadingPage />
  ) : (
    <>
      <ul className={className}>{children}</ul>
      {!clientSide && !hideEndOfList && (
        <EndOfList
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      )}
    </>
  );
};

export default List;
