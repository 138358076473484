import {
  Checkbox,
  Input,
  Loading,
  LoadingPage,
  RadioGroup,
  icons,
} from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import useLocalStorage, { LSType } from '../../../../../hooks/useLocalStorage';
import PaymentMethodItem from '../../../../patient-billing/PaymentMethodItem';
import useCreditDevices from '../../../../settings/clinic/useCreditDevices';
import { PayRequest } from './PatientPaymentModal';
import WaitingForPayment from './WaitingForPayment';
import IconButton from '../../../../common/IconButton';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { FormErrors } from '@chiroup/core/types/ErrorResponse.type';
import {
  CCPaymentFields,
  PaymentMethodType,
  EpayCustomerRecord,
} from '@chiroup/core/types/PatientBillling.type';
import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import { ValueOf } from '@chiroup/core/types/ValueOf.type';
import { EPayStatus } from '@chiroup/core/enums/EPayStatus.enum';

type Props = {
  value: Partial<PatientPayment & CCPaymentFields>;
  onChange: (
    key: keyof PatientPayment | keyof CCPaymentFields,
  ) => (val: ValueOf<PatientPayment & CCPaymentFields>) => void;
  disabled: boolean;
  errors: FormErrors;
  isLoading: boolean;
  isSubmitting: boolean;
  waitingForTerminal: boolean;
  payment: PatientPayment;
  selectedPaymentCard: PaymentMethodType | null;
  epayCustomer: EpayCustomerRecord | null;
  setSelectedPaymentCard: (paymentMethod: PaymentMethodType | null) => void;
  setTerminalTransaction: Dispatch<SetStateAction<PayRequest | null>>;
  onError: (message: string | null) => void;
  setWaitingForTerminal: Dispatch<SetStateAction<boolean>>;
  terminalTransaction: PayRequest | null;
  onSuccess: (closeAndFetch?: boolean, force?: boolean) => Promise<void>;
  refetchEpayCustomer: () => void;
};

const CreditCardDeviceSelector = ({
  value,
  onChange,
  disabled,
  errors,
  isLoading,
  isSubmitting,
  waitingForTerminal,
  payment,
  selectedPaymentCard,
  epayCustomer,
  setSelectedPaymentCard,
  setTerminalTransaction,
  onError,
  setWaitingForTerminal,
  terminalTransaction,
  onSuccess,
  refetchEpayCustomer,
}: Props) => {
  const { setItem, getItem } = useLocalStorage();
  const { data, isFetching, refetch } = useCreditDevices();

  useEffect(() => {
    if (data?.length && !value) {
      const lsData = getItem(LSType.both, 'deviceKey');
      if (lsData) {
        const existsInData = data.find((d: any) => d.key === lsData);
        if (existsInData) {
          onChange('deviceKey')(lsData);
          onChange('epayPublicKey')(existsInData.publicKey);
        }
      }
      onChange('deviceKey')(data[0].key);
      onChange('epayPublicKey')(data[0].publicKey);
    }
  }, [data, value, onChange, getItem]);

  const handlePaymentCardError = useCallback(
    (err: any) => {
      err = JSON.parse(err);
      if (err.code === '0') {
        onError(null);
      } else if (err.code) {
        onError(err.message);
      }
    },
    [onError],
  );

  const isManualEntry = useMemo(() => {
    const selectedDevice = data?.find((d: any) => d.key === value.deviceKey);
    return selectedDevice?.terminal_type === 'manual';
  }, [data, value.deviceKey]);

  useEffect(() => {
    let paymentCard: any = null;
    if ((window as any).usaepay && isManualEntry && value.epayPublicKey) {
      const client = new (window as any).usaepay.Client(value.epayPublicKey);
      paymentCard = client.createPaymentCardEntry();

      const paymentCardConfig = {
        styles: ` 
        #payjs-cnum-wrapper {
          padding-right: 5px;
        }
        #payjs-exp-wrapper {
          padding-right: 5px;
        }
        #payjs-cv2-wrapper {
          padding-right: 5px;
        }
  
        #payjs-input-icon {
          padding-left: 5px;
        }
              #payjs-input-icon:hover {
                  height: 22px;
              }
      
              @media screen and (max-width: 400px) {
                  .payjs-base {
                      font-size: 10px;
                  }
              }
          `,
      };

      paymentCard.generateHTML(paymentCardConfig);
      paymentCard.addHTML('paymentCardContainer');
      paymentCard.addEventListener('error', handlePaymentCardError);
    }
  }, [
    selectedPaymentCard,
    value.type,
    value.deviceKey,
    value.epayPublicKey,
    handlePaymentCardError,
    isManualEntry,
  ]);

  const onCCTerminalMessage = useCallback(
    (data: PayRequest) => {
      setTerminalTransaction(data);
      if (data.status === EPayStatus.Success) {
        setWaitingForTerminal(false);
        onSuccess(true, true);
      } else if (data.status === EPayStatus.Error) {
        onError(data.error ?? 'An error occurred');
        setWaitingForTerminal(false);
      }
    },
    [setTerminalTransaction, setWaitingForTerminal, onSuccess, onError],
  );

  return isFetching ? (
    <div className="flex flex-row gap-2 text-gray-900 item-center">
      <Loading color="text-gray-700" size={6} />
      <div>Fetching devices...</div>
    </div>
  ) : data?.length ? (
    <div>
      <RadioGroup
        name="deviceKey"
        className="col-span-2"
        label={
          <div className="flex flex-row gap-2 items-center">
            <span>Select Device</span>
            <ArrowPathIcon
              className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer"
              onClick={() => refetch()}
            />
          </div>
        }
        value={value.deviceKey}
        onChange={(val: string) => {
          onChange('deviceKey')(val);
          onChange('epayPublicKey')(
            data.find((d: any) => d.key === val).publicKey,
          );
          setItem(LSType.both, 'deviceKey', val);
        }}
        disabled={disabled || isFetching}
        options={data.map((device: any) => ({
          value: device.key,
          text: (
            <div className="flex flex-row items-center gap-2">
              <div>{device.name}</div>
              <div
                className={classNames(
                  'flex h-5 w-5 items-center justify-center text-3xl',
                  device?.status === 'connected'
                    ? 'text-primary-500'
                    : 'text-red-500',
                )}
              >
                {device?.status === 'connected' ? icons.link : icons.linkBreak}
              </div>
            </div>
          ),
          disabled: device?.status !== 'connected',
        }))}
      />
      <div className="pt-6">
        {isManualEntry && (
          <div>
            {isLoading ? (
              <LoadingPage />
            ) : (
              <>
                {!!epayCustomer?.payment_methods?.length && (
                  <div className="bg-gray-300 p-6 rounded-md">
                    <h3 className="pb-2 font-medium text-md">
                      Saved Payment Methods:
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      {epayCustomer.payment_methods.map((method) => (
                        <div key={method.key}>
                          <PaymentMethodItem
                            paymentMethod={method}
                            selected={selectedPaymentCard}
                            refetchEpayCustomer={refetchEpayCustomer}
                            setSelected={setSelectedPaymentCard}
                            patientId={epayCustomer.customerid}
                            custKey={epayCustomer.key}
                            disabled={
                              isSubmitting ||
                              waitingForTerminal ||
                              payment.isRefunded
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!selectedPaymentCard && (
                  <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
                    <Input
                      name="fname"
                      className="col-span-2"
                      label="Cardholder first name *"
                      value={value.fname}
                      onChange={onChange('fname')}
                      errors={errors?.fieldErrors?.lname}
                      disabled={
                        isSubmitting || waitingForTerminal || payment.isRefunded
                      }
                    />
                    <Input
                      name="lname"
                      className="col-span-2"
                      label="Cardholder last name *"
                      value={value.lname}
                      onChange={onChange('lname')}
                      errors={errors?.fieldErrors?.lname}
                      disabled={
                        isSubmitting || waitingForTerminal || payment.isRefunded
                      }
                    />
                    <Input
                      name="streetAddress"
                      className="col-span-2"
                      label="Cardholder street address *"
                      value={value.streetAddress}
                      errors={errors?.fieldErrors?.streetAddress}
                      onChange={onChange('streetAddress')}
                      disabled={
                        isSubmitting || waitingForTerminal || payment.isRefunded
                      }
                    />
                    <Input
                      name="zip"
                      className="col-span-2"
                      label="Cardholder zip code *"
                      value={value.postalCode}
                      errors={errors?.fieldErrors?.postalCode}
                      onChange={onChange('postalCode')}
                      disabled={
                        isSubmitting || waitingForTerminal || payment.isRefunded
                      }
                    />
                    <Checkbox
                      className="col-span-4"
                      label="Save payment method?"
                      name="saveCard"
                      onChange={onChange('saveCard')}
                      value={value.saveCard || false}
                      disabled={
                        isSubmitting || waitingForTerminal || payment.isRefunded
                      }
                    />
                  </div>
                )}
              </>
            )}
            <div
              className={
                !selectedPaymentCard?.key && value.epayPublicKey
                  ? 'block'
                  : 'hidden'
              }
            >
              <hr className="my-4" />
              <label
                htmlFor="paymentCardContainer"
                className="block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2"
              >
                Credit/Debit Card
              </label>
              <div id="paymentCardContainer" className="h-12">
                {/* Payment Card iframe will go here */}
              </div>
            </div>
          </div>
        )}
      </div>

      {terminalTransaction?.status === EPayStatus.Timeout ? (
        <div className="text-red-500">
          The payment request has timed out. Please try again.
        </div>
      ) : (
        terminalTransaction?.status === EPayStatus.Pending &&
        value.deviceKey && (
          <WaitingForPayment
            terminalTransaction={terminalTransaction}
            waitingForTermial={waitingForTerminal}
            onCCTerminalMessage={onCCTerminalMessage}
            setWaitingForTerminal={setWaitingForTerminal}
            setTerminalTransaction={setTerminalTransaction}
          />
        )
      )}
    </div>
  ) : (
    <div>
      <span>No devices found</span>
      <IconButton
        icon={icons.refresh}
        onClick={() => refetch()}
        disabled={isFetching}
        className="ml-2 h-6 w-6"
      />
    </div>
  );
};

export default CreditCardDeviceSelector;
