import { LoadingPage, OpenClosedStates } from '@chiroup/components';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import { IntegrationInvoice } from '@chiroup/core/types/Invoice.type';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import { useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MeContext } from '../../../../../contexts/me.context';
import CommonInvoiceModal from '../../../../billing/CommonInvoiceModal';
import AlertBlock from '../../../../common/AlertBlock';
import List from '../../../../common/list/List';
import useInvoices from './Invoice/hooks/useInvoices';
import TransactionInvoiceListRow from './TransactionInvoiceListRow';
import TransactionPatientInvoice from './TransactionPatientInvoice';
import TransactionSuperbillInvoice from './TransactionSuperbillInvoice';
import WriteOffModal from './WriteOffModal';

type Props = {
  workingTransaction: PatientTransaction | null;
  refetchBalance?: () => void;
};
const TransactionInvoiceList: React.FC<Props> = ({
  workingTransaction,
  refetchBalance,
}) => {
  const { me } = useContext(MeContext);
  const { data, isFetching, refetch, restError } = useInvoices({
    patientId: workingTransaction?.patient?.id ?? '',
    billingKey: workingTransaction?.billingKey ?? '',
    returnIntegrationInvoices: true,
    // trace: true,
  });
  const [selectedInvoice, setSelectedInvoice] =
    useState<IntegrationInvoice | null>(null);
  const [invoiceModalState, setInvoiceModalState] = useState(false);
  const { hasAccess } = useContext(MeContext);

  const clickInvoiceCallback = (invoice: IntegrationInvoice) => {
    setSelectedInvoice((p) => {
      p = invoice;
      return p;
    });
    setInvoiceModalState(true);
  };

  const updateInvoiceCallback = ({
    invoice,
  }: {
    invoice: IntegrationInvoice | null | undefined;
    fetch?: boolean;
  }) => {
    // clog({ updateInvoiceCallback: invoice });
    if (!invoice) return;
    setSelectedInvoice((p) => {
      p = invoice;
      return p;
    });
    refetch();
  };

  return (
    <>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div className="print:hidden">
          <AlertBlock message={restError} level={`error`} />
          <List
            isFetching={isFetching}
            className="w-full text-left text-sm text-gray-600 overflow-hidden bg-transparent rounded-b-md"
          >
            {data?.map((invoice: IntegrationInvoice) => {
              return (
                <TransactionInvoiceListRow
                  item={invoice}
                  loading={isFetching}
                  key={invoice.id}
                  clickInvoiceCallback={clickInvoiceCallback}
                />
              );
            })}
          </List>
        </div>
      )}
      <CommonInvoiceModal
        invoiceId={selectedInvoice?.id ?? -1}
        state={
          invoiceModalState ? OpenClosedStates.Open : OpenClosedStates.Closed
        }
        close={(isDirty) => {
          setInvoiceModalState(false);
          if (isDirty) refetch();
        }}
        clinicId={me?.selectedClinic?.ID ?? -1}
        containerCallback={function (
          _: IntegrationInvoice | undefined | null,
        ): void {
          throw new Error('Function not implemented.');
        }}
        updateInvoiceCallback={updateInvoiceCallback}
      />
      {hasAccess(FeatureFlags.ehr) && (
        <Routes>
          <Route
            path={`/:invoiceId/write-off/*`}
            element={
              <WriteOffModal
                refetch={refetch}
                refetchBalance={refetchBalance}
              />
            }
          />
          <Route
            path={`/:invoiceId/super-bill/*`}
            element={<TransactionSuperbillInvoice refetch={refetch} />}
          />
          <Route
            path={`/:invoiceId/*`}
            element={<TransactionPatientInvoice refetch={refetch} />}
          />
        </Routes>
      )}
    </>
  );
};
export default TransactionInvoiceList;
