import {
  BusinessRuleSolution,
  Button,
  ButtonColors,
  Currency,
  HashCardDisplay,
  HashCardDisplayColors,
  Loading,
  MakeBrowserWait,
  OpenClosedStates,
  Select,
  TimestampDisplay,
  Toggle,
  TrivialTooltip,
  icons,
  trivialDownload,
} from '@chiroup/components';
import {
  ArrowTopRightOnSquareIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CogIcon,
  PencilIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { classNames } from '@chiroup/core/functions/classNames';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import businessRulesService from '../../services/businessRules.service';
import { integrationService } from '../../services/integration.service';
import patientBillingService from '../../services/patientBilling.service';
import Modal from '../common/Modal';
import { metaByInvoiceStatus } from './metaByInvoiceStatus';
import { MeContext } from '../../contexts/me.context';
import useIntegrationInvoice from './hooks/useIntegrationInvoice';
import IconButton from '../common/IconButton';
import {
  BusinessRuleItemType,
  calculatePatientAge,
  STRING_ANY_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { clog } from '@chiroup/core/functions/clog';
import { formatCurrency } from '@chiroup/core/functions/format';
import {
  IntegrationInvoice,
  InvoiceStatusEnum,
  AllInvoiceStatusOptions,
  AllowedInvoiceStatusTransitions,
  InvoiceGenerationEdiXref,
  InvoiceStatusData,
  PayorPriorityDisplay,
  InvoiceStatusTips,
} from '@chiroup/core/types/Invoice.type';
import {
  primaryInsuredTypeDisplay,
  PrimaryInsuredTypes,
  primaryInsuredNameDisplay,
} from '@chiroup/core/types/PatientInsurance.type';

type Props = {
  invoiceId: number;
  state: OpenClosedStates;
  close?: (isDirty?: boolean) => void;
  clinicId: number;
  trace?: boolean;
  containerCallback: (_: IntegrationInvoice | null | undefined) => void;
  updateInvoiceCallback?: (_: {
    invoice: IntegrationInvoice | null | undefined;
    refetch?: boolean;
  }) => void;
  readonly?: boolean;
  onModalStateChange?: (args?: any) => void;
};

const CommonInvoiceModal: React.FC<Props> = ({
  invoiceId,
  state,
  trace = false,
  close = (isDirty?: boolean) => {
    if (trace) clog(`Close not implemented: ${isDirty}.`);
  },
  updateInvoiceCallback = (_) => {
    if (trace) {
      clog('CommonInvoiceModal :: updateInvoiceCallback not implemented.');
    }
  },
  readonly = false,
}) => {
  const { pathname } = useLocation();
  if (!invoiceId || invoiceId <= 0) {
    const parts = pathname.split('/');
    invoiceId = Number(parts[parts.length - 1]);
  }
  const { me } = useContext(MeContext);
  const clinicId = me?.selectedClinic?.ID ?? -1;
  const {
    data: rawInvoice,
    toggleElectronicBilling,
    isRestActive: toggleRestActive,
    setInvoiceData,
    isFetching,
    restFailed,
    setRestFailed,
  } = useIntegrationInvoice({
    clinicId,
    id: invoiceId,
  });
  const invoice = useMemo(() => {
    if (!rawInvoice) {
      return {} as IntegrationInvoice;
    }
    return rawInvoice;
  }, [rawInvoice]);

  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [newStatus, setNewStatus] = useState<InvoiceStatusEnum | null>(null);
  const { selectedLocationFull } = useContext(MeContext);
  const navigate = useNavigate();

  const shrinkButtons = useMemo(() => {
    return (
      invoice.status === InvoiceStatusEnum.Submitted &&
      invoice.electronicBilling === false
    );
  }, [invoice]);

  //  In case we want to do something before closing.
  const componentClose = (isDirty?: boolean) => {
    close(isDirty);
  };

  const metaContext = useMemo(() => {
    if (!invoice) return {};
    const res = {
      [invoice.id]: {
        delayReason: invoice.delayReason,
        generation: invoice.generation,
        claimControlNumber: invoice.claimControlNumber,
      },
    };
    return res;
  }, [invoice]);

  useEffect(() => {
    if (!invoice) return;
    setNewStatus(invoice.status as InvoiceStatusEnum);
  }, [invoice]);

  const validTransitions = useMemo(() => {
    if (!invoice) return [];
    return AllInvoiceStatusOptions.filter((option) => {
      return (
        AllowedInvoiceStatusTransitions[invoice.status as InvoiceStatusEnum]?.[
          option.value
        ] || option.value === invoice.status
      );
    });
  }, [invoice]);

  const userTz = useMemo(() => {
    return selectedLocationFull.timezone;
  }, [selectedLocationFull]);

  const disableToggle = useMemo(() => {
    if (!invoice) return false;
    return (
      invoice.status === InvoiceStatusEnum.Submitted ||
      invoice.status === InvoiceStatusEnum.Received ||
      invoice.status === InvoiceStatusEnum.Partial ||
      invoice.status === InvoiceStatusEnum.Paid
    );
  }, [invoice]);

  const [resolvedSomething, setResolvedSomething] = useState(false);
  const { createToast } = useContext(ToastContext);
  const [isRestActive, setIsRestActive] = useState<{
    resolve?: boolean;
    save?: boolean;
    test?: boolean;
    submit?: boolean;
    download?: boolean;
    downloadComplete?: boolean;
    downloadFields?: boolean;
    updateStatus?: boolean;
    saveResolved?: boolean;
  }>({});
  // const [downloadContent, setDownloadContent] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  // 'saved' is used to decide if the buttons have been pushed once already.
  // The modal goes unresponsive and needs to be closed in re-opened at that
  // point. Might not be the desired behavior, but that was the idea.
  const [saved, setSaved] = useState<boolean>(false);

  const anyRestActive = useMemo(() => {
    return (
      isRestActive.resolve ||
      isRestActive.save ||
      isRestActive.test ||
      isRestActive.submit ||
      isRestActive.download ||
      isRestActive.downloadComplete ||
      isRestActive.downloadFields ||
      isRestActive.updateStatus ||
      isRestActive.saveResolved ||
      toggleRestActive
    );
  }, [
    isRestActive.download,
    isRestActive.downloadComplete,
    isRestActive.downloadFields,
    isRestActive.resolve,
    isRestActive.save,
    isRestActive.saveResolved,
    isRestActive.submit,
    isRestActive.test,
    isRestActive.updateStatus,
    toggleRestActive,
  ]);

  const noStatus = useMemo(() => {
    return (
      !invoice ||
      !invoice.statusData ||
      !invoice.statusData.items ||
      !invoice.statusData.items[0] ||
      !invoice.statusData.items[0].final
    );
  }, [invoice]);

  const latestInvoiceMessages = useMemo(() => {
    if (!invoice) return null;
    const statusData =
        typeof invoice?.statusData === 'string'
          ? ChiroUpJSON.parse(invoice?.statusData)
          : invoice?.statusData,
      items = statusData?.items ?? [],
      latest = items[0] ?? null,
      obj = {
        issues: latest?.issues ?? [],
        notes: latest?.notes ?? [],
      };
    return obj;
  }, [invoice]);

  const hasIssues = useMemo(() => {
    return (
      latestInvoiceMessages &&
      latestInvoiceMessages.issues &&
      latestInvoiceMessages.issues.length !== 0
    );
  }, [latestInvoiceMessages]);

  const issues = useMemo(() => {
    return (latestInvoiceMessages?.issues || []) as BusinessRuleItemType[];
  }, [latestInvoiceMessages]);

  const notes = useMemo(() => {
    return (latestInvoiceMessages?.notes || []) as BusinessRuleItemType[];
  }, [latestInvoiceMessages]);

  const submitDisabled = useMemo(() => {
    if (!hasIssues) return false;
    return !resolvedSomething || hasIssues || saved || isRestActive.submit;
  }, [hasIssues, saved, isRestActive, resolvedSomething]);

  const _save = async (e?: any, passedInvoice?: IntegrationInvoice) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    setIsRestActive((p) => ({ ...p, saveResolved: true }));

    try {
      const results = await patientBillingService.setInvoiceStatus({
          clinicId,
          invoiceId: invoice.id,
          issues: {
            [invoice.id]:
              passedInvoice?.statusData?.items?.[0]?.issues ?? issues ?? [],
          },
        }),
        result = results && results[0] ? results[0] : null;

      if (result) {
        setInvoiceData(result);
        updateInvoiceCallback({ invoice: result });

        setSaved(false); // Okay, we SAVED it, but we still want the buttons to be available.
        setIsDirty(false);
        setResolvedSomething(false);
      } else {
        createToast({
          title: `Save Resolved Issues`,
          description: `No result returned.`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
    } catch (e: any) {
      createToast({
        title: `Save Resolved Issues`,
        description: `${e.message ?? e.response?.data?.message}`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
      console.error({ e });
    }
    setIsRestActive((p) => ({ ...p, saveResolved: false }));
  };

  const saveActiveIndicator = useMemo(() => {
    const saveResolvedEnabled =
      (resolvedSomething &&
        !saved &&
        !isRestActive.resolve &&
        !isRestActive.download &&
        !isRestActive.downloadComplete &&
        !isRestActive.downloadFields &&
        !isRestActive.test &&
        !isRestActive.submit) ||
      isDirty;
    if (!saveResolvedEnabled || readonly) return null;
    return (
      <div className="absolute top-1 right-4">
        {isRestActive.saveResolved ? (
          <Loading style={`tiny-inline`} color="text-tray-600 mt-1" />
        ) : null}
      </div>
    );
  }, [
    isDirty,
    isRestActive.download,
    isRestActive.downloadComplete,
    isRestActive.downloadFields,
    isRestActive.resolve,
    isRestActive.saveResolved,
    isRestActive.submit,
    isRestActive.test,
    readonly,
    resolvedSomething,
    saved,
  ]);

  const [autoSaveHandler, setAutoSaveHandler] = useState<NodeJS.Timeout | null>(
    null,
  );

  const resolveCallback = ({
    resolveId,
  }: {
    resolveId: number | null;
    location: any;
  }) => {
    if (typeof resolveId !== 'number') return;
    if (!issues) return;
    if (!issues[resolveId]) return;
    const clone = ChiroUpJSON.clone(invoice);
    clone.statusData.items[0].issues.splice(resolveId, 1);
    setInvoiceData(() => clone);
    setTimeout(() => {
      setSaved(false);
    }, 100);

    if (autoSaveHandler) {
      clearTimeout(autoSaveHandler);
    }
    setAutoSaveHandler(
      setTimeout(() => {
        _save(null, clone);
        setAutoSaveHandler(null);
      }, 1250), // debouce 1.25 seconds
    );

    setResolvedSomething(true);
    setIsDirty(true);
  };

  const age = useMemo(() => {
    if (!invoice || !invoice.transactionDate) return null;
    const now = new Date().getTime();
    const delta = (now - (invoice?.transactionDate ?? 0)) / 1000 / 60 / 60 / 24;
    return Math.floor(delta);
  }, [invoice]);

  const statusDataItem = useMemo(() => {
    if (invoice && invoice.id !== invoiceId) return null;
    return typeof invoice?.statusData === 'object'
      ? invoice?.statusData?.items?.[0]
      : null;
  }, [invoice, invoiceId]);

  const submitInvoice = useCallback(
    async (invoice: IntegrationInvoice, testOnly?: boolean) => {
      /**
       * Known state please...
       */
      testOnly = !!testOnly;
      const isNotTestOnly = !testOnly,
        statusWas = invoice.status;

      setSaved(false);
      let result = null;
      let res = {
        count: 0,
        issues: [] as BusinessRuleItemType[],
      };

      if (
        invoice.status === InvoiceStatusEnum.Submitted &&
        invoice.electronicBilling === false &&
        invoice.generation !== InvoiceGenerationEdiXref.Original
      ) {
        // console.log(invoice, metaContext);
      } else {
        res = businessRulesService.invoiceStatusTransition({
          invoice: { ...invoice, ...metaContext?.[invoice.id] },
          targetStatus: InvoiceStatusEnum.Submitted,
          // trace: true,
        });
      }

      if (res.count) {
        setSaved(true);
        const clone = ChiroUpJSON.clone(invoice);
        if (clone?.statusData?.items?.[0]?.issues) {
          clone.statusData.items[0].issues = res.issues;
        } else {
          clone.statusData = clone.statusData ?? {};
          clone.statusData.items = res.issues;
        }

        updateInvoiceCallback({
          invoice: clone,
          refetch: statusWas && clone.status && statusWas !== clone.status,
        });
        setInvoiceData(clone);
        return;
      }

      if (testOnly) {
        setIsRestActive((p) => ({ ...p, test: true }));
      } else {
        setIsRestActive((p) => ({ ...p, submit: true }));
      }

      try {
        result = (await patientBillingService.get837P({
          clinicId,
          payload: {
            context: {
              invoiceIds: [invoice.id],
              update: isNotTestOnly,
              targetStatus: InvoiceStatusEnum.Submitted,
              meta: { ...metaContext },
            },
          },
        })) as any;
        /**
         * Look for overall issues.
         */
        let allIssues = [];
        if (result?.meta?.issues?.length) {
          createToast({
            title: `Submit Invoice`,
            description: `${
              result?.meta?.issues?.length
                ? result?.meta?.issues
                    .map((i: BusinessRuleItemType) => i.text)
                    .join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
          allIssues = result?.meta?.issues ?? [];
        }

        /**
         * Invoice-specific issues.
         */
        if (result?.processed?.[invoice.id].issues?.length) {
          allIssues = allIssues.concat(result?.processed?.[invoice.id].issues);
        }

        if (allIssues.length === 0) {
          createToast({
            title: testOnly ? `Test` : `Submit`,
            description: `Invoice successfully processed.`,
            type: ToastTypes.Success,
            duration: 5000,
          });
          if (
            invoice.electronicBilling &&
            !testOnly &&
            !invoice?.integrationActive
          ) {
            trivialDownload({ content: result?.data ?? '' });
          }
        }

        setSaved(testOnly ? false : true);
      } catch (e: any) {
        console.error({ e });
        createToast({
          title: testOnly ? `Test` : `Submit`,
          description: `${e.message ?? e.response?.data?.me}`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }

      if (result?.invoice) {
        updateInvoiceCallback({
          invoice: result?.invoice,
          refetch: statusWas !== result.invoice.status,
        });
        setInvoiceData(result.invoice);
      }

      if (testOnly) {
        setIsRestActive((p) => ({ ...p, test: false }));
      } else {
        setIsRestActive((p) => ({ ...p, submit: false }));
      }
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      metaContext,
      clinicId,
      updateInvoiceCallback,
      setInvoiceData,
      setSaved,
      createToast,
    ],
  );

  const downloadCMS1500 = useCallback(
    async (invoice: IntegrationInvoice, complete: boolean = true) => {
      if (isRestActive.download) return;
      setIsRestActive((p) => ({ ...p, download: true }));
      try {
        const res = (await patientBillingService.downloadCMS1500({
          clinicId,
          invoiceId: invoice.id,
          complete,
        })) as any;
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = (reader.result as string)
            ?.replace(/"/g, '')
            ?.replace(/^data:application\/pdf;base64,/, '');

          try {
            const binaryString = atob(base64String);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            trivialDownload({
              content: bytes,
              name: `CMS1500_${invoice.number}.pdf`,
              mimeType: 'application/pdf',
            });
          } catch (error) {
            console.error('Error decoding Base64:', error);
          }
        };
        // reader.readAsDataURL(res);
        reader[
          import.meta.env.VITE_ACTUAL_STAGE === 'local'
            ? 'readAsDataURL'
            : 'readAsText'
        ](res);
      } catch (e: any) {
        createToast({
          title: `Download`,
          description: `${e.message ?? e.response?.data?.message}`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
        console.error({ e });
      }
      setIsRestActive((p) => ({ ...p, download: false }));
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      clinicId,
      createToast,
      isRestActive.download,
      setResolvedSomething,
      setIsDirty,
    ],
  );

  const resolve = useCallback(
    async (invoice: IntegrationInvoice) => {
      if (isRestActive.resolve || saved) return;
      const toastTitle = 'Resolve';
      setIsRestActive((p) => ({ ...p, resolve: true }));
      try {
        const result = await businessRulesService.resolveInvoiceIssues({
          clinicId,
          invoice, // The invoice should JUST have any unresolved issues or no issues.
          promote: true, // Move to unsubmitted if no errors.
          trace,
        });
        if (trace) clog({ resolveInvoiceIssues_result: { result } });

        /**
         * We get back and updated invoice with any potential issues that
         * were found.
         */
        const statusData = result.statusData as InvoiceStatusData,
          items = statusData?.items ?? [],
          latest = items[0] ?? null,
          issues = latest?.issues ?? [];

        if (issues.length) {
          createToast({
            title: toastTitle,
            description: `${
              issues?.length
                ? issues.map((i: BusinessRuleItemType) => i.text).join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
        } else {
          createToast({
            title: toastTitle,
            description: `Invoice successfully processed.`,
            type: ToastTypes.Success,
            duration: 5000,
          });
        }

        updateInvoiceCallback({ invoice: result });
        setInvoiceData(result);
        setSaved(false);
      } catch (e: any) {
        console.error({ e });
        createToast({
          title: `Save`,
          description: `${e.message ?? e.response?.data?.message}`.replace(
            /(^"|"$)/g,
            '',
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
      setIsRestActive((p) => ({ ...p, resolve: false }));
      setIsDirty(true);
    },
    [
      clinicId,
      createToast,
      isRestActive.resolve,
      saved,
      setInvoiceData,
      trace,
      updateInvoiceCallback,
    ],
  );

  const test = useCallback(
    async ({
      invoice,
      targetStatus = InvoiceStatusEnum.Unsubmitted,
    }: {
      invoice: IntegrationInvoice;
      targetStatus?: InvoiceStatusEnum;
    }) => {
      if (isRestActive.test || saved) return;
      setIsRestActive((p) => ({ ...p, test: true }));
      let result: any | null = null;
      const statusWas = invoice.status;

      try {
        result = await patientBillingService.get837P({
          clinicId,
          payload: {
            context: {
              invoiceIds: [invoice.id],
              update: false,
              targetStatus,
              meta: { ...metaContext },
            },
          },
        });
        // result = await businessRulesService.apply({
        //   clinicId,
        //   body: {
        //     rule: 'invoice.0001',
        //     context: {
        //       invoice,
        //       targetStatus,
        //       meta: { ...metaContext },
        //     },
        //     update: invoice.status !== targetStatus,
        //   },
        // });

        /**
         * Look for overall issues.
         */
        // let allIssues = [];
        if (result?.meta?.issues?.length) {
          createToast({
            title: `Submit Invoice`,
            description: `${
              result?.meta?.issues?.length
                ? result?.meta?.issues
                    .map((i: BusinessRuleItemType) => i.text)
                    .join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
          // allIssues = result?.meta?.issues ?? [];
        }

        /**
         * Invoice-specific issues.
         */
        // if (result?.processed?.[invoice.id].issues?.length) {
        //   allIssues = allIssues.concat(result?.processed?.[invoice.id].issues);
        // }

        if (trace) clog({ resolveInvoiceIssues_result: { result } });
        if (result?.invoice) {
          updateInvoiceCallback({
            invoice: result?.invoice,
            refetch: statusWas !== result.invoice.status,
          });
          setInvoiceData(result.invoice);
        }
        setSaved(true);
      } catch (e: any) {
        createToast({
          title: `${invoice.status} to ${targetStatus}`,
          description: `${e.message ?? e.response?.data?.message}`.replace(
            /(^"|"$)/g,
            '',
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
        console.error({ e });
      }
      setIsRestActive((p) => ({ ...p, test: false }));
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      clinicId,
      createToast,
      isRestActive.test,
      metaContext,
      saved,
      setInvoiceData,
      trace,
      updateInvoiceCallback,
    ],
  );

  const buttonsByInvoiceStatus = useCallback(
    (invoice: IntegrationInvoice) => {
      if (readonly) return null;
      const status = invoice?.status,
        statusData =
          typeof invoice?.statusData === 'string'
            ? ChiroUpJSON.parse(invoice?.statusData)
            : invoice?.statusData,
        items = statusData?.items ?? [],
        latest = items[0] ?? null,
        issues = latest?.issues ?? [],
        noIssues = latest ? latest?.issues?.length === 0 : true, // Issues must be explicitly set.
        updateStatusButton =
          validTransitions.length > 1 ? ( // Its current status and one other.
            <Button
              className={classNames(shrinkButtons ? 'h-10' : '')}
              text={shrinkButtons ? '' : 'Status'}
              onClick={() => {
                setUpdatingStatus(true);
              }}
              icon={<CogIcon />}
              tooltipIconClassName="text-gray-600 w-3 h-3 ml-2"
              color={ButtonColors.plainWithBorder}
              loading={isRestActive.updateStatus}
              disabled={updatingStatus}
              tooltip="Update the invoice status."
            />
          ) : null;
      switch (status) {
        case InvoiceStatusEnum.Pending:
        case InvoiceStatusEnum.Rejected:
          if (noIssues) {
            return (
              <div className="flex flex-row justify-end space-x-4">
                {updateStatusButton}
                <Button
                  text="Test"
                  onClick={() => {
                    test({ invoice });
                  }}
                  loading={isRestActive.test}
                  disabled={isRestActive.test || saved || isRestActive.submit}
                  tooltip={[
                    'This will run the business rules',
                    'and move the invoice to unsubmitted if there are no issues.',
                  ].join(' ')}
                  color={ButtonColors.primary}
                />
                <Button
                  text="Submit"
                  onClick={() => {
                    submitInvoice(invoice);
                  }}
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  loading={isRestActive.submit}
                  disabled={isRestActive.submit || saved || isRestActive.save}
                  tooltip={[
                    'This will run the business rules and submit the invoice if there',
                    'are no issues.',
                  ].join(' ')}
                  color={ButtonColors.primary}
                />
              </div>
            );
          }
          return (
            <div className="flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text={!hasIssues ? 'Resolve' : 'Save'}
                onClick={() => {
                  resolve(invoice);
                }}
                loading={isRestActive.resolve}
                disabled={
                  !resolvedSomething ||
                  isRestActive.resolve ||
                  saved ||
                  isRestActive.submit
                }
                tooltip={[
                  'This will resolve the issues and try to move the invoice to unsubmitted.',
                ].join(' ')}
                color={ButtonColors.primary}
              />
              <Button
                text="Submit"
                onClick={() => {
                  submitInvoice(invoice);
                }}
                icon={<CloudArrowUpIcon className="h-6 w-6" />}
                loading={isRestActive.submit}
                disabled={
                  issues.length !== 0 ||
                  isRestActive.submit ||
                  saved ||
                  isRestActive.resolve ||
                  isRestActive.test
                }
                tooltip={[
                  'This will run the business rules and submit the invoice if there',
                  'are no issues.',
                ].join(' ')}
                color={ButtonColors.primary}
              />
            </div>
          );
        case InvoiceStatusEnum.Unsubmitted: {
          return (
            <div className="w-full flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text="Test"
                onClick={() => {
                  submitInvoice(invoice, true);
                }}
                disabled={submitDisabled}
                loading={isRestActive.test}
                color={ButtonColors.primary}
              />
              <Button
                text={invoice.electronicBilling ? 'Submit' : 'Generate'}
                icon={<CloudArrowUpIcon className="h-6 w-6" />}
                onClick={() => {
                  submitInvoice(invoice);
                }}
                disabled={submitDisabled}
                loading={isRestActive.submit}
                color={ButtonColors.primary}
              />
            </div>
          );
        }
        case InvoiceStatusEnum.Received:
        case InvoiceStatusEnum.Partial: {
          return (
            <div>
              {updateStatusButton}
              <Button
                text="ERA"
                tooltip="Manual ERA data entry."
                icon={<PencilIcon />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  navigate(`/billing/invoices/${invoice.id}`);
                }}
              />
            </div>
          );
        }
        case InvoiceStatusEnum.Submitted: {
          return (
            <>
              {updateStatusButton}
              <Button
                text="ERA"
                tooltip="Manual ERA data entry."
                icon={<PencilIcon />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  navigate(`/billing/invoices/${invoice.id}`);
                }}
              />
              {invoice?.generation !== InvoiceGenerationEdiXref.Original &&
              invoice?.generation !== InvoiceGenerationEdiXref.Replacement ? (
                <Button
                  text="Submit"
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  onClick={() => {
                    submitInvoice(invoice);
                  }}
                  disabled={submitDisabled}
                  loading={isRestActive.submit}
                  color={ButtonColors.primary}
                />
              ) : invoice?.electronicBilling !== false ? (
                <Button
                  text="Download"
                  icon={<CloudArrowDownIcon className="h-6 w-6" />}
                  onClick={async () => {
                    if (!invoice) return;
                    if (!invoice.exchangeId) {
                      createToast({
                        title: `Download`,
                        description: `No exchangeId found.`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      setSaved(true);
                      return;
                    }
                    setIsRestActive((p) => ({ ...p, download: true }));
                    try {
                      const res = (await integrationService.download({
                        clinicId: invoice.clinicId,
                        exchangeId: invoice.exchangeId,
                      })) as any;
                      if (res && res.body && res.body.length > 0) {
                        trivialDownload({ content: res.body });
                      }
                    } catch (e: any) {
                      setSaved(true); // Yes, I know. We're not saving...so sue me.
                      createToast({
                        title: `Download`,
                        description: `${
                          e.message ?? e.response?.data?.message
                        }`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      console.error({ hereIsTheE: e });
                    }
                    setIsRestActive((p) => ({ ...p, download: false }));
                    // setIsDirty(true);
                  }}
                  disabled={isRestActive.download}
                  loading={isRestActive.download}
                  color={ButtonColors.primary}
                />
              ) : (
                <div
                  className={classNames(
                    shrinkButtons ? 'space-x-2' : 'space-x-3',
                  )}
                >
                  <Button
                    text="Complete"
                    icon={<CloudArrowDownIcon className="h-6 w-6" />}
                    tooltip="Download 1500 form with fields filled in."
                    onClick={async () => {
                      if (!invoice) return;
                      if (!invoice.exchangeId) {
                        createToast({
                          title: `Download`,
                          description: `No exchangeId found.`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        setSaved(true);
                        return;
                      }
                      setIsRestActive((p) => ({
                        ...p,
                        downloadComplete: true,
                      }));
                      try {
                        await downloadCMS1500(invoice);
                        // trivialDownload({ content: res });
                      } catch (e: any) {
                        setSaved(true); // Yes, I know. We're not saving...so sue me.
                        createToast({
                          title: `Download`,
                          description: `${
                            e.message ?? e.response?.data?.message
                          }`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        console.error({ hereIsTheE: e });
                      }
                      setIsRestActive((p) => ({
                        ...p,
                        downloadComplete: false,
                      }));
                      // setIsDirty(true);
                    }}
                    disabled={
                      isRestActive.downloadComplete ||
                      isRestActive.download ||
                      isRestActive.downloadFields
                    }
                    loading={isRestActive.downloadComplete}
                    color={ButtonColors.primary}
                  />
                  <Button
                    text="Fields"
                    icon={<CloudArrowDownIcon className="h-6 w-6" />}
                    tooltip="Download a blank page with the fields in the correct positions."
                    onClick={async () => {
                      if (!invoice) return;
                      if (!invoice.exchangeId) {
                        createToast({
                          title: `Download`,
                          description: `No exchangeId found.`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        setSaved(true);
                        return;
                      }
                      setIsRestActive((p) => ({ ...p, downloadFields: true }));
                      try {
                        await downloadCMS1500(invoice, false);
                        // trivialDownload({ content: res });
                      } catch (e: any) {
                        setSaved(true); // Yes, I know. We're not saving...so sue me.
                        createToast({
                          title: `Download`,
                          description: `${
                            e.message ?? e.response?.data?.message
                          }`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        console.error({ hereIsTheE: e });
                      }
                      setIsRestActive((p) => ({ ...p, downloadFields: false }));
                      // setIsDirty(true);
                    }}
                    disabled={
                      isRestActive.downloadComplete ||
                      isRestActive.download ||
                      isRestActive.downloadFields
                    }
                    loading={isRestActive.downloadFields}
                    color={ButtonColors.primary}
                  />
                </div>
              )}
            </>
          );
        }
        case InvoiceStatusEnum.Waiting:
          /**
           * An invoice without a status or a priority has not been looked
           * at by the business rules. Probably an initial invoice. This
           * should only happen when priority is not zero. The user has no
           * operations to perform if it is not the primary.
           */
          if (noStatus || invoice.priority === 0) {
            return (
              <>
                {updateStatusButton}
                <Button
                  text="Test"
                  onClick={() => {
                    test({
                      invoice,
                      targetStatus: InvoiceStatusEnum.Unsubmitted,
                    });
                  }}
                  loading={isRestActive.test}
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  disabled={isRestActive.test || saved || isRestActive.submit}
                  color={ButtonColors.primary}
                  tooltip="This will run the business rules and move the invoice to unsubmitted if there are no issues."
                />
              </>
            );
          }
          return null;

        default:
          if (trace) clog(`No button for status=${status}.`);
          return updateStatusButton;
      }
    },
    [
      hasIssues,
      isRestActive.resolve,
      isRestActive.submit,
      isRestActive.test,
      noStatus,
      readonly,
      submitDisabled,
      validTransitions,
      createToast,
      downloadCMS1500,
      isRestActive.download,
      isRestActive.downloadComplete,
      isRestActive.downloadFields,
      isRestActive.save,
      isRestActive.updateStatus,
      navigate,
      resolve,
      resolvedSomething,
      saved,
      shrinkButtons,
      submitInvoice,
      test,
      trace,
      updatingStatus,
    ],
  );

  if (state === OpenClosedStates.Closed) return null;
  if (!invoice) {
    if (trace) clog('The invoice is empty.');
    // return null;
  }

  const moreDetailClassName = 'text-xs text-gray-600';

  return (
    <Modal
      isOpen={state === OpenClosedStates.Open ? true : false}
      omitClasses="sm:max-w-lg"
      addClasses="sm:max-w-xl"
      className="inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full print:hidden sm:max-w-lg"
    >
      {/* <pre>{ChiroUpJSON.pretty(validTransitions)}</pre> */}
      <div className="flex flex-row w-full mb-4 rounded-lg overflow-hidden rounded-b-none">
        <div className="grow bg-primary-500 text-white font-bold flex flex-row space-x-4 justify-center text-lg pt-6 p-4">
          {invoice.id === invoiceId ? (
            <>
              <div>
                {invoice.uiLegalName ?? <cite>- Payor not Available -</cite>}
              </div>
              <Loading
                flag={toggleRestActive || (isFetching && !restFailed)}
                style={`tiny-inline-white`}
              />
            </>
          ) : (
            <div className="italic text-sm">
              {restFailed ? 'Error' : 'Loading...'}
            </div>
          )}
        </div>

        <div className="bg-primary-500 text-white p-4">
          <button
            onClick={() => {
              componentClose(isDirty);
              if (isDirty) {
                updateInvoiceCallback({ invoice: null, refetch: isDirty });
              }
              setTimeout(() => {
                setSaved(false);
                setResolvedSomething(false);
                setRestFailed(false);
              }, 750);
            }}
          >
            <XCircleIcon className="h-8 w-8" />
          </button>
        </div>
      </div>
      <div className="px-6">
        {invoice.id === invoiceId ? (
          <HashCardDisplay
            header={<div>Invoice Detail</div>}
            headerComponentRight={
              <div className="flex flex-row space-x-4">
                <div className="text-center w-2/3 py-1 px-3 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                  {PayorPriorityDisplay[invoice.priority ?? 0]}
                </div>
                <div className="flex flex-row">
                  <div className="whitespace-nowrap text-center py-1 px-4 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                    {invoice.status}
                  </div>
                  <TrivialTooltip text={InvoiceStatusTips[invoice.status]} />
                </div>
              </div>
            }
            containerClassName="w-full mt-8"
            noHover={true}
            skipNullValues={true}
            grid={{ cols: 3, labelSpan: 1, valueSpan: 2 }}
            addClassName={'shadow-lg'}
            hash={{
              Patient: (
                <div className="flex flex-col w-full">
                  <div className="flex">
                    {invoice?.patient?.displayName ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames('flex', moreDetailClassName)}>
                    {invoice?.patient?.dateOfBirth
                      ? '(' +
                        ChiroUpDayJsCommon.display.date(
                          invoice.patient.dateOfBirth,
                        ) +
                        ' age ' +
                        calculatePatientAge({
                          dob: invoice.patient.dateOfBirth,
                        }) +
                        ')'
                      : null}
                  </div>
                </div>
              ),
              'Primary Insured': (
                <div className="flex flex-col">
                  <div>
                    {primaryInsuredTypeDisplay(invoice?.primaryInsured?.type)}
                  </div>
                  {invoice?.primaryInsured?.type ===
                  PrimaryInsuredTypes.Patient ? null : (
                    <div className={classNames('flex', moreDetailClassName)}>
                      {primaryInsuredNameDisplay(invoice?.primaryInsured)}
                    </div>
                  )}
                  <div className={classNames('flex', moreDetailClassName)}>
                    Member Id:{' '}
                    {invoice?.primaryInsured?.memberId ?? <cite>-na-</cite>}
                  </div>
                </div>
              ),
              'Transaction Date':
                rawInvoice?.billingKey && rawInvoice?.patientId ? (
                  <Link
                    to={`/patients/${rawInvoice?.patientId}/billing/${rawInvoice?.billingKey}`}
                    className="text-primary-600 hover:text-primary-700"
                  >
                    <div className="flex flex-row space-x-2">
                      <div>
                        <TimestampDisplay
                          ts={invoice.transactionDate}
                          itemTz={invoice.tz}
                          userTz={userTz}
                          asDateOnly={true}
                        />
                      </div>
                      {age ? (
                        <div className="flex flex-row">
                          ({age})
                          <TrivialTooltip text="(nnn) - The number of days since the transaction date." />
                        </div>
                      ) : null}
                    </div>
                  </Link>
                ) : (
                  <div className="flex flex-row space-x-2">
                    <div>
                      <TimestampDisplay
                        ts={invoice.transactionDate}
                        itemTz={invoice.tz}
                        userTz={userTz}
                      />
                    </div>
                    {age ? (
                      <div className="flex flex-row">
                        ({age})
                        <TrivialTooltip text="(nnn) - The number of days since the transaction date." />
                      </div>
                    ) : null}
                  </div>
                ),
              Number: invoice.number ?? invoice.controlNumber,
              'EDI Batch': invoice.interchangeControlNumber ? (
                <div className="flex flex-row">
                  <div>{invoice.interchangeControlNumber}</div>
                  <div className="cursor-pointer hover:text-primary-500">
                    <Link to={`/billing/exchanges?id=${invoice.exchangeId}`}>
                      <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                    </Link>
                  </div>
                </div>
              ) : null,

              Created: (
                <TimestampDisplay
                  ts={invoice.createdAt}
                  itemTz={invoice?.tz}
                  userTz={userTz}
                />
              ),
              Updated: invoice?.statusAt ? (
                <div>
                  {/* <pre>
                    {invoice?.statusAt} <br />
                    {new Date(invoice?.statusAt).toISOString()}
                    <br />
                    {new Date(invoice?.statusAt).toString()}
                  </pre> */}
                  <TimestampDisplay
                    ts={invoice.statusAt}
                    itemTz={invoice?.tz}
                    userTz={userTz}
                    errorValue={null}
                  />
                </div>
              ) : null,
              'Billed Amount':
                invoice?.payorLineItems && invoice.id === invoiceId
                  ? null
                  : invoice.billedAmount,
              Balance:
                invoice?.payorLineItems && invoice.id === invoiceId
                  ? null
                  : invoice.balance,
              // This looks screwy, but it puts in things that must be filled in
              // depending on the invoice state and the age of the transaction.
              ...metaByInvoiceStatus({
                invoice,
                readonly,
                callback: (args: any) => {
                  updateInvoiceCallback(args);
                  const invoice = args?.invoice || null;
                  if (invoice) setInvoiceData(invoice);
                },
              }),
              'Electronic Billing': (
                <div
                  className="flex flex-row space-x-4"
                  title={`${
                    invoice.electronicBilling
                      ? 'Electronic-billing automation.'
                      : 'Paper-billing automation.'
                  }`}
                >
                  <Toggle
                    value={!!invoice.electronicBilling}
                    disabled={disableToggle || anyRestActive}
                    onChange={async (_: any) => {
                      await toggleElectronicBilling();
                      setIsDirty(true);
                    }}
                  />
                  {invoice.electronicBilling ? null : (
                    <div className="w-full flex justify-end">
                      <span className="rounded-lg bg-yellow-300 pt-1 px-2 mt-1 text-[10px] leading-[10px] font-light text-gray-600 ml-2 whitespace-nowrap h-5">
                        Paper Billing
                      </span>
                    </div>
                  )}
                </div>
              ),
              'Billing Profile': (
                <div className="flex flex-col w-full">
                  <div className="">
                    {invoice?.billingProfile?.name ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames(moreDetailClassName)}>
                    {invoice?.billingProfile?.npi
                      ? `(NPI #: ${invoice.billingProfile.npi})`
                      : null}
                  </div>
                </div>
              ),
              Provider: (
                <div className="flex flex-col w-full">
                  <div className="">
                    {invoice?.provider?.name ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames(moreDetailClassName)}>
                    {invoice?.provider?.npi
                      ? `(NPI #: ${invoice.provider.npi})`
                      : null}
                  </div>
                </div>
              ),
              'Place of Service': invoice?.pos ? invoice.pos : null,
            }}
            tips={{
              Generation: [
                'Replacement: Required when the payor paid',
                'incorrectly or an element of data on the claim was either not previously',
                'sent or needs to be corrected, for example rendering provider, units,',
                'amounts, or billing codes.',
                'Void: Required to eliminate the previously submitted claim when a claim',
                'is submitted in error or should not have been submitted. The entire claim',
                'must match the original with the exception of the claim frequency code,',
                'condition code, payer assigned claim number, and the patient control number.',
                'Voiding claims result in the recoupment of any payments made by the Payor',
                'and should only be used when all other measures have been taken.',
              ].join(' '),
            }}
          />
        ) : (
          <>
            <Loading style={`standard-gray`} flag={isFetching && !restFailed} />
            {restFailed ? (
              <div className="pb-4 italic">The invoice failed to load.</div>
            ) : null}
          </>
        )}
        {statusDataItem ? (
          <div className="relative">
            <HashCardDisplay
              containerClassName="w-full mt-8"
              header={`Last Status Update`}
              noHover={true}
              addClassName={'shadow-lg'}
              headerComponentRight={saveActiveIndicator}
              grid={{ cols: 3, labelSpan: 1, valueSpan: 2 }}
              hash={{
                Timestamp: (
                  <TimestampDisplay
                    ts={statusDataItem.ts}
                    itemTz={invoice?.tz}
                    userTz={userTz}
                  />
                ),
                Previous: statusDataItem.previous ?? <cite>-na-</cite>,
                Final: statusDataItem.final ?? <cite>-na-</cite>,
                Issues: issues.length ? issues : null, // Null omits these.
                Notes: notes.length ? notes : null, // Null omits the line.
              }}
              components={{
                Issues: (
                  <div>
                    {issues.length
                      ? issues.map((item, i) => {
                          return (
                            <div
                              key={`brs-${i}`}
                              className={classNames(
                                'px-4 pb-1',
                                HashCardDisplayColors[i % 2],
                                i === 0 ? 'pt-1' : '',
                              )}
                            >
                              <BusinessRuleSolution
                                result={item}
                                workTextErrors={true}
                                resolveId={i}
                                resolveCallback={resolveCallback}
                                trace={trace}
                                isRestActive={anyRestActive}
                                data={{
                                  billingKey: invoice.billingKey,
                                  clinicId,
                                }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                ),
                Notes: (
                  <div>
                    {notes.length
                      ? notes.map((item, i) => {
                          return (
                            <div
                              key={`brs-${i}`}
                              className={classNames(
                                'px-4 pb-1',
                                HashCardDisplayColors[i % 2],
                                i === 0 ? 'pt-1' : '',
                              )}
                            >
                              <BusinessRuleSolution
                                result={item}
                                workTextErrors={true}
                                resolveId={i}
                                resolveCallback={resolveCallback}
                                trace={trace}
                                isRestActive={anyRestActive}
                                data={{
                                  billingKey: invoice.billingKey,
                                  clinicId,
                                }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                ),
              }}
            />
          </div>
        ) : null}
        {invoice?.payorLineItems &&
          invoice?.payorLineItems?.length !== 0 &&
          invoice.id === invoiceId && (
            <div className="mt-8">
              <HashCardDisplay
                header={<div>Billed Services</div>}
                components={{
                  ...invoice.payorLineItems.reduce((acc, pli, idx) => {
                    acc[`${pli.payorId}.${pli.controlNumber}`] = (
                      <div
                        className={classNames(
                          HashCardDisplayColors[idx % 2],
                          'grid grid-cols-4',
                        )}
                      >
                        <div className="px-4 pt-2">
                          <span className=" text-gray-400">Service</span>
                          <TrivialTooltip
                            type="info"
                            text={`(${pli.status}) - ${pli.description}`}
                          />
                        </div>
                        <div className="px-4 pt-2 opacity-50 flex justify-end">
                          Units
                        </div>
                        <div className="px-4 pt-2 opacity-50 flex justify-end">
                          Billed
                        </div>
                        <div className="px-4 pt-2 opacity-50 flex justify-end">
                          Balance
                        </div>
                        <div className="px-4 pb-2">
                          {pli.submittedProcedureCode}
                          <TrivialTooltip
                            type="info"
                            text={pli.controlNumber}
                            tipClassName="w-64"
                          />
                        </div>
                        <div className="px-4 pb-2 flex justify-end">
                          {pli.submittedUnits}
                        </div>
                        <div className="px-4 pb-2 flex justify-end">
                          <Currency value={pli.billedAmount} />
                        </div>
                        <div className="px-4 pb-2 flex justify-end">
                          <Currency
                            value={
                              (pli.billedAmount ?? 0) -
                              (pli.remitAmount ?? 0) -
                              (pli.adjustmentTotal ?? 0)
                            }
                          />
                        </div>
                        {pli.remitDate && (
                          <div className="border-t border-gray-900/5 px-6 py-6 col-span-4">
                            <div className="underline">Payment</div>

                            <div className="flex w-full mb-5">
                              <div className="flex justify-between text-gray-500 text-sm w-full">
                                <div className="flex flex-row space-x-2">
                                  <TimestampDisplay
                                    ts={pli.remitDate}
                                    itemTz={invoice?.tz}
                                    format={ChiroUpDayJsCommon.format.date}
                                    userTz={userTz}
                                  />{' '}
                                  {pli.postedDate ? (
                                    <>
                                      <span>posted</span>
                                      <TimestampDisplay
                                        ts={pli.postedDate}
                                        itemTz={invoice?.tz}
                                        userTz={userTz}
                                        format={ChiroUpDayJsCommon.format.date}
                                      />
                                    </>
                                  ) : null}
                                </div>
                                <div>{formatCurrency(pli.remitAmount)}</div>
                              </div>
                            </div>

                            <div className="underline">Adjustments</div>
                            {pli.adjustments?.map((adj: any, index: number) => (
                              <div
                                key={`pli-${pli.id}-adj-${adj.groupCode}-${index}`}
                              >
                                <div
                                  key={adj.groupCode}
                                  className="flex flex-row text-sm"
                                >
                                  <div className="flex flex-col">
                                    <div>{adj.groupDescription}</div>
                                  </div>
                                </div>

                                {adj.items.map(
                                  (item: any, itemIndex: number) => (
                                    <div key={`adj-${itemIndex}`}>
                                      <div className="flex w-full"></div>
                                      <div
                                        key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}`}
                                        className="flex justify-between text-gray-500 text-sm w-full mb-2"
                                      >
                                        <div>{item.description}</div>
                                        <div className="self-end">
                                          {formatCurrency(item.amount)}
                                        </div>
                                      </div>

                                      <div className="w-full text-xs">
                                        {item.remarks &&
                                          item.remarks.length > 0 && (
                                            <div className="flex flex-row">
                                              <div className="flex flex-col">
                                                <div className="text-gray-500">
                                                  {item.remarks.map(
                                                    (
                                                      remark: any,
                                                      remarkIndex: number,
                                                    ) => (
                                                      <div
                                                        key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}-remark-${remark.code}-${remarkIndex}`}
                                                        className="flex flex-row mt-2 text-sm"
                                                      >
                                                        <div className="flex flex-col">
                                                          <div className="text-gray-500">
                                                            {remark.description}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ),
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            ))}
                            {!pli.adjustments ||
                              (pli.adjustments?.length === 0 && (
                                <div className="flex w-full">
                                  <div className="flex justify-between text-gray-500 text-sm w-full">
                                    <div>No adjustments were made.</div>
                                  </div>
                                </div>
                              ))}
                            {(pli?.remarks?.length ?? 0) !== 0 && (
                              <div className="mt-6">Additional Remarks</div>
                            )}
                            {pli.remarks?.map(
                              (remark: any, remarkIndex: number) => (
                                <div
                                  key={`pli-${pli.id}-remark-${remark.code}-${remarkIndex}`}
                                  className="flex flex-row mt-2 text-sm"
                                >
                                  <div className="flex flex-col">
                                    <div className="text-gray-500">
                                      {remark.description}
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                            {/* {!pli.remarks ||
                            (pli.remarks?.length === 0 && (
                              <div className="flex w-full">
                                <div className="flex justify-between text-gray-500 text-sm w-full">
                                  <div>No remarks</div>
                                </div>
                              </div>
                            ))} */}

                            {(pli?.internalNotes?.length ?? 0) > 0 && (
                              <div className="mt-6">Internal Notes</div>
                            )}
                            {pli.internalNotes?.map(
                              (note: any, noteIndex: number) => (
                                <div
                                  key={`pli-${pli.id}-remark-${note.createdAt}-${noteIndex}`}
                                  className="flex flex-row mt-2 text-sm"
                                >
                                  <div className="flex flex-col">
                                    <div className="text-gray-500">
                                      {note.message}
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                            {/* {!pli.internalNotes ||
                            (pli.internalNotes?.length === 0 && (
                              <div className="flex w-full">
                                <div className="flex justify-between text-gray-500 text-sm w-full">
                                  <div>No notes</div>
                                </div>
                              </div>
                            ))} */}
                          </div>
                        )}
                      </div>
                    );
                    return acc;
                  }, {} as STRING_ANY_HASH),
                  Balance: (
                    <div
                      className={classNames(
                        'bg-gray-100 border-t-2 border-gray-200',
                        'grid grid-cols-4',
                      )}
                    >
                      <div>&nbsp;</div>
                      <div className="px-4 py-2 flex justify-end">&nbsp;</div>
                      <div className="px-4 py-2 flex justify-end">
                        <Currency value={invoice.billedAmount} />
                      </div>
                      <div className="px-4 py-2 flex justify-end">
                        <Currency value={invoice.balance} />
                      </div>
                    </div>
                  ),
                }}
                containerClassName="w-full mt-8"
                noHover={true}
                skipNullValues={true}
                addClassName={'shadow-lg'}
              />
            </div>
          )}
      </div>
      {invoice.id === invoiceId ? (
        <>
          {updatingStatus && (
            <div className="w-100 p-6 grid grid-cols-7">
              <div className="flex flex-grow w-full col-span-6">
                <Select
                  label="New Status"
                  name="newStatus"
                  value={newStatus}
                  options={validTransitions}
                  onChange={(val: any) => {
                    if (!val) return;
                    setNewStatus(val);
                  }}
                  limit={1}
                  className={`w-full`}
                />
              </div>
              <div className="flex space-x-2 justify-start mt-9 ml-4">
                <IconButton
                  className="h-5 w-5 hover:text-primary-500 text-gray-600 pt-0.5"
                  icon={<XCircleIcon className="h-6 w-6" />}
                  tooltip="Cancel"
                  onClick={() => {
                    setUpdatingStatus(false);
                  }}
                  disabled={isRestActive.updateStatus}
                />
                <IconButton
                  onClick={async () => {
                    if (!newStatus) return;
                    setSaved(false);
                    setUpdatingStatus(false);
                    setIsRestActive((p) => ({ ...p, updateStatus: true }));
                    const clone = ChiroUpJSON.clone(invoice);
                    clone.status = newStatus;

                    try {
                      const results =
                          await patientBillingService.setInvoiceStatus({
                            clinicId,
                            invoiceId: invoice.id,
                            status: newStatus,
                            persistIssues: true,
                          }),
                        result = results && results[0];

                      setInvoiceData(result);
                      updateInvoiceCallback({ invoice: result, refetch: true });

                      setSaved(true);
                    } catch (e: any) {
                      createToast({
                        title: `Update Status`,
                        description: `${
                          e.message ?? e.response?.data?.message
                        }`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      console.error({ e });
                    }
                    setSaved(false);
                    setIsRestActive((p) => ({ ...p, updateStatus: false }));
                  }}
                  disabled={
                    isRestActive.updateStatus ||
                    newStatus === null ||
                    newStatus === invoice.status
                  }
                  loading={isRestActive.updateStatus}
                  className="h-5 w-5 hover:text-primary-500 text-gray-600"
                  icon={icons.saveDisk}
                  tooltip="Save"
                />
              </div>
            </div>
          )}
          <div
            className={classNames(
              'flex justify-end grow p-6',
              shrinkButtons ? 'space-x-2' : 'space-x-4',
            )}
          >
            {buttonsByInvoiceStatus(invoice)}
          </div>
        </>
      ) : null}
      {trace ? (
        <div>
          <pre>
            {ChiroUpJSON.pretty({
              hasIssues,
              saved,
              latestInvoiceMessages,
              invoiceId,
            })}
          </pre>
        </div>
      ) : null}
      <MakeBrowserWait isWaiting={anyRestActive} />
    </Modal>
  );
};

export default CommonInvoiceModal;
