import { Button, ButtonColors } from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import { TransactionItemSubtypeEnum } from '@chiroup/core/types/PatientTransaction.type';

type Props = {
  containerClassName?: string;
  subtype: TransactionItemSubtypeEnum;
  setActiveSubtype: React.Dispatch<React.SetStateAction<string>>;
};

export const NetItemSubtypeNotImplemented: React.FC<Props> = ({
  containerClassName = 'w-full',
  subtype,
  setActiveSubtype,
}) => {
  const onChangeHandler = async (e: any) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    setActiveSubtype('');
  };

  if (!subtype) return null;

  return (
    <div
      className={classNames(
        containerClassName,
        'flex flex-col items-center border border-gray-300 rounded-md p-4',
      )}
    >
      <div className="m-4">{subtype}: Not implemented.</div>
      <div className="block">
        <Button
          onClick={onChangeHandler}
          text="Close"
          color={ButtonColors.primary}
        />
      </div>
    </div>
  );
};
