import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../../../contexts/me.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import { PatientTransactionActivity } from '@chiroup/core/types/PatientTransaction.type';

const getQuery = function (clinicId: number) {
  return async (context: QueryFunctionContext) => {
    const id = context.queryKey[1] as string;
    if (id === 'add') {
      return {} as any;
    }
    return patientBillingService.listPatientTransactionActivity(clinicId, id);
  };
};

const useTransactionActivity = ({ billingKey }: { billingKey: string }) => {
  const { me } = useContext(MeContext);
  const { status, data, error, isFetching, refetch } = useQuery<
    PatientTransactionActivity[]
  >(['purchaseActivity', billingKey], getQuery(me?.selectedClinic?.ID || -1), {
    // getNextPageParam: (lastGroup) => lastGroup?.skip || undefined,
    refetchOnWindowFocus: false,
  });

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
  };
};

export default useTransactionActivity;
