import { useState, useRef, useEffect, useCallback } from 'react';
import { PayRequest } from '../transactions/PatientPaymentModal';

const useTerminalTransactionWatcher = () => {
  const [terminalTransaction, setTerminalTransaction] =
    useState<PayRequest | null>(null);
  const callbackRef = useRef<((transaction: any) => void) | null>(null);

  // Function to subscribe to state changes
  const subscribeToTransactionStateChange = useCallback(
    (callback: (transaction: any) => void) => {
      callbackRef.current = callback;

      // Return the unsubscribe function
      return () => {
        callbackRef.current = null;
      };
    },
    [],
  );

  useEffect(() => {
    // Call the callback when terminalTransaction changes
    if (callbackRef.current) {
      callbackRef.current(terminalTransaction);
    }
  }, [terminalTransaction]);

  const waitForTransaction = useCallback(() => {
    return new Promise((resolve, reject) => {
      // Subscribe and get the unsubscribe function
      const unsubscribe = subscribeToTransactionStateChange((newState) => {
        if (newState.complete) {
          unsubscribe(); // Unsubscribe as soon as we know we're done
          if (newState.error) {
            reject(newState.error);
          } else if (newState.result?.toLowerCase() === 'approved') {
            resolve('approved');
          }
        }
      });

      // Optional: If you need to handle the component unmounting before the transaction completes
      // return a cleanup function from here that calls unsubscribe
      return unsubscribe;
    });
  }, [subscribeToTransactionStateChange]);

  return { terminalTransaction, setTerminalTransaction, waitForTransaction };
};

export default useTerminalTransactionWatcher;
