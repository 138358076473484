import {
  Button,
  ButtonColors,
  InputMasked,
  LoadingPage,
} from '@chiroup/components';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../../common/Modal';
import { useContext, useEffect, useState } from 'react';
import useInvoice from './Invoice/hooks/useInvoice';
import patientBillingService from '../../../../../services/patientBilling.service';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import { useQueryClient } from 'react-query';
import { useForm } from '@chiroup/hooks';

type Props = {
  refetch: () => void;
  refetchBalance?: () => void;
};

const validation = {
  amount: {
    function: {
      value: (value: any) => {
        const { amount, max } = value;
        if (parseFloat(amount) <= 0) {
          return 'Amount must be greater than 0';
        } else if (parseFloat(amount) > parseFloat(max)) {
          return 'Amount must be less than or equal to the balance';
        }
        return false;
      },
    },
  },
};

const WriteOffModal: React.FC<Props> = ({ refetch, refetchBalance }) => {
  const [id, setId] = useState<number | null>(null);
  const [updating, setUpdating] = useState(false);
  const { me } = useContext(MeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isFetching } = useInvoice({
    invoiceId: id ?? -1,
  });

  const { createToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { value, errors, onChange, patchValue, registerSubmit } = useForm<{
    amount: number;
    max: number;
  }>(
    {
      amount: data?.invoiceData?.balance || 0,
      max: data?.invoiceData?.balance || 0,
    },
    validation,
  );

  const onSuccess = () => {
    closeModal();
  };

  useEffect(() => {
    if (
      data?.invoiceData?.balance &&
      (!value.amount || value.max !== data?.invoiceData.balance)
    ) {
      patchValue({
        amount: data.invoiceData.balance,
        max: data.invoiceData.balance,
      });
    }
  }, [data, data?.invoiceData, onChange, patchValue, value, value.amount]);

  const onFail = () => {
    createToast({
      type: ToastTypes.Fail,
      title: 'Error',
      description: 'There was an error writing off this invoice.',
    });
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const writeOffIndex = pathSegments.findIndex(
      (segment) => segment === 'write-off',
    );
    setId(writeOffIndex > 0 ? +pathSegments[writeOffIndex - 1] : null);
  }, [location.pathname]);

  const closeModal = () => {
    const path = location.pathname;
    const nextToLastIndex = path.lastIndexOf('/', path.lastIndexOf('/') - 1);
    const newPath =
      nextToLastIndex > 0 ? path.substring(0, nextToLastIndex) : '/';
    navigate(newPath + location?.search || '');
  };

  const writeOff = async () => {
    try {
      setUpdating(true);
      const res = await patientBillingService.writeOffPatientInvoice({
        invoiceId: id || -1,
        clinicId: me.selectedClinic?.ID || -1,
        amount: value.amount || 0,
      });

      queryClient.setQueryData(['invoice', id], res);
      queryClient.invalidateQueries('transaction', {
        refetchActive: true,
        refetchInactive: false,
      });
      queryClient.invalidateQueries(['transactions', data?.patientId]);
      refetch();
      refetchBalance?.();
      onSuccess();
    } catch (e) {
      console.error(e);
      onFail();
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Modal isOpen close={closeModal}>
      <div>
        {isFetching ? (
          <LoadingPage />
        ) : (
          <>
            <div className="mt-3 text-center sm:mt-5 flex flex-col gap-6">
              <div className="flex flex-col">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
                  id="modal-headline"
                >
                  Write Off
                </h3>
              </div>
              <form
                className="space-y-6"
                onSubmit={registerSubmit(writeOff, {})}
              >
                <InputMasked
                  className="w-full"
                  label={`Write off amount`}
                  name="amount"
                  placeholder="0.00"
                  value={value.amount}
                  onChange={onChange('amount')}
                  errors={errors?.fieldErrors?.amount}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                />
              </form>
            </div>
            <div className={'mt-6 flex flex-row gap-2'}>
              <Button
                text={'Write off'}
                fullWidth
                onClick={registerSubmit(writeOff, {})}
                loading={updating}
                disabled={updating || isFetching}
              />
              <Button
                text="Close"
                onClick={closeModal}
                fullWidth
                color={ButtonColors.plain}
                className="border border-gray-300 dark:border-darkGray-600"
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default WriteOffModal;
