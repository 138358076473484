import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import settingsService from '../../../services/settings.service';
import { DisciplineTreatment } from '@chiroup/core/types/Discipline.type';

const query = (clinicId: number) => {
  return async (context: QueryFunctionContext) => {
    const id = Number(context.queryKey[1]);
    return settingsService.getDisciplineTreatment(clinicId, id);
  };
};

const useDisciplineTreatments = (id?: number | null) => {
  const { me } = useContext(MeContext);
  const { data, isFetching } = useQuery<DisciplineTreatment>(
    ['disciplineTreatments', id],
    query(me?.selectedClinic?.ID || -1),
    {
      refetchOnWindowFocus: false,
      // staleTime = 24 hours
      staleTime: 1000 * 60 * 60 * 24,
      enabled: !!id,
    },
  );

  return {
    data,
    isFetching,
  };
};

export default useDisciplineTreatments;
