import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Checkbox, Input, InputMasked, Select } from '@chiroup/components';
import useHealthcareProvidersAll from '../../../../../hooks/useHealthcareProvidersAll';
import useClinicUsers from '../../../../../hooks/useClinicUsers';
import userService from '../../../../../services/user.service';
import { MeContext } from '../../../../../contexts/me.context';
import { combineUserDetails, providerOptionsMemo } from './helpers';
import HealthcareProviderCreate from '../../../../settings/database/healthcare-providers/HealthcareProviderCreate';
import { USStatesAndTerritories } from '@chiroup/core/constants/UsStatesAndTerritories';
import { titleCaseEnumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import {
  Visit,
  IdNumberType,
  ProviderType,
  ClaimNatureSelectOptions,
  EPSDTRefferalType,
} from '@chiroup/core/types/PatientVisit.type';
import {
  ProviderLicense,
  ProviderCommercialNumber,
} from '@chiroup/core/types/UserSettingsBilling.type';

type Props = {
  value: Partial<Visit>;
  setVisitForm: React.Dispatch<React.SetStateAction<Visit>>;
  disabled?: boolean;
};

const updateNestedFields = (
  setVisitForm: React.Dispatch<React.SetStateAction<Visit>>,
  key: string,
  value: any,
) => {
  setVisitForm((prev) => {
    return {
      ...prev,
      encounterInfo: {
        ...prev.encounterInfo,
        claimInfo: {
          ...prev.encounterInfo?.claimInfo,
          [key]: value,
        },
      },
    };
  });
};

const ClaimInfoForm: React.FC<Props> = ({ value, setVisitForm, disabled }) => {
  const {
    nature,
    onsetDate,
    // relatedEvent,
    // relatedEventDate,
    EPSDTRefferal,
    unableWorkStartDate,
    unableWorkEndDate,
    disabilityStartDate,
    disabilityEndDate,
    hospitalStartDate,
    hospitalEndDate,
    providerType,
    providerId,
    providerNpi,
    idNumber,
    idNumberType,
    employment,
    auto,
    autoState,
    otherAccident,
    accidentDate,
    acuteManifestationChronicConditionDate,
    firstVisitDate,
    initialTreatmentDate,
    lastXRayDate,
    latestVisitDate,
    prescriptionDate,
    reportEndDate,
    reportStartDate,
  } = value.encounterInfo?.claimInfo || {};

  const [claimInfoFormUsers, setClaimInfoFormUsers] = useState<any[]>([]);

  const { me } = useContext(MeContext);

  const { data: healthcareProviders, refetch } = useHealthcareProvidersAll();
  const { data: users } = useClinicUsers();

  const providerOptions = useMemo(
    () =>
      providerOptionsMemo(
        users ?? [],
        healthcareProviders?.data ?? [],
        providerType,
      ),
    [providerType, users, healthcareProviders?.data],
  );

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (users?.length && me?.selectedClinic?.ID) {
        try {
          const billingData = await userService.listBilling(
            me.selectedClinic.ID,
          );
          if (isMounted) {
            setClaimInfoFormUsers(combineUserDetails(users, billingData));
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    }; // Cleanup function to prevent memory leaks
  }, [users, me?.selectedClinic?.ID]);

  const handleProviderChange = (id: string | number) => {
    updateNestedFields(setVisitForm, 'providerId', id);
    updateNestedFields(setVisitForm, 'providerNpi', undefined);
    updateNestedFields(setVisitForm, 'idNumber', undefined);
    updateNestedFields(setVisitForm, 'idNumberType', undefined);
    let provider;
    if (typeof id === 'string') {
      provider = claimInfoFormUsers?.find((user) => user.ID === id);
    } else if (typeof id === 'number') {
      provider = healthcareProviders?.data?.find(
        (provider) => provider.ID === id,
      );
      updateNestedFields(setVisitForm, 'providerNpi', provider?.npiNumber);
    }
    updateNestedFields(setVisitForm, 'providerNpi', provider?.npiNumber);
    if (idNumberType) {
      switch (idNumberType) {
        case IdNumberType.StateLicenseNumber:
          updateNestedFields(setVisitForm, 'idNumber', provider?.licenseNumber);
          break;
        case IdNumberType.UPINNumber:
          updateNestedFields(setVisitForm, 'idNumber', provider?.upin);
          break;
        case IdNumberType.ProviderCommercialNumber:
          updateNestedFields(
            setVisitForm,
            'idNumber',
            provider?.commercialNumber,
          );
          break;
        default:
          break;
      }
    }
  };

  const handleIdTypeChange = (type: string) => {
    updateNestedFields(setVisitForm, 'idNumberType', type);
    if (providerId) {
      const provider =
        typeof providerId === 'string'
          ? claimInfoFormUsers?.find((user) => user.ID === providerId)
          : healthcareProviders?.data?.find(
              (provider) => provider.ID === providerId,
            );
      switch (type) {
        case IdNumberType.StateLicenseNumber:
          updateNestedFields(
            setVisitForm,
            'idNumber',
            provider?.licenseNumber ||
              provider?.stateLicenseNumber ||
              provider?.providerLicenses?.[0]?.licenseNumber,
          );
          break;
        case IdNumberType.UPINNumber:
          updateNestedFields(setVisitForm, 'idNumber', provider?.upin);
          break;
        case IdNumberType.ProviderCommercialNumber:
          updateNestedFields(
            setVisitForm,
            'idNumber',
            provider?.commercialNumber ||
              provider?.providerCommercialNumbers?.[0]?.number,
          );
          break;
        default:
          break;
      }
    }
  };

  const handleProviderTypeChange = (type: string) => {
    updateNestedFields(setVisitForm, 'providerType', type);
    updateNestedFields(setVisitForm, 'providerId', undefined);
    updateNestedFields(setVisitForm, 'providerNpi', undefined);
    updateNestedFields(setVisitForm, 'idNumber', undefined);
    updateNestedFields(setVisitForm, 'idNumberType', undefined);
  };

  const handleValueChange = useCallback(
    (key: string) => (eventValue: any) => {
      updateNestedFields(setVisitForm, key, eventValue);
    },
    [setVisitForm],
  );

  const onCreate = () => {
    refetch();
  };

  const getIdNumberOptions = useCallback(
    (type?: string, providerType?: string, providerId?: string) => {
      if (
        type &&
        providerId &&
        (providerType === ProviderType.OrderingProvider ||
          providerType === ProviderType.SupervisingProvider)
      ) {
        const provider = claimInfoFormUsers?.find(
          (user) => user.ID === providerId,
        );
        switch (type) {
          case IdNumberType.StateLicenseNumber:
            return provider?.providerLicenses?.map(
              (license: ProviderLicense) => ({
                text: license.licenseNumber,
                value: license.licenseNumber,
              }),
            );
          case IdNumberType.ProviderCommercialNumber:
            return provider?.providerCommercialNumbers?.map(
              (pcn: ProviderCommercialNumber) => ({
                text: pcn.number,
                value: pcn.number,
              }),
            );
          default:
            return [];
        }
      }
      return [];
    },
    [claimInfoFormUsers],
  );

  const idNumberOptions = useMemo(
    () => getIdNumberOptions(idNumberType, providerType, String(providerId)),
    [getIdNumberOptions, idNumberType, providerType, providerId],
  );

  return (
    <>
      <Select
        value={nature}
        onChange={handleValueChange('nature')}
        name="conditionNature"
        label="Nature of condition"
        options={ClaimNatureSelectOptions}
        className="col-span-2"
        limit={1}
        disabled={disabled}
        clearable
      />
      <InputMasked
        name="onsetDate"
        label="Onset of current symptoms or illness"
        value={onsetDate}
        onChange={handleValueChange('onsetDate')}
        className="col-span-2"
        disabled={disabled}
        patternFormat="##/##/####"
        placeholder="MM/DD/YYYY"
      />
      {/* <Select
        name="relatedEvent"
        label="Enter another date related to the condition"
        value={relatedEvent}
        onChange={handleValueChange('relatedEvent')}
        options={ClaimRelatedEventSelectOptions}
        className="col-span-2"
        limit={1}
        clearable
        disabled={disabled}
      />
      {!!relatedEvent && (
        <InputMasked
          name="relatedEventDate"
          label="Date"
          value={relatedEventDate}
          onChange={handleValueChange('relatedEventDate')}
          className="col-span-2"
          disabled={disabled}
          patternFormat="##/##/####"
          placeholder="MM/DD/YYYY"
        />
      )} */}
      <Select
        name="EPSDTRefferal"
        label="EPSDT Referral"
        value={EPSDTRefferal}
        onChange={handleValueChange('EPSDTRefferal')}
        options={titleCaseEnumToArrayOfOptions(EPSDTRefferalType)}
        className="col-span-2"
        limit={1}
        disabled={disabled}
      />

      <div className=" p-4 col-span-4 bg-gray-300 rounded-md ">
        <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
          <p className="col-span-4">Condition Related Dates</p>
          <InputMasked
            name="acuteManifestationChronicConditionDate"
            label="Acute Manifestation of a Chronic Condition"
            value={acuteManifestationChronicConditionDate}
            onChange={handleValueChange(
              'acuteManifestationChronicConditionDate',
            )}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="firstVisitDate"
            label="Property & Casualty First Date of Contact"
            value={firstVisitDate}
            onChange={handleValueChange('firstVisitDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="initialTreatmentDate"
            label="Initial Treatment"
            value={initialTreatmentDate}
            onChange={handleValueChange('initialTreatmentDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="lastXRayDate"
            label="Last X-Ray"
            value={lastXRayDate}
            onChange={handleValueChange('lastXRayDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="latestVisitDate"
            label="Latest Visit or Consultation"
            value={latestVisitDate}
            onChange={handleValueChange('latestVisitDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="prescriptionDate"
            label="Prescription"
            value={prescriptionDate}
            onChange={handleValueChange('prescriptionDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="reportStartDate"
            label="Report Start (Assumed Care Date)"
            value={reportStartDate}
            onChange={handleValueChange('reportStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="reportEndDate"
            label="Report End (Relinquished Care Date)"
            value={reportEndDate}
            onChange={handleValueChange('reportEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <p className="pt-6">Other Related Dates</p>
          <p className="col-span-4 pt-2 font-light text-md">
            Dates patient unable to work
          </p>
          <InputMasked
            name="unableWorkStartDate"
            label="Start Date"
            value={unableWorkStartDate}
            onChange={handleValueChange('unableWorkStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="unableWorkEndDate"
            label="End Date"
            value={unableWorkEndDate}
            onChange={handleValueChange('unableWorkEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <p className="col-span-4 pt-4 font-light text-md">Disability dates</p>
          <InputMasked
            name="disabilityStartDate"
            label="Start Date"
            value={disabilityStartDate}
            onChange={handleValueChange('disabilityStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="disabilityEndDate"
            label="End Date"
            value={disabilityEndDate}
            onChange={handleValueChange('disabilityEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <p className="col-span-4 pt-4 font-light text-md">
            Hospital dates related to current services
          </p>
          <InputMasked
            name="hospitalStartDate"
            label="Start Date"
            value={hospitalStartDate}
            onChange={handleValueChange('hospitalStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <InputMasked
            name="disabilityEndDate"
            label="End Date"
            value={hospitalEndDate}
            onChange={handleValueChange('hospitalEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        </div>
      </div>
      <div className="col-span-4 pt-4 relative">
        <p>Provider Details</p>
        <span className="absolute right-0">
          <HealthcareProviderCreate onCreate={onCreate} providerOnly />
        </span>
      </div>
      <Select
        label="Provider type"
        name="providerType"
        options={titleCaseEnumToArrayOfOptions(ProviderType)}
        value={providerType}
        // onChange={handleValueChange('providerType')}
        onChange={handleProviderTypeChange}
        className="col-span-2"
        limit={1}
        clearable
        disabled={disabled}
      />
      {!!providerType && (
        <>
          <Select
            label="Provider"
            name="providerName"
            value={providerId}
            onChange={handleProviderChange}
            options={providerOptions}
            autocomplete
            limit={1}
            className="col-span-2"
            disabled={disabled}
          />
          {!!providerId && (
            <Input
              name="providerNPI"
              label="Provider NPI"
              value={providerNpi}
              onChange={handleValueChange('providerNpi')}
              className="col-span-2"
              disabled={disabled}
            />
          )}
          {!!providerId && (
            <Select
              value={idNumberType}
              name="idNumberType"
              label="ID number type"
              onChange={handleIdTypeChange}
              options={titleCaseEnumToArrayOfOptions(IdNumberType)}
              className="col-span-2"
              limit={1}
              clearable
              disabled={disabled}
            />
          )}
          {!!idNumberType && providerType === ProviderType.ReferringProvider ? (
            <Input
              value={idNumber}
              onChange={handleValueChange('idNumber')}
              name="idNumber"
              label="ID number"
              className="col-span-2"
              disabled={disabled}
            />
          ) : !!idNumberType &&
            (providerType === ProviderType.SupervisingProvider ||
              providerType === ProviderType.OrderingProvider) ? (
            idNumberType === IdNumberType.UPINNumber ? (
              <Input
                value={idNumber}
                onChange={handleValueChange('idNumber')}
                name="idNumber"
                label="ID number"
                className="col-span-2"
                disabled={disabled}
              />
            ) : (
              <Select
                value={idNumber}
                name="idNumber"
                label="ID number"
                onChange={handleValueChange('idNumber')}
                options={idNumberOptions}
                limit={1}
                className="col-span-2"
                hint={
                  !idNumberOptions?.length
                    ? 'You have no ID numbers of this type'
                    : undefined
                }
                clearable
                disabled={disabled || !idNumberOptions?.length}
              />
            )
          ) : null}
        </>
      )}

      <div className="p-4 col-span-4 bg-gray-300 rounded-md">
        <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
          <p className="col-span-4">Condition Related To:</p>
          <Checkbox
            name="employment"
            value={employment ?? false}
            onChange={handleValueChange('employment')}
            label="Employment"
            className="col-span-4"
            disabled={
              disabled ||
              (!!value?.encounterInfo?.claimInfo?.auto &&
                !!value?.encounterInfo?.claimInfo?.otherAccident)
            }
          />
          <div className="col-span-1 flex items-center ">
            <Checkbox
              name="auto"
              value={auto ?? false}
              onChange={handleValueChange('auto')}
              label="Auto accident"
              disabled={
                disabled ||
                (!!value?.encounterInfo?.claimInfo?.employment &&
                  !!value?.encounterInfo?.claimInfo?.otherAccident)
              }
            />
          </div>

          <div className="col-span-3 flex items-center ">
            {auto && (
              <>
                <InputMasked
                  name="accidentDate"
                  label="Accident Date"
                  value={accidentDate}
                  onChange={handleValueChange('accidentDate')}
                  className="col-span-2 mr-4"
                  disabled={disabled}
                  patternFormat="##/##/####"
                  placeholder="MM/DD/YYYY"
                />
                <Select
                  name="autoState"
                  value={autoState ?? false}
                  onChange={handleValueChange('autoState')}
                  label="State accident occurred in"
                  options={USStatesAndTerritories}
                  limit={1}
                  autocomplete
                  disabled={disabled}
                />
              </>
            )}
          </div>

          <Checkbox
            name="other"
            value={otherAccident}
            onChange={handleValueChange('otherAccident')}
            label="Other accident"
            className="col-span-1"
            disabled={
              disabled ||
              (!!value?.encounterInfo?.claimInfo?.auto &&
                !!value?.encounterInfo?.claimInfo?.employment)
            }
          />
          {otherAccident && (
            <div className="col-span-3 flex items-center ">
              <InputMasked
                name="accidentDate"
                label="Accident Date"
                value={accidentDate}
                onChange={handleValueChange('accidentDate')}
                className="col-span-2"
                disabled={disabled}
                patternFormat="##/##/####"
                placeholder="MM/DD/YYYY"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ClaimInfoForm;
