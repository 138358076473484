import { PatientInvoice } from '@chiroup/core/types/PatientInvoice.type';
import { TiptapEditor } from '../../../../../common/tiptap/TiptapEditor';

type Props = {
  invoiceData: PatientInvoice;
  setInvoiceData: React.Dispatch<
    React.SetStateAction<PatientInvoice | undefined>
  >;
};

const InvoiceNotes: React.FC<Props> = ({ invoiceData, setInvoiceData }) => {
  return (
    <div className="mb-6 px-4 pb-8">
      <TiptapEditor
        value={invoiceData?.notes}
        label="Notes"
        onChange={(e) => {
          setInvoiceData((prev) => {
            if (!prev) return prev;
            return { ...prev, notes: e };
          });
        }}
      />
    </div>
  );
};
export default InvoiceNotes;
