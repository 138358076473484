import { Currency } from '@chiroup/components';
import { InvoiceDocument } from '@chiroup/core/types/PatientInvoice.type';

type Props = {
  subtotal: number;
  tax: number;
  total: number;
  balance: number;
  notes?: InvoiceDocument;
};

const InvoiceTotals: React.FC<Props> = ({
  subtotal = 0,
  tax = 0,
  total = 0,
  balance = 0,
  notes,
}) => {
  const renderNotes = () => {
    // Ensure that notes.content is an array
    if (!Array.isArray(notes?.content)) {
      return null;
    }

    return notes?.content?.map((paragraph, index) => (
      <ul className="text-sm" key={index}>
        {paragraph.content?.map((item, itemIndex) => (
          <li key={itemIndex}>{item.text}</li>
        ))}
      </ul>
    ));
  };
  return (
    <div className="p-2 avoid-page-break ">
      <div className="flex justify-between space-x-4 ">
        {/* First Column: Notes and Balance Due */}
        <div className=" print:border rounded-md text-xs mt-2 w-1/2 ">
          {/* Row for Notes Label */}
          <div className="hidden print:block mb-1 p-2 bg-gray-100 rounded">
            <span className="text-md font-semibold">Notes:</span>
          </div>

          {/* Row for Balance Due Upon Receipt */}

          {/* Row for Notes Content */}
          <div className="hidden print:block p-2">
            <div className="mb-1">Balance Due Upon Receipt</div>
            {renderNotes()}
          </div>
        </div>
        {/* Second Column: Totals */}
        <div className="p-4 print:p-2 border rounded-md text-xs mt-2 w-1/2">
          <div className="flex items-center mb-1">
            <span>Subtotal:</span>
            <span className="flex-grow border-b border-dotted border-gray-300  pt-3"></span>
            <Currency value={subtotal} />
          </div>
          <div className="flex items-center mb-1">
            <span>Tax:</span>
            <span className="flex-grow border-b border-dotted border-gray-300  pt-3"></span>
            <Currency value={tax} />
          </div>
          <div className="flex items-center mb-1">
            <span>Total:</span>
            <span className="flex-grow border-b border-dotted border-gray-300  pt-3"></span>
            <Currency value={total} />
          </div>
          <div className="flex items-center">
            <span>Remaining Balance:</span>
            <span className="flex-grow border-b border-dotted border-gray-300 pt-3"></span>
            <Currency value={balance} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTotals;
