import { useNavigate } from 'react-router-dom';
import Modal from '../../../../common/Modal';
import { databaseService } from '../../../../settings/database/database.service';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import { v4 } from 'uuid';
import { useContext, useMemo, useState } from 'react';
import { MeContext } from '../../../../../contexts/me.context';
import useSupplements from '../../../../../hooks/useSupplements';

import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import Button, { ButtonColors } from '../../../../common/Button';
import { LoadingPage } from '@chiroup/components';
import Scrollbars from 'react-custom-scrollbars-2';
import Header from '../../../../layout/Header';
import {
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
  TransactionTypeEnum,
  toTransactionItemSubtype,
} from '@chiroup/core/types/PatientTransaction.type';
import { ReferenceCodeDisplay } from '@chiroup/core/types/ReferenceCode.type';

type PackageSupplementsModalProps = {
  patientId: string;
  transaction: PatientTransaction | null;
  addPendingItems: (items: PatientTransactionItemType[]) => void;
  isOpen?: boolean;
  isRouted?: boolean;
  onClose?: () => void;
};

const SupplementsModal = ({
  isOpen,
  isRouted = true,
  onClose,
  patientId,
  transaction,
  addPendingItems,
}: PackageSupplementsModalProps) => {
  const navigate = useNavigate();
  const close = () => {
    if (!isRouted) {
      onClose?.();
      return;
    }
    navigate(`/patients/${patientId}/billing/${transaction?.billingKey}`);
  };
  const { me } = useContext(MeContext);
  const [isRestActive, setIsRestActive] = useState<boolean>(false);
  const { createToast } = useContext(ToastContext);
  const { data, isFetching } = useSupplements({
    omitOnline: true,
    getReferenceCodes: true,
  });

  const supplementIdsHash: { [key: number]: boolean } = useMemo(() => {
    return transaction?.items?.reduce((obj: any, item) => {
      if (item.subtype === TransactionItemSubtypeEnum.Supplement) {
        obj[item?.supplementId || ''] = item.units;
      }
      return obj;
    }, {});
  }, [transaction?.items]);

  const handleSupplementSelectedClick = async (id: any) => {
    if (!id || supplementIdsHash?.[id]) return;
    setIsRestActive(true);
    const groupId = v4();
    const supplement = data?.data?.find((s) => s.ID === id);
    const serviceItems: any = [];
    if (!supplement) {
      createToast({
        title: 'Not found!',
        description: <>The selected supplement or service was not available!</>,
        type: ToastTypes.Info,
        duration: 5000,
      });
      return;
    }
    /**
     * Two Use Cases:
     *    1) AdHoc transaction that _does not_ get any services.
     *    2) Hybrid which can have services.
     */
    if (
      supplement.referenceCodes?.length &&
      (transaction?.type === TransactionTypeEnum.Hybrid ||
        transaction?.type === TransactionTypeEnum.Encounter)
    ) {
      const allUsedServiceCodes: string[] = supplement.referenceCodes.reduce(
        (a: string[], c: ReferenceCodeDisplay) => {
          a.push(c.code);
          return a;
        },
        [],
      );
      if (allUsedServiceCodes.length) {
        const { data: completeListofServices } =
          (await databaseService.getCodes({
            clinicId: me?.selectedClinic?.ID,
            codes: allUsedServiceCodes,
          })) as { data: any };
        const services =
          completeListofServices && Array.isArray(completeListofServices)
            ? completeListofServices
            : [completeListofServices];
        services.forEach((service) => {
          if (service.amount) {
            supplement.price = 0;
            serviceItems.push(
              toTransactionItemSubtype(TransactionItemSubtypeEnum.Service, {
                ...service,
                groupId,
              }),
            );
          }
        });
      }
    }
    const supplementItem = toTransactionItemSubtype(
      TransactionItemSubtypeEnum.Supplement,
      {
        ...supplement,
        groupId: serviceItems.length > 0 ? groupId : null,
        supplementId: id,
      },
    );
    supplementItem.id = v4();
    // return [supplementItem].concat(serviceItems);
    return addPendingItems([supplementItem].concat(serviceItems));
  };

  return (
    <Modal isOpen={isRouted ? true : !!isOpen} close={close} addClasses="h-4/5">
      <Header title="Supplements and Supplies" />
      <Scrollbars className="w-full h-96 pt-6">
        {isFetching ? (
          <LoadingPage />
        ) : (
          <ul className="list-none gap-2 mt-4">
            {data?.data?.map((page, i: number) => (
              <li
                key={i}
                className={`mb-2 flex shadow-sm rounded-md select-none cursor-pointer col-span-1 ${
                  supplementIdsHash?.[page.ID] ? 'opacity-100' : 'opacity-40'
                } hover:opacity-100`}
                onClick={() => {
                  handleSupplementSelectedClick(page.ID).then((resp: any) => {
                    if (isRestActive) return;
                    addPendingItems(resp);
                    setIsRestActive(false);
                  });
                }}
              >
                {page.image && (
                  <img
                    src={page.image}
                    className="flex-shrink-0 flex items-center justify-center w-16 h-16 rounded-l-md border-l border-t border-b border-gray-300 dark:border-darkGray-800"
                    alt={page.name}
                  />
                )}
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-800 rounded-r-md">
                  <div className="flex-1 px-4 py-2 text-sm">
                    <div className="flex gap-4 justify-between">
                      <div className="flex gap-4">
                        <span title={page.name}>{page.name}</span>
                        <span>${page.price}</span>
                      </div>
                      {supplementIdsHash?.[page?.ID] && (
                        <CheckCircleIcon className="h-8 w-8 text-primary-500 mr-2" />
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Scrollbars>
      <Button
        text="Close"
        color={ButtonColors.plainWithBorder}
        className="mt-4"
        onClick={() => {
          close();
        }}
      />
    </Modal>
  );
};

export default SupplementsModal;
