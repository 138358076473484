import { classNames } from '@chiroup/core/functions/classNames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const CodeList = forwardRef(function CodeList(
  {
    items,
    command,
    loading,
  }: {
    items: {
      code: string;
      codeSet: string;
      description: string;
    }[];
    command: (args: { id: string; label: string }) => void;
    loading: boolean;
  },
  ref,
) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = items[index];

    if (item) {
      command({
        id: item.code,
        label: `${item.code} - ${item.description}`,
      });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="relative overflow-hidden rounded-lg bg-white p-1 text-sm text-zinc-700 shadow-md border border-gray-300">
      {loading ? (
        <div className="item">Loading...</div>
      ) : items.length ? (
        items.map((item, index) => (
          <button
            className={classNames(
              `item flex w-full flex-row items-center gap-1 rounded-md border-2 p-1 text-left hover:bg-zinc-100`,
              index === selectedIndex
                ? 'is-selected border-primary-500'
                : 'border-transparent',
            )}
            key={index}
            onClick={() => selectItem(index)}
          >
            <div className="flex items-center">
              <span
                className={classNames(
                  index === selectedIndex ? 'font-semibold' : 'font-normal',
                  'block truncate',
                )}
              >
                {item.code} - {item.description}
              </span>
            </div>
          </button>
        ))
      ) : (
        <div className="item">No matches found</div>
      )}
    </div>
  );
});

export default CodeList;
