/**
 * [2023-11-03.0952 by Brian]
 *    Added the ability to use click events as well as the standard route
 *    navigation. Hopefully, it is backwards compatible.
 */
import classNames from 'classnames';
import React, { ChangeEvent, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export type Tab = {
  name: string;
  href?: string;
  active?: boolean;
  transparent?: boolean;
};

type Props = {
  tabs: Tab[];
  rightSide?: React.ReactNode;
  onClick?: (e: ChangeEvent<HTMLSelectElement>, idx?: number) => void;
  transparent?: boolean;
};

const Tabs: React.FC<Props> = ({
  tabs,
  rightSide,
  onClick,
  transparent = false,
}) => {
  const [selectedValue, setSelectedValue] = React.useState<number>(0);
  const navigate = useNavigate();
  const useClickEvent = !!onClick;
  const handleClickEvent = onClick
    ? onClick
    : (_: ChangeEvent<HTMLSelectElement>, __?: number) => {
        console.warn('Tabs: no onClick.');
      };

  const handleLinkChange = (e: ChangeEvent<HTMLSelectElement>) => {
    navigate(e.target.value, { replace: true });
  };

  const val = useMemo(() => {
    if (useClickEvent) {
      // const value = tabs.find((tab) => tab.active)?.href;
      return selectedValue;
    }
    const value = tabs.find((tab) => tab.active)?.href;
    return value || 'General';
  }, [tabs, selectedValue, useClickEvent]);

  if (!tabs.length) {
    return null;
  }

  return (
    <>
      <div className="sm:hidden flex justify-center">
        <nav className="w-full flex justify-center relative">
          {Boolean(tabs.length) && (
            <select
              className="relative left-0 mt-4 block w-full rounded-md border-gray-300 text-base focus:border-primary-600 focus:outline-none focus:ring-primary-600 sm:text-md mx-3"
              onChange={(e: any) => {
                (useClickEvent ? handleClickEvent : handleLinkChange)(e);
                setSelectedValue(e.target.value);
              }}
              value={val}
            >
              {(tabs || []).map((tab, tabIdx) => (
                <option
                  className="absolute left-0 z-10"
                  value={useClickEvent ? tabIdx : tab.href}
                  key={useClickEvent ? tabIdx : tab.href}
                >
                  {tab.name}
                </option>
              ))}
            </select>
          )}
        </nav>
      </div>
      <div
        className={classNames(
          ' dark:bg-darkGray-900 overflow-x-auto hidden sm:block',
          transparent ? 'bg-transparent' : 'bg-white',
        )}
      >
        <div className="border-b border-gray-300 dark:border-darkGray-800 px-2 flex flex-row justify-between w-full">
          <nav className="-mb-px flex">
            {useClickEvent
              ? (tabs || []).map((tab, tabIdx) => (
                  <div
                    key={tabIdx}
                    className={[
                      'cursor-pointer whitespace-nowrap mr-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 dark:text-darkGray-400',
                      tab.active
                        ? 'border-primary-500 font-medium text-sm leading-5 text-primary-600 focus:outline-none'
                        : 'hover:text-gray-700 dark:hover:text-darkGray-300 hover:border-gray-300 dark:hover:border-gray-600 focus:outline-none focus:text-gray-700 focus:border-gray-300',
                    ].join(' ')}
                    onClick={(e: any) => {
                      handleClickEvent(e, tabIdx);
                    }}
                  >
                    {tab.name}
                  </div>
                ))
              : (tabs || []).map((tab) => (
                  <Link
                    key={tab.href}
                    to={tab.href || '/'}
                    className={[
                      'whitespace-nowrap mr-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 dark:text-darkGray-400',
                      tab.active
                        ? 'border-primary-500 font-medium text-sm leading-5 text-primary-600 focus:outline-none'
                        : 'hover:text-gray-700 dark:hover:text-darkGray-300 hover:border-gray-300 dark:hover:border-gray-600 focus:outline-none focus:text-gray-700 focus:border-gray-300',
                    ].join(' ')}
                  >
                    {tab.name}
                  </Link>
                ))}
          </nav>
          {rightSide}
        </div>
      </div>
    </>
  );
};

export default Tabs;
