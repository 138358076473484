import React, { useMemo } from 'react';

type Props = {
  url: string;
  className?: string;
  publicFile?: boolean;
};

const UploadedImage: React.FC<Props> = ({
  url,
  className = 'h-auto w-full',
  publicFile,
}) => {
  const urlToUse = useMemo(() => {
    if (publicFile) {
      if (url.includes('https://')) {
        return url;
      } else if (url) {
        return `${import.meta.env.VITE_S3_ASSETS_URL}/${url}`;
      }
    } else {
      return;
    }
  }, [url, publicFile]);

  return <img src={urlToUse} className={className} alt={urlToUse} />;
};

export default UploadedImage;
