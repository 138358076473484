import { Button, ButtonColors, Input } from '@chiroup/components';
import Modal from '../../common/Modal';
import { useContext, useState } from 'react';
import useDatabaseItem from '../../settings/database/useDatabaseItem';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import { InstanceKeys } from '@chiroup/core/constants/DatabaseFeatureCommon';

type Props = {
  showCaseTypeModal: boolean;
  close: () => void;
  patchValue?: (values: any) => void;
};

const CreateCaseTypeModal: React.FC<Props> = ({
  showCaseTypeModal,
  close,
  patchValue,
}) => {
  const { createToast } = useContext(ToastContext);
  const [customCaseType, setCustomCaseType] = useState<string>('');
  const { save, isSaving } = useDatabaseItem({
    instanceKey: InstanceKeys.caseTypes,
    id: 'add',
  });
  const onSubmit = async () => {
    try {
      const res = (await save({ name: customCaseType })) as {
        id: number;
        name: string;
      };
      patchValue?.({ caseType: res.id });
      close();
    } catch (e) {
      console.error(e);
      onFail();
    }
  };

  const onFail = () => {
    createToast({
      title: 'Sorry!',
      description: <div>The case type could not be saved</div>,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  return (
    <Modal isOpen={showCaseTypeModal} close={close}>
      <div className="bg-white  text-gray-700 dark:text-darkGray-100 font-medium grid-cols-4 gap-4 z-10">
        <div className="mb-6 sm:mb-0 w-full flex items-center col-span-4">
          <h3 className="text-sm leading-6 ">Custom Case Type</h3>
        </div>
        <Input
          name="customCaseType"
          value={customCaseType}
          onChange={setCustomCaseType}
          disabled={isSaving}
        />
      </div>
      <div className="mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense">
        <Button
          text="Close"
          onClick={close}
          fullWidth
          color={ButtonColors.plainWithBorder}
        />
        <Button
          text="Submit"
          onClick={onSubmit}
          fullWidth
          disabled={!customCaseType || isSaving}
          loading={isSaving}
          color={ButtonColors.primary}
        />
      </div>
    </Modal>
  );
};
export default CreateCaseTypeModal;
