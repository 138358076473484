import { Currency } from '@chiroup/components';
import { InvoicePayor } from '@chiroup/core/types/PatientInvoice.type';

type InvoiceTableRowProps = {
  item: InvoicePayor;
  index: number;
  lastIndex: number;
};
const PrintInvoicePayorTableRow: React.FC<InvoiceTableRowProps> = ({
  item,
  index,
  lastIndex,
}) => {
  return (
    <tr
      className={`text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800 ${
        item.hidden ? 'hidden' : ''
      }`}
    >
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.description}
      </td>
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.detail}
      </td>
      <td className="whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
        {item.courtesyBilling ? null : (
          <Currency
            value={index === lastIndex ? item.patientResponsibility : 0}
          />
        )}
      </td>
    </tr>
  );
};

export default PrintInvoicePayorTableRow;
