import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { formatLogoURL } from '../../../../../common/formatLogoUrl';

type Props = {
  location: Partial<ClinicLocation>;
  statement?: boolean;
};

const InvoiceClinicHeader: React.FC<Props> = ({
  location,
  statement = false,
}) => {
  const formatPhoneNumber = (phoneNumber: string) => {
    const digits = phoneNumber.replace(/\D/g, '');
    const areaCode = digits.substring(1, 4);
    const firstPart = digits.substring(4, 7);
    const secondPart = digits.substring(7, 11);
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  };

  if (!location) {
    return (
      <div className="container mx-auto p-4 print:p-2 flex justify-between items-center">
        <div>
          <cite>[ Location not available. ]</cite>
        </div>
      </div>
    );
  }

  return (
    <div className="py-2 px-2 flex justify-between items-center">
      <div className="flex items-center">
        {location?.logo && (
          <img
            src={formatLogoURL(location?.logo ?? '')}
            alt="Clinic Logo"
            width={75}
            className="mr-4"
          />
        )}
        <p className="text-2xl font-thin text-gray-700">{location.name}</p>
      </div>
      <div className="text-left pt-3 text-md text-gray-700">
        <div className="font-light">{location?.name ?? ''}</div>
        {location?.address1 && (
          <div className="text-gray-700 font-light">
            {location.address1 ?? ''}
          </div>
        )}
        {location?.address2 && (
          <div className="text-gray-700 font-light">
            {location.address2 ?? ''}
          </div>
        )}
        <div className="text-gray-700 font-light">
          {`${location?.city ?? ''}${
            location?.state ? `, ${location.state ?? ''}` : ''
          } ${location?.zip ?? ''}`}
        </div>
        {statement && location?.phone && (
          <div className="text-gray-700 font-light">
            {formatPhoneNumber(location.phone)}
          </div>
        )}
        {statement && location?.email && (
          <div className="text-gray-700 font-light">{location?.email}</div>
        )}
        {statement && location?.site && (
          <div className="text-gray-700 font-light">{location?.site}</div>
        )}
      </div>
    </div>
  );
};

export default InvoiceClinicHeader;
