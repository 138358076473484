import { LoadingPage } from '@chiroup/components';
import { useNativeTheme } from '@chiroup/hooks';
import * as Sentry from '@sentry/react';
// import { Hub } from 'aws-amplify/utils';
import { getCurrentUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { StopListenerCallback } from '@aws-amplify/core/dist/esm/Hub/types';
import { Suspense, lazy, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Loading from './components/common/Loading';
import Toasts from './components/common/toasts/Toasts';
import Container from './components/layout/Container';
import MarketingAddOns from './components/marketing-add-ons/MarketingAddOns';
import Schedule from './components/schedule/Schedule';
import { ThemeContext } from './contexts/theme.context';
import { ToastProvider } from './contexts/toast.context';
import { ManagedActionItemsProvider } from './contexts/managedActionItems.context';
import useLocalStorage, { LSType } from './hooks/useLocalStorage';
import { useScript } from './hooks/useScript';
import ConsolidatedTransactionModal from './components/patients/detail/billing/transactions/ConsolidatedTransactionModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const payJS: any;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// const forceLogoutTime = Number(import.meta.env.VITE_INACTIVITY_LOGOUT_TIME || 120);

const Home = lazy(() => import('./components/home/Home'));
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));
const Billing = lazy(() => import('./components/billing/BillingContainer'));
const Reporting = lazy(
  () => import('./components/reporting/ReportingContainer'),
);
const Patients = lazy(() => import('./components/patients/Patients'));
const ClinicalSkills = lazy(
  () => import('./components/clinical-skills/ClinicalSkills'),
);
const PracticeResources = lazy(
  () => import('./components/practice-resources/PracticeResources'),
);
const Settings = lazy(() => import('./components/settings/Settings'));
const ReportContainer = lazy(
  () => import('./components/report/ReportContainer'),
);
const Admin = lazy(() => import('./components/admin/Admin'));
const LoggedOut = lazy(() => import('./components/logged-out/LoggedOut'));
const Research = lazy(() => import('./components/research/Research'));

// const WhatsNew = lazy(() => import('./components/whats-new/WhatsNew'));

const queryClient = new QueryClient();
const PAY_JS_URL = import.meta.env.VITE_PAY_JS_URL as string;

function App() {
  const [authState, setAuthState] = useState<string>();
  const [user, setUser] = useState<any>();
  const nativeTheme = useNativeTheme();
  const [theme, setTheme] = useState<'light' | 'dark'>('light');
  const { setItem, getItem, removeItem } = useLocalStorage();
  const [appLoading, setAppLoading] = useState<boolean>(false);

  const scriptStatus = useScript(PAY_JS_URL, {
    removeOnUnmount: false,
  });

  useEffect(() => {
    if (scriptStatus === 'ready') {
      // Monkey patch the payJS object to update the addEventListner function
      (window as any).usaepay.PaymentCard.prototype.addEventListener =
        function (eventType: any, response: any) {
          const paymentCardiFrame =
            document.getElementById('paymentCardIFrame');

          function messageListener(event: any) {
            if (event.source === (paymentCardiFrame as any)?.contentWindow) {
              const msg = event.data.split('::');
              if (msg[0] === eventType) {
                response(msg[1]);
              }
            }
          }

          // Add the event listener
          window.addEventListener('message', messageListener);

          // Cleanup: Remove the event listener when the component unmounts
          return function cleanup() {
            window.removeEventListener('message', messageListener);
          };
        };
    } else if (scriptStatus === 'error') {
      // Handle script loading error here if needed.
      console.error('Failed to load useepay script.');
    }
  }, [scriptStatus]);

  // useEffect(() => {
  //   let isDestroyed = false;
  //   let interval: NodeJS.Timeout;
  //   const doIt = async () => {
  //     interval = setInterval(async () => {
  //       const issuedAt =
  //         AuthThing?.user?.signInUserSession?.accessToken?.payload?.iat;
  //       const lastAccessTokenExpiration = dayjs(convertToMS(issuedAt));
  //       const now = dayjs();

  //       const timeSinceLastAccessToken = now.diff(
  //         lastAccessTokenExpiration,
  //         'minutes'
  //       );
  //       if (!isDestroyed && timeSinceLastAccessToken >= forceLogoutTime) {
  //         setIsIdle(true);
  //       }
  //     }, 5000);
  //   };
  //   doIt();
  //   return () => {
  //     isDestroyed = true;
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (authState === 'authenticated' && isIdle) {
  //     setInactivityModalOpen(true);
  //   }
  // }, [isIdle, authState]);

  const toggleScheme = () => {
    const themeToUse = theme === 'light' ? 'dark' : 'light';
    setTheme(themeToUse);
    if (themeToUse === nativeTheme) {
      removeItem(LSType.user, 'theme');
    } else {
      setItem(LSType.user, 'theme', themeToUse);
    }
  };

  useEffect(() => {
    let isDestroyed = false;
    let stopListener: StopListenerCallback;
    const doIt = async () => {
      const currentUser = await getCurrentUser().catch((err) => {
        console.error({ err });
      });
      if (currentUser && !isDestroyed) {
        setAuthState('authenticated');
        setUser(currentUser);
      }
      stopListener = Hub.listen('auth', (data) => {
        if (!isDestroyed) {
          if (data.payload.event === 'tokenRefresh_failure') {
            setAuthState('signedOut');
            // setInactivityModalOpen(true);
          } else if (data.payload.event === 'signedOut') {
            setAuthState('signedOut');
          } else if (data.payload.event === 'signedIn') {
            setAuthState('authenticated');
            setUser(data.payload.data);
          }
        }
      });
    };
    doIt();
    return () => {
      isDestroyed = true;
      stopListener?.();
    };
  }, []);

  useEffect(() => {
    const savedTheme = getItem(LSType.user, 'theme');
    setTheme(savedTheme || nativeTheme);
  }, [nativeTheme, getItem]);

  useEffect(() => {
    document.body.classList.add('dark:bg-darkGray-900');
    document.body.classList.add('print:bg-white');
    document.body.classList.add('print:dark:bg-white');
    const method = theme === 'dark' ? 'add' : 'remove';
    document.documentElement.classList[method]('dark');
  }, [theme]);

  return (
    <QueryClientProvider client={queryClient}>
      <ManagedActionItemsProvider>
        <ToastProvider>
          <ThemeContext.Provider
            value={{
              theme,
            }}
          >
            <>
              <BrowserRouter>
                {appLoading ? (
                  <LoadingPage />
                ) : authState === 'authenticated' && user ? (
                  <Container>
                    <Suspense fallback={<Loading />}>
                      <SentryRoutes>
                        <Route path="/" element={<Home />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/billing/*" element={<Billing />} />
                        <Route path="/reporting/*" element={<Reporting />} />
                        <Route path="/patients/*" element={<Patients />} />
                        <Route path="/schedule/*" element={<Schedule />} />
                        <Route path="/research/*" element={<Research />} />
                        <Route path="/report/*" element={<ReportContainer />} />
                        <Route
                          path="/clinical-skills/*"
                          element={<ClinicalSkills />}
                        />
                        <Route
                          path="/practice-resources/*"
                          element={<PracticeResources />}
                        />
                        <Route
                          path="/settings/*"
                          element={
                            <Settings
                              theme={theme}
                              toggleScheme={toggleScheme}
                            />
                          }
                        />
                        <Route path="/admin/*" element={<Admin />} />
                        {/* <Route path="/news">
                        <WhatsNew />
                      </Route> */}
                        <Route
                          path="/marketing-add-ons/*"
                          element={<MarketingAddOns />}
                        />
                        <Route
                          path="consolidated/transaction/*"
                          element={
                            <ConsolidatedTransactionModal mode={'route'} />
                          }
                        />
                      </SentryRoutes>
                      {/* <Footer /> */}
                    </Suspense>
                  </Container>
                ) : (
                  <Suspense fallback={<Loading />}>
                    <div className="h-full flex flex-col">
                      <div style={{ minHeight: 'calc(100vh - 100px)' }}>
                        <LoggedOut
                          authState={authState}
                          setAppLoading={setAppLoading}
                          theme={theme}
                        />
                      </div>
                      {/* <LoggedOutFooter /> */}
                    </div>
                  </Suspense>
                )}
              </BrowserRouter>
              {/* <InactivityModal
              open={inactivityModalOpen}
              setOpen={setInactivityModalOpen}
              loggedOut={authState === 'signedOut'}
              setIsIdle={setIsIdle}
            /> */}
              <Toasts />
            </>
          </ThemeContext.Provider>
        </ToastProvider>
      </ManagedActionItemsProvider>
      <Tooltip id={`main-tooltip`} className="max-w-56 z-50" />
    </QueryClientProvider>
  );
}

export default App;
