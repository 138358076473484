import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { clog } from '@chiroup/core/functions/clog';
import { BadRequestError } from '@chiroup/core/functions/errors';
import {
  EraWithClaimsType,
  EraManualAddType,
  EraBatchManualAddType,
  ClaimResponseEraType,
  ClaimResponseIncentiveType,
} from '@chiroup/core/types/Era.type';
import {
  IntegrationExchangeFilterType,
  ValidExchangeFunctionsEnum,
  IntegrationExchangeTypeEnum,
  ChiroUpIdPattern,
  IntegrationExchangeDisplayByType,
  EraFilterType,
  IntegrationSummaryType,
  ClaimManualSearchFilterType,
  ClaimResponseFilterType,
  IntegrationInvoiceFilterType,
  InsuranceEligibilityRequestFilterType,
} from '@chiroup/core/types/Integration.type';
import { Invoice, IntegrationInvoice } from '@chiroup/core/types/Invoice.type';
import { PayorLineItemType } from '@chiroup/core/types/Payor.type';
import { hasProperty } from '@chiroup/core/functions/hasProperty';
import { mimeTypeByExtension } from '@chiroup/core/functions/edi';
import { TaxonomyCodeReference } from '@chiroup/core/constants/EdiCodes/TaxonomyCodeReference';
import { InsuranceEligibilityRequestType } from '@chiroup/core/types/InsuranceEligibilityRequest.type';

type exchangeArgsType = IntegrationExchangeFilterType & {
  f: ValidExchangeFunctionsEnum;
  clinicId?: number;
  id?: string;
  trace?: boolean;
  types?: IntegrationExchangeTypeEnum[];
  page?: number;
  limit?: number;
};

const validExchangeFunctions: STRING_ANY_HASH = {
  [ValidExchangeFunctionsEnum.Activity]: {
    validateInput: (args: exchangeArgsType, issues: string[]) => {
      const { id } = args;
      if (!id || typeof id !== 'string') issues.push(`Invalid id: ${id}`);
      if (!ChiroUpIdPattern.test(`${id}`)) issues.push(`Invalid id: ${id}`);
      return issues.length === 0;
    },
    queryParams: (args: exchangeArgsType, params: string[]) => {
      const { id } = args;
      params.push(`id=${id}`);
    },
  },
  [ValidExchangeFunctionsEnum.UIList]: {
    validateInput: (args: exchangeArgsType, issues: string[]) => {
      const { type = [] } = args;
      if (type.length !== 0) {
        type.forEach((t) => {
          if (!IntegrationExchangeDisplayByType[t]) {
            issues.push(`Invalid type: ${t}`);
          }
        });
      }
      return issues.length === 0;
    },
    queryParams: (args: exchangeArgsType, params: string[]) => {
      const { types = [] } = args;
      params.push(`types=${types.join('+')}`);
    },
  },
  [ValidExchangeFunctionsEnum.ByType]: {
    validateInput: (args: exchangeArgsType, issues: string[]) => {
      const { types = [] } = args;
      if (types.length !== 0) {
        types.forEach((t) => {
          if (!IntegrationExchangeDisplayByType[t]) {
            issues.push(`Invalid type: ${t}`);
          }
        });
      }
      return issues.length === 0;
    },
    queryParams: (args: exchangeArgsType, params: string[]) => {
      const { types = [] } = args;
      params.push(`types=${types.join('+')}`);
    },
  },
  [ValidExchangeFunctionsEnum.Single]: {
    validateInput: (args: exchangeArgsType, issues: string[]) => {
      const { id } = args;
      if (!id || typeof id !== 'string') issues.push(`Invalid id: ${id}`);
      if (!ChiroUpIdPattern.test(`${id}`)) issues.push(`Invalid id: ${id}`);
      return issues.length === 0;
    },
    queryParams: (args: exchangeArgsType, params: string[]) => {
      const { id } = args;
      params.push(`id=${id}`);
    },
  },
  [ValidExchangeFunctionsEnum.Options]: {
    validateInput: (args: exchangeArgsType, issues: string[]) => {
      return true;
    },
    queryParams: (args: exchangeArgsType, params: string[]) => {
      return;
    },
  },
};

const integrationServiceFactory = () => {
  const era = async ({ clinicId, id }: { clinicId: number; id: number }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An era id is required.');
    const res: EraWithClaimsType = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/eras/${id}`,
      {},
    );
    return res ?? null;
  };

  const orphanedEras = async (
    args: EraFilterType & {
      clinicId: number;
      id?: string;
      trace?: boolean;
      page?: number;
      limit?: number;
      active?: boolean | null;
    },
  ) => {
    const { clinicId, trace = false } = args;
    const issues = [];

    if (!clinicId) issues.push('A clinicId is required.');

    if (issues.length > 0) throw new BadRequestError(issues.join(' '));

    const qargs = [];
    if (trace) qargs.push('trace=1');

    const q = qargs.length > 0 ? `?${qargs.join('&')}` : '';
    try {
      const res: IntegrationSummaryType = await ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/integration/orphaned-eras${q}`,
        { body: { ...args } },
      );

      return res;
    } catch (e) {
      clog({ e });
      throw e;
    }
  };

  const eras = async (
    args: EraFilterType & {
      clinicId: number;
      id?: string;
      trace?: boolean;
      page?: number;
      limit?: number;
      active?: boolean | null;
    },
  ) => {
    const { clinicId, trace = false } = args;
    const issues = [];

    if (!clinicId) issues.push('A clinicId is required.');

    if (issues.length > 0) throw new BadRequestError(issues.join(' '));

    const qargs = [];
    if (trace) qargs.push('trace=1');

    const q = qargs.length > 0 ? `?${qargs.join('&')}` : '';
    try {
      const res: IntegrationSummaryType = await ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/integration/eras${q}`,
        { body: { ...args } },
      );

      return res;
    } catch (e) {
      clog({ e });
      throw e;
    }
  };

  const insuranceEligibilityRequest = async ({
    clinicId,
    id,
  }: {
    clinicId?: number;
    id?: number;
  }) => {
    if (!clinicId) throw new Error('A clinic id is required.');
    if (!id) throw new Error('An eligibility request id is required.');

    const res: InsuranceEligibilityRequestType = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/eligibility-requests/${id}`,
      {},
    );
    return res ?? null;
  };

  const insuranceEligibilityRequestAdd = async ({
    clinicId,
    request,
  }: {
    clinicId?: number;
    request: Partial<InsuranceEligibilityRequestType>;
  }) => {
    if (!clinicId) throw new Error('A clinic id is required.');
    if (!request) throw new Error('An request required.');

    const res: InsuranceEligibilityRequestType = await ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/integration/eligibility-requests/`,
      { body: request },
    );
    return res ?? null;
  };

  const insuranceEligibilityRequests = async (
    args: InsuranceEligibilityRequestFilterType & {
      clinicId: number;
      id?: string;
      trace?: boolean;
      page?: number;
      limit?: number;
      active?: boolean | null;
    },
  ) => {
    const { clinicId, trace = false } = args;
    const issues = [];

    if (!clinicId) issues.push('A clinicId is required.');

    if (issues.length > 0) throw new BadRequestError(issues.join(' '));

    const qargs = [];
    if (trace) qargs.push('trace=1');

    const q = qargs.length > 0 ? `?${qargs.join('&')}` : '';
    try {
      const res: IntegrationSummaryType = await ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/integration/eligibility-requests${q}`,
        { body: { ...args } },
      );

      return res;
    } catch (e) {
      clog({ e });
      throw e;
    }
  };

  const eraDelete = async ({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');

    const res: any = await ChiroUpAPI.del(
      'api',
      `/transactions/${clinicId}/integration/eras/${id}`,
      {},
    );
    return res ?? null;
  };

  const claimManualSearch = async ({
    clinicId,
    payorId,
    billingProfileId,
    params,
  }: {
    clinicId: number;
    payorId: number;
    billingProfileId: number;
    params: ClaimManualSearchFilterType;
  }) => {
    const {
      search = '',
      patientId = '',
      limit,
      skip = null,
      dateStart,
      dateEnd,
    } = params;

    if (!clinicId || !payorId) {
      // return [];
    }

    const ands = [];
    if (search) ands.push(`search=${search}`);
    if (patientId) ands.push(`patientId=${patientId}`);
    if (billingProfileId) ands.push(`billingProfileId=${billingProfileId}`);
    if (limit) ands.push(`limit=${limit}`);
    if (skip) ands.push(`skip=${skip}`);
    if (dateStart) ands.push(`dateStart=${dateStart}`);
    if (dateEnd) ands.push(`dateEnd=${dateEnd}`);
    const q = ands.length > 0 ? `?${ands.join('&')}` : '';
    const res: Invoice[] = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/payor/${payorId}/claim-manual-search${q}`,
      {},
    );
    return res ?? null;
  };

  const claimResponseMatches = async ({
    clinicId,
    id,
    search,
  }: {
    clinicId: number;
    id: number;
    search: string;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');
    const res: Invoice[] = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/${id}/matches?search=${search}`,
      {},
    );
    return res ?? null;
  };

  const claimResponseApply = async ({
    clinicId,
    id,
    invoiceId,
  }: {
    clinicId: number;
    id: number;
    invoiceId: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');
    if (!invoiceId) throw new BadRequestError('An invoiceId is required.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/${id}/apply`,
      { body: { invoiceId } },
    );
    return res ?? null;
  };

  const claimResponsePost = async ({
    clinicId,
    id,
    body,
  }: {
    clinicId: number;
    id: number;
    body: {
      writeOffAmount: number;
      allocationAmount: number;
      billingKey: string;
    };
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/${id}/post`,
      { body },
    );
    return res ?? null;
  };

  const claimResponseDelete = async ({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');

    const res: any = await ChiroUpAPI.del(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/${id}`,
      {},
    );
    return res ?? null;
  };

  const claimIncentiveDelete = async ({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');

    const res: any = await ChiroUpAPI.del(
      'api',
      `/transactions/${clinicId}/integration/claim-incentives/${id}`,
      {},
    );
    return res ?? null;
  };

  const claimResponseUnapply = async ({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/${id}/unapply`,
      {},
    );
    return res ?? null;
  };

  const claimIncentivePost = async ({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-incentives/${id}/post`,
      {},
    );
    return res ?? null;
  };

  const claimResponseManualAdd = async ({
    clinicId,
    response,
  }: {
    clinicId: number;
    response: EraManualAddType;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!response) throw new BadRequestError('No ERA response provided.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/manual-add`,
      { body: response },
    );
    return res ?? null;
  };

  const eraBatchManualAdd = async ({
    clinicId,
    response,
    post,
  }: {
    clinicId: number;
    response: EraBatchManualAddType;
    post?: boolean;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!response) throw new BadRequestError('No ERA batch provided.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/batch-manual-add?post=${
        post ? 'true' : 'false'
      }`,
      { body: response },
    );
    return res ?? null;
  };

  const eraBatchManualAddClaim = async ({
    clinicId,
    eraId,
    response: claim,
    post,
  }: {
    clinicId: number;
    eraId: number;
    response: EraManualAddType;
    post?: boolean;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!eraId) throw new BadRequestError('An eraId is required.');
    if (!claim) throw new BadRequestError('No claim response provided.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/batch-manual-add-claim/${eraId}?post=${
        post ? 'true' : 'false'
      }`,
      { body: claim },
    );
    return res ?? null;
  };

  const eraBatchManualAddIncentive = async ({
    clinicId,
    eraId,
    incentive,
    post,
  }: {
    clinicId: number;
    eraId: number;
    incentive: ClaimResponseIncentiveType;
    post?: boolean;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!eraId) throw new BadRequestError('An eraId is required.');
    if (!incentive) throw new BadRequestError('No claim response provided.');

    const res: any = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/batch-manual-add-incentive/${eraId}?post=${
        post ? 'true' : 'false'
      }`,
      { body: incentive },
    );
    return res ?? null;
  };

  const payorLineItems = async ({
    clinicId,
    invoiceId,
  }: {
    clinicId: number;
    invoiceId: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!invoiceId) throw new BadRequestError('An invoiceId is required.');
    const res: PayorLineItemType[] = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/payor-line-items/${invoiceId}`,
      {},
    );
    return res ?? null;
  };

  const claimIncentive = async ({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');
    const res: ClaimResponseIncentiveType = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/claim-incentives/${id}`,
      {},
    );
    return res ?? null;
  };

  const claimResponse = async ({
    clinicId,
    id,
  }: {
    clinicId: number;
    id: number;
  }) => {
    if (!clinicId) throw new BadRequestError('A clinicId is required.');
    if (!id) throw new BadRequestError('An id is required.');
    const res: ClaimResponseEraType = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/claim-responses/${id}`,
      {},
    );
    return res ?? null;
  };

  const claimResponses = async (
    args: ClaimResponseFilterType & {
      clinicId: number;
      id?: string;
      trace?: boolean;
      page?: number;
      limit?: number;
      active?: boolean | null;
    },
  ) => {
    const { clinicId, trace = false } = args;
    const issues = [];

    if (!clinicId) issues.push('A clinicId is required.');

    if (issues.length > 0) throw new BadRequestError(issues.join(' '));

    if (trace) {
      clog({
        integrationService_exchange: {
          ...args,
        },
      });
    }

    const qargs = [];
    if (trace) qargs.push('trace=1');

    const q = qargs.length > 0 ? `?${qargs.join('&')}` : '';
    try {
      const res: IntegrationSummaryType = await ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/integration/claim-responses${q}`,
        { body: { ...args } },
      );

      return res;
    } catch (e) {
      clog({ e });
      throw e;
    }
  };

  /**
   * Given a clinicId, this returns a summary of the file/document
   * exchanges for that clinic.
   *
   * @param param0
   * @returns
   */
  const exchange = async (
    args: IntegrationExchangeFilterType & {
      f: ValidExchangeFunctionsEnum;
      clinicId: number;
      id?: string;
      trace?: boolean;
      page?: number;
      limit?: number;
      active?: boolean | null;
    },
  ) => {
    const { f, clinicId, billingProfileId, trace = false } = args;
    const issues = [];
    if (!f) issues.push('A function must be specified.');
    if (!clinicId) issues.push('A clinicId is required.');
    if (!validExchangeFunctions[f]) {
      issues.push(`Invalid exchange function: ${f}.`);
    }
    if (!billingProfileId && f === ValidExchangeFunctionsEnum.ByType) {
      issues.push('A billingProfileId is required.');
    }
    if (issues.length > 0) throw new BadRequestError(issues.join(' '));
    if (!validExchangeFunctions[f].validateInput(args, issues)) {
      throw new BadRequestError(issues.join(' '));
    }

    if (args.id) {
      (args as any).exchangeId = args.id;
      delete args.id;
    }
    if (trace) {
      clog({
        integrationService_exchange: {
          ...args,
        },
      });
    }

    const qargs = [];
    if (trace) qargs.push('trace=1');

    const q = qargs.length > 0 ? `?${qargs.join('&')}` : '';
    try {
      const res: IntegrationSummaryType = await ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/integration/exchange${q}`,
        { body: { ...args } },
      );
      if (trace) {
        clog({
          _______integration_service_response_______: { function: args.f, res },
        });
      }
      return res;
    } catch (e) {
      clog({ integrationService_exchangeSummary_error: { e } });
      throw e;
    }
  };

  const invoiceById = async ({
    clinicId,
    id,
  }: {
    clinicId?: number;
    id?: number;
  }) => {
    if (!clinicId) throw new Error('A clinic id is required.');
    if (!id) throw new Error('An invoice id is required.');

    const res: IntegrationInvoice = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/invoice/${id}`,
      {},
    );
    return res ?? null;
  };

  const invoicesByBillingKey = async ({
    clinicId,
    trace,
    billingKey,
    includeLineItems,
  }: {
    clinicId?: number;
    trace?: boolean;
    billingKey?: string;
    includeLineItems?: boolean;
  }) => {
    if (!clinicId) throw new Error('A clinic id is required.');
    if (!billingKey) {
      throw new Error(`A billing key is required.`);
    }

    if (trace) {
      clog({
        integrationService_invoicesByBillngKey: { clinicId, billingKey },
      });
    }

    const res: IntegrationInvoice[] = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/invoice${trace ? '?trace=1' : ''}`,
      { body: { billingKey, includeLineItems } },
    );
    if (trace) clog({ integrationService_invoicesByBillngKey: { res } });

    return res ?? null;
  };

  const invoicesByStatus = async ({
    clinicId,
    trace,
    page,
    limit,
    options,
  }: IntegrationInvoiceFilterType & {
    clinicId?: number;
    trace?: boolean;
    page?: number;
    limit?: number;
    options?: IntegrationInvoiceFilterType;
  }) => {
    if (!clinicId) throw new Error('clinicId is required.');
    /**
     * TODO: Put in some sane validation. If they want a huge date range,
     * we should probably make them specify only certain statuses.
     */

    const args = {
      clinicId,
      ...options,
    };
    if (trace) {
      clog({
        integrationService_invoicesByStatus: { ...args },
      });
    }

    const res: { count: number; data: IntegrationInvoice[]; page: number } =
      await ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/integration/invoice${
          trace ? '?trace=1' : ''
        }`,
        {
          body: { ...args, limit, page },
        },
      );
    if (trace) clog({ integrationService_invoicesByStatus: { res } });

    return res ?? null;
  };

  const list = async ({
    clinicId,
    trace,
  }: {
    clinicId?: number;
    trace?: boolean;
  }) => {
    if (!clinicId) throw new Error('clinicId is required');

    const res: STRING_ANY_HASH = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration`,
      {},
    );
    if (trace) clog({ integrationService: { res } });
    return res ?? null;
  };

  const settingsByClinicId = async ({
    clinicId,
    trace,
  }: {
    clinicId?: number;
    trace?: boolean;
  }) => {
    if (!clinicId) throw new Error('clinicId is required');

    const res: STRING_ANY_HASH = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration${trace ? '?trace=1' : ''}`,
      {},
    );
    if (trace) clog({ integrationService: { res } });
    return res ?? null;
  };

  const remote = async ({
    clinicId,
    script,
    trace,
  }: {
    clinicId?: number;
    trace?: boolean;
    script?: STRING_ANY_HASH[];
  }) => {
    if (!clinicId) throw new Error('clinicId is required.');
    if (trace) {
      clog({ integration_service_ts: { clinicId, script, trace } });
    }

    const res: STRING_ANY_HASH = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/remote${trace ? '?trace=1' : ''}`,
      { body: script },
    );
    if (trace) clog({ integrationService_remote: { res } });
    return res ?? null;
  };

  const upload = async ({
    clinicId,
    profileId,
    file,
    trace,
    type,
  }: {
    clinicId: number;
    profileId: number;
    trace?: boolean;
    file?: any;
    type?: string;
  }) => {
    if (!clinicId) throw new Error('clinicId is required.');
    const name = file?.name ?? '-bogus-.-bad',
      pcs = name.split('.'),
      size = file?.size ?? -1,
      basename = pcs.shift() ?? '-bogus-',
      ftype = pcs.pop() || '',
      body = {
        body: {
          name,
          profileId,
          basename:
            ftype && ftype !== type
              ? [].concat(basename, ftype, pcs).join('.')
              : [].concat(basename, pcs).join('.'),
          type,
          size,
          mimeType: mimeTypeByExtension(type),
        },
      };

    if (trace) {
      clog({
        integration_service_upload_in: { clinicId, file, trace, body },
      });
    }

    const res: STRING_ANY_HASH = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/upload${trace ? '?trace=1' : ''}`,
      body,
    );
    if (trace) clog({ integration_service_upload_resp: { res } });
    return res ?? null;
  };

  const download = async ({
    clinicId,
    exchangeId,
    trace = false,
  }: {
    clinicId?: number;
    exchangeId?: string;
    trace?: boolean;
  }): Promise<string | null> => {
    if (trace) {
      clog({
        integration_service_download_in: { clinicId, exchangeId, trace },
      });
    }

    if (!clinicId) throw new Error('clinicId is required.');
    if (!exchangeId) throw new Error('An exchange id is required.');

    const args = [];
    if (exchangeId) args.push(`exchangeId=${exchangeId}`);
    if (trace) args.push('trace=1');

    const res: string = await ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/integration/download${
        args.length > 0 ? `?${args.join('&')}` : ''
      }`,
      {},
    );
    return res ?? null;
  };

  const parse = async ({
    clinicId,
    exchangeId,
    key,
    trace = false,
  }: {
    clinicId?: number;
    exchangeId?: string;
    key?: string;
    trace?: boolean;
  }): Promise<string | null> => {
    if (!clinicId) throw new Error('clinicId is required.');
    if (!key) throw new Error('key is required.');

    if (trace) {
      clog({
        integration_service_parse_in: { clinicId, key, trace },
      });
    }

    const res: string = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/parse${trace ? '?trace=1' : ''}`,
      { body: { key, exchangeId } },
    );

    if (trace) {
      clog({
        integration_service_parse_out: { res },
      });
    }
    return res ?? null;
  };

  const toggleInvoiceElectronicBilling = async ({
    clinicId,
    invoice,
    trace = false,
  }: {
    clinicId?: number;
    invoice: IntegrationInvoice;
    trace?: boolean;
  }): Promise<string | null> => {
    if (!clinicId) throw new Error('A clinic id is required.');
    if (!invoice) throw new Error('Invoice is required.');

    if (trace) {
      clog({
        toggleInvoiceElectronicBilling: { clinicId, invoice, trace },
      });
    }

    const res: string = await ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/invoice/${invoice.id}`,
      {
        body: {
          invoiceData: {},
          patientId: invoice.patient.id,
          action: { toggleElectronicBilling: true },
        },
      },
    );

    if (trace) {
      clog({
        integration_service_parse_out: { res },
      });
    }
    return res ?? null;
  };

  const testClaimsForBillingProfile = async ({
    clinicId,
    providerId,
    billingProfileId,
    locationId,
  }: {
    clinicId?: number;
    providerId?: string;
    billingProfileId?: number;
    locationId?: number;
  }) => {
    if (!clinicId) throw new Error('A clinic id is required.');
    if (!providerId) throw new Error('A provider id is required.');
    if (!billingProfileId) throw new Error('A billing profile id is required.');
    if (!locationId) throw new Error('A location id is required.');

    const res: string = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/claim-test-billing-profile`,
      {
        body: {
          providerId,
          billingProfileId,
          locationId,
        },
      },
    );

    return res ?? null;
  };

  const isValidTaxonomyCode = ({ code }: { code?: string }) => {
    if (!code) return false;
    return hasProperty(TaxonomyCodeReference, code);
  };

  const retryExchange = async ({
    clinicId,
    exchangeId,
  }: {
    clinicId?: number;
    exchangeId?: string;
  }) => {
    if (!clinicId) throw new Error('A clinic id is required.');
    if (!exchangeId) throw new Error('An exchange id is required.');

    await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/integration/exchange/${exchangeId}/retry`,
      {},
    );
  };

  return {
    claimResponse,
    claimResponseApply,
    claimResponseMatches,
    claimResponsePost,
    claimResponses,
    claimResponseUnapply,
    claimIncentive,
    download,
    era,
    eras,
    eraBatchManualAdd,
    exchange,
    invoiceById,
    invoicesByBillingKey,
    invoicesByStatus,
    isValidTaxonomyCode,
    list,
    parse,
    remote,
    settingsByClinicId,
    toggleInvoiceElectronicBilling,
    upload,
    claimResponseManualAdd,
    claimManualSearch,
    testClaimsForBillingProfile,
    eraBatchManualAddClaim,
    payorLineItems,
    insuranceEligibilityRequests,
    insuranceEligibilityRequestAdd,
    insuranceEligibilityRequest,
    retryExchange,
    orphanedEras,
    claimResponseDelete,
    eraDelete,
    eraBatchManualAddIncentive,
    claimIncentiveDelete,
    claimIncentivePost,
  };
};

export const integrationService = integrationServiceFactory();
