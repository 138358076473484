import { Currency } from '@chiroup/components';
import { TransformedTreatment } from '../TransactionSuperbillInvoice';

type InvoiceTableRowProps = {
  item?: TransformedTreatment;
};

const SuperbillInvoiceTreatmentTableRow: React.FC<InvoiceTableRowProps> = ({
  item,
}) => {
  const removeBillingCode = (description: string, billingCode: string) => {
    const regex = new RegExp(`${billingCode}-`);
    return description.replace(regex, '');
  };

  return (
    <tr className="text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
      <td
        className={`whitespace-normal break-words py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6`}
      >
        {item?.code}
      </td>
      <td
        className={`whitespace-normal break-words py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6`}
      >
        {removeBillingCode(item?.description || '', item?.code || '')}
      </td>
      <td
        className={`whitespace-normal break-words py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6`}
      >
        {item?.diagnoses}
      </td>
      <td
        className={`whitespace-normal break-words py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6`}
      >
        {item?.modifiers?.join(', ')}
      </td>
      <td className="whitespace-normal break-words py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
        <Currency value={item?.fee} />
      </td>
      <td className="whitespace-normal break-words py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
        {item?.units}
      </td>
      <td className="whitespace-normal break-words py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
        <Currency value={item?.total} />
      </td>
    </tr>
  );
};

export default SuperbillInvoiceTreatmentTableRow;
