import React, { ChangeEvent } from 'react';
import Tabs from '../../../../layout/Tabs';
import { STRING_BOOLEAN_HASH } from '../../../../../../../../libs/core/src/lib/constants/globals';

type Props = {
  activeTabName?: string;
  id?: string;
  rightSide?: React.ReactNode;
  setActiveTabName?: (name: string) => void;
  consolidatedView?: boolean;
  switches?: STRING_BOOLEAN_HASH;
};

const TransactionEditorTabs: React.FC<Props> = ({
  activeTabName,
  id,
  rightSide,
  setActiveTabName,
  consolidatedView = false,
  switches = {},
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (e: ChangeEvent<HTMLSelectElement>, idx?: number) => {
    const setto = typeof idx === 'number' ? idx : +e.target.value;
    setActiveTab(setto);
    if (setActiveTabName) {
      setActiveTabName(tabs[setto].name);
    }
  };

  const tabs = [
    {
      name: 'Detail',
    },
    {
      name: 'Invoices',
    },
    {
      name: 'Activity',
    },
  ]
    .map((tab, i) => ({ ...tab, active: activeTab === i }))
    .filter((tab) => !switches[tab.name] === false);

  return (
    <div className="print:hidden relative bg-transparent">
      <Tabs
        tabs={tabs}
        rightSide={rightSide}
        onClick={handleTabChange}
        transparent={true}
      />
    </div>
  );
};

export default TransactionEditorTabs;
