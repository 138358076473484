import { Currency, Input } from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { TidyInputValue } from '@chiroup/core/constants/TidyInputValue';
import { useMemo } from 'react';

type Props = {
  value: PatientTransactionItemType;
  onRemove?: (ord: number) => void;
  readonly?: boolean;
  onChange: (property: string) => (value: any) => void;
  index: number;
  overrideApplied?: boolean;
  hideUnits?: boolean;
  hasServices?: boolean;
  patientName?: string;
  clickableRows?: boolean;
  patientId?: string;
  billingKey?: string;
};

export const TransactionItem: React.FC<Props> = ({
  value: item,
  onRemove,
  readonly = false,
  onChange,
  index,
  overrideApplied = false,
  hideUnits = false,
  hasServices = false,
  patientName,
  clickableRows = false,
  patientId,
  billingKey,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (clickableRows) {
      if (item?.subtype === TransactionItemSubtypeEnum.Payment) {
        navigate(
          `/patients/${patientId}/billing/${
            item?.payorId ? 'payor-payments' : 'payments'
          }/${item?.paymentSource}${
            item?.payorId && billingKey ? `?returnTo=${billingKey}` : ''
          }`,
        );
      }
    }
  };

  const hasRemoveHandler = useMemo(() => {
    return (
      !!onRemove &&
      !readonly &&
      item?.subtype !== TransactionItemSubtypeEnum.Treatment
    );
  }, [item?.subtype, onRemove, readonly]);

  const bgColor = index % 2 === 0 ? 'bg-gray-50' : 'bg-white';
  const hoverColor =
    index % 2 === 0
      ? 'hover:bg-gray-200 cursor-pointer'
      : 'hover:bg-gray-100 cursor-pointer';

  const commonClassNames = useMemo(() => {
    return classNames(
      bgColor,
      `w-full text-gray-800 text-sm p-2`,
      clickableRows ? hoverColor : '',
    );
  }, [bgColor, clickableRows, hoverColor]);

  /**
   * s u p p l e m e n t
   */
  if (item.subtype === TransactionItemSubtypeEnum.Supplement) {
    return (
      <div
        data-component="transaction-supplement-item"
        className={commonClassNames}
      >
        <div
          className="flex flex-row justify-between w-full"
          onClick={handleClick}
        >
          <div className="flex flex-row gap-1 items-center">
            {hasRemoveHandler && !readonly && (
              <XMarkIcon
                className="w-4 h-4 cursor-pointer text-gray-400 hover:text-gray-700"
                onClick={() => {
                  onRemove?.(item.id);
                }}
              />
            )}
            <span>{item.description}</span>
          </div>
          <div className="flex flex-row space-x-2 align-middle">
            <Currency value={item?.amount} className="pt-2" />
            {readonly && !hideUnits ? (
              <div className="pt-2">
                <div>{`x ${item?.units}`}</div>
              </div>
            ) : (
              !hideUnits && (
                <>
                  <div className="flex align-middle pt-2">{' x '}</div>
                  <Input
                    className="w-16"
                    label=""
                    type="number"
                    tooltipClassName="text-xs"
                    name="units"
                    onChange={(e: any) => {
                      onChange('units')(e);
                    }}
                    value={item?.units || ''}
                    min="1"
                  />{' '}
                  <div className="flex align-middle pt-2">units</div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * p a y m e n t
   */
  if (item.subtype === TransactionItemSubtypeEnum.Payment) {
    return (
      <div
        data-component="transaction-payment-item"
        className={commonClassNames}
      >
        <div
          className="flex flex-row justify-between w-full"
          onClick={handleClick}
        >
          <div className="flex">
            {item.code ? `${item.code} - ` : ''}
            {item.subtype === TransactionItemSubtypeEnum.Payment
              ? `${item.payorId ? 'Payor payment' : 'Patient payment'}`
              : item.description}
            {item?.subtype === TransactionItemSubtypeEnum.Payment
              ? ` - ${item.payorId ? item.payorName : patientName}`
              : ''}
          </div>
          <div className={classNames('flex py-2')}>
            <Currency value={item?.amount} />
          </div>
        </div>
      </div>
    );
  }

  /**
   * t r e a t m e n t
   */
  if (item.subtype === TransactionItemSubtypeEnum.Treatment) {
    // hasServices = false;
    return (
      <div
        data-component="transaction-treatment-item"
        className={commonClassNames}
      >
        <div
          className="flex flex-row justify-between w-full"
          onClick={handleClick}
        >
          <div className="flex flex-row gap-1 items-center">
            {readonly ? (
              <div className="flex flex-col">
                <p>{item?.description}</p>
              </div>
            ) : (
              <div className="flex flex-col">
                {hasServices ? null : (
                  <div className="text-sm font-medium">Description</div>
                )}
                <div className="p-2 block w-full border-gray-300 rounded-md text-sm sm:leading-5 dark:border-darkGray-600 focus:ring-2 text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed">
                  {item?.description ?? '- n.a. -'}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row">
            <div className={classNames('py-2')}>
              {readonly ? (
                <Currency value={item?.amount} />
              ) : (
                <Input
                  className="w-24 mr-2"
                  label="Amount"
                  tooltipClassName="text-xs"
                  name="amount"
                  onChange={(e: any) => {
                    onChange('amount')(TidyInputValue.Currency(e));
                  }}
                  value={item?.amount || ''}
                  placeholder="0.00"
                />
              )}
            </div>
            {hideUnits ? null : (
              <div className="pl-1 py-2">
                {readonly ? (
                  <div>{`x ${item?.units}`}</div>
                ) : (
                  <Input
                    label="Units"
                    disabled={true}
                    value={item?.units}
                    className="w-12"
                    name={'units'}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * t h e   r e s t   ( b l a h)
   */
  return (
    <div data-component="transaction-item" className={commonClassNames}>
      <div
        className="flex flex-row justify-between w-full"
        onClick={handleClick}
      >
        <div className="flex flex-row gap-1 items-center">
          {hasRemoveHandler &&
            !readonly &&
            item?.subtype !== TransactionItemSubtypeEnum.Service &&
            item?.subtype !== TransactionItemSubtypeEnum.PatientService && (
              <XMarkIcon
                className="w-4 h-4 cursor-pointer text-gray-400 hover:text-gray-700"
                onClick={() => {
                  onRemove?.(item.id);
                }}
              />
            )}
          {readonly ||
          item?.subtype === TransactionItemSubtypeEnum.Service ||
          item?.subtype === TransactionItemSubtypeEnum.PatientService ? (
            <span>{item.code ? `${item.code} - ` : ''}</span>
          ) : item?.subtype === TransactionItemSubtypeEnum.Package ? (
            <div className="flex flex-col">
              <p>{item?.description}</p>
            </div>
          ) : null}
        </div>
        <div className="flex flex-row">
          {readonly ||
          item?.subtype === TransactionItemSubtypeEnum.Service ||
          item?.subtype === TransactionItemSubtypeEnum.PatientService ||
          item?.subtype === TransactionItemSubtypeEnum.PatientWriteOff ||
          item?.subtype === TransactionItemSubtypeEnum.Package ||
          overrideApplied ? (
            <div
              className={classNames(
                'py-2',
                overrideApplied &&
                  item?.subtype !== TransactionItemSubtypeEnum.Override
                  ? 'line-through'
                  : '',
              )}
            >
              <Currency value={item?.amount} />
            </div>
          ) : null}
          {item?.subtype === TransactionItemSubtypeEnum.PatientWriteOff ? (
            <></>
          ) : readonly && !hideUnits ? (
            <div className="pl-1 py-2">
              <div>{`x ${item?.units}`}</div>
            </div>
          ) : !hideUnits &&
            item?.subtype !== TransactionItemSubtypeEnum.Package ? (
            <>
              <div className="py-2 px-2 ">{' x '}</div>
              <Input
                className=" w-24"
                label="Units"
                type="number"
                tooltipClassName="text-xs"
                name="units"
                onChange={(e: any) => {
                  onChange('units')(e);
                }}
                value={item?.units || ''}
                min="0"
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
