import { Currency } from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { XMarkIcon } from '@heroicons/react/24/outline';

type Props = {
  value: PatientTransactionItemType;
  onRemove?: (itemId: number) => void;
  readonly?: boolean;
  onChange: (property: string) => (value: any) => void;
  index: number;
  percentDiscountApplied?: boolean;
};

export const TransactionItemAdjustment: React.FC<Props> = ({
  value: item,
  onRemove,
  readonly = false,
  index,
  percentDiscountApplied = false,
}) => {
  const hasRemoveHandler = !!onRemove;
  const bgColor = index % 2 === 0 ? 'bg-gray-50' : 'bg-white';

  return (
    <div
      className={classNames(bgColor, `w-full text-gray-800 text-sm px-2 p-4`)}
    >
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row gap-1 items-center">
          {hasRemoveHandler && !readonly && (
            <XMarkIcon
              className="w-4 h-4 cursor-pointer text-gray-400 hover:text-gray-700"
              onClick={() => {
                onRemove?.(item?.id);
              }}
            />
          )}
          <span>
            {item.code ? `${item.code} - ` : ''}
            {item.description}
          </span>
        </div>
        {percentDiscountApplied && (
          <span>{item?.adjustment?.percentOff || 0} % off</span>
        )}
        <div className="flex flex-row">
          {item?.subtype === TransactionItemSubtypeEnum.Override ||
          item?.subtype === TransactionItemSubtypeEnum.AdditionalFee ? (
            <div className="flex w-26 flex-row space-x-2 justify-end">
              <Currency value={item?.amount} />
            </div>
          ) : (
            <div className="flex w-26 flex-row space-x-2 justify-end text-red-500">
              (<Currency value={item?.amount} />)
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
