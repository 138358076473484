import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import { integrationService } from '../../../services/integration.service';

const getIntegrationInvoice = ({
  clinicId,
  id,
}: {
  clinicId: number;
  id: number;
}) => {
  return async (context: QueryFunctionContext) => {
    if (!id || id === -1) return null;
    return integrationService.invoiceById({
      clinicId,
      id,
    });
  };
};

const useIntegrationInvoice = ({
  clinicId,
  id,
}: {
  clinicId: number;
  id: number;
  onStartupCallback?: (data: any) => void;
}) => {
  const [isRestActive, setIsRestActive] = useState(false);
  const [restFailed, setRestFailed] = useState(false);
  const { createToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const { data, isFetching, refetch } = useQuery(
    ['integrationInvoice', id],
    getIntegrationInvoice({ clinicId, id }),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      keepPreviousData: true,
      retry: 0,
      onError: (error: any) => {
        setRestFailed(true);
        createToast({
          title: `Fetch Invoice`,
          description: `${
            error?.response?.data?.message ?? error?.message ?? 'Unknown error.'
          }`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      },
    },
  );

  const destroyData: any = () => {
    queryClient.setQueryData(['integrationInvoice', id], null);
    // queryClient.invalidateQueries(['integrationInvoice', id]);
  };

  const setInvoiceData = (data: any) => {
    queryClient.setQueryData(['integrationInvoice', id], data);
  };

  const toggleElectronicBilling = async () => {
    if (!data) return;

    let resp: any = null;
    setIsRestActive(true);

    try {
      resp = (await integrationService.toggleInvoiceElectronicBilling({
        clinicId,
        invoice: data, // We pass the whole thing as it has the patient id on it.
      })) as {
        electronicBilling: boolean;
        billingKey: string;
        legalName: string;
      } | null;

      queryClient.setQueryData(['integrationInvoice', id], (prev: any) => ({
        ...prev,
        electronicBilling: resp?.electronicBilling,
      }));
    } catch (e: any) {
      createToast({
        title: `Toggle Electronic Billing`,
        description: `${
          e?.response?.data?.message ?? e?.message ?? 'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }

    setIsRestActive(false);
    return resp;
  };

  return {
    data: data || null,
    destroyData,
    isFetching,
    isRestActive,
    refetch,
    restFailed,
    setRestFailed,
    setInvoiceData,
    toggleElectronicBilling,
  };
};

export default useIntegrationInvoice;
