import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import CodeList from './CodeList';
import { CodeSets } from '@chiroup/core/types/BillingCode.type';

const codeSuggestion = ({ clinicId = -1 }: { clinicId?: number }) => {
  return {
    items: async ({ query, ...args }: { query: string }) => {
      const res = await ChiroUpAPI.get('api', `/settings/${clinicId}/codes`, {
        queryParams: {
          type: `${CodeSets.CPT}|${CodeSets.ICD10CM}|${CodeSets.SERVICE}|${CodeSets.DIAGNOSTIC}`,
          search: query || 'a',
          weighted: true,
          page: 1,
          limit: 5,
        },
      });
      return res?.data || [];
    },
    render: () => {
      let component: any;
      let popup: any;

      return {
        onStart: (props: any) => {
          props.loading = false;
          component = new ReactRenderer(CodeList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          });
        },

        onUpdate(props: any) {
          props.loading = false;
          component?.updateProps?.(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }
          // If the key is alphanumeric or symbols, do this...
          if (props.event.key.match(/^[\w\d]$/)) {
            props.loading = true;
            component?.updateProps?.(props);
          }

          return component?.ref?.onKeyDown?.(props);
        },

        onExit() {
          popup?.[0].destroy();
          component.destroy();
        },
      };
    },
  };
};

export default codeSuggestion;
