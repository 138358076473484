import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientBillingService from '../../../services/patientBilling.service';

const getQuery = function (clinicId: number) {
  return async (context: QueryFunctionContext) => {
    const patientId = context.queryKey[1] as string;
    return await patientBillingService.getEpayCustomer(patientId, clinicId);
  };
};

const useEpayCustomer = (id: string) => {
  const meContext = useContext(MeContext);

  const {
    data: epayCustomer,
    error,
    refetch,
    isFetching,
  } = useQuery(
    ['epayCustomer', id],
    getQuery(meContext?.me?.selectedClinic?.ID as number),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return {
    epayCustomer: epayCustomer ?? null,
    error,
    refetch,
    isFetching,
  };
};

export default useEpayCustomer;
