import React, { ForwardedRef } from 'react';
import InvoiceClinicHeader from './Invoice/InvoiceClincHeader';
import InvoiceTotals from './Invoice/InvoiceTotals';
import InvoicePaymentHistory from './Invoice/InvoicePaymentHistory';
import PrintInvoiceHeader from './print-patient-invoice/PrintInvoiceHeader';
import PrintInvoiceBreakdown from './print-patient-invoice/PrintInvoiceBreakDown';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { Invoice } from '@chiroup/core/types/Invoice.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { PatientInvoice } from '@chiroup/core/types/PatientInvoice.type';

type Props = {
  data: Invoice;
  invoiceData: PatientInvoice;
  patient: Patient;
  ref: ForwardedRef<HTMLDivElement>;
};

const TransactionPatientInvoicePrintView = React.forwardRef<
  HTMLDivElement,
  Props
>(({ data, patient, invoiceData }, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div ref={ref} className="mx-16">
      <InvoiceClinicHeader location={invoiceData.location} />
      <PrintInvoiceHeader
        id={data?.controlNumber ? `${data.controlNumber}` : 'unknown'}
        patient={patient}
        selectedLocation={invoiceData.location as ClinicLocation}
        issuedDate={(invoiceData.issuedDate as string) ?? ''}
        status={invoiceData.status}
      />
      <PrintInvoiceBreakdown
        items={invoiceData.invoiceBreakdown?.items ?? []}
        lineItems={invoiceData.invoiceBreakdown?.lineItems ?? []}
        payors={invoiceData?.payors ?? {}}
        purchaseDate={invoiceData.invoiceBreakdown?.purchaseDate ?? ''}
        provider={invoiceData.invoiceBreakdown?.renderingProvider ?? ''}
        providerId={invoiceData.invoiceBreakdown?.renderingProviderId ?? ''}
        license={invoiceData?.invoiceBreakdown?.providerLicense ?? null}
        patientResponsibility={invoiceData?.patientResponsibility ?? 0}
      />
      <InvoiceTotals
        subtotal={invoiceData?.subTotal || 0}
        tax={invoiceData?.taxTotal || 0}
        total={invoiceData?.total || 0}
        balance={invoiceData?.balance || 0}
        notes={invoiceData?.notes || undefined}
      />
      <InvoicePaymentHistory payments={invoiceData?.paymentHistory} />
    </div>
  );
});
export default TransactionPatientInvoicePrintView;
