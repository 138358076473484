import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';
import { ErrorResponse } from '@chiroup/core/types/ErrorResponse.type';
import { PatientBalanceInfo } from '@chiroup/core/types/Patient.type';

type ErrorData = {
  title: string;
  description: string;
  link: {
    text: string;
    url: string;
  };
};

const errorData = (error: ErrorResponse): ErrorData => {
  if (error.response?.status === 401) {
    return {
      title: 'Unauthorized',
      description: "You are not authorized to view this patient's details.",
      link: {
        text: 'Go back to patients',
        url: '/patients',
      },
    };
  }
  if (error.response?.status === 404) {
    return {
      title: 'Patient not found',
      description: 'This patient could not be found.',
      link: {
        text: 'Go back to patients',
        url: '/patients',
      },
    };
  }
  return {
    title: 'An error occurred while fetching this patient.',
    description:
      'If you believe that this is an error and would like to try again, please refresh your browser.',
    link: {
      text: 'Go back to patients',
      url: '/patients',
    },
  };
};

const getPatientBillingBalanceQuery = (clinicId = -1, id = '') => {
  return async (context: QueryFunctionContext) => {
    return patientService.getBillingBalances({
      clinicId,
      patientId: id,
    });
  };
};

const usePatientBillingBalances = (id?: string) => {
  const { me } = useContext(MeContext);

  const [queryErrors, setQueryErrors] = useState<ErrorData>();
  const {
    data,
    isFetching,
    refetch,
    error: queryError,
    remove,
    isFetched,
  } = useQuery<PatientBalanceInfo, ErrorResponse>(
    ['patientsBillingBalances', id],
    getPatientBillingBalanceQuery(me?.selectedClinic?.ID, id),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (queryError) {
      setQueryErrors(errorData(queryError));
    }
  }, [queryError]);

  return {
    isFetching,
    data,
    refetch,
    queryErrors,
    remove,
    isFetched,
  };
};

export default usePatientBillingBalances;
