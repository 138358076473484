type InvoiceTableRowProps = {
  items?: (string | null)[];
};
const InvoiceTableRow: React.FC<InvoiceTableRowProps> = ({ items }) => {
  return (
    <tr className="text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
      {items?.map((item, index) => (
        <td
          key={index}
          className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 ${
            item?.startsWith('$') || item?.startsWith('-$') ? 'text-right' : ''
          }`}
        >
          {item}
        </td>
      ))}
    </tr>
  );
};

export default InvoiceTableRow;
