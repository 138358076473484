import { formatPhone } from '@chiroup/core/functions/format';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { InvoiceStatusEnum } from '@chiroup/core/types/Invoice.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

type InvoiceHeaderProps = {
  id: string;
  patient?: Partial<Patient>;
  selectedLocation?: Partial<ClinicLocation>;
  issuedDate?: string;
  status?: InvoiceStatusEnum;
};

const PrintInvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  id,
  patient,
  selectedLocation,
  issuedDate,
  status,
}) => {
  const getStatusColor = (status: InvoiceStatusEnum) => {
    if (status === InvoiceStatusEnum.Paid) {
      return 'bg-primary-500';
    } else if (status === InvoiceStatusEnum.Partial) {
      return 'bg-yellow-500';
    } else return 'bg-red-500';
  };

  return (
    <div className="text-xs w-full ">
      <div className="flex justify-between">
        <div className="flex items-baseline">
          <div className="font-semibold text-sm flex items-center">
            <p className="m-0">Invoice Number</p>
            <span className="font-normal hidden print:block">{`: ${id}`}</span>
          </div>
          <div className="bg-gray-300 rounded p-2 print:hidden">{id}</div>
        </div>
        <div className="flex items-baseline">
          <div className="font-semibold text-sm">Invoice Date</div>
          <span className="font-normal hidden print:block">{`: ${issuedDate}`}</span>
        </div>
      </div>
      <div className="hidden print:block pb-2">
        <div className="flex items-center space-x-2">
          <div className="font-semibold text-sm">
            Status: <span className=" p-2 bg-white font-normal">{status}</span>
          </div>
        </div>
      </div>

      <div
        className={`${getStatusColor(
          status ?? InvoiceStatusEnum.Unsubmitted,
        )} rounded p-2 mb-4 text-center text-white text-md mt-2 print:hidden`}
      >
        {status ?? InvoiceStatusEnum.Unsubmitted}
      </div>
      <hr className="my-2 hidden print:block" />
      <div className="grid grid-cols-8 text-xs">
        <div className="col-span-3 grid grid-cols-[auto_1fr] items-start">
          <div className="font-semibold pr-1 text-xs">Patient:</div>
          <div>
            <div className="font-semibold">
              {`${patient?.fname} ${patient?.lname}`}
            </div>
            {patient?.address1 && <div>{patient.address1}</div>}
            {patient?.address2 && <div>{patient.address2}</div>}
            <div>
              {`${patient?.city ?? ''}${
                patient?.state ? `, ${patient.state}` : ''
              } ${patient?.zip ?? ''}`}
            </div>
            <Link
              className="text-primary-500 cursor-pointer print:hidden"
              to={`/patients/${patient?.ID}`}
            >
              <span className="text-gray-700">Patient Link:</span>{' '}
              {`${patient?.fname} ${patient?.lname}`}
            </Link>
            <p className="pt-2"></p>
            <div className="hidden print:block min-w-full">
              {patient?.phone && (
                <div className="flex items-center pb-1">
                  <PhoneIcon className="mr-1 h-3 w-3 text-gray-500" />
                  <span>{formatPhone(patient.phone)}</span>
                </div>
              )}
              {patient?.email && (
                <div className="flex items-center">
                  <EnvelopeIcon className="mr-1 h-3 w-3 text-gray-500" />
                  <span>{patient?.email}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-5 text-xs">
          <div className="mb-4">
            <div className="mb-4 grid grid-cols-[max-content_1fr] items-start">
              <div
                className="font-semibold mr-1 text-xs"
                style={{ width: '80px' }}
              >
                Bill To:
              </div>
              <div>
                <div className="font-semibold">
                  {`${patient?.fname} ${patient?.lname}`}
                </div>
                {patient?.address1 && <div>{patient.address1}</div>}
                {patient?.address2 && <div>{patient.address2}</div>}
                <div>
                  {`${patient?.city ?? ''}${
                    patient?.state ? `, ${patient.state}` : ''
                  } ${patient?.zip ?? ''}`}
                </div>
                <div className="flex flex-wrap items-center gap-2">
                  {patient?.phone && (
                    <div className="flex items-center">
                      <PhoneIcon className="mr-1 h-3 w-3 text-gray-500" />
                      <span>{formatPhone(patient.phone)}</span>
                    </div>
                  )}
                  {patient?.email && (
                    <div className="flex items-center">
                      <EnvelopeIcon className="mr-1 h-3 w-3 text-gray-500" />
                      <span>{patient?.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-[max-content_1fr] items-start">
              <div
                className="font-semibold mr-1 text-xs"
                style={{ width: '80px' }}
              >
                Payable To:
              </div>
              <div>
                <div className="font-semibold">
                  {selectedLocation?.name ?? ''}
                </div>
                {selectedLocation?.address1 && (
                  <div>{selectedLocation.address1}</div>
                )}
                {selectedLocation?.address2 && (
                  <div>{selectedLocation.address2}</div>
                )}
                <div>
                  {`${selectedLocation?.city ?? ''}${
                    selectedLocation?.state ? `, ${selectedLocation.state}` : ''
                  } ${selectedLocation?.zip ?? ''}`}
                </div>
                <div className="flex flex-wrap items-center gap-2">
                  {selectedLocation?.phone && (
                    <div className="flex items-center">
                      <PhoneIcon className="mr-1 h-3 w-3 text-gray-500" />
                      <span>{formatPhone(selectedLocation?.phone)}</span>
                    </div>
                  )}
                  {selectedLocation?.email && (
                    <div className="flex items-center">
                      <EnvelopeIcon className="mr-1 h-3 w-3 text-gray-500" />
                      <span>{selectedLocation?.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintInvoiceHeader;
