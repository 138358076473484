import { Node } from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import Suggestion from '@tiptap/suggestion';

const MacroPluginKey = new PluginKey('macro');
const Macro = Node.create({
  name: 'macro',
  group: 'inline',
  inline: true,
  selectable: true,
  isolating: false,
  atom: false,
  exitable: true,
  marks: '_',
  draggable: false,
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }: any) {
        let _a;
        return `${options.suggestion.char}${
          (_a = node.attrs.label) !== null && _a !== void 0 ? _a : node.attrs.id
        }`;
      },
      suggestion: {
        char: '/',
        pluginKey: MacroPluginKey,
        command: ({ editor, range, props }: any) => {
          let _a, _b;
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace =
            (_a =
              nodeAfter === null || nodeAfter === void 0
                ? void 0
                : nodeAfter.text) === null || _a === void 0
              ? void 0
              : _a.startsWith(' ');
          if (overrideSpace) {
            range.to += 1;
          }
          editor
            .chain()
            .focus()
            .insertContentAt(range, props.label || '')
            .run();
          (_b = window.getSelection()) === null || _b === void 0
            ? void 0
            : _b.collapseToEnd();
        },
        allow: ({ state, range }: any) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);
          return allow;
        },
      },
    };
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            'data-id': attributes.id,
          };
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }
          return {
            'data-label': attributes.label,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMacro = false;
          const { selection } = state;
          const { empty, anchor } = selection;
          if (!empty) {
            return false;
          }
          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMacro = true;
              tr.insertText(
                this.options.suggestion.char || '',
                pos,
                pos + node.nodeSize,
              );
              return false;
            }
            return false;
          });
          return isMacro;
        }),
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export { Macro, MacroPluginKey, Macro as default };
