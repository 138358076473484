import { SelectOption } from '@chiroup/components';
import { CCSBodyPickerQuestionIds } from '@chiroup/core/constants/CCSQuestionIds';
import { titleCase } from '@chiroup/core/functions/titleCase';
import { HealthcareProvider } from '@chiroup/core/types/HealthcareProvider.type';
import { PatientSurvey } from '@chiroup/core/types/PatientSurvey.type';
import { ProviderType, Visit } from '@chiroup/core/types/PatientVisit.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { UserSettingsBillingType } from '@chiroup/core/types/UserSettingsBilling.type';
import { User } from '@sentry/react';

const getSurveyQuestion = (id: string, survey: any) => {
  return survey?.questions?.find((question: any) => question.id === id);
};

export const getBodyPickerInfo = (survey: PatientSurvey) => {
  let parts;
  let sourceOfPain;
  let secondaryParts;
  let secondarySourceOfPain;
  let tertiaryParts;
  let tertiarySourceOfPain;

  const ccsBodyPickerQ = getSurveyQuestion(
    CCSBodyPickerQuestionIds.primary,
    survey,
  );
  const ccsSecondaryBodyPickerQ = getSurveyQuestion(
    CCSBodyPickerQuestionIds.secondary,
    survey,
  );
  const ccsTertiaryBodyPickerQ = getSurveyQuestion(
    CCSBodyPickerQuestionIds.tertiary,
    survey,
  );

  const ccsParts = ccsBodyPickerQ?.answer?.value?.parts;

  const ccsSourceOfPain = ccsBodyPickerQ?.answer?.value?.sourceOfPain;

  if (ccsParts) {
    const entries = Object.entries(ccsParts);
    parts = entries;
    sourceOfPain = ccsSourceOfPain;
  }

  const secondaryCcsParts = ccsSecondaryBodyPickerQ?.answer?.value?.parts;
  const secondaryCcsSourceOfPain =
    ccsSecondaryBodyPickerQ?.answer?.value?.sourceOfPain;
  if (secondaryCcsParts) {
    const entries = Object.entries(secondaryCcsParts);
    secondaryParts = entries;
    secondarySourceOfPain = secondaryCcsSourceOfPain;
  }

  const tertiaryCcsParts = ccsTertiaryBodyPickerQ?.answer?.value?.parts;
  const tertiaryCcsSourceOfPain =
    ccsTertiaryBodyPickerQ?.answer?.value?.sourceOfPain;
  if (tertiaryCcsParts) {
    const entries = Object.entries(tertiaryCcsParts);
    tertiaryParts = entries;
    tertiarySourceOfPain = tertiaryCcsSourceOfPain;
  }

  //   const disSurveys = data.surveys.filter((item) =>
  //     disabilitySurvey.includes(item.surveyId)
  //   );

  return {
    parts,
    sourceOfPain,
    secondaryParts,
    secondarySourceOfPain,
    tertiaryParts,
    tertiarySourceOfPain,
    // disabilitySurveys: disSurveys,
  };
};

export const combineUserDetails = (
  users: Partial<User[]>,
  billingDetails: Partial<UserSettingsBillingType[]>,
) => {
  // This function filters users by role, checks for exclusion from the provider list,
  // and then combines user details with their respective billing details.

  // Filter out users based on role and exclusion status
  const filteredUsers = users?.filter(
    (user) => user?.role?.includes(UserRoles.Provider),
  );

  return filteredUsers.map((user) => {
    const billingDetail = billingDetails.find(
      (detail) => detail?.userId === user?.ID,
    );

    if (billingDetail) {
      // Destructure the billingDetail object to exclude fields ID and userId
      const { ID, userId, ...otherDetails } = billingDetail;
      return { ...user, ...otherDetails };
    }

    return user;
  });
};

export const providerOptionsMemo = (
  users: User[],
  healthcareProviders: HealthcareProvider[],
  providerType?: ProviderType,
) => {
  if (providerType) {
    let options: SelectOption[] = (users || [])
      .filter((user) => user?.role?.includes(UserRoles.Provider))
      .map((user) => ({
        text: user.name,
        value: user.ID,
      }));

    if (providerType === ProviderType.ReferringProvider) {
      options = (healthcareProviders || [])
        .filter((h) => !h.facility)
        .map((h) => ({
          text: `${titleCase(h.lname || '')}, ${titleCase(h.fname || '')}`,
          value: h.ID,
        }));
    }

    return options;
  }
};

export const updateNestedFields = (
  setVisitForm: React.Dispatch<React.SetStateAction<Visit>>,
  key: string,
  value: any,
) => {
  // Logic to update nested fields
  setVisitForm((prev) => ({
    ...prev,
    encounterInfo: {
      ...prev.encounterInfo,
      claimInfo: {
        ...prev.encounterInfo?.claimInfo,
        [key]: value,
      },
    },
  }));
};
