import { useContext, useMemo } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ManagedActionItemsContext } from '../../../../apps/chiroup/src/contexts/managedActionItems.context';

type ManagedActionItemsProps = {
  containerClassName?: string;
  innerClassName?: string;
  style: any;
};
export const ManagedActionItems: React.FC<ManagedActionItemsProps> = ({
  containerClassName,
  innerClassName,
  style,
}: ManagedActionItemsProps) => {
  const { remove, items } = useContext(ManagedActionItemsContext);
  const components = useMemo(() => {
    return items.map((item, i) => {
      return (
        <div key={i} className="flex flex-row space-x-4">
          <div
            className="flex cursor-pointer text-green-600 hover:text-green-500"
            onClick={() => {
              if (item && item.action) {
                item.action(item);
              } else {
                remove(item.id);
              }
            }}
            title="Acknowledge this notification."
          >
            <CheckCircleIcon className="h-6 w-6" />
          </div>
          <div className="flex">{item.title}</div>
        </div>
      );
    });
  }, [items, remove]);
  if (items.length === 0) return null;

  return (
    <div className={containerClassName} style={style}>
      <div className={innerClassName}>
        {components}
        {/* <pre>
          {ChiroUpJSON.pretty(items.map((i) => ({ ...i, action: null })))}
          </pre> */}
      </div>
    </div>
  );
};
