import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../../../../../contexts/me.context';
import userService from '../../../../../../../services/user.service';
import { UserSettingsBillingType } from '@chiroup/core/types/UserSettingsBilling.type';

const getQuery = function (clinicId: number, skipUseQuery?: boolean) {
  return async (context: QueryFunctionContext) => {
    if (skipUseQuery) return;
    const userId = context.queryKey[1] as string;
    if (!userId || userId === '- n. a. -') return;
    return userService.getBilling(userId, clinicId);
  };
};

const useProviderLicenses = (userId: string, skipUseQuery?: boolean) => {
  const { me } = useContext(MeContext);
  const { status, data, error, isFetching, refetch } =
    useQuery<UserSettingsBillingType>(
      ['license', userId],
      getQuery(me?.selectedClinic?.ID || -1, skipUseQuery),
      {
        refetchOnWindowFocus: false,
      },
    );

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
  };
};

export default useProviderLicenses;
