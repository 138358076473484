import { Loading } from '@chiroup/components';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useWS from '../../../../../components/schedule/hooks/useWS';
import { PayRequest } from './PatientPaymentModal';
import { EPayStatus } from '@chiroup/core/enums/EPayStatus.enum';
import { titleCase } from '@chiroup/core/functions/titleCase';

type Props = {
  terminalTransaction: PayRequest | null;
  onCCTerminalMessage: (data: PayRequest) => void;
  waitingForTermial: boolean;
  setWaitingForTerminal: (value: boolean) => void;
  setTerminalTransaction: Dispatch<SetStateAction<PayRequest | null>>;
};

const WaitingForPayment: React.FC<Props> = ({
  terminalTransaction,
  onCCTerminalMessage,
  waitingForTermial,
  setWaitingForTerminal,
  setTerminalTransaction,
}) => {
  const [timer, setTimer] = useState<
    ReturnType<typeof setTimeout> | null | undefined
  >(null);
  useWS(
    `payment:${terminalTransaction?.id}`,
    (message: { data: PayRequest }) => {
      const data = message.data;
      onCCTerminalMessage(data);
    },
  );

  useEffect(() => {
    if (terminalTransaction?.status === EPayStatus.Pending && !timer) {
      setTimer(
        setTimeout(() => {
          setTerminalTransaction({
            ...terminalTransaction,
            status: EPayStatus.Timeout,
          });
          setWaitingForTerminal(false);
        }, 180000),
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [
    terminalTransaction,
    timer,
    setTerminalTransaction,
    setWaitingForTerminal,
  ]);

  return (
    <div>
      <p className="flex items-center">
        {waitingForTermial && <Loading color="text-gray-400" size={4} />}
        <span className="ml-2 text-sm text-gray-500 flex flex-row gap-2 items-center">
          {titleCase(
            // terminalTransaction?.error ??
            //   terminalTransaction?.result ??
            terminalTransaction?.status ?? 'Something Else',
          )}
          <XMarkIcon
            className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer"
            onClick={() => {
              setWaitingForTerminal(false);
              setTerminalTransaction(null);
            }}
          />
        </span>
      </p>
    </div>
  );
};

export default WaitingForPayment;
