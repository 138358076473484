import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';

dayjs.extend(timezone);

const getTrackList = (
  patientId: string,
  providerId: string,
  clinicId: number,
) => {
  return async (context: QueryFunctionContext) => {
    return await patientService.getPatientProviderTrackList(
      clinicId,
      patientId,
      providerId,
    );
  };
};

const usePatientProviderTrackList = ({
  patientId,
  providerId,
  enabled = true,
}: {
  patientId: string;
  providerId: string;
  enabled?: boolean;
}) => {
  const { me } = useContext(MeContext);
  const { status, data, error, isFetching, refetch } = useQuery<any | null>(
    ['patientAndProvider', patientId, providerId],
    getTrackList(patientId, providerId, me.selectedClinic?.ID ?? -1),
    {
      refetchOnWindowFocus: false,
      enabled: enabled,
    },
  );

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
  };
};

export default usePatientProviderTrackList;
