import { LoadingPage } from '@chiroup/components';

type Props = {
  signature: string;
  fetchingSignature: boolean;
  date: string;
  clinicianName: string;
  clinicianDegrees: string;
};

const SuperbillISignatureBlock: React.FC<Props> = ({
  signature,
  fetchingSignature,
  date,
  clinicianName,
  clinicianDegrees,
}) => {
  return (
    <div className="hidden print:flex avoid-page-break w-full">
      <div className="pl-6  py-2 pr-5 text-xs mt-2 w-1/2">
        <div className="h-20 mb-10">
          {fetchingSignature ? (
            <LoadingPage />
          ) : (
            signature.length > 0 && (
              <img src={signature} alt="signature" width={200} />
            )
          )}
        </div>
        <p className="pt-2">
          {clinicianName} {clinicianDegrees}
        </p>
        <p className="pb-8">{date}</p>
      </div>
      <div className="py-2 pr-5 text-xs mt-24 w-1/2">
        <div className="max-w-md w-full">
          <div className="flex items-center space-x-4 pb-8">
            <span>Patient Signature:</span>
            <div className="flex-grow border-b border-gray-400 pt-3"></div>
          </div>
          <div className="flex items-center space-x-4">
            <span>Date:</span>
            <div className="flex-grow border-b border-gray-400 pt-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperbillISignatureBlock;
