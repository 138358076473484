import { Loading } from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  IntegrationInvoice,
  PayorPriorityIdText,
} from '@chiroup/core/types/Invoice.type';
import { CogIcon } from '@heroicons/react/20/solid';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  item: IntegrationInvoice | null;
  loading: boolean;
  clickInvoiceCallback?: (_: IntegrationInvoice) => void;
};

const TransactionInvoiceListRow: React.FC<Props> = ({
  item,
  loading,
  clickInvoiceCallback,
}) => {
  const location = useLocation();
  if (!item) return null;

  return (
    <li
      key={item.id}
      className={classNames('grid grid-cols-5 mx-2 space-x-6 relative')}
    >
      <Loading flag={loading} />

      <div className="relative py-5 col-span-1">
        <div className="flex gap-x-6">
          <div className="flex-auto">
            <div className="flex items-start gap-x-3">
              <div className="text-sm font-medium leading-6 text-gray-900 flex flex-row gap-2 items-center whitespace-nowrap">
                # {`${item.number ?? item.controlNumber}`}
              </div>
            </div>
            {item.payorId ? (
              <div className="text-nowrap leading-3 text-gray-500 text-xs">
                {item.legalName}
              </div>
            ) : (
              <div className="text-xs leading-3 text-gray-500">
                {item.invoiceData?.issuedDate}
              </div>
            )}
          </div>
        </div>
        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100"></div>
        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100"></div>
      </div>
      <div className="col-span-1 py-5 ext-sm leading-6 text-gray-900">
        <p>{`${PayorPriorityIdText[item.priority as number] ?? ''}`}</p>
      </div>
      <div className="whitespace-nowrap col-span-1 py-5 ext-sm leading-6 text-gray-900">
        <p>{`${item.status}`}</p>
      </div>
      <div className="py-5 text-right col-span-2 sm:col-span-2">
        <div className="flex justify-end">
          {item.payorId ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                if (clickInvoiceCallback) clickInvoiceCallback(item);
              }}
            >
              <CogIcon className="h-6 w-6 text-primary-600" />
              {/* <Link
              to={`/integration/837p?id=${item.id}&pid=${item.patientId}&bk=${item.billingKey}`}
              className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer"
            >
              EDI 837P
            </Link> */}
            </div>
          ) : (
            <>
              <Link
                to={`${item.id}${location?.search || ''}`}
                className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer"
              >
                View
              </Link>
              {item.invoiceData.balance > 0 && (
                <Link
                  to={`${item.id}/write-off${location?.search || ''}`}
                  className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer pl-2"
                >
                  Write Off
                </Link>
              )}
              {item.superBill && (
                <Link
                  to={`${item.id}/super-bill${location?.search || ''}`}
                  className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer pl-2"
                >
                  Superbill
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </li>
  );
};

export default TransactionInvoiceListRow;
