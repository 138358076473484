import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import MacroList from './MacroList';

const macroSuggestion = ({
  macros,
}: {
  macros: {
    id: number;
    phrase: string;
    shortcut: string | null;
  }[];
}) => ({
  items: ({ query }: { query: string }) => {
    return macros
      .filter((item) => {
        const searchTerm = `${item.shortcut}`;
        return searchTerm.toLowerCase().startsWith(query.toLowerCase());
      })
      .slice(0, 5);
  },

  render: () => {
    let component: any;
    let popup: any;

    return {
      onStart: (props: any) => {
        component = new ReactRenderer(MacroList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: any) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
});

export default macroSuggestion;
