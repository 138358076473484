/**
 * Tips for those who might read documentation....
 *
 * 1) This is a mass of things calling other things across a lot
 *    of components. Some of the callbacks/functions that are
 *    passed call setLocalData which is in the main component.
 *    So, when a sub-component calls it, we often get a React
 *    error. The way we've gotten around that is to use a
 *    setTimeout() with a 0 delay. This puts the call at the
 *    end of the Javascript execution queue and allow React to
 *    shut the hell up.
 *
 * 2) A "reload" of the specific transactions displayed in a
 *    loop by this component, can be done by using the
 *    queryClient.setQueryData() function. The individual
 *    transaction components watch their data (useEffect) and
 *    when the data is at variance with the local row, the local
 *    row is updated from the queryClient data. It looks like
 *    magic so seemed worth a mention.
 */
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../../common/Modal';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CogIcon,
  FaceFrownIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useConsolidatedTransaction } from '../hooks/useConsolidatedTransaction';

import {
  Button,
  ButtonColors,
  Checkbox,
  ConfirmModal,
  Loading,
  MakeBrowserWait,
  ManagedActionItems,
} from '@chiroup/components';
import useApplicablePatientPackageList from '../../../../../hooks/useApplicablePatientPackageList';
import { v4 } from 'uuid';
import useDisciplineTreatments from '../../../../settings/clinic/useDisciplineTreatments';
import useClaimWriteOff from '../hooks/useClaimWriteOff';
import { MeContext } from '../../../../../contexts/me.context';

import useClaimAllocation from '../hooks/useClaimAllocation';
import usePatientBillingBalances from '../../../hooks/usePatientBillingBalances';
import useResponsibilities from '../../../../../hooks/useResponsibilities';
import TransactionEditorTabs from './TransactionEditorTabs';
import ButtonGroup, {
  ButtonGroupType,
} from '../../../../common/buttons/ButtonGroup';
import dayjs from 'dayjs';
import TransactionEditorTabContent from './TransactionEditorTabContent';
import usePatientPayment from './usePatientPayment';
import TransactionPaymentModal from './TransactionPaymentModal';
import AllocateNonBillableToPatientModal from './AllocteNonBillableToPatientModal';
import WriteOffNonBillableBalanceModal from './WriteOffNonBillableBalanceModal';
import ChoosePackageModal from './ChoosePackageModal';
import classNames from 'classnames';
import { TransactionContext } from '../../../../../contexts/transaction.context';
import { useTransaction } from '../hooks/useTransaction';
import { TabSummary } from './TabSummary';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  NUMBER_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import {
  ChiroUpTransactionCommon,
  ChiroUpDayJsCommon,
} from '@chiroup/core/constants/stringConstants';
import { balanceFromItems } from '@chiroup/core/functions/balanceFromItems';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { findNewDiscount } from '@chiroup/core/functions/findNewDiscount';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import {
  PatientTransaction,
  TransactionTypeEnum,
  TransactionPurchaseSubtypeEnum,
  TransactionAppendItemsType,
  TransactionItemSubtypeEnum,
  PatientTransactionItemType,
  TransactionItemTypeEnum,
  toTransactionItemSubtype,
  isaServiceItem,
  PatientTransactionClass,
  PatientOnTransactionType,
  ProviderOnTransactionType,
  isaPaymentItem,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { useQueryClient } from 'react-query';
import { ArrowUpCircleIcon } from '@heroicons/react/24/solid';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { LocationCard } from './LocationCard';
import qs from 'query-string';
import { PatientContext } from '../../../../../contexts/patient.context';
import { createPatientName } from '@chiroup/core/functions/createPatientName';
import SupplementsModal from './SupplementsModal';
import CodeEncounterDiagnosisServices from '../../visits/notes/services/CodeEncounterDiagnosisServices';
import AlertBlock from '../../../../common/AlertBlock';
import ClaimInfoForm from '../../visits/notes/ClaimInfoForm';
import { ClaimInfo } from '@chiroup/core/types/PatientVisit.type';
import {
  MergeTransactionsOptions,
  mergeTransactionsStandalone,
} from './functions/mergeTransactions.standalone';
import { ManagedActionItemsContext } from '../../../../../contexts/managedActionItems.context';

const ClassNames = {
  bg: 'bg-gray-200',
};

const BASE_TAB = 'Detail';
type Phase = { name: string; header: string; next: number };

const phases: Phase[] = [
  {
    name: 'New',
    header: '',
    next: 2,
  },
  {
    name: 'Edit',
    header: '',
    next: 2,
  },
  {
    name: 'View',
    header: '',
    next: 1,
  },
];

export type InstanceComponents = {
  lockButton?: ReactNode;
  unMergeButton?: ReactNode;
};

enum ActionsOnClick {
  Lock = 1,
  UnMerge = 2,
}

type LifeCycle = {
  isDirty?: () => boolean; // You tell me if it is dirty.
  isRestActive?: () => boolean; // You tell me if a REST call is active.
  save?: (cb?: () => void) => void; // You do the save and do what you like.
  cancel?: () => void; // The cancel is hit BEFORE ANYTHING else.
  close?: () => void; // Close after something.
};

type EncounterContext = {
  assessmentCodes: any | null | undefined;
  clinicId: number;
  importDiagnosesAlways: boolean | null | undefined;
  horizontal?: boolean | null | undefined;
  omitTitle: boolean;
  persistDiagnosesList: boolean | null | undefined;
  patientId: string;
  plan: any | null | undefined;
  saveVisit: any | null | undefined;
  isSavingVisit?: boolean | null | undefined;
  setVisitForm: any | null | undefined;
  visitForm: any | null | undefined;
};

type Props = {
  encounterContext?: EncounterContext | null | undefined;
  initialContext?: PatientTransaction[] | null | undefined;
  id?: string | null;
  isOpen?: boolean;
  onCloseView?: (data?: PatientTransaction[] | null | undefined) => void;
  mode?: 'modal' | 'route';
  setInitialContext?: React.Dispatch<
    React.SetStateAction<PatientTransaction[] | null | undefined>
  >;
  trace?: boolean;
  lifeCycle?: LifeCycle | null | undefined;
};
// https://localhost:3000/patients/de6a1888-cc06-4d7b-af83-24b5b0a65296/encounters/580d947d-689a-48c7-acdb-b3d8a7328297/complaint/2fdf7902-7490-41e2-9980-cc794e1118b6/subjective
// https://localhost:3000/patients/de6a1888-cc06-4d7b-af83-24b5b0a65296/billing/580d947d-689a-48c7-acdb-b3d8a7328297
// https://localhost:3000/schedule?open=f2b965fa-59b6-4477-bfec-bba083a07419

export type LockButtonStateType = {
  available: boolean;
  locked: boolean;
};

/**
 * Notes...
 * 
 * TODO: A couple of TODOs in ScheduleAppointment.tsx.
 * TODO: Stop multiple write-off calls.
 * TODO: Stop multiple track calls.
 * TODO: Summary needs to reflect treatment prices. Charge for treatments if they are > 0. Summary.
 * TODO: Need to be able to remove treatments?
 * TODO: Warning no providers found.
 * 
 * TODO: We do not want to allow deleting treatments.
 * TODO: Capture the individual items and put them back on on-merge.
 * TODO: Move Primary and Notes to the bottom.
 * TODO: Changing the treatment on a slot needs to update the MERGED transactions.
 * 
 * TODO: Fetch write-off error in dev.
 * 

  TODO DDL: [in dev and staging, needs to be in prod.]
    alter table PatientTransaction
    add merged varchar(50) null comment 'When not null, represents the billingKey of the transaction that contains the items that were originally on this transaction. There should be no items when merged IS NOT NULL.';

    create index PT_by_merged
    on PatientTransaction (merged);
  /DDL

 * 4) TODO: When data integrity checks fail, return the transactions and
 *    show the difference between them.
 * 
 * 
 *
 * @param param0
 * @returns
 */
const ConsolidatedTransactionModal: React.FC<Props> = ({
  encounterContext,
  id,
  initialContext,
  isOpen = true,
  onCloseView = null,
  mode = 'modal',
  setInitialContext,
  trace = false,
  lifeCycle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { me, hasRole, selectedLocationFull } = useContext(MeContext);
  const { patient } = useContext(PatientContext);

  /**
   * u s e S t a t e
   */
  const [singleProviderOption, setSingleProviderOption] =
      useState<ProviderOnTransactionType | null>(null),
    [countofProviders, setCountOfProviders] = useState<number>(0);
  const [confirmMergeOpen, setConfirmMergeOpen] = useState<boolean>(false);
  const [mergeOptions, setMergeOptions] = useState<MergeTransactionsOptions>({
    units: true,
    treatments: true,
    providers: {},
  });
  const [localData, setLocalData] = useState<PatientTransaction[] | null>(null);
  const [payAvailability, setPayAvailability] = useState<STRING_BOOLEAN_HASH>(
    {},
  );

  const [showPatientPaymentModal, setShowPatientPaymentModal] =
    useState<boolean>(false);

  const editingNewData = useMemo(() => {
    return localData?.[0]?.billingKey && localData?.[0]?.id === -1;
  }, [localData]);
  const [currentPhase, setCurrentPhase] = useState<Phase>(phases[2]);
  // const [lifeCycleRestActive, setLifeCycleRestActive] = useState(false);

  // useEffect(() => {
  //   console.log('how many runs....');
  // }, []);

  const topOfPageRef = useRef<HTMLDivElement>(null);
  const [slotContext, setSlotContext] = useState<NUMBER_ANY_HASH>({
    [-1]: { ref: topOfPageRef, role: 'top' },
  });

  /**
   * u s e M e m o
   */

  const isAdHoc = useMemo(() => {
      return localData?.[0]?.type === TransactionTypeEnum.AdHoc;
    }, [localData]),
    isNotAdHoc = useMemo(() => {
      return !isAdHoc;
    }, [isAdHoc]);

  const isEncounterMode = useMemo(() => {
      return !!encounterContext;
    }, [encounterContext]),
    isNotEncounterMode = useMemo(() => {
      return !isEncounterMode;
    }, [isEncounterMode]);

  const isEditingNew = useMemo(() => {
    // console.log({ ThisIsTheIsEditingNewId: id });
    if (id?.indexOf('new') === 0) return true;
    return false;
  }, [id]);

  const isBillingStarted = useMemo(() => {
    return localData?.some((row) => row.isBillingStarted);
  }, [localData]);

  const textPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-500' : 'text-primary-500';
    }, [isBillingStarted]),
    hoverTextPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-600' : 'text-primary-600';
    }, [isBillingStarted]);

  // The idea here is that the patient id is passed on new, otherwise, we don't use it.
  const patientId = useMemo(() => {
    if (isEditingNew && id) {
      const [, search] = id.split('?'),
        queryParams = qs.parse(search);
      return queryParams?.patientId;
    }
    return null;
  }, [id, isEditingNew]);

  const locations = useMemo(() => {
    return me?.selectedClinic?.locations ?? [];
  }, [me?.selectedClinic?.locations]);

  const userTz = useMemo(() => {
    return selectedLocationFull.timezone;
  }, [selectedLocationFull]);

  const itemId = useMemo(() => {
    let res: string | null | undefined = null;

    // console.log({ IncomingId: id });
    if (id) {
      if (!editingNewData) res = id;
      if (res?.indexOf('new') === 0) {
        res = null;
      }
    }
    if (mode === 'route' && location && location.pathname) {
      const pcs = location.pathname.split('/');
      res = pcs[pcs.length - 1];
    }
    return res;
  }, [editingNewData, id, location, mode]);

  // const previousRoute = useMemo(() => {
  //   console.log({ location });
  //   if (!location) return '-none-';
  //   if (location.state && location.state.from) {
  //     return location.state.from;
  //   }
  // }, [location]);

  const childrenByType = useMemo(() => {
    return (
      localData?.reduce((acc: STRING_BOOLEAN_HASH, row: PatientTransaction) => {
        acc[String(row.type)] = true;
        return acc;
      }, {}) ?? {}
    );
  }, [localData]);

  const mixedTypes = useMemo(() => {
    return Object.keys(childrenByType).length > 1;
  }, [childrenByType]);

  const providerOptions = useMemo(() => {
    if (!localData || !localData?.length) return {};
    const provs: { [key: string]: ProviderOnTransactionType } = {},
      skipProviderById: { [key: string]: boolean } = {};

    for (const row of localData ?? []) {
      const pid = row.provider?.id;
      if (pid && !row?.provider?.usingPrimary && !provs[pid] && row.provider) {
        provs[pid] = row.provider;
      }
      // Ignore providers with locked or merged transactions.
      if (row.merged || row?.items?.some((item) => item.locked)) {
        if (pid) skipProviderById[pid] = true;
      }
    }
    // REMOVE all the providers that are already merged...

    const deletedProvs: { [key: string]: ProviderOnTransactionType } = {};

    Object.keys(skipProviderById).forEach((key) => {
      deletedProvs[key] = ChiroUpJSON.clone(provs[key]);
      delete provs[key];
    });

    // REMOVE all the providers who have locked services.

    const keys = Object.keys(provs);
    if (keys.length === 1) {
      setSingleProviderOption(provs[keys[0]]);
    } else {
      setSingleProviderOption(null);
    }

    setMergeOptions((p) => {
      const n = ChiroUpJSON.clone(p);
      for (const prov of Object.values(provs ?? {})) {
        n.providers[prov.id] = true;
      }
      return n;
    });
    const len = Object.keys(provs ?? []).length;
    setCountOfProviders(len);

    if (countofProviders < 2) {
      return {};
    }

    return provs;
  }, [countofProviders, localData]);

  const mergeContext = useMemo(() => {
    const notMerged: { [key: string]: PatientTransaction } = {},
      mergedInto: { [key: string]: PatientTransaction[] } = {};
    for (const row of localData ?? []) {
      if (row.merged) {
        mergedInto[row.merged] = mergedInto[row.merged] ?? [];
        mergedInto[row.merged].push(row);
      } else {
        notMerged[row.billingKey] = row;
      }
    }

    const maybeMerge = mergeTransactionsStandalone({
      transactions: localData ?? [],
      options: mergeOptions,
    });
    const maybeDiffs = ChiroUpJSON.compare({
      obj1: { value: localData },
      obj2: { value: maybeMerge },
    });
    let isMerged = maybeDiffs.length !== 0;

    isMerged = Object.keys(notMerged).length === Object.keys(mergedInto).length;

    // console.log({
    //   mergeContext: { notMerged, mergedInto, maybeDiffs, isMerged },
    // });

    return {
      not: notMerged,
      into: mergedInto,
      isMerged,
    };
  }, [localData, mergeOptions]);

  /**
   * H o o k s
   */

  const {
    items: managedActionItems,
    add: addManagedActionItem,
    clear: clearManagedActionItems,
  } = useContext(ManagedActionItemsContext);

  const {
    data,
    isError,
    failed,
    isFetching,
    isSaving,
    onCloseCleanup,
    refetch,
    snapshot,
    save: saveConsolidatedTransaction,
  } = useConsolidatedTransaction({
    billingKey: itemId,
  });

  const isAnyRestRunning = useMemo(() => {
    // console.log({
    //   isAnyRestRunning: {
    //     isFetching,
    //     isSaving,
    //     isError,
    //     lifeCycleRestActive: !!lifeCycle?.isRestActive?.(),
    //   },
    // });
    return isFetching || isSaving || isError || !!lifeCycle?.isRestActive?.();
  }, [isError, isFetching, isSaving, lifeCycle]);

  useEffect(() => {
    if (data && data.length) {
      setLocalData(ChiroUpJSON.clone(data));
    }
  }, [data]);

  /**
   * For new transactions, we need to set the UI data structures without
   * making a REST call as it isn't there yet!
   */
  useEffect(() => {
    if (
      initialContext &&
      Array.isArray(initialContext) &&
      initialContext.length &&
      typeof setInitialContext === 'function'
    ) {
      setLocalData(() => ChiroUpJSON.clone(initialContext));
      setInitialContext(null);
      setCurrentPhase(phases[1]);
    } else if (isEncounterMode && encounterContext) {
      setCurrentPhase(phases[1]);
    }
    // console.log({ InitialContext: initialContext });
  }, [encounterContext, initialContext, isEncounterMode, setInitialContext]);

  const queryClient = useQueryClient();

  const nextEncounterDateDayjs = useMemo(() => {
    if (!localData || !localData?.length) return undefined;
    const localRow = localData[0];
    return localRow?.nextRequestedEncounterDate
      ? dayjs(localRow?.nextRequestedEncounterDate * 1000)
      : undefined;
  }, [localData]);

  const buttonGroupButtons = useMemo(() => {
    const buttons: ButtonGroupType[] = [];
    if (!localData || isEncounterMode || !localData.length) return buttons;

    const transaction = localData[0];

    if (transaction.hasAppointment) {
      buttons.unshift({
        label: 'Appointment',
        to: `/schedule?open=${transaction.billingKey}`,
      });
    }

    if (
      !!transaction?.provider?.displayName?.length &&
      !!transaction?.nextRequestedEncounterDate
    ) {
      buttons.push({
        label: nextEncounterDateDayjs
          ? 'Schedule appointment on ' +
            nextEncounterDateDayjs.format('M/DD/YYYY')
          : 'Next Appointment',
        to: [
          `/schedule?endDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
          `startDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
          `patient=${transaction?.patient?.id}`,
          `patientName=${transaction?.patient?.displayName}`,
          `clinician=${transaction?.provider?.id}`,
          `treatment=${transaction?.treatmentId}`,
          `open=add`,
        ].join('&'),
      });
    } else {
      buttons.push({
        label: 'Next Appointment',
        to: [
          `/schedule?patient=${transaction?.patient?.id}`,
          `patientName=${transaction?.patient?.displayName}`,
          `clinician=${transaction?.appointmentClinicianId}`,
          `treatment=${transaction?.treatmentId}`,
          `open=add`,
        ].join('&'),
      });
    }
    return buttons;
  }, [isEncounterMode, localData, nextEncounterDateDayjs]);

  const close = () => {
    setTimeout(() => {
      onCloseCleanup();
      setLocalData(null);
      setCurrentPhase(phases[2]);
      clearManagedActionItems();
    }, 750);
    if (mode === 'route') navigate(-1);
    onCloseView?.();
  };

  /**
   * M e m o   A f t e r   H o o k s
   */
  const lockButtonStateByProviderId = useMemo(() => {
    if (!localData || !localData?.length) return {};
    const resp: { [key: string]: LockButtonStateType } = {};
    for (const row of localData) {
      if (row?.provider?.id && !resp[row.provider.id]) {
        resp[row.provider.id] = {
          available:
            row?.services?.length === 0
              ? false
              : localData
                  .filter((ld) => ld?.provider?.id === row?.provider?.id)
                  .every(
                    (transaction) =>
                      transaction?.services?.every(
                        (service) => (service?.diagnoses?.length ?? 0) > 0,
                      ),
                  ),
          locked:
            !!row?.services?.length &&
            !!row?.services?.every((service) => !!service?.locked),
        };
      }
    }
    return resp;
  }, [localData]);

  const isBalanceTransfer = useMemo(() => {
    if (!localData || !localData?.length) return false;
    const rowThatIsBalanceTransfer = localData?.find(
      (row: PatientTransaction) => {
        return (
          row?.type === TransactionTypeEnum.AdHoc &&
          String(row?.subtype) ===
            TransactionPurchaseSubtypeEnum.BalanceTransfer
        );
      },
    );
    return !!rowThatIsBalanceTransfer;
  }, [localData]);

  const isReadOnly = useMemo(() => {
    if (isBalanceTransfer) {
      return true;
    }
    return currentPhase.name !== 'Edit' && currentPhase.name !== 'New';
  }, [currentPhase.name, isBalanceTransfer]);

  const isNotReadOnly = useMemo(() => {
    return !isReadOnly;
  }, [isReadOnly]);

  const buttonColor = useMemo(() => {
    if (!localData || !localData?.length) return ButtonColors.primary;
    return localData?.[0]?.isBillingStarted
      ? ButtonColors.accent
      : ButtonColors.primary;
  }, [localData]);

  const trivialTooltip = useMemo(() => {
    if (!localData || !localData?.length) return { text: '' };
    return {
      text: localData?.[0]?.isBillingStarted
        ? ChiroUpTransactionCommon.billingStarted
        : '',
    };
  }, [localData]);

  const tabSummary = useMemo(() => {
    if (!localData || !localData?.length) return null;
    const workingTransaction = localData?.[0] as any;
    return (
      <TabSummary
        value={workingTransaction}
        omitClassName="rounded-lg hover:border-gray-400"
      />
    );
  }, [localData]);

  const mainTitle = useMemo(() => {
    if (!localData?.[0]?.patient) return undefined;
    const $i = localData?.[0] as any,
      resp = [
        ChiroUpDayJsCommon.display.datetimeWithTz(
          $i.transactionDate as number,
          $i.tz,
          userTz,
          ChiroUpDayJsCommon.format.date,
        ),
      ];
    // if ($i.startTime) {
    //   resp.push('starting at');
    //   resp.push(
    //     dayjs($i.startTime)
    //       .tz($i?.tz)
    //       .format(ChiroUpDayJsCommon.format.time),
    //   );
    // }
    return resp.join(' ');
  }, [localData, userTz]);

  /**
   * F u n c t i o n s
   */

  const newTransaction = (opts: any, location?: ClinicLocation) => {
    const newx = typeof opts === 'string' && opts === 'ad-hoc';
    const idToUse = newx ? v4() : opts.id;
    let newObject: PatientTransaction | null = null;
    /**
     * Use cases:
     * 1) New transaction without an appointment (ad-hoc).
     * 2) New transaction with an appointment.
     */
    if (newx) {
      newObject = PatientTransactionClass.newRecord({
        billingKey: idToUse,
        patient: patient
          ? ({
              id: patientId,
              fname: patient.fname,
              lname: patient.lname,
              phone: patient.phone,
              email: patient.email,
              displayName: createPatientName(patient),
            } as PatientOnTransactionType)
          : undefined,
        clinicId: me.selectedClinic?.ID || -1,
        locationId: location?.ID || -1,
        tz: selectedLocationFull?.timezone,
        type: TransactionTypeEnum.AdHoc,
        created: {
          id: me?.ID || '',
          ts: Date.now(),
          displayName: me?.name || '',
        },
      });
    } else {
      // setWorkingTransaction(
      //   PatientTransactionClass.initialFromAppointment(
      //     opts as AppointmentClass,
      //     { id: me?.ID || '', ts: -1, displayName: me?.name || '' },
      //     patient,
      //   ),
      // );
      console.log('TODO: new transaction with appointment');
    }

    onCloseView?.(newObject ? [newObject] : null);
  };

  /**
   * Rules: Locking and un-merging are done on a per-rendering-provider
   * basis. So, if you hit one of those buttons, any services on any
   * transaction for which the provider is the same, will be affected.
   *
   * Other use cases not covered:
   *
   *   1) If they change the provider, the locking is not changed.
   *   2) ...probably more.
   */
  const actionsOnClick = useCallback(
    (
      action: ActionsOnClick,
      transaction: PatientTransaction | null | undefined,
    ) => {
      if (!transaction) return;
      const newo = ChiroUpJSON.clone(localData) as PatientTransaction[],
        providerId = transaction.provider?.id as string,
        isTransactionLocked = lockButtonStateByProviderId[providerId]?.locked;

      if (action === ActionsOnClick.Lock) {
        for (const row of newo ?? []) {
          if (row?.provider?.id === providerId) {
            for (const service of row?.services ?? []) {
              service.locked = !isTransactionLocked;
            }
            for (const item of (row?.items ?? []).filter((item) =>
              isaServiceItem(item),
            )) {
              item.locked = !isTransactionLocked;
            }
          }
        }
      } else if (action === ActionsOnClick.UnMerge) {
        if (lockButtonStateByProviderId?.[providerId]?.locked) {
          return;
        }
        for (const row of newo ?? []) {
          if (!row) continue;
          if (row.merged === transaction.billingKey) {
            row.merged = null;
          }
        }
      } else {
        alert(`Unknown action '${action}'!`);
        return;
      }

      // console.log({ newo });
      for (const t of newo) {
        queryClient.setQueryData(['transaction', t.billingKey], t);
      }

      /**
       * [IF]   Just a message.
       * [ELSE] The data needs to be cleared so React
       *        will make the newly configured objects
       *        again.
       */
      if (action === ActionsOnClick.Lock) {
        addManagedActionItem({
          id: 'must-save-after-lock',
          title:
            'Save must be clicked to commit the status of diagnostic pointing.',
        });
      } else if (action === ActionsOnClick.UnMerge) {
        addManagedActionItem({
          id: 'must-save-after-merge',
          title:
            'Click save to commit the changes. Click cancel to revert the merge.',
          persistent: false,
        });
      }

      setLocalData(() => {
        return newo;
      });
    },
    [addManagedActionItem, localData, lockButtonStateByProviderId, queryClient],
  );

  const mergeTransactions = useCallback(
    ({
      beforeCallback,
      afterCallback,
    }: {
      beforeCallback?: () => void;
      afterCallback?: () => void;
    }) => {
      beforeCallback?.();
      const newData = mergeTransactionsStandalone({
        transactions: localData ?? [],
        options: mergeOptions,
        queryClient,
      });

      // TODO: Maybe find a way around this React hack.
      // This blinks. But it is the easiest way to make React
      // play nice. Otherwise, it simply re-uses what it has
      // and that is not what we want.
      setTimeout(() => {
        setLocalData(() => {
          return [];
        });
        setTimeout(() => {
          setLocalData(() => {
            return newData;
          });
        }, 0);
      }, 0);

      setSlotContext(() => {
        return {
          [-1]: { ref: topOfPageRef, role: 'top' },
        };
      });

      addManagedActionItem({
        id: 'must-save-after-merge',
        title:
          'Click save to commit the changes. Click cancel to revert the merge.',
        persistent: false,
      });

      afterCallback?.();
    },
    [addManagedActionItem, localData, mergeOptions, queryClient],
  );

  /**
   * R e t u r n
   */
  if (mixedTypes) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              Mixed transaction types are not supported.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (failed) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              The transaction could not be loaded.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if ((localData?.[0]?.id ?? -1) === -1 && !localData?.[0]?.billingKey) {
    return (
      <Modal
        isOpen={isOpen}
        close={close}
        isFullScreen={true}
        addClasses={`bg-transparent`}
        omitClasses="bg-white"
      >
        <div
          className="rounded-lg overflow-hidden bg-white relative"
          ref={topOfPageRef}
        >
          {tabSummary}
          <div
            className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
            style={{ top: '2rem', right: '2.5rem' }}
            onClick={close}
          >
            <XMarkIcon />
          </div>
          <Loading
            flag={isFetching}
            style={`standard-gray`}
            addClassName="bg-gray-50"
          />

          <div className="flex flex-col mt-4 sm:grid-cols-2 xl:grid-cols-3 w-full px-4 pb-4">
            <div
              className="col-span-1 dark:border-darkGray-800 dark:bg-darkGray-700 dark:hover:border-darkGray-500"
              key={`ad-hoc-appt`}
            >
              {!isAnyRestRunning ? (
                locations.length > 0 ? (
                  <>
                    <div className="font-bold font-sans text-md text-gray-600">
                      Supplement or Supply Purchase by Location
                    </div>

                    {!!me?.selectedClinic?.locations?.length && (
                      <div className="flex p-4">
                        {me.selectedClinic.locations.map((location) => (
                          <div
                            className="flex "
                            key={`location-${location.ID}`}
                          >
                            <LocationCard
                              location={location}
                              onClick={(location: ClinicLocation) => {
                                // console.log(
                                //   `new adhoc transaction for ${location}`,
                                // );
                                newTransaction('ad-hoc', location);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <cite>Locations are not set up.</cite>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>
        <MakeBrowserWait isWaiting={isAnyRestRunning} />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      isFullScreen={true}
      addClasses={`bg-transparent`}
      omitClasses="bg-white"
    >
      <div
        className="rounded-lg overflow-hidden bg-white relative"
        ref={topOfPageRef}
      >
        {tabSummary}
        <div
          className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
          style={{ top: '2rem', right: '2.5rem' }}
          onClick={close}
        >
          <XMarkIcon />
        </div>
        <Loading
          flag={isFetching}
          style={`standard-gray`}
          addClassName="bg-gray-50"
        />
        <div
          data-id="consolidated-button-bar"
          className={classNames(
            isFetching || isError ? 'hidden' : '',
            ClassNames.bg,
            'flex flex-row justify-between pl-4 pt-4 pr-12 w-full',
          )}
          // Yes, I hate myself. Needed an easy button. Don't judge too harshly, big story. [BWM]
          style={
            isNotAdHoc && isNotEncounterMode
              ? { top: '3.5rem', right: '0' }
              : {}
          }
        >
          {/* <pre data-id="debug-is-here" className="bg-yellow-500">
            {ChiroUpJSON.pretty({
              isAnyRestRunning,
              isFetching,
              isSaving,
              isError,
              payAvailability,
            })}
          </pre> */}
          <div className="flex flex-row font-bold text-lg text-gray-600">
            {isNotAdHoc && isNotEncounterMode ? (
              <div className="w-16">&nbsp;</div>
            ) : null}
            <div className="pl-3 pt-2">{mainTitle}</div>
          </div>
          <ButtonGroup
            buttons={buttonGroupButtons}
            disabled={isFetching || isSaving || !localData?.length}
            isEmptyOkay={true}
          />
          <div>
            {currentPhase.name === 'View' &&
              // !isBalanceTransfer &&
              !!localData &&
              !isFetching &&
              !isSaving &&
              !isError &&
              hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
                <div className="flex justify-end h-8 space-x-4 pr-1">
                  <Button
                    text="Pay"
                    onClick={() => {
                      setShowPatientPaymentModal(true);
                    }}
                    color={buttonColor}
                    disabled={
                      isAnyRestRunning ||
                      Object.keys(payAvailability).filter(
                        (k) => payAvailability[k],
                      ).length === 0
                    }
                  />
                  <Button
                    text="Edit"
                    onClick={() => {
                      setCurrentPhase(phases[currentPhase.next]);
                      // setAddingNewItem(false);
                    }}
                    trivialTooltip={trivialTooltip}
                    color={buttonColor}
                  />
                </div>
              )}
            {isNotReadOnly && (
              <div className="flex flex-row justify-end space-x-2 h-8 pr-2">
                <Button
                  text="Cancel"
                  onClick={() => {
                    if (editingNewData || isEncounterMode) {
                      close();
                      return;
                    }
                    if (isSaving) return;
                    setCurrentPhase(phases[currentPhase.next]);
                    clearManagedActionItems();
                    const rollbackObject = snapshot
                      ? ChiroUpJSON.parse(snapshot, null)
                      : null;
                    setSlotContext(() => {
                      return {
                        [-1]: { ref: topOfPageRef, role: 'top' },
                      };
                    });
                    setTimeout(() => {
                      setLocalData(() => {
                        return rollbackObject;
                      });
                    }, 0);

                    // Reset the cached transaction data since we may have been
                    // updating it.
                    for (const o of rollbackObject ?? []) {
                      queryClient.setQueryData(
                        ['transaction', o.billingKey],
                        o,
                      );
                    }
                  }}
                  color={ButtonColors.plain}
                />
                {mergeContext.isMerged || isEncounterMode ? null : (
                  <Button
                    text="Merge"
                    onClick={(e: any) => {
                      e?.preventDefault?.();
                      e?.stopPropagation?.();
                      setConfirmMergeOpen(true);
                    }}
                    color={buttonColor}
                    disabled={isAnyRestRunning}
                  />
                )}

                <Button
                  text="Save"
                  loading={isSaving}
                  disabled={
                    !localData ||
                    isFetching ||
                    isSaving ||
                    !!managedActionItems.length
                  }
                  color={buttonColor}
                  trivialTooltip={trivialTooltip}
                  onClick={async (e: any) => {
                    if (managedActionItems.length) {
                      return;
                    }

                    const diff = ChiroUpJSON.compare({
                        obj1: { value: data ?? {} },
                        obj2: { value: localData },
                        options: {
                          compareLeafAs: {
                            amount: 'number',
                            locked: 'boolean',
                          },
                          strict: true,
                        },
                      }),
                      localIsClean = Object.keys(diff).length === 0;
                    let lifeCycleIsDirty = false;

                    const woops = {
                      id: 'no-changes',
                      title: 'No changes detected.',
                      persistent: true,
                    };

                    // Is there anything dirty from the lifeCycle perspective?
                    if (lifeCycle && lifeCycle.isDirty) {
                      lifeCycleIsDirty = lifeCycle.isDirty();
                      if (lifeCycleIsDirty) {
                        console.log(
                          '......there are dirty things according to the lifecycle.',
                        );
                      }
                      if (lifeCycleIsDirty) {
                        if (lifeCycle?.save) {
                          try {
                            lifeCycle?.save?.();
                          } catch (e) {
                            console.error(e);
                            addManagedActionItem({
                              id: 'life-cycle-save-error',
                              title:
                                'There was an error in the lifecycle save.',
                            });
                            return;
                          }
                        } else {
                          addManagedActionItem({
                            id: 'no-life-cycle-save',
                            title: 'There is a lifecycle, but no save method.',
                          });
                          return;
                        }
                        if (localIsClean) return;
                      }
                    }

                    if (localIsClean && !lifeCycleIsDirty) {
                      addManagedActionItem(woops);
                      return;
                    }

                    // console.log({ diff });
                    const res: any | null | undefined =
                      await saveConsolidatedTransaction({
                        clinicId: me?.selectedClinic?.ID,
                        payload: localData,
                      });
                    if (!res) {
                      addManagedActionItem({
                        id: 'save-failed',
                        title:
                          'Save failed. Check the console for details errors.',
                        persistent: true,
                      });
                    } else if (res.statusCode !== 200) {
                      addManagedActionItem({
                        id: 'save-failed',
                        title: res.message,
                        persistent: true,
                      });
                    } else {
                      // Leave it in edit mode if we're in encounter mode.
                      if (!isEncounterMode) {
                        setCurrentPhase(phases[currentPhase.next]);
                      }
                      setTimeout(() => {
                        setLocalData(() => {
                          return res.items;
                        });
                      }, 0);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <ManagedActionItems
          containerClassName={classNames(ClassNames.bg, 'pr-12 pt-4')}
          innerClassName="rounded-lg text-sm border border-gray-200 bg-orange-50 p-4"
          style={{
            paddingLeft: isAdHoc || isEncounterMode ? '1.5rem' : '5.6rem',
          }}
        />
        <div
          className={classNames('flex flex-row space-x-4 pb-8', ClassNames.bg)}
        >
          <div className="pl-6 pr-12 py-6 flex flex-col space-y-6 grow">
            {localData && Array.isArray(localData) && localData.length
              ? localData.map((row: PatientTransaction, idx) => (
                  <div key={`txn.${idx}`} className="bg-white">
                    <SingleTransaction
                      actionsOnClick={actionsOnClick}
                      context={slotContext}
                      currentPhase={currentPhase}
                      encounterContext={encounterContext}
                      hoverTextPrimary={hoverTextPrimary}
                      isBalanceTransfer={isBalanceTransfer}
                      isFetching={isAnyRestRunning}
                      mergeContext={mergeContext}
                      isNotReadOnly={isNotReadOnly}
                      isReadOnly={isReadOnly}
                      isSaving={isSaving}
                      lockButtonStateByProviderId={lockButtonStateByProviderId}
                      ord={idx}
                      setPayAvailability={setPayAvailability}
                      refetch={refetch}
                      row={row}
                      setConsolidatedData={setLocalData}
                      setContext={setSlotContext}
                      setCurrentPhase={setCurrentPhase}
                      textPrimary={textPrimary}
                      trace={trace}
                    />
                  </div>
                ))
              : null}
            {/* <pre>{ChiroUpJSON.pretty(data)}</pre> */}
          </div>
        </div>
        {isError ? (
          <div className="w-full flex justify-center p-8">
            <div className="h-24 w-24 text-red-500">
              <FaceFrownIcon />
            </div>
          </div>
        ) : null}
      </div>
      {showPatientPaymentModal ? (
        <Modal
          addClasses="max-w-4xl w-full"
          omitClasses="sm:max-w-lg"
          isOpen
          close={() => setShowPatientPaymentModal(false)}
        >
          <p>This is where the consolidated payment modal pops up.</p>
          <p>Feel free to work it.</p>
          <div className="flex justify-center mt-4">
            <Button
              text="Close"
              onClick={() => {
                setShowPatientPaymentModal(false);
              }}
            />
          </div>
        </Modal>
      ) : null}
      {confirmMergeOpen ? (
        <ConfirmModal
          isOpen={true}
          confirm={(e: any) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            mergeTransactions({
              beforeCallback: () => {
                setConfirmMergeOpen(false);
              },
            });
          }}
          close={() => setConfirmMergeOpen(false)}
          confirmText="Continue"
          focusOnCancel={true}
          confirmDisabled={Object.values(mergeOptions?.providers ?? {}).every(
            (value) => value === false,
          )}
        >
          <div className="mt-4 font-sans font-light text-sm flex flex-col space-y-2">
            {/* <pre>{ChiroUpJSON.pretty({ mergeOptions, providerOptions })}</pre> */}
            <p>
              This will merge multiple transactions into one. The options below
              control the process.
            </p>
            {/* <pre>
              {ChiroUpJSON.pretty({
                singleProviderOption,
                isNull: singleProviderOption === null,
                undef: singleProviderOption === undefined,
                countofProviders,
              })}
            </pre> */}
            {countofProviders > 1 ? (
              <p>
                By default, the transactions for all rendering providers will be
                affected. By unchecking a provider, they will be ignored.
              </p>
            ) : singleProviderOption ? (
              <p>
                All <cite>{singleProviderOption.displayName}</cite> transactions
                will be affected.
              </p>
            ) : (
              <AlertBlock
                issues={[
                  {
                    text: 'No providers found.',
                  },
                ]}
              />
            )}
            <p>
              When <cite>merge units</cite> is checked, the number of units for
              a given service code on the merged transaction will be the sum of
              the units on the individual transactions instead of the merged
              transaction having multiple items for the same service code.
            </p>
            <p>
              When <cite>merge treatments</cite> is checked, all treatments for
              a particular provider will be put under a single transaction.
            </p>
          </div>

          <div className="font-sans font-medium text-md mt-4 text-primary-500 mb-2">
            Options
          </div>
          <div className="flex flex-col space-y-2">
            <ProvidersToMerge
              providers={providerOptions}
              options={mergeOptions}
              onClick={(provider) => {
                setMergeOptions((p) => {
                  if (!p) return p;
                  const key = provider?.id ?? '';
                  if (typeof p?.providers?.[key] !== 'boolean') {
                    p.providers = p.providers ?? {};
                    p.providers[key] = true;
                  } else {
                    p = {
                      ...p,
                      providers: {
                        ...p.providers,
                        [key]: !p.providers[key],
                      },
                    };
                  }
                  return p;
                });
              }}
            />
            <Checkbox
              value={mergeOptions.units}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    units: !p.units,
                  };
                });
              }}
              label="Merge units"
            />
            <Checkbox
              value={mergeOptions.treatments}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    treatments: !p.treatments,
                  };
                });
              }}
              label="Merge treatments"
            />
          </div>
        </ConfirmModal>
      ) : null}
    </Modal>
  );
};

type SingleProps = {
  actionsOnClick: (
    action: ActionsOnClick,
    transaction: PatientTransaction | null | undefined,
  ) => void;
  context: NUMBER_ANY_HASH;
  currentPhase: Phase;
  encounterContext?: EncounterContext | null | undefined;
  hoverTextPrimary: string;
  isBalanceTransfer: boolean;
  isFetching: boolean;
  isNotReadOnly: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
  lockButtonStateByProviderId?: { [key: string]: LockButtonStateType };
  mergeContext: any;
  ord: number;
  setPayAvailability: Dispatch<SetStateAction<STRING_BOOLEAN_HASH>>;
  refetch: () => void;
  row: PatientTransaction;
  setConsolidatedData: Dispatch<SetStateAction<PatientTransaction[] | null>>;
  setContext: Dispatch<SetStateAction<NUMBER_ANY_HASH>>;
  setCurrentPhase: Dispatch<SetStateAction<Phase>>;
  textPrimary: string;
  trace?: boolean;
};
const SingleTransaction: React.FC<SingleProps> = (passedArguments) => {
  const {
    actionsOnClick,
    context,
    currentPhase,
    encounterContext,
    hoverTextPrimary,
    isBalanceTransfer,
    isFetching,
    isNotReadOnly,
    isReadOnly,
    isSaving,
    lockButtonStateByProviderId,
    mergeContext,
    ord,
    setPayAvailability,
    refetch,
    row,
    setConsolidatedData,
    setContext,
    setCurrentPhase,
    textPrimary,
    trace,
  } = passedArguments;

  /**
   * u s e S t a t e
   */
  const [expandedClaimInfo, setExpandedClaimInfo] = useState<boolean>(false);
  const [traceOpen, setTraceOpen] = useState<boolean>(!trace); // Initially closed.
  const [showSupplementsModal, setShowSupplementsModal] =
    useState<boolean>(false);

  const [isChoosePackageModal, setIsChoosePackageModal] =
    useState<boolean>(false);
  const [triggerAppendItems, setTriggerAppendItems] =
    useState<TransactionAppendItemsType | null>(null);
  const [localRow, setLocalRow] = useState<PatientTransaction | null>(row);

  const editingNewTransaction = useMemo(() => {
    return (localRow?.id ?? -1) === -1;
  }, [localRow]);

  const [activeSubtype, setActiveSubtype] = useState<string>('');
  const [isWritingOff, setIsWritingOff] = useState<boolean>(false);
  const [isAllocating, setIsAllocating] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [allocatableBalance, setAllocatableBalance] = useState<number | null>(
    null,
  );
  const [showAllocacteNonBillableModal, setShowAllocateNonBillableModal] =
    useState<boolean>(false);
  const [fetchingAllocatableBalance, setFetchingAllocatableBalance] =
    useState<boolean>(false);
  const [showWriteOffNonBillableModal, setShowWriteOffNonBillableModal] =
    useState<boolean>(false);
  const [activeTabName, setActiveTabName] = useState<string>(BASE_TAB);

  const [diagnosesOrder, setDiagnosesOrder] = useState<string[]>([]);

  const [incomingClaimInfo, setIncomingClaimInfo] = useState<ClaimInfo | null>(
    null,
  );

  // const [isTransactionLocked, setIsTransactionLocked] =
  //   useState<boolean>(false);

  /**
   * H o o k s
   */
  const myRef = useRef(null);
  const { pathname } = useLocation();

  const { me, selectedLocationFull, hasRole, hasAccess } =
    useContext(MeContext);

  const { data: patientPackages } = useApplicablePatientPackageList({
    patientId: row?.patient?.id,
    locationId: row?.locationId,
  });

  const { data: disciplineTreatment } = useDisciplineTreatments(
    row?.treatmentId,
  );

  const {
    data: writeOffInfo,
    writeOffClaims,
    isRestActive: isProcessingWriteOff,
  } = useClaimWriteOff({
    clinicId: me?.selectedClinic?.ID,
    billingKey: editingNewTransaction ? null : localRow?.billingKey,
    isErrorOkay: true,
  });

  const { allocateClaims } = useClaimAllocation({
    clinicId: me?.selectedClinic?.ID,
    billingKey: localRow?.billingKey,
  });

  const { data: balances, refetch: refetchBalance } = usePatientBillingBalances(
    localRow?.patient?.id,
  );

  const {
    save,
    selectedPaymentCard,
    setSelectedPaymentCard,
    clear,
    data: payment,
  } = usePatientPayment({
    patientId: localRow?.patient?.id || '',
    paymentId: 'new',
    bulkResponse: true,
    setConsolidatedData,
    setTransactionData: setLocalRow,
  });

  const {
    status,
    data,
    // isFetching,
    // refetch,
    insurances,
    payors,
    snapshot,
    locked,
    services,
    setServices,
    setSnapshot,
    setInsurances,
    setLocked,
    setPayors,
    setCourtesyBilling,
    setSuperBill,
    allowBillingPriorityChange,
    superBill,
    courtesyBilling,
    transactionReadonly,
    listofServices,
    setListofServices,
  } = useTransaction(row?.billingKey);

  /**
   * u s e E f f e c t
   */
  useEffect(() => {
    if (!(localRow?.merged && localRow?.items?.length === 0)) {
      setContext((prev) => ({
        ...prev,
        [ord]: {
          ...prev[ord],
          ref: myRef,
        },
      }));
    }
  }, [
    localRow?.items?.length,
    localRow?.merged,
    mergeContext.isMerged,
    ord,
    setContext,
  ]);

  /**
   * u s e M e m o
   */

  const isLocalReadOnly = useMemo(() => {
    return Boolean(isReadOnly || localRow?.merged);
  }, [isReadOnly, localRow?.merged]);

  // You can't unmerge if there are payments. [Seems like a sound decision.]
  const hasPayments = useMemo(() => {
    return localRow?.items?.filter((item) => isaPaymentItem(item)).length !== 0;
  }, [localRow?.items]);

  const isEncounterMode = useMemo(() => {
      return !!encounterContext;
    }, [encounterContext]),
    isNotEncounterMode = useMemo(() => {
      return !isEncounterMode;
    }, [isEncounterMode]);

  const visitForm = useMemo(() => {
    if (
      isNotEncounterMode ||
      !encounterContext ||
      !encounterContext.visitForm
    ) {
      return null;
    }
    return encounterContext.visitForm.id === localRow?.billingKey
      ? encounterContext.visitForm
      : null;
  }, [encounterContext, isNotEncounterMode, localRow?.billingKey]);

  const claimInfo = useMemo(() => {
    return visitForm?.encounterInfo?.claimInfo;
  }, [visitForm?.encounterInfo?.claimInfo]);

  // Only once please.
  useEffect(() => {
    if (claimInfo && incomingClaimInfo === null) {
      setIncomingClaimInfo(ChiroUpJSON.clone(claimInfo));
    }
    /**
     * Use cases:
     *
     * 1) After a save, the 'id' of the row will change so
     *    it needs to be reflected in the UI.
     * 2) If the # of items changes on the data, we need
     *    to reflect that in the UI as well.
     * 3) If this guy was unmerged, we need to load too.
     * 4) If locked is changed.
     */
    const rowLocked = localRow?.items?.some((item) => item.locked),
      dataLocked = data?.items?.some((item: PatientTransaction) => item.locked);
    if (
      localRow &&
      data &&
      localRow.billingKey === data.billingKey &&
      (localRow.id !== data.id ||
        localRow?.items?.length !== data?.items?.length ||
        localRow?.merged !== data?.merged ||
        rowLocked !== dataLocked)
    ) {
      setLocalRow(data);
    }
  }, [claimInfo, data, incomingClaimInfo, localRow]);

  const isAdHoc = useMemo(() => {
    return (
      data?.type === TransactionTypeEnum.AdHoc ||
      localRow?.type === TransactionTypeEnum.AdHoc
    );
  }, [data?.type, localRow?.type]);

  const isTransactionLocked = useMemo(() => {
    return lockButtonStateByProviderId?.[row?.provider?.id as string]?.locked;
  }, [lockButtonStateByProviderId, row?.provider?.id]);

  const nav = useMemo(() => {
    if (!context) return [];
    return Object.entries(context).map(([key, value], idx) => {
      const iKey = Number(key);
      if (iKey === ord) {
        return (
          <li
            key={`nav-${ord}-${idx}`}
            className="font-sans font-black text-2xl flex justify-center items-center cursor-auto pr-1"
            style={{ padding: '0 0.25rem' }}
          >
            {idx + 1}
          </li>
        );
      }
      if (iKey < 0 && ord === 0) return null; // No link to top on  first item.
      let display: ReactNode = null;
      if (iKey < 0) {
        display = <ArrowUpCircleIcon className="h-8 w-8" />;
      } else {
        display = (
          <div className="flex items-center justify-center h-4 w-4 border border-gray-400 p-3 rounded-full hover:text-white hover:bg-primary-500 align-middle">
            {idx + 1}
          </div>
        );
      }
      return (
        <li
          key={`nav-${ord}-${idx}`}
          className={classNames(
            'flex align-middle font-sans text-sm font-semibold text-gray-400 justify-center items-center cursor-pointer',
            iKey < 0 ? `${textPrimary} ${hoverTextPrimary}` : ' ',
          )}
          onClick={(e: any) => {
            // console.log('onClick slot', key);
            e.stopPropagation();
            e.preventDefault();
            context?.[iKey]?.ref?.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          {display}
        </li>
      );
    });
  }, [context, hoverTextPrimary, ord, textPrimary]);

  const discountAlreadyApplied = useMemo(() => {
    return localRow?.items?.some(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Adjustment ||
        item?.subtype === TransactionItemSubtypeEnum.Override,
    );
  }, [localRow?.items]);

  //returning an array of all the patient packages that can be used to apply to the service / treatment
  const canApplyPackage = useMemo(() => {
    let packageThatCanBeApplied: number[] = [];
    const alreadyPackageApplied = row?.items?.some((item) => item?.packageId);

    if (alreadyPackageApplied) return [];

    const itemsToUse = row?.insurances?.length
      ? row?.items?.filter((item) => !item?.insuranceBillable)
      : row?.items;

    const hasServices = itemsToUse?.filter(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Service ||
        item?.subtype === TransactionItemSubtypeEnum.PatientService,
    );

    //if there are no nonbillable services and patient has insurances, then no package can be applied
    if (!hasServices?.length && row?.insurances?.length) {
      return [];
    }

    if (hasServices?.length) {
      hasServices?.forEach((service) => {
        if (
          service?.code &&
          patientPackages?.data?.servicesCovered?.[service?.code as any]
        ) {
          packageThatCanBeApplied = packageThatCanBeApplied.concat(
            patientPackages?.data?.servicesCovered?.[service?.code as any],
          );
        }
      });
    } else if (
      row?.treatmentId &&
      patientPackages?.data?.treatmentsCovered?.[row?.treatmentId]
    ) {
      packageThatCanBeApplied = packageThatCanBeApplied.concat(
        patientPackages?.data?.treatmentsCovered?.[row?.treatmentId],
      );
    }

    return [...new Set(packageThatCanBeApplied)];
  }, [
    patientPackages?.data?.servicesCovered,
    patientPackages?.data?.treatmentsCovered,
    row?.insurances?.length,
    row?.items,
    row?.treatmentId,
  ]);

  const packageDisabled = useMemo(() => {
    return (
      currentPhase.name !== 'Edit' ||
      !row ||
      isFetching ||
      isSaving ||
      !canApplyPackage?.length
    );
  }, [row, currentPhase.name, isFetching, isSaving, canApplyPackage]);

  const patientBalance = useMemo(() => {
    return createDecimal(localRow?.patientBalance ?? 0);
  }, [localRow?.patientBalance]);

  const nonBillable = useMemo(() => {
    return localRow?.insurances?.some((i) => !i.billable);
  }, [localRow?.insurances]);

  // TODO: This looks important. Not sure where this was used.
  const [isBalanceAllocatedToPatient, setIsBalanceAllocatedToPatient] =
    useState(false);

  const editingNew = useMemo(() => {
    return localRow?.billingKey === 'new';
  }, [localRow?.billingKey]);

  const isTransactionPaid = useMemo(() => {
    let resp = false;
    if (localRow?.type === TransactionTypeEnum.AdHoc) {
      const balance = localRow?.balance ?? 0;
      if (balance > 0) {
        resp = false;
      }
      resp = balance > 0;
    } else {
      if (Number(localRow?.balance || 0) <= 0) {
        resp = true;
      }
      if (Number(localRow?.patientBalance || 0) <= 0) {
        resp = true;
      }
      if (patientBalance.equals(0)) {
        resp = true;
      }
    }
    // Can this be done in a useMemo??? Needed a timeout!!!
    setTimeout(() => {
      setPayAvailability?.((prev) => {
        const key = localRow?.billingKey;
        if (!key) return prev;
        return { ...prev, [key]: resp };
      });
    }, 0);
    return resp;
  }, [
    localRow?.balance,
    localRow?.billingKey,
    localRow?.patientBalance,
    localRow?.type,
    patientBalance,
    setPayAvailability,
  ]);

  /**
   * H o o k s   a f t e r   u s e M e m o
   */
  const {
    patientResponsibility,
    insurancesToUse,
    responsibilities,
    payorResponsibilityTotal,
    balanceAllocatedToPatient,
  } = useResponsibilities({
    insurances,
    workingTransaction: localRow,
    courtesyBilling,
    superBill,
  });

  /**
   * F u n c t i o n s
   */

  const findThePackageCredit = useCallback(
    ({
      packageId,
      items,
      insurances,
    }: {
      packageId?: number | null;
      items: PatientTransactionItemType[];
      insurances?: Partial<AppointmentInsuranceType>[];
    }) => {
      const packageToApply = patientPackages?.data?.packages?.find(
        (pkg) => pkg.ID === packageId,
      ) as any;

      const packageName = packageToApply?.packageName;

      //insurances could be an empty array so only want to sue working transaction insurances if insurances is undefined
      const insuranceArrToUse =
        insurances !== undefined ? insurances : row?.insurances;

      const itemsToUse = insuranceArrToUse?.length
        ? items?.filter((item) => !item?.insuranceBillable)
        : items;

      const hasServices = itemsToUse?.some(
        (item) =>
          item?.subtype === TransactionItemSubtypeEnum.Service ||
          item?.subtype === TransactionItemSubtypeEnum.PatientService,
      );

      let newCredit;

      if (!hasServices) {
        const costOfTreatment = items?.find(
          (item) =>
            item.type === TransactionItemTypeEnum.Debit &&
            item.subtype === TransactionItemSubtypeEnum.Treatment,
        )?.amount;

        newCredit = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.Adjustment,
          {
            amount: costOfTreatment,
            description: `${packageName} - (package applied)`,
            packageId: packageId,
            adjustment: { structure: '$ Discount' },
          },
        );
        newCredit.id = v4();
      } else {
        const doesPackageCoverTreatment =
          patientPackages?.data?.treatmentsCovered?.[row?.treatmentId || -1];

        const codesAssociatedWithTreatment = doesPackageCoverTreatment
          ? disciplineTreatment?.referenceCodes?.map((code) => code.code)
          : [];
        const servicesThatCanBeCredited = [
          ...(codesAssociatedWithTreatment || []),
          ...(Object.keys(patientPackages?.data?.servicesCovered || {}) || []),
        ];

        const totalCoveredByPackage = itemsToUse?.reduce((acc, item) => {
          if (servicesThatCanBeCredited.includes(item.code as string)) {
            acc = acc.plus(
              createDecimal(item?.amount || 0).times(item?.units || 1),
            );
          }
          return acc;
        }, createDecimal(0));

        newCredit = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.Adjustment,
          {
            amount: totalCoveredByPackage?.toFixed(2),
            description: `${packageName} - (package applied)`,
            packageId: packageId,
            adjustment: { structure: '$ Discount' },
          },
        );
        newCredit.id = v4();
      }
      return newCredit;
    },
    [
      patientPackages?.data?.packages,
      patientPackages?.data?.treatmentsCovered,
      patientPackages?.data?.servicesCovered,
      row?.insurances,
      row?.treatmentId,
      disciplineTreatment?.referenceCodes,
    ],
  );

  const genDupeKey = (item: PatientTransactionItemType) => {
    return `${item?.code}|${item?.type}|${item?.description}|${item?.amount}|${item?.subtype}`;
  };

  const onChangeConsolidatedTransaction =
    (itemId: number) => (property: string | number) => (value: any) => {
      // console.log({ TransactionEditorTabContent: { itemId, property, value } });
      if (!localRow) return;
      const newobj = JSON.parse(JSON.stringify(localRow));
      newobj.items = newobj.items || [];
      const ord = newobj.items.findIndex(
        (item: PatientTransactionItemType) => item.id === itemId,
      );
      if (ord === -1) return;
      newobj.items[ord] = newobj.items[ord] || {};
      newobj.items[ord][property] = value;
      const percentDiscountApplied = newobj.items?.find(
        (item: PatientTransactionItemType) =>
          item.subtype === 'Adjustment' &&
          item?.adjustment?.structure === '% Discount',
      );
      if (percentDiscountApplied) {
        const newItemVals = findNewDiscount(newobj.items);
        newobj.items = newItemVals;
      }
      setLocalRow(newobj);

      // Update the top-level datastructure.
      setTimeout(() => {
        setConsolidatedData((prev) => {
          for (let i = 0; i < (prev ?? []).length; i++) {
            if (!prev?.[i]) continue;
            const o = prev[i];
            if (o.billingKey === newobj.billingKey) {
              prev[i].items = newobj.items;
            }
          }
          return prev;
        });
      }, 0);
    };
  const addPendingItems = useCallback(
    (items: PatientTransactionItemType[]) => {
      if (!localRow) return;
      // console.log({ items });
      const newobj = ChiroUpJSON.clone(localRow);
      newobj.items = newobj.items || [];
      const nextItems = ChiroUpJSON.clone(newobj.items);

      /**
       * If we have dupes, just increase the units.
       *
       * 1) Generate a detection key for each item so we can track the # of units.
       * 2) Go through the new items and just update the units of any existing matches.
       *    Otherwise, just add the new item to the list.
       */
      const nextKeys = nextItems.reduce(
        (a: any, c: PatientTransactionItemType) => {
          const key = genDupeKey(c);
          if (!a[key]) {
            a[key] = c.units || 1;
            return a;
          }
          a[key] = a[key] + 1;
          return a;
        },
        {},
      );
      /**
       * Add what needs to be added or just increment the units if we have detected
       * a duplicate.
       */
      items.forEach((item) => {
        const key = genDupeKey(item);
        if (nextKeys[key]) {
          nextKeys[key] = nextKeys[key] + item.units;
        } else {
          nextItems.push(item);
        }
      });
      /**
       * True up the units on the items from the hash.
       */
      nextItems.forEach((item: PatientTransactionItemType) => {
        const key = genDupeKey(item);
        if (nextKeys[key]) {
          item.units = nextKeys[key];
        }
      });
      newobj.items = nextItems;
      /*** end of keeping out the dupes */
      const percentDiscountApplied = newobj?.items?.find(
        (item: { subtype: string; adjustment?: { percentOff: number } }) =>
          item.subtype === 'Adjustment' && item?.adjustment?.percentOff,
      );
      const overrideApplied = newobj?.items?.find(
        (item: { subtype: string; adjustment?: { structure: string } }) =>
          item.subtype === TransactionItemSubtypeEnum.Override,
      );
      if (percentDiscountApplied && !overrideApplied) {
        const returnedItems = findNewDiscount(newobj.items);
        newobj.items = returnedItems;
      }
      const servicesToAdd = items?.filter(
        (obj: PatientTransactionItemType) =>
          obj.subtype === TransactionItemSubtypeEnum.Service ||
          obj.subtype === TransactionItemSubtypeEnum.PatientService,
      );
      if (servicesToAdd && servicesToAdd.length) {
        setTriggerAppendItems((p) => ({ ...p, services: servicesToAdd }));
      }
      setLocalRow(newobj);

      // Update the parent so the stuff is saved.
      setTimeout(() => {
        setConsolidatedData((prev) => {
          for (const o of prev ?? []) {
            if (o.billingKey === newobj.billingKey) {
              o.items = newobj.items;
            }
          }
          return prev;
        });
      }, 0);

      setActiveSubtype('');
    },
    [localRow, setConsolidatedData, setActiveSubtype, setTriggerAppendItems],
  );

  const applyPackage = useCallback(
    (id: number) => {
      const packageToApply = patientPackages?.data?.packages?.find(
        (pkg) => pkg.ID === id,
      ) as any;

      const packageName = packageToApply?.packageName;
      const newCredit = findThePackageCredit?.({
        packageId: packageToApply?.ID,
        items: row?.items || [],
      });

      newCredit.id = v4();
      const itemsToAdd = [newCredit];
      const anyAdditionalFee = packageToApply?.additionalPayment;
      if (anyAdditionalFee && anyAdditionalFee > 0) {
        //need to add a Debit.......
        const additionalFee = toTransactionItemSubtype(
          TransactionItemSubtypeEnum.AdditionalFee,
          {
            amount: anyAdditionalFee,
            description: `${packageName} - (additional fee)`,
            packageId: id,
          },
        );
        additionalFee.id = v4();
        itemsToAdd.push(additionalFee);
      }

      return addPendingItems(itemsToAdd);
    },
    [
      patientPackages?.data?.packages,
      findThePackageCredit,
      row?.items,
      addPendingItems,
    ],
  );

  /**
   * This encapsulates the business rules as to whether the stupid
   * "Pay" button appears or not. You would think that would be easy
   * to determine, but you would be wrong.
   *
   * If it is an adhoc transaction (supplies or supplements), the
   * balance rules. Otherwise, the patient responsibility rules.
   *
   * @param transaction
   * @returns
   */
  // const getTransactionIsPaid = useCallback(
  //   (transaction: PatientTransaction) => {
  //     if (transaction?.type === TransactionTypeEnum.AdHoc) {
  //       const balance = transaction.balance ?? 0;
  //       if (balance > 0) {
  //         return false;
  //       }
  //       return true;
  //     } else {
  //       if (Number(transaction.balance || 0) <= 0) {
  //         return true;
  //       }
  //       if (Number(transaction.patientBalance || 0) <= 0) {
  //         return true;
  //       }
  //       if (patientBalance.equals(0)) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [patientBalance],
  // );

  /**
   * Not sure this is better, but the idea is to make the
   * JSX easier to read by encapsulating the button state
   * logic in one place.
   *
   * We also use the same buttons multiple places.
   *
   */
  const BUTTON = useMemo(() => {
    return {
      addPackage: {
        text: 'Add Package',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        icon: <PlusCircleIcon className="h-6 w-6" />,
        color: ButtonColors.plainWithBorder,
        disabled:
          !localRow ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          localRow?.items?.some(
            (item: PatientTransactionItemType) =>
              item.type === TransactionItemTypeEnum.Debit &&
              item?.subtype !== TransactionItemSubtypeEnum.Tax,
          ),
      },
      allocateBalance: {
        text: 'Allocate Balance',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none',
        disabled:
          currentPhase.name === 'Edit' ||
          isNotReadOnly ||
          !allocatableBalance ||
          !localRow ||
          isFetching ||
          isSaving ||
          fetchingAllocatableBalance ||
          isWritingOff ||
          isAllocating,
      },
      applyPackage: {
        text: 'Apply package',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none', // Use flex-none to prevent the button from growing
        trivialTooltip: insurances.length
          ? {
              text: 'Only non billable services can be covered by a package',
            }
          : {
              text: '',
            },
        disabled: packageDisabled,
      },
      discount: {
        text: 'Discount',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        disabled:
          !localRow ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          discountAlreadyApplied,
      },
      pay: {
        text: 'Pay',
        color: ButtonColors.plainWithBorder,
        className: 'flex-none -ml-4', // Use flex-none to prevent the button from growing
        disabled:
          currentPhase.name === 'Edit' ||
          isNotReadOnly ||
          !localRow ||
          isFetching ||
          isSaving ||
          isWritingOff ||
          isAllocating ||
          isTransactionPaid,
      },
      supplementsAndSupplies: {
        text: 'Add Supplements/Supplies',
        className: 'flex-none', // Use flex-none to prevent the button from growing
        icon: <PlusCircleIcon className="h-6 w-6" />,
        color: ButtonColors.plainWithBorder,
        disabled:
          !localRow ||
          isFetching ||
          isSaving ||
          isTransactionLocked ||
          localRow?.items?.some(
            (item: PatientTransactionItemType) =>
              item.subtype === TransactionItemSubtypeEnum.Package,
          ),
      },
    };
  }, [
    localRow,
    isFetching,
    isSaving,
    isTransactionLocked,
    currentPhase.name,
    isNotReadOnly,
    allocatableBalance,
    fetchingAllocatableBalance,
    isWritingOff,
    isAllocating,
    insurances.length,
    packageDisabled,
    discountAlreadyApplied,
    isTransactionPaid,
  ]);

  const CLICKS = {
    addLineItem: (subtype: TransactionItemSubtypeEnum | string) => {
      setActiveSubtype(subtype);
    },
    new: (e: any) => {
      const newTxn = JSON.parse(JSON.stringify(localRow));
      newTxn.items.push({});
      newTxn.balance = balanceFromItems({
        startingBalance: newTxn.startingBalance,
        items: newTxn.items,
        productTaxRate: selectedLocationFull?.productTaxRate,
        serviceTaxRate: selectedLocationFull?.serviceTaxRate,
        transactionType: newTxn.type,
      }).balance;
      setLocalRow(newTxn);
    },
    add: (e: any) => {
      const newTxn = JSON.parse(JSON.stringify(localRow));
      newTxn.items.push({});
      newTxn.balance = balanceFromItems({
        startingBalance: newTxn.startingBalance,
        items: newTxn.items,
        productTaxRate: selectedLocationFull?.productTaxRate,
        serviceTaxRate: selectedLocationFull?.serviceTaxRate,
        transactionType: newTxn.type,
      }).balance;
      setLocalRow(newTxn);
    },
  };

  const myUrl = useMemo(() => {
    return `/patients/${localRow?.patient?.id}/encounters/${localRow?.billingKey}`;
  }, [localRow?.billingKey, localRow?.patient?.id]);

  const myEncounter = useMemo(() => {
    // console.log({ myEncounter: { pathname, myUrl } });
    const matches = pathname.match(/\/encounters\/([^/]+)/),
      res = matches?.[1] ?? '-bogus-';
    return res === localRow?.billingKey;
  }, [localRow?.billingKey, pathname]);

  const getButtons = useMemo(() => {
    const buttons: ButtonGroupType[] = [];
    if (!localRow) return buttons;
    if (activeTabName !== 'Detail') return buttons;

    if (
      (!isEncounterMode && localRow.hasEncounter) ||
      (localRow.hasEncounter && !myEncounter)
    ) {
      if (!myEncounter) {
        buttons.push({
          label: 'Encounter',
          to: myUrl,
        });
      }
    }

    return buttons;
  }, [activeTabName, isEncounterMode, localRow, myEncounter, myUrl]);

  // This is done once. Lots of dependencies, easier than making a real component.
  const actionButtonsComponent = useMemo(() => {
    return (
      <div className="w-full flex flex-row justify-end space-x-4 mt-4">
        {!!canApplyPackage?.length && (
          <Button
            {...BUTTON.applyPackage}
            onClick={() =>
              canApplyPackage?.length > 1
                ? setIsChoosePackageModal(true)
                : applyPackage(canApplyPackage?.[0])
            }
          />
        )}
        {currentPhase.name === 'Edit' ? null : (
          <Button {...BUTTON.pay} onClick={() => setOpenPaymentModal(true)} />
        )}
        {nonBillable && !!allocatableBalance && (
          <Button
            {...BUTTON.allocateBalance}
            onClick={() => setShowAllocateNonBillableModal(true)}
          />
        )}
        {editingNew ||
        activeTabName === 'Activity' ? null : isBalanceTransfer ? (
          <div>... balance transfers are not supported in this view ...</div>
        ) : null}
      </div>
    );
  }, [
    canApplyPackage,
    BUTTON.applyPackage,
    BUTTON.pay,
    BUTTON.allocateBalance,
    currentPhase.name,
    nonBillable,
    allocatableBalance,
    editingNew,
    activeTabName,
    isBalanceTransfer,
    applyPackage,
  ]);

  const showDueToTrace = useMemo(() => {
    return localRow?.merged && localRow?.items?.length === 0 && trace;
  }, [localRow?.items?.length, localRow?.merged, trace]);

  /**
   * R e t u r n s
   */
  if (localRow?.merged && localRow?.items?.length === 0 && !trace) {
    return null;
  }

  if (isEncounterMode && !localRow?.hasEncounter) {
    return null;
  }

  if (isEncounterMode && !myEncounter) {
    return isFetching ? null : (
      <div className={ClassNames.bg}>
        <cite>No such encounter.</cite>
      </div>
    );
  }

  if (isEncounterMode) {
    return (
      <TransactionContext.Provider
        value={{
          status,
          data,
          isFetching,
          refetch,
          insurances,
          payors,
          snapshot,
          locked,
          services,
          setServices,
          setSnapshot,
          setInsurances,
          setLocked,
          setPayors,
          setCourtesyBilling,
          setSuperBill,
          allowBillingPriorityChange,
          courtesyBilling,
          superBill,
          transactionReadonly,
          listofServices,
          setListofServices,
        }}
      >
        <div
          data-component="in-encounter-mode"
          className={classNames('flex flex-row space-x-8', ClassNames.bg)}
          ref={myRef}
        >
          <div className="rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4 bg-white">
            <CodeEncounterDiagnosisServices
              {...encounterContext}
              codeEncounterDiagnosisServicesListProps={{
                componentClassName: 'w-full',
              }}
              patientId={encounterContext?.patientId || ''}
              clinicId={me?.selectedClinic?.ID ?? -1}
              parentIsa={`slot`}
              horizontal={true}
              consolidatedView={true}
              setPatientTransaction={setLocalRow}
              setConsolidatedData={setConsolidatedData}
              instanceComponents={{
                lockButton: lockButtonStateByProviderId?.[
                  localRow?.provider?.id as string
                ] ? (
                  lockButtonStateByProviderId?.[
                    localRow?.provider?.id as string
                  ].available ? (
                    <LockButtonContent
                      state={
                        lockButtonStateByProviderId?.[
                          localRow?.provider?.id as string
                        ]
                      }
                      transaction={localRow}
                      readonly={isLocalReadOnly}
                      isBillingStarted={localRow?.isBillingStarted}
                      onClick={(e: any) => {
                        actionsOnClick(ActionsOnClick.Lock, localRow);
                      }}
                      componentClassName={[
                        'absolute',
                        'flex-row',
                        'flex',
                        'font-medium',
                        'font-sans',
                        'justify-end',
                        'mb-4',
                        'p-1',
                        'pl-1',
                        'right-0',
                        'space-x-2',
                        'text-sm',
                        'top-2',
                        'w-24',
                      ].join(' ')}
                    />
                  ) : null
                ) : null,
                unMergeButton: (
                  <div>
                    <h1>Yelp at BWM!!!</h1>
                  </div>
                ),
              }}
              onChangeConsolidatedTransaction={onChangeConsolidatedTransaction}
            />
            {visitForm ? (
              <div className="mt-6">
                <div
                  className="flex flex-row space-x-2 text-primary-600 cursor-pointer"
                  onClick={(e: any) => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                    setExpandedClaimInfo(!expandedClaimInfo);
                  }}
                >
                  <div className="mb-2.5 block relative text-lgfont-medium leading-5 sm:mt-px sm:pt-2 ">
                    Claim Info
                  </div>
                  <div className="pt-2">
                    {expandedClaimInfo ? (
                      <ChevronUpIcon className="w-5 h-5" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5" />
                    )}
                  </div>
                </div>
                {!!visitForm?.insuranceSubmitted?.length && (
                  <AlertBlock
                    level="caution"
                    message={ChiroUpTransactionCommon.billingStarted}
                  />
                )}
                {expandedClaimInfo ? (
                  <form>
                    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300 p-4 mb-6">
                      <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
                        <ClaimInfoForm
                          value={visitForm}
                          setVisitForm={encounterContext?.setVisitForm}
                        />
                      </div>
                    </div>
                    <div
                      className={`mt-6 grid gap-3 grid-flow-row-dense ${
                        visitForm?.signed ? 'w-full' : 'grid-cols-2'
                      }`}
                    >
                      {/* <Button
                    text="Close"
                    onClick={() => close(false)}
                    fullWidth
                    color={ButtonColors.plain}
                    className="border border-gray-300 dark:border-darkGray-600 cursor-pointer"
                  /> */}

                      {/* <Button
                    text="Save"
                    color={
                      visitForm?.insuranceSubmitted?.length
                        ? ButtonColors.accent
                        : ButtonColors.primary
                    }
                    onClick={() => {
                      setVisitForm({
                        ...visitForm,
                        carePlan: {
                          ...visitForm.carePlan,
                          claimInfo: {
                            ...visitForm.encounterInfo.claimInfo,
                          },
                        },
                      });
                      onSubmit();
                    }}
                    fullWidth
                    className="cursor-pointer"
                    loading={isSaving}
                    disabled={isSaving}
                    trivialTooltip={{
                      text: visitForm?.insuranceSubmitted?.length
                        ? ChiroUpTransactionCommon.billingStarted
                        : '',
                    }}
                  /> */}
                    </div>
                  </form>
                ) : null}
              </div>
            ) : null}
            {trace ? (
              <div className="mt-4">
                <Button
                  onClick={() => setTraceOpen(!traceOpen)}
                  text="Toggle #1"
                  color={ButtonColors.plainWithBorder}
                />
                <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
                  {/* <pre>{ChiroUpJSON.pretty(localRow)}</pre> */}
                  <h1>localRow</h1>
                  <pre>
                    {ChiroUpJSON.pretty({
                      id: localRow?.id,
                      billingKey: localRow?.billingKey,
                      provider: localRow?.provider,
                      type: localRow?.type,
                      merged: localRow?.merged,
                      hasPayments,
                      // encounterContext,
                    })}
                  </pre>
                  <hr />
                  <h1>data</h1>
                  <pre>
                    {ChiroUpJSON.pretty({
                      id: data?.id,
                      billingKey: data?.billingKey,
                      provider: data?.provider,
                    })}
                  </pre>
                  <hr />
                  <pre>
                    {ChiroUpJSON.pretty({ claimInfo, incomingClaimInfo })}
                  </pre>
                  <hr />
                  <pre>
                    {ChiroUpJSON.pretty({
                      localRowInsurances: localRow?.insurances,
                    })}
                  </pre>
                </div>
              </div>
            ) : null}{' '}
          </div>
        </div>
        <MakeBrowserWait isWaiting={isFetching || isSaving} />
      </TransactionContext.Provider>
    );
  }

  if (isAdHoc) {
    return (
      <TransactionContext.Provider
        value={{
          status,
          data,
          isFetching,
          refetch,
          insurances,
          payors,
          snapshot,
          locked,
          services,
          setServices,
          setSnapshot,
          setInsurances,
          setLocked,
          setPayors,
          setCourtesyBilling,
          setSuperBill,
          allowBillingPriorityChange,
          courtesyBilling,
          superBill,
          transactionReadonly,
          listofServices,
          setListofServices,
        }}
      >
        <div
          className={classNames('flex flex-row space-x-8', ClassNames.bg)}
          ref={myRef}
        >
          <div className="rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4 bg-white">
            <SingleAdHoc
              {...passedArguments}
              parentProperties={{
                actionButtonsComponent,
                activeSubtype,
                activeTabName,
                addPendingItems,
                allocatableBalance,
                applyPackage,
                balanceAllocatedToPatient,
                balances,
                BUTTON,
                canApplyPackage,
                clear,
                data,
                editingNew,
                fetchingAllocatableBalance,
                findThePackageCredit,
                insurances,
                insurancesToUse,
                isAllocating,
                isTransactionPaid,
                isWritingOff,
                localRow,
                nonBillable,
                onChangeConsolidatedTransaction,
                openPaymentModal,
                packageDisabled,
                patientPackages,
                patientResponsibility,
                payment,
                payorResponsibilityTotal,
                refetchBalance,
                responsibilities,
                save,
                selectedPaymentCard,
                setActiveSubtype,
                setDiagnosesOrder,
                setIsChoosePackageModal,
                setLocalRow,
                setOpenPaymentModal,
                setSelectedPaymentCard,
                setShowAllocateNonBillableModal,
                setShowSupplementsModal,
                showSupplementsModal,
                triggerAppendItems,
                isTransactionLocked,
                hasAccess,
                discountAlreadyApplied,
                CLICKS,
              }}
            />
          </div>
        </div>
        <SupplementsModal
          patientId={localRow?.patient?.id || ''}
          transaction={localRow}
          addPendingItems={addPendingItems}
          isOpen={showSupplementsModal}
          isRouted={false}
          onClose={() => setShowSupplementsModal(false)}
        />{' '}
        <MakeBrowserWait isWaiting={isFetching || isSaving} />
      </TransactionContext.Provider>
    );
  }

  return (
    <TransactionContext.Provider
      value={{
        status,
        data,
        isFetching,
        refetch,
        insurances,
        payors,
        snapshot,
        locked,
        services,
        setServices,
        setSnapshot,
        setInsurances,
        setLocked,
        setPayors,
        setCourtesyBilling,
        setSuperBill,
        allowBillingPriorityChange,
        courtesyBilling,
        superBill,
        transactionReadonly,
        listofServices,
        setListofServices,
      }}
    >
      <div
        className={classNames('flex flex-row space-x-8', ClassNames.bg)}
        ref={myRef}
      >
        <div className="flex w-8 justify-end">
          <ul className="space-y-1">{nav.map((item) => item)}</ul>
        </div>
        <div
          className={classNames(
            'rounded-lg border overflow-hidden grow shadow-inner pt-0 px-4 pb-4',
            myEncounter
              ? 'bg-primary-50'
              : showDueToTrace
                ? 'bg-orange-100'
                : 'bg-white',
          )}
        >
          <TransactionEditorTabs
            activeTabName={activeTabName}
            setActiveTabName={setActiveTabName}
            consolidatedView={true}
            switches={{
              Activity: !editingNewTransaction,
              Invoices: !editingNewTransaction,
              Detail: !editingNewTransaction,
            }}
          />

          <div>
            {editingNew ? (
              <div>... new transactions not supported in this view ...</div>
            ) : (
              <div className="relative">
                <div className="p-4">
                  <ButtonGroup
                    buttons={getButtons}
                    disabled={isFetching || isSaving || !localRow}
                    isEmptyOkay={true}
                  />
                </div>

                <div className="p-4">
                  {isFetching && !isSaving ? (
                    <div className="flex justify-center p-4">
                      <Loading color="text-gray-400" />
                    </div>
                  ) : (
                    <>
                      {/* Shut the hell up empty <></> error! */}
                      <TransactionEditorTabContent
                        activeSubtype={activeSubtype}
                        activeTabName={activeTabName}
                        consolidatedView={true}
                        editing={currentPhase.name === 'Edit'}
                        isLoading={isFetching}
                        noCodesMessage="No codes are associated with this purchase."
                        readonly={isLocalReadOnly}
                        setActiveSubtype={setActiveSubtype}
                        setConsolidatedData={setConsolidatedData}
                        setDiagnosesOrder={setDiagnosesOrder}
                        setWorkingTransaction={setLocalRow}
                        workingTransaction={localRow}
                        saveServicesCallback={() => {
                          setCurrentPhase(phases[currentPhase.next]);
                        }}
                        absoluteServiceItems={localRow?.services}
                        onChangeConsolidatedTransaction={
                          onChangeConsolidatedTransaction
                        }
                        patientPackages={patientPackages}
                        remoteControl={null} /* remoteControl */
                        triggerAppendItems={triggerAppendItems}
                        addPendingItems={addPendingItems}
                        findThePackageCredit={findThePackageCredit}
                        insurancesToUse={insurancesToUse}
                        responsibilities={responsibilities}
                        payorResponsibilityTotal={payorResponsibilityTotal}
                        patientResponsibility={patientResponsibility}
                        balanceAllocatedToPatient={balanceAllocatedToPatient}
                        instanceComponents={{
                          lockButton: lockButtonStateByProviderId?.[
                            localRow?.provider?.id as string
                          ] ? (
                            lockButtonStateByProviderId?.[
                              localRow?.provider?.id as string
                            ].available ? (
                              <LockButtonContent
                                state={
                                  lockButtonStateByProviderId?.[
                                    localRow?.provider?.id as string
                                  ]
                                }
                                transaction={localRow}
                                readonly={isLocalReadOnly}
                                isBillingStarted={localRow?.isBillingStarted}
                                onClick={(e: any) => {
                                  actionsOnClick(ActionsOnClick.Lock, localRow);
                                }}
                              />
                            ) : null
                          ) : null,
                          unMergeButton:
                            hasPayments ||
                            showDueToTrace ||
                            !mergeContext?.into?.[
                              localRow?.billingKey ?? ''
                            ] ? null : (
                              <UnMergeButtonContent
                                transaction={localRow}
                                state={
                                  lockButtonStateByProviderId?.[
                                    localRow?.provider?.id as string
                                  ]
                                }
                                isBillingStarted={localRow?.isBillingStarted}
                                readonly={isLocalReadOnly}
                                onClick={(e: any) => {
                                  e?.stopPropagation?.();
                                  e?.preventDefault?.();
                                  actionsOnClick(
                                    ActionsOnClick.UnMerge,
                                    localRow,
                                  );
                                }}
                              />
                            ),
                        }}
                        refetchBalance={refetchBalance}
                        actionButtonsComponent={
                          <div className="w-full flex flex-row justify-end space-x-4 mt-4">
                            {!!canApplyPackage?.length && (
                              <Button
                                {...BUTTON.applyPackage}
                                onClick={() =>
                                  canApplyPackage?.length > 1
                                    ? setIsChoosePackageModal(true)
                                    : applyPackage(canApplyPackage?.[0])
                                }
                              />
                            )}
                            {currentPhase.name === 'Edit' ? null : (
                              <Button
                                {...BUTTON.pay}
                                onClick={() => setOpenPaymentModal(true)}
                              />
                            )}
                            {nonBillable && !!allocatableBalance && (
                              <Button
                                {...BUTTON.allocateBalance}
                                onClick={() =>
                                  setShowAllocateNonBillableModal(true)
                                }
                              />
                            )}
                            {editingNew ||
                            activeTabName ===
                              'Activity' ? null : isBalanceTransfer ? (
                              <div>
                                ... balance transfers are not supported in this
                                view ...
                              </div>
                            ) : null}
                          </div>
                        }
                      />
                    </>
                  )}
                </div>
                {activeTabName === 'Detail' &&
                isNotReadOnly &&
                activeSubtype === '' ? (
                  <div className="flex flex-row flex-wrap gap-2 m-4 justify-start">
                    {/* <div className="flex-grow"></div>{' '} */}
                    <div className="flex flex-none gap-2">
                      {!insurances.length &&
                        !localRow?.items?.filter(
                          (i) =>
                            i.subtype === TransactionItemSubtypeEnum.Service ||
                            i.subtype ===
                              TransactionItemSubtypeEnum.PatientService,
                        )?.length && (
                          <Button
                            {...BUTTON.discount}
                            onClick={() =>
                              CLICKS.addLineItem(
                                TransactionItemSubtypeEnum.Adjustment,
                              )
                            }
                          />
                        )}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {localRow && (
            <TransactionPaymentModal
              payment={payment as PatientPayment}
              transaction={localRow}
              isOpen={openPaymentModal}
              close={() => setOpenPaymentModal(false)}
              save={save}
              patientId={localRow.patient?.id || ''}
              selectedPaymentCard={selectedPaymentCard}
              setSelectedPaymentCard={setSelectedPaymentCard}
              clear={clear}
              balances={balances}
              refetchList={() => {
                console.log(
                  '......TODO: refetchList on the transaction payment modal.....',
                );
              }}
            />
          )}
          <MakeBrowserWait isWaiting={isFetching || isSaving} />
          {localRow?.billingKey && currentPhase.name !== 'Edit' && (
            <>
              <AllocateNonBillableToPatientModal
                isOpen={showAllocacteNonBillableModal}
                close={() => setShowAllocateNonBillableModal(false)}
                amount={allocatableBalance}
                billingKey={localRow?.billingKey}
                refetch={refetch}
                patientId={localRow?.patient?.id || ''}
                isAllocating={isAllocating}
                setIsAllocating={setIsAllocating}
              />
              <WriteOffNonBillableBalanceModal
                isOpen={showWriteOffNonBillableModal}
                close={() => setShowWriteOffNonBillableModal(false)}
                amount={allocatableBalance}
                billingKey={localRow?.billingKey}
                refetch={refetch}
                patientId={localRow?.patient?.id || ''}
                isProcessingWriteOff={isWritingOff}
                setIsProcessingWriteOff={setIsWritingOff}
              />
            </>
          )}

          <ChoosePackageModal
            isOpen={isChoosePackageModal}
            close={() => setIsChoosePackageModal(false)}
            applyPackage={applyPackage}
            canApplyPackages={canApplyPackage}
            packages={patientPackages?.data?.packages}
          />

          {trace ? (
            <div className="mt-4">
              <Button
                onClick={() => setTraceOpen(!traceOpen)}
                text="Toggle #2"
                color={ButtonColors.plainWithBorder}
              />
              <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
                {/* <pre>{ChiroUpJSON.pretty(localRow)}</pre> */}
                <h1>localRow</h1>
                <pre>
                  {ChiroUpJSON.pretty({
                    id: localRow?.id,
                    billingKey: localRow?.billingKey,
                    provider: localRow?.provider,
                    type: localRow?.type,
                    merged: localRow?.merged,
                    hasPayments,
                    encounterContext,
                    isLocalReadOnly,
                    isTransactionLocked,
                    lockButtonStateByProviderId,
                  })}
                </pre>
                <hr />
                <h1>data</h1>
                <pre>
                  {ChiroUpJSON.pretty({
                    id: data?.id,
                    billingKey: data?.billingKey,
                    provider: data?.provider,
                    merged: data?.merged,
                    services: data?.services,
                  })}
                </pre>
                <hr />
                <pre>
                  {ChiroUpJSON.pretty({ services: localRow?.services })}
                </pre>
                <pre>{ChiroUpJSON.pretty({ items: localRow?.items })}</pre>
                <hr />
                <pre>{ChiroUpJSON.pretty(lockButtonStateByProviderId)}</pre>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <SupplementsModal
        patientId={localRow?.patient?.id || ''}
        transaction={localRow}
        addPendingItems={addPendingItems}
        isOpen={showSupplementsModal}
        isRouted={false}
        onClose={() => setShowSupplementsModal(false)}
      />
    </TransactionContext.Provider>
  );
};

type LockButtonProps = {
  isBillingStarted: boolean | undefined;
  state: LockButtonStateType | null | undefined;
  transaction: PatientTransaction | null | undefined;
  readonly: boolean;
  onClick?: (e: any) => void;
  componentClassName?: string;
  iconContainerClassName?: string;
};
const LockButtonContent: React.FC<LockButtonProps> = ({
  isBillingStarted,
  state,
  transaction,
  readonly,
  onClick = (e: any) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    console.log('TODO: lock/unlock');
  },
  componentClassName = [
    'flex-row',
    'flex',
    'font-bold',
    'font-sans',
    'justify-end',
    'mb-4',
    'pl-1',
    'p-1',
    'rounded-md',
    'space-x-1',
    'text-sm',
  ].join(' '),
  iconContainerClassName = 'flex items-center space-x-1',
}: LockButtonProps) => {
  const iconClassName = 'h-4 w-4';
  if (!state) return null;
  if (
    transaction?.type !== TransactionTypeEnum.Encounter &&
    transaction?.type !== TransactionTypeEnum.Hybrid
  ) {
    return null;
  }

  return (
    <div
      className={classNames(
        componentClassName,
        readonly
          ? 'text-gray-400'
          : isBillingStarted
            ? 'text-accent-400 cursor-pointer hover:text-accent-600'
            : 'text-primary-400 cursor-pointer hover:text-primary-600',
      )}
      onClick={readonly ? undefined : onClick}
    >
      {' '}
      {readonly && state.locked ? (
        <div className={iconContainerClassName}>
          <LockClosedIcon className={iconClassName} />
          <span>Locked</span>
        </div>
      ) : readonly && !state.locked ? (
        <div className={iconContainerClassName}>
          <LockOpenIcon className={iconClassName} />
          <span>Unlocked</span>
        </div>
      ) : !readonly && state.locked ? (
        <div className={iconContainerClassName}>
          <LockOpenIcon className={iconClassName} />
          <span>Unlock</span>
        </div>
      ) : !readonly && !state.locked ? (
        <div className={iconContainerClassName}>
          <LockClosedIcon className={iconClassName} />
          <span>Lock</span>
        </div>
      ) : (
        '... not sure how this happened ...'
      )}
    </div>
  );
};

type AdHocProps = {
  actionsOnClick: (
    action: ActionsOnClick,
    transaction: PatientTransaction | null | undefined,
  ) => void;
  context: NUMBER_ANY_HASH;
  currentPhase: Phase;
  isBalanceTransfer: boolean;
  isFetching: boolean;
  isNotReadOnly: boolean;
  isReadOnly: boolean;
  isSaving: boolean;
  lockButtonStateByProviderId?: { [key: string]: LockButtonStateType };
  ord: number;
  parentProperties: any;
  refetch: () => void;
  row: PatientTransaction;
  setConsolidatedData: Dispatch<SetStateAction<PatientTransaction[] | null>>;
  setContext: Dispatch<SetStateAction<NUMBER_ANY_HASH>>;
  setCurrentPhase: Dispatch<SetStateAction<Phase>>;
  trace?: boolean;
};

const SingleAdHoc: React.FC<AdHocProps> = ({
  actionsOnClick,
  context,
  currentPhase,
  isBalanceTransfer = false,
  isFetching,
  isNotReadOnly = true,
  isReadOnly = false,
  isSaving,
  lockButtonStateByProviderId = {},
  ord,
  parentProperties = {},
  refetch,
  row,
  setConsolidatedData,
  setContext,
  setCurrentPhase,
  trace = false,
}) => {
  /**
   * Stuff from the parent...
   */
  const {
    BUTTON,
    actionButtonsComponent,
    activeSubtype,
    activeTabName,
    addPendingItems,
    allocatableBalance,
    applyPackage,
    balances,
    balanceAllocatedToPatient,
    canApplyPackage,
    clear,
    data,
    editingNew,
    fetchingAllocatableBalance,
    findThePackageCredit,
    isTransactionPaid,
    insurances,
    insurancesToUse,
    isAllocating,
    isWritingOff,
    localRow,
    nonBillable,
    onChangeConsolidatedTransaction,
    openPaymentModal,
    packageDisabled,
    patientPackages,
    patientResponsibility,
    payorResponsibilityTotal,
    payment,
    refetchBalance,
    responsibilities,
    save,
    selectedPaymentCard,
    setShowSupplementsModal,
    setActiveSubtype,
    setDiagnosesOrder,
    setIsChoosePackageModal,
    setLocalRow,
    setOpenPaymentModal,
    setSelectedPaymentCard,
    setShowAllocateNonBillableModal,
    triggerAppendItems,
    isTransactionLocked,
    hasAccess,
    discountAlreadyApplied,
    CLICKS,
  } = parentProperties;

  /**
   * u s e S t a t e
   */
  const [traceOpen, setTraceOpen] = useState<boolean>(!trace); // Initially closed.

  /**
   * R e t u r n
   */
  return (
    <div data-id="ad-hoc-transaction-view">
      <div className="p-4">
        {isFetching && !isSaving ? (
          <div className="flex justify-center p-4">
            <Loading color="text-gray-400" />
          </div>
        ) : (
          <>
            {/* ...quiet... */}
            <TransactionEditorTabContent
              absoluteServiceItems={localRow?.services}
              actionButtonsComponent={actionButtonsComponent}
              activeSubtype={activeSubtype}
              activeTabName={activeTabName}
              addPendingItems={addPendingItems}
              balanceAllocatedToPatient={balanceAllocatedToPatient}
              consolidatedView={true}
              editing={currentPhase.name === 'Edit'}
              findThePackageCredit={findThePackageCredit}
              insurancesToUse={insurancesToUse}
              isLoading={isFetching}
              leftSideComponent={
                <div
                  data-id="add-extra-items-component"
                  className="pt-4 flex flex-row flex-wrap space-x-2 justify-start"
                >
                  <Button
                    {...BUTTON.supplementsAndSupplies}
                    onClick={() => {
                      setShowSupplementsModal(true);
                    }}
                  />
                  {hasAccess(FeatureFlags.packages) && (
                    <Button
                      {...BUTTON.addPackage}
                      onClick={() => {
                        console.log('TODO: ....add a new package');
                      }}
                    />
                  )}
                  {/* <div className="flex-grow"></div>{' '} */}
                  <div className="flex flex-none gap-2">
                    {!insurances.length &&
                      !localRow?.items?.filter(
                        (i: PatientTransactionItemType) =>
                          i.subtype === TransactionItemSubtypeEnum.Service ||
                          i.subtype ===
                            TransactionItemSubtypeEnum.PatientService,
                      )?.length && (
                        <Button
                          {...BUTTON.discount}
                          onClick={() =>
                            CLICKS.addLineItem(
                              TransactionItemSubtypeEnum.Adjustment,
                            )
                          }
                        />
                      )}
                  </div>
                </div>
              }
              instanceComponents={{
                lockButton: lockButtonStateByProviderId?.[
                  localRow?.provider?.id as string
                ] ? (
                  lockButtonStateByProviderId?.[
                    localRow?.provider?.id as string
                  ].available ? (
                    <LockButtonContent
                      state={
                        lockButtonStateByProviderId?.[
                          localRow?.provider?.id as string
                        ]
                      }
                      transaction={localRow}
                      readonly={isReadOnly}
                      isBillingStarted={localRow?.isBillingStarted}
                      onClick={(e: any) => {
                        actionsOnClick(ActionsOnClick.Lock, localRow);
                      }}
                    />
                  ) : null
                ) : null,
                unMergeButton: (
                  <div>
                    <h1>Yelp #3 for BWM!</h1>
                  </div>
                ),
              }}
              noCodesMessage="No codes are associated with this purchase."
              onChangeConsolidatedTransaction={onChangeConsolidatedTransaction}
              patientPackages={patientPackages}
              patientResponsibility={patientResponsibility}
              payorResponsibilityTotal={payorResponsibilityTotal}
              readonly={isReadOnly}
              remoteControl={null} /* remoteControl */
              responsibilities={responsibilities}
              saveServicesCallback={() => {
                setCurrentPhase(phases[currentPhase.next]);
              }}
              setActiveSubtype={setActiveSubtype}
              setConsolidatedData={setConsolidatedData}
              setDiagnosesOrder={setDiagnosesOrder}
              setWorkingTransaction={setLocalRow}
              triggerAppendItems={triggerAppendItems}
              workingTransaction={localRow}
              refetchBalance={refetchBalance}
            />{' '}
          </>
        )}
      </div>
      {localRow && (
        <TransactionPaymentModal
          payment={payment as PatientPayment}
          transaction={localRow}
          isOpen={openPaymentModal}
          close={() => setOpenPaymentModal(false)}
          save={save}
          patientId={localRow.patient?.id || ''}
          selectedPaymentCard={selectedPaymentCard}
          setSelectedPaymentCard={setSelectedPaymentCard}
          clear={clear}
          balances={balances}
          refetchList={() => {
            // Update the current transaction...
            // update the parent...
            console.log(
              '......TODO: refetchList on the transaction payment modal.....',
            );
          }}
        />
      )}

      {trace ? (
        <div className="mt-4">
          <Button
            onClick={() => setTraceOpen(!traceOpen)}
            text="Toggle #3"
            color={ButtonColors.plainWithBorder}
          />
          <div className={classNames('p-4', traceOpen ? '' : 'hidden')}>
            {/* <pre>{ChiroUpJSON.pretty(localRow)}</pre> */}
            <h1>localRow</h1>
            <pre>
              {ChiroUpJSON.pretty({
                id: localRow?.id,
                billingKey: localRow?.billingKey,
                provider: localRow?.provider,
                type: localRow?.type,
                activeTabName,
                merged: localRow?.merged,
              })}
            </pre>
            <hr />
            <h1>data</h1>
            <pre>
              {ChiroUpJSON.pretty({
                id: data?.id,
                billingKey: data?.billingKey,
                provider: data?.provider,
              })}
            </pre>
            <hr />
            <pre>{ChiroUpJSON.pretty({ items: localRow?.items })}</pre>
            <hr />
            <pre>{ChiroUpJSON.pretty(lockButtonStateByProviderId)}</pre>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ConsolidatedTransactionModal;

type ProvidersToMerge = {
  providers?: {
    [key: string]: ProviderOnTransactionType & { selected?: boolean };
  };
  options: MergeTransactionsOptions;
  onClick: (provider?: ProviderOnTransactionType) => void;
  containerClassName?: string;
};

const ProvidersToMerge: React.FC<ProvidersToMerge> = ({
  providers,
  onClick,
  options = {},
  containerClassName = '',
}: ProvidersToMerge) => {
  const aProviders = useMemo(() => {
    if (!providers) return [];
    return Object.values(providers);
  }, [providers]);

  if (!aProviders.length) return null;

  return (
    <>
      {aProviders.map((provider) => {
        return (
          <Checkbox
            key={provider.id}
            value={options?.providers?.[provider.id]}
            onChange={() => {
              onClick(provider);
            }}
            label={`Merge for ${provider.displayName}`}
          />
        );
      })}
    </>
  );
};

type UnMergeButtonProps = {
  isBillingStarted: boolean | undefined;
  state: LockButtonStateType | null | undefined;
  transaction: PatientTransaction | null | undefined;
  readonly: boolean;
  onClick?: (e: any) => void;
  componentClassName?: string;
  iconContainerClassName?: string;
};
const UnMergeButtonContent: React.FC<UnMergeButtonProps> = ({
  isBillingStarted,
  state,
  transaction,
  readonly,
  onClick = (e: any) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    console.log('TODO: unmerge');
  },
  componentClassName = [
    'flex-row',
    'flex',
    'font-bold',
    'font-sans',
    'justify-end',
    'mb-4',
    'pl-1',
    'p-1',
    'rounded-md',
    'space-x-1',
    'text-sm',
  ].join(' '),
  iconContainerClassName = 'flex items-center space-x-1',
}: UnMergeButtonProps) => {
  const iconClassName = 'h-4 w-4';
  // You can't unmerge the merged, you have to unmerge the mergee, as it were.
  if (
    transaction?.type !== TransactionTypeEnum.Encounter &&
    transaction?.type !== TransactionTypeEnum.Hybrid &&
    !transaction?.merged &&
    state?.locked
  ) {
    return null;
  }

  return (
    <div
      className={classNames(
        componentClassName,
        readonly || state?.locked
          ? 'text-gray-400 cursor-not-allowed'
          : isBillingStarted
            ? 'text-accent-400 cursor-pointer hover:text-accent-600'
            : 'text-primary-400 cursor-pointer hover:text-primary-600',
      )}
      onClick={readonly ? undefined : onClick}
    >
      {' '}
      {readonly || state?.locked ? (
        <div className={iconContainerClassName}>
          <CogIcon className={iconClassName} />
          <span>Merged</span>
        </div>
      ) : (
        <div className={iconContainerClassName}>
          <CogIcon className={iconClassName} />
          <span>Unmerge</span>
        </div>
      )}
    </div>
  );
};
