import {
  CakeIcon,
  EnvelopeIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import UploadedImage from '../../../../common/uploader/UploadedImage';
import { formatPhone } from '@chiroup/core/functions/format';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import { STRING_BOOLEAN_HASH } from '@chiroup/core/constants/globals';

type Props = {
  value: PatientTransaction | null;
  outerClassName?: string;
  omitClassName?: string;
};

export const TabSummary: React.FC<Props> = ({
  value,
  outerClassName = 'w-full',
  omitClassName,
}) => {
  const containerClassName =
    'relative flex space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:border-gray-400 justify-between items-center';
  const omitHash: STRING_BOOLEAN_HASH = (
    omitClassName ? omitClassName.split(/\s+/) : []
  ).reduce((acc, cur) => ({ ...acc, [cur]: true }), {});
  const secondClassName = containerClassName
    .split(/\s+/)
    .filter((key) => !omitHash[key])
    .join(' ');

  return (
    <div className={outerClassName}>
      <div className={secondClassName}>
        <div className="flex flex-row items-end gap-4 w-full">
          <div className="flex-shrink-0">
            {value?.patient?.profilePhoto ? (
              <UploadedImage
                url={value.patient.profilePhoto}
                className="rounded-full h-10 w-10"
              />
            ) : (
              <svg
                className="flex-shrink-0 text-gray-300 h-10 w-10 group-hover:text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2 2 16 16"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
          <div className="flex-1 flex flex-row w-full">
            <div className="flex flex-row justify-between items-end w-full">
              <div className="min-w-0 flex-1">
                <span className="text-sm font-medium text-gray-900">
                  {value?.patient?.displayName}
                </span>
                {!!value?.patient?.phone && (
                  <span className="truncate text-sm text-gray-500 flex flex-row gap-2 items-end">
                    <PhoneIcon className="h-5 w-5" />
                    <span>{formatPhone(value.patient.phone)}</span>
                  </span>
                )}
                {!!value?.patient?.email && (
                  <span className="truncate text-sm text-gray-500 flex flex-row gap-2 items-end">
                    <EnvelopeIcon className="h-5 w-5" />
                    <span>{value.patient.email}</span>
                  </span>
                )}
              </div>
              <div className="min-w-0 flex-1 text-right items-end h-full flex flex-col justify-end">
                {!!value?.patient?.dateOfBirth && (
                  <span className="truncate text-sm text-gray-500 flex flex-row gap-2 items-end">
                    <CakeIcon className="h-5 w-5" />
                    <span>{value.patient.dateOfBirth}</span>
                  </span>
                )}
                {!!value?.patient?.accountNumber && (
                  <span className="truncate text-sm text-gray-500 flex flex-row gap-2 items-end">
                    <UserIcon className="h-5 w-5" />
                    <span>{value.patient.accountNumber}</span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify({ summary: value?.patient }, null, 2)}</pre> */}
    </div>
  );
};
