import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { PaymentFilters } from '../components/patients/detail/billing/hooks/usePayments';
import { TransactionFilters } from '../components/patients/detail/billing/hooks/useTransactions';
import { BusinessRuleItemType } from '@chiroup/core/constants/globals';
import { BadRequestError } from '@chiroup/core/functions/errors';
import {
  CreateEdiPostType,
  InvoiceStatusEnum,
} from '@chiroup/core/types/Invoice.type';
import {
  CCPaymentFields,
  EpayCustomerTransactionAction,
  EpayModifyTransaction,
  PaymentMethodUpdateType,
} from '@chiroup/core/types/PatientBillling.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import { PatientInvoice } from '@chiroup/core/types/PatientInvoice.type';
import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import {
  PatientBalanceTransferProps,
  PatientTransaction,
} from '@chiroup/core/types/PatientTransaction.type';
import { resolve } from 'path';

const patientBillingService = () => {
  const listPatientPayment = async (
    clinicId?: number,
    params?: PaymentFilters & {
      skip?: number;
      limit?: number;
      patient?: string;
      credits?: boolean;
      subtypes?: string;
    },
  ) => {
    return ChiroUpAPI.get('api', `/transactions/${clinicId}/payment`, {
      queryParams: params,
    });
  };

  const getPatientPayment = async ({
    clinicId,
    paymentId,
    bulkResponse = false,
  }: {
    clinicId: number;
    paymentId: string;
    bulkResponse?: boolean;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/payment/${paymentId}${
        bulkResponse ? '?bulkResponse=true' : ''
      }`,
      {},
    );
  };

  const savePatientPayment = async ({
    clinicId,
    payment,
    bulkResponse = false,
  }: {
    clinicId: number;
    payment: Partial<PatientPayment>;
    bulkResponse?: boolean;
  }) => {
    const q = bulkResponse ? '?bulk=true' : '';
    if (payment.id) {
      return ChiroUpAPI.put(
        'api',
        `/transactions/${clinicId}/payment/${payment.id}${q}`,
        { body: payment },
      );
    }
    return ChiroUpAPI.post('api', `/transactions/${clinicId}/payment${q}`, {
      body: payment,
    });
  };

  const createPatientInsurance = async (
    body: Partial<Insurance>,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/patients/${patientId}/insurance`,
      {
        body,
      },
    );
  };

  const listPatientInsurance = async (
    clinicId?: number,
    patientId?: string,
    providerId?: string,
  ): Promise<{ data: Insurance[] }> => {
    if (!clinicId || !patientId) return Promise.resolve({ data: [] });
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/patients/${patientId}/insurance`,
      {
        queryParams: { providerId },
      },
    );
  };

  const listPatientTransaction = async (
    clinicId?: number,
    params?: TransactionFilters & {
      skip?: number;
      limit?: number;
      patient?: string;
    },
  ) => {
    return ChiroUpAPI.get('api', `/transactions/${clinicId}/transaction`, {
      queryParams: params,
    });
  };

  const listPatientTransactionActivity = async (
    clinicId?: number,
    billingKey?: string,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/transaction/${billingKey}/activity`,
      {},
    );
  };

  const getConsolidatedTransaction = async (
    clinicId?: number,
    billingKey?: string,
  ) => {
    if (!billingKey || !clinicId || billingKey === 'new') {
      return Promise.reject({ statusCode: 404, message: 'Not found.' });
    }

    // Extra fields in the REST are put back in for the UI to use.
    // The backend should not care as it gets the same data as before.
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/consolidated/transaction/${billingKey}`,
      {},
    );
  };

  const saveConsolidatedTransaction = async ({
    clinicId,
    payload,
  }: {
    clinicId: number | undefined | null;
    payload: PatientTransaction[] | null | undefined;
  }) => {
    if (!clinicId || !payload) return Promise.reject({ statusCode: 400 });
    let res = null;
    try {
      res = await ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/consolidated/transaction`,
        {
          body: payload,
        },
      );
      return res;
    } catch (err: any) {
      console.log({ errorFromThePatientBillingService: { err } });
      // throw new Error('Does this throw?');
      return err?.response?.data ?? err;
    }
  };

  const getPatientTransaction = async (
    clinicId?: number,
    billingKey?: string,
  ) => {
    if (!billingKey || !clinicId || billingKey === 'new') {
      return Promise.reject({ statusCode: 404, message: 'Not found.' });
    }

    // Extra fields in the REST are put back in for the UI to use.
    // The backend should not care as it gets the same data as before.
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/transaction/${billingKey}`,
      {},
    ).then((resp) => {
      if (resp && resp.insurances && Array.isArray(resp.insurances)) {
        resp.insurances = resp.insurances.map((i: any) => {
          return {
            ...i,
            patientID: resp?.patient?.id,
            appointmentID: resp.billingKey,
          };
        });
      }
      return resp;
    });
  };

  const savePatientBalanceTransfer = async ({
    clinicId,
    locationId,
    patientId,
    transfer,
  }: {
    clinicId: number;
    locationId: number | null | undefined;
    patientId: string | null | undefined;
    transfer: PatientBalanceTransferProps;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/${patientId}/balance-transfer`,
      {
        body: { ...transfer, locationId },
      },
    );
  };

  const deletePatientBalanceTransfer = async ({
    clinicId,
    patientId,
    billingKey,
  }: {
    clinicId: number;
    patientId: string | null | undefined;
    billingKey: string | null | undefined;
  }) => {
    if (!patientId || !billingKey || clinicId === -1) {
      throw new BadRequestError('A required parameter was not available.');
    }

    return ChiroUpAPI.del(
      'api',
      `/transactions/${clinicId}/${patientId}/balance-transfer/${billingKey}/delete`,
      {},
    );
  };

  const savePatientTransaction = async (args: {
    clinicId?: number;
    transaction?: PatientTransaction;
  }) => {
    const { clinicId, transaction } = args;
    const billingKey = transaction?.billingKey;
    /**
     * No REST if they are wicked (and don't pass parameters).
     */
    if (!billingKey || !clinicId || billingKey === 'new') return [];
    if (transaction?.id && transaction.id > 0) {
      return ChiroUpAPI.put(
        'api',
        `/transactions/${clinicId}/transaction/${billingKey}`,
        { body: transaction },
      );
    }
    return ChiroUpAPI.post('api', `/transactions/${clinicId}/transaction`, {
      body: transaction,
    });
  };

  const createTransactionActivity = async ({
    billingKey,
    clinicId,
  }: {
    billingKey: string;
    clinicId: number;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/transaction/${billingKey}/activity`,
      {},
    );
  };

  const createTransactionActivities = async ({
    billingKeys,
    clinicId,
    type,
  }: {
    billingKeys: string[];
    clinicId: number;
    type?: string;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/transaction/activities`,
      {
        body: { billingKeys, type },
      },
    );
  };

  const getPatientInsurance = async (
    id: string,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/patients/${patientId}/insurance/${id}`,
      {},
    );
  };

  const updatePatientInsurance = async (
    id: string,
    values: Partial<Insurance>,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.put(
      'api',
      `/patients/${clinicId}/patients/${patientId}/insurance/${id}`,
      {
        body: { ...values },
      },
    );
  };

  const deletePatientInsurance = async (
    id: string,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/patients/${clinicId}/patients/${patientId}/insurance/${id}`,
      {},
    );
  };

  const getEpayPublicKey = async (clinicId: number) => {
    return ChiroUpAPI.get('api', `/epay/${clinicId}/get-payment-key`, {});
  };

  const submitEpayPayment = async ({
    values,
    patientId,
    clinicId,
    paymentKey,
    payMethodKey,
  }: {
    values: CCPaymentFields;
    patientId: string;
    clinicId?: number;
    paymentKey?: string;
    payMethodKey?: string;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/${patientId}/submit-payment`,
      {
        body: { paymentKey, payMethodKey, values },
      },
    );
  };

  const getEpayCustomer = async (patientId: string, clinicId?: number) => {
    return ChiroUpAPI.get(
      'api',
      `/epay/${clinicId}/${patientId}/get-customer`,
      {},
    );
  };

  const listEpayCustomerTransactions = async (
    patientId: string,
    clinicId?: number,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/epay/${clinicId}/${patientId}/customer-transactions`,
      {},
    );
  };

  const listEpayBatches = async (clinicId: number) => {
    return ChiroUpAPI.get('api', `/epay/${clinicId}/batches`, {});
  };

  const getEpayBatch = async (clinicId: number, key: string) => {
    return ChiroUpAPI.get('api', `/epay/${clinicId}/batches/${key}`, {});
  };

  const closeEpayBatch = async (clinicId: number, key: string) => {
    return ChiroUpAPI.post('api', `/epay/${clinicId}/batches/${key}`, {});
  };

  const ePayTransactionAction = async (
    patientId: string,
    action: EpayCustomerTransactionAction,
    item: EpayModifyTransaction,
    clinicId?: number,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/epay/${clinicId}/${patientId}/customer-transaction`,
      {
        body: { item, action },
      },
    );
  };

  const deletePaymentTransaction = async (
    clinicId: number,
    billingKey: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/transactions/${clinicId}/payment/${billingKey}`,
      {},
    );
  };

  const refundPaymentTransaction = async (
    clinicId: number,
    billingKey: string,
  ) => {
    return ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/payment/${billingKey}/refund`,
      {},
    );
  };

  const getEpayTerminal = async ({
    clinicId,
    deviceKey,
  }: {
    clinicId: number;
    deviceKey: string;
  }) => {
    return ChiroUpAPI.get('api', `/epay/${clinicId}/terminal/${deviceKey}`, {});
  };

  const createNewPayRequest = async ({
    amount,
    clinicId,
    patientId,
    deviceKey,
    payment,
  }: {
    amount: number;
    clinicId: number;
    patientId: string;
    deviceKey: string;
    payment: any;
  }): Promise<{ id: number }> => {
    return ChiroUpAPI.post(
      'api',
      `/epay/${clinicId}/${patientId}/payment-engine-request`,
      {
        body: { amount, deviceKey, payment },
      },
    );
  };

  const updatePayRequest = async ({
    billingKey,
    payload,
    clinicId,
    deviceKey,
  }: {
    billingKey: string;
    payload: PatientPayment & CCPaymentFields;
    clinicId: number;
    patientId: string;
    deviceKey: string;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/payment/${billingKey}`,
      {
        body: { billingKey, payload, deviceKey },
      },
    );
  };

  const checkPayRequestStatus = async ({
    clinicId,
    payRequestKey,
  }: {
    clinicId: number;
    payRequestKey: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/epay/${clinicId}/payment-engine-request/${payRequestKey}`,
      {},
    );
  };

  const getInvoice = async ({
    invoiceId,
    clinicId,
    use,
  }: {
    invoiceId: number;
    clinicId: number;
    use?: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/invoice/${invoiceId}${
        use ? `?use=${use}` : ''
      }`,
      {},
    );
  };

  /**
   * This does a POST, as it needs a payload with a lot of data.
   *
   * @param param0
   * @returns
   */
  const get837P = async ({
    payload,
    clinicId,
  }: {
    payload: CreateEdiPostType;
    clinicId: number;
  }) => {
    return ChiroUpAPI.post('api', `/transactions/${clinicId}/invoice/837p`, {
      body: payload,
    });
  };

  const listInvoices = async ({
    billingKey,
    patientId,
    clinicId,
  }: {
    billingKey: string;
    patientId: string;
    clinicId: number;
  }) => {
    if (!billingKey || !clinicId || !patientId) return null;
    return ChiroUpAPI.get('api', `/transactions/${clinicId}/invoice`, {
      queryParams: { billingKey, patientId },
    });
  };

  const listAllPatientInvoicesByBillingKeys = async ({
    patientId,
    clinicId,
    billingKeys,
  }: {
    patientId: string;
    clinicId: number;
    billingKeys: string[];
  }) => {
    if (!clinicId || !patientId) return null;
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/invoice/${patientId}/billing-keys`,
      {
        queryParams: { billingKeys },
      },
    );
  };

  const updatePatientInvoice = async ({
    invoiceData,
    invoiceId,
    patientId,
    clinicId,
    dispatched,
  }: {
    invoiceData: PatientInvoice;
    invoiceId: number;
    patientId: string;
    clinicId: number;
    dispatched?: boolean;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/invoice/${invoiceId}`,
      { body: { invoiceData, patientId, dispatched } },
    );
  };

  const emailPatientInvoice = async ({
    invoiceId,
    clinicId,
    superBill = false,
    includeTaxId = false,
    includeTaxIdType = false,
  }: {
    invoiceId: number;
    clinicId: number;
    superBill?: boolean;
    includeTaxId?: boolean;
    includeTaxIdType?: boolean;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/invoice/${invoiceId}/email`,
      {
        queryParams: { superBill, includeTaxId, includeTaxIdType },
      },
    );
  };

  const writeOffPatientInvoice = async ({
    invoiceId,
    clinicId,
    amount,
  }: {
    invoiceId: number;
    clinicId: number;
    amount: number;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/invoice/${invoiceId}/write-off`,
      { body: amount },
    );
  };

  const generateStatement = async ({
    clinicId,
    patientId,
    startDate,
    endDate,
    type,
    payors,
  }: {
    clinicId: number;
    patientId: string;
    startDate: string;
    endDate: string;
    type: string;
    payors: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/statement/${patientId}`,
      {
        queryParams: { startDate, endDate, type, payors },
      },
    );
  };

  const unapplyPayorPayment = async ({
    clinicId,
    billingKey,
  }: {
    clinicId: number;
    billingKey: string;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/payment/${billingKey}/unapply`,
      {},
    );
  };

  const downloadCMS1500 = async ({
    clinicId,
    invoiceId,
    complete,
  }: {
    clinicId: number;
    invoiceId: number;
    complete: boolean;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/invoice/cms1500`,
      {
        body: { invoiceId, complete },
      },
      {
        type: 'blob',
      },
    );
  };

  const getClaimWriteOffInfo = async ({
    clinicId,
    billingKey,
  }: {
    clinicId: number;
    billingKey: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/transaction/${billingKey}/write-off`,
      {},
    );
  };

  const writeOffTransactionClaims = async ({
    clinicId,
    billingKey,
    body,
  }: {
    clinicId: number;
    billingKey: string;
    body: any;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/transaction/${billingKey}/write-off`,
      { body },
    );
  };

  const associateClaim = async ({
    clinicId,
    orphanId,
    payorId,
  }: {
    clinicId: number;
    orphanId: number;
    payorId: any;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/orphaned-claim/${orphanId}/associate`,
      { body: { payorId } },
    );
  };

  const markOrphanWorked = async ({
    clinicId,
    orphanId,
  }: {
    clinicId: number;
    orphanId: number;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/orphaned-claim/${orphanId}/worked`,
      {},
    );
  };

  const getClaimAllocationInfo = async ({
    clinicId,
    billingKey,
  }: {
    clinicId: number;
    billingKey: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/transaction/${billingKey}/allocate`,
      {},
    );
  };

  const allocateTransactionClaims = async ({
    clinicId,
    billingKey,
    body,
  }: {
    clinicId: number;
    billingKey: string;
    body: any;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/transaction/${billingKey}/allocate`,
      { body },
    );
  };

  const allocateAmountToPatient = async ({
    clinicId,
    billingKey,
    amount,
    patientId,
  }: {
    clinicId: number;
    billingKey: string;
    amount: number;
    patientId: string;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/${patientId}/transaction/${billingKey}/allocate-balance-to-patient`,
      { body: { amount } },
    );
  };

  const writeOffNonBillablePayor = async ({
    clinicId,
    billingKey,
    amount,
    patientId,
  }: {
    clinicId: number;
    billingKey: string;
    amount: number;
    patientId: string;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/${patientId}/transaction/${billingKey}/write-off-non-billable-payor`,
      { body: { amount } },
    );
  };

  const getBalanceForAllocation = async ({
    clinicId,
    billingKey,
    patientId,
  }: {
    clinicId: number;
    billingKey: string;
    patientId: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/${patientId}/transaction/${billingKey}/balance-for-allocation`,
      {},
    );
  };

  const saltPatientTransaction = async ({
    clinicId,
    target,
  }: {
    clinicId?: number;
    target?: PatientTransaction;
  }) => {
    if (!clinicId || !target) return Promise.reject({ statusCode: 404 });
    return await ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/transaction/${target.billingKey}/salt`,
      { body: { insurances: target.insurances } },
    );
  };

  const setInvoiceStatus = async ({
    clinicId,
    invoiceId,
    ids,
    status = null,
    issues = null,
    persistIssues = false,
  }: {
    clinicId: number;
    invoiceId?: number;
    status?: InvoiceStatusEnum | null;
    ids?: number[];
    issues?: { [key: string]: BusinessRuleItemType[] } | null;
    persistIssues?: boolean;
  }) => {
    if (invoiceId && ids) {
      return Promise.reject({
        statusCode: 400,
        message: 'Specify an invoice id or an array or ids, not both.',
      });
    }
    if (invoiceId) {
      ids = [invoiceId];
    }
    if (ids) {
      return ChiroUpAPI.post(
        'api',
        `/transactions/${clinicId}/integration/invoice-status`,
        {
          body: { ids, status, issues, persistIssues },
        },
      );
    }
    return Promise.reject({ statusCode: 404 });
  };

  const updateCustomerPaymentMethod = async ({
    avs_postalcode,
    avs_street,
    method_name,
    expires,
    cardholder,
    key,
    custKey,
    patientId,
    clinicId,
  }: Partial<PaymentMethodUpdateType>) => {
    return ChiroUpAPI.put(
      'api',
      `/epay/${clinicId}/${patientId}/update-payment-method`,
      {
        body: {
          avs_postalcode,
          avs_street,
          method_name,
          expires,
          cardholder,
          key,
          custKey,
        },
      },
    );
  };

  const deleteCustomerPaymentMethod = async ({
    key,
    custKey,
    patientId,
    clinicId,
  }: Partial<PaymentMethodUpdateType>) => {
    return ChiroUpAPI.del(
      'api',
      `/epay/${clinicId}/${patientId}/delete-payment-method/${key}/${custKey}`,
      {},
    );
  };

  const fetchSuperBillsByDate = async ({
    clinicId,
    patientId,
    startDate,
    endDate,
  }: any) => {
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/${patientId}/super-bills`,
      {
        queryParams: { startDate, endDate },
      },
    );
  };

  const getPatientPackage = async ({
    patientId,
    billingKey,
    clinicId,
  }: {
    patientId?: string;
    billingKey?: string | null;
    clinicId?: number;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/packages/${patientId}/${billingKey}`,
      {},
    );
  };

  const updatePatientPackage = async ({
    patientId,
    billingKey,
    clinicId,
    expirationDate,
    additionalPayment,
  }: {
    patientId?: string;
    billingKey?: string | null;
    clinicId?: number;
    expirationDate?: string;
    additionalPayment?: number;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/patients/${clinicId}/packages/${patientId}/${billingKey}`,
      {
        body: {
          expirationDate,
          additionalPayment,
        },
      },
    );
  };

  //list of packages they can use for a specific transaction
  const getApplicablePatientPackageList = async ({
    patientId,
    locationId,
    clinicId,
    hasAccess,
  }: {
    patientId?: string;
    locationId?: number;
    clinicId?: number;
    hasAccess: boolean;
  }) => {
    if (!hasAccess) return { data: {} };
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/packages/${patientId}/list/${locationId}`,
      {},
    );
  };

  //all packages that a patient has bought list.
  const getPatientPackageList = async ({
    patientId,
    clinicId,
    params,
  }: {
    patientId?: string;
    clinicId?: number;
    params?: {
      skip?: number;
      limit?: number;
    };
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/packages/${patientId}/list`,
      {
        queryParams: params,
      },
    );
  };

  return {
    allocateTransactionClaims,
    checkPayRequestStatus,
    closeEpayBatch,
    createNewPayRequest,
    createPatientInsurance,
    createTransactionActivities,
    createTransactionActivity,
    deletePatientBalanceTransfer,
    deletePatientInsurance,
    deletePaymentTransaction,
    downloadCMS1500,
    emailPatientInvoice,
    ePayTransactionAction,
    generateStatement,
    get837P,
    getClaimAllocationInfo,
    getClaimWriteOffInfo,
    getConsolidatedTransaction,
    getEpayBatch,
    getEpayCustomer,
    getEpayPublicKey,
    getEpayTerminal,
    getInvoice,
    getPatientInsurance,
    getPatientPayment,
    getPatientTransaction,
    listAllPatientInvoicesByBillingKeys,
    listEpayBatches,
    listEpayCustomerTransactions,
    listInvoices,
    listPatientInsurance,
    listPatientPayment,
    listPatientTransaction,
    listPatientTransactionActivity,
    refundPaymentTransaction,
    saltPatientTransaction,
    saveConsolidatedTransaction,
    savePatientBalanceTransfer,
    savePatientPayment,
    savePatientTransaction,
    setInvoiceStatus,
    submitEpayPayment,
    unapplyPayorPayment,
    updatePatientInsurance,
    updatePatientInvoice,
    updatePayRequest,
    writeOffPatientInvoice,
    writeOffTransactionClaims,
    updateCustomerPaymentMethod,
    deleteCustomerPaymentMethod,
    getPatientPackage,
    associateClaim,
    markOrphanWorked,
    fetchSuperBillsByDate,
    getApplicablePatientPackageList,
    getPatientPackageList,
    getBalanceForAllocation,
    allocateAmountToPatient,
    writeOffNonBillablePayor,
    updatePatientPackage,
  };
};

export default patientBillingService();
