import { databaseService } from '../../../../settings/database/database.service';
import { useContext, useRef, useState } from 'react';
import { MeContext } from '../../../../../contexts/me.context';
import DatabaseAutocomplete from '../../../../settings/database/DatabaseAutocomplete';
import { InstanceKeys } from '@chiroup/core/constants/DatabaseFeatureCommon';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  PatientTransactionItemType,
  PatientTransaction,
  AdjustmentStructureEnum,
  toTransactionItemSubtype,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';

type Props = {
  containerClassName?: string;
  onClick?: (item: PatientTransactionItemType) => void;
  transaction: PatientTransaction | null;
  addPendingItems: (items: PatientTransactionItemType[]) => void;
};

export const NewItemSubtypeAdjustment: React.FC<Props> = ({
  containerClassName = 'w-full',
  onClick,
  transaction,
  addPendingItems,
}) => {
  const { me } = useContext(MeContext);
  const [item, setItem] = useState<string>('');

  const onChangeHandler = async (id: any) => {
    setItem(id);
    if (!id || !transaction) return;

    const itemToAdd = await databaseService.get<{
      name: string;
      value: number;
      structure: string;
    }>({
      clinicId: me?.selectedClinic?.ID as number,
      database: InstanceKeys.billingAdjustments,
      id: id as number,
    });

    const itemsToAdd = [];
    // There is a more compact way to do this, but not now.
    if (itemToAdd.structure === AdjustmentStructureEnum.DollarOverride) {
      const item = toTransactionItemSubtype(
        TransactionItemSubtypeEnum.Override,
        {
          type: TransactionItemTypeEnum.Debit,
          subtype: TransactionItemSubtypeEnum.Override,
          amount: Number(itemToAdd.value),
          units: 1,
          description: itemToAdd.name,
          groupId: null,
          id: -1,
          patientTransactionId: -1,
          adjustmentId: id,
          adjustment: {
            structure: itemToAdd.structure,
          },
        },
      );
      itemsToAdd.push(item);
    } else if (itemToAdd.structure === AdjustmentStructureEnum.DollarDiscount) {
      const item: PatientTransactionItemType = toTransactionItemSubtype(
        TransactionItemSubtypeEnum.Adjustment,
        {
          type: TransactionItemTypeEnum.Credit,
          subtype: TransactionItemSubtypeEnum.Adjustment,
          amount: Number(itemToAdd.value),
          units: 1,
          description: itemToAdd.name,
          groupId: null,
          id: -1,
          patientTransactionId: -1,
          adjustmentId: id,
          adjustment: { structure: itemToAdd.structure },
        },
      );
      itemsToAdd.push(item);
    } else if (
      itemToAdd.structure === AdjustmentStructureEnum.PercentDiscount
    ) {
      const item: PatientTransactionItemType = toTransactionItemSubtype(
        TransactionItemSubtypeEnum.Adjustment,
        {
          type: TransactionItemTypeEnum.Credit,
          subtype: TransactionItemSubtypeEnum.Adjustment,
          amount: null,
          units: 1,
          description: itemToAdd.name,
          groupId: null,
          id: -1,
          patientTransactionId: -1,
          adjustmentId: id,
          adjustment: {
            percentOff: Number(itemToAdd.value),
            structure: itemToAdd.structure,
          },
        },
      );
      itemsToAdd.push(item);
    } else {
      throw new Error(`Invalid adjustment structure: ${itemToAdd.structure}.`);
    }
    addPendingItems(itemsToAdd);
  };

  const buttonRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classNames(
        containerClassName,
        'p-2 border border-gray-300 rounded-md bg-gray-50 mb-4 relative',
      )}
    >
      <DatabaseAutocomplete
        value={item}
        className="col-span-4"
        onChange={onChangeHandler}
        buttonRef={buttonRef}
        searchRef={searchRef}
        label="Discount"
        instanceKey={InstanceKeys.billingAdjustments}
        getInitialOptions
        hideEndOfList={false}
      />
    </div>
  );
};
