import { Input, Select } from '@chiroup/components';
import {
  IntegrationInvoice,
  InvoiceStatusEnum,
  InvoiceGenerationOptions,
  ClaimDelayReasonOptions,
} from '@chiroup/core/types/Invoice.type';
import { ReactNode } from 'react';

export const metaByInvoiceStatus = ({
  invoice,
  readonly,
  callback,
}: {
  invoice: IntegrationInvoice;
  readonly: boolean;
  callback: (args: { invoice: IntegrationInvoice; fetch?: boolean }) => void;
}) => {
  if (readonly) return undefined;
  const now = new Date().getTime(),
    delta = (now - (invoice?.transactionDate ?? 0)) / 1000 / 60 / 60 / 24,
    age = Math.floor(delta),
    status = invoice?.status,
    res: { [key: string]: ReactNode } = {};

  switch (status) {
    case InvoiceStatusEnum.Rejected: {
      res.Generation = (
        <Select
          name="invoice.generation"
          className="-mt-3"
          options={[
            { value: '', text: '-- select --' },
            ...InvoiceGenerationOptions,
          ]}
          value={invoice.generation}
          onChange={(e) => {
            callback({
              invoice: { ...invoice, generation: e },
              fetch: false,
            });
          }}
          limit={1}
          native
        />
      );
      res['Payor Claim Control #'] = (
        <Input
          name="invoice.payorClaimControlNumber"
          className="input"
          value={invoice.claimControlNumber}
          onChange={(e) => {
            callback({
              invoice: { ...invoice, claimControlNumber: e },
              fetch: false,
            });
          }}
        />
      );

      break;
    }
  }
  if (age >= 90) {
    res['Delay Reason'] = (
      <Select
        name="invoice.delay"
        options={[{ text: '-- select --', value: '' }].concat(
          ClaimDelayReasonOptions,
        )}
        className="-mt-3"
        value={invoice.delayReason}
        onChange={(e) => {
          callback({
            invoice: { ...invoice, delayReason: e },
            fetch: false,
          });
        }}
        limit={1}
        native
      />
    );
  }
  return res;
};
