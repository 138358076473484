/**
 * [2024-12-03.1354 by Brian]
 *
 * This is abstracted out to a standalone function so that it can be tested.
 * I'm not saying I wrote any tests, but we can sure write tests to send in
 * canned data and test to see if it merges the way it should.
 *
 * ...baby steps.
 *
 */
import { STRING_BOOLEAN_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import {
  isaPaymentItem,
  isaServiceItem,
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';

export type MergeTransactionsOptions = {
  treatments?: boolean;
  units?: boolean;
  providers?: STRING_BOOLEAN_HASH;
};

// This is for the typing. A simpler data structure was easier
// to bork.
export const mergeTransactionsStandalone = ({
  transactions,
  options,
  queryClient,
  trace = false,
}: {
  transactions: PatientTransaction[];
  options: MergeTransactionsOptions;
  queryClient?: any;
  trace?: boolean;
}) => {
  const targets: { [key: string]: PatientTransaction } = {},
    sources: { [key: string]: PatientTransaction[] } = {},
    newById: { [key: string]: PatientTransaction } = {},
    clone = ChiroUpJSON.clone(transactions); // Don't bork the original.

  if (trace) {
    console.log({
      mergeTransaction: {
        transactions,
        options,
        hasQueryClient: !!queryClient,
      },
    });
  }
  for (const row of clone ?? []) {
    const providerId = row?.provider?.id ?? '',
      treatmentId = options.treatments ? 'any' : row?.treatmentId,
      key = [row?.provider?.id, treatmentId].join('.');
    // Yes, they REALLY have to want to skip this ;-)
    if (
      typeof options?.providers?.[providerId] === 'boolean' &&
      options?.providers?.[providerId] === false
    ) {
      continue;
    }
    if (!targets[key]) {
      targets[key] = row;
    } else {
      if (!sources[key]) {
        /**
         * Business rule:
         *   - We can't merge a transaction IF it has any payments on
         *     it. That would require a LOT of extra work on the backend.
         *
         *     TODO: Maybe add support for this. Would have to find the
         *     payment and then change its paymentToward.
         */
        const hasPayments = row?.items?.some((i: PatientTransactionItemType) =>
          isaPaymentItem(i),
        );
        if (hasPayments) continue;
        sources[key] = [];
      }
      sources[key].push(row);
    }
  }

  for (const [t, s] of Object.entries(sources)) {
    for (const row of s) {
      for (const item of row?.items ?? []) {
        // We merge the treatment unless told not to do so.
        if (
          item.subtype === TransactionItemSubtypeEnum.Treatment &&
          typeof options?.treatments === 'boolean' &&
          options?.treatments === false
        ) {
          continue;
        }
        /**
         * Use case...
         *
         * They MAY have multiple treatments on one transaction this
         * way, but they cannot bill for it. So, this merge may break
         * the ability of the user to generate EDI.
         */
        const treatmentAlreadyThere = targets[t].items.find((i) => {
          return (
            i.subtype === TransactionItemSubtypeEnum.Treatment &&
            i.description === item.description // Not idea, but we were only supposed to have one.
          );
        });
        if (!treatmentAlreadyThere) {
          targets[t].items.push(item);
        }
      }
      newById[row.billingKey] = {
        ...row,
        merged: targets[t].billingKey,
        items: [],
        services: [],
      };
    }
  }

  if (options.units) {
    for (const t of Object.values(targets)) {
      const itemsByCodeAndType = t.items.reduce(
        (acc, item) => {
          if (isaServiceItem(item)) {
            const key = [item.code, item.subtype].join('.');
            if (!acc[key]) {
              acc[key] = item;
            } else {
              acc[key].units = (acc[key].units ?? 0) + (item?.units ?? 0);
            }
          }
          return acc;
        },
        {} as { [key: string]: PatientTransactionItemType },
      );
      const nonServiceItems = t.items.filter((i) => !isaServiceItem(i));
      t.items = [...nonServiceItems, ...Object.values(itemsByCodeAndType)];
      // Maybe keep them in the same order. Might cut down on saves.
      t.items.sort((a, b) => {
        if (a.type !== b.type) {
          return a?.type?.localeCompare(b?.type ?? '') ?? 0;
        }
        if (a.subtype !== b.subtype) {
          return a?.subtype?.localeCompare(b?.subtype ?? '') ?? 0;
        }
        return (a?.id ?? 0) - (b?.id ?? 0);
      });
    }
  }

  // At this point, we should have all the services added or units
  // merged so we can set the services array again.
  for (const t of Object.values(targets)) {
    t.services = t.items.filter((i) => isaServiceItem(i));
    newById[t.billingKey] = t;
  }

  for (let i = 0; i < (clone?.length ?? 0); i++) {
    if (newById[clone[i].billingKey]) {
      clone[i] = newById[clone[i].billingKey];
      queryClient?.setQueryData?.(
        ['transaction', clone[i].billingKey],
        clone[i],
      );
    }
  }

  if (trace) {
    console.log({
      mergeTransaction: { targets, sources, sourceById: newById },
    });
  }

  return clone;
};
