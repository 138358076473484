import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { NewUserForm } from '../components/settings/clinic/ClinicSettingsUsersAddUserModal';
import { MeContext } from '../contexts/me.context';
import { ToastContext, ToastTypes } from '../contexts/toast.context';
import clinicService from '../services/clinic.service';
import { User, UserRoles } from '@chiroup/core/types/User.type';
import { Clinician } from '@chiroup/core/types/Me.type';

const getQuery = () => {
  return async (context: QueryFunctionContext) => {
    const selectedClinicId = context.queryKey[1] as string;
    const userId = context.queryKey[2] as string;
    return clinicService.getUsers(Number(selectedClinicId), userId);
  };
};

const createName = (user: Partial<NewUserForm & { ID: string | null }>) => {
  return `${user.title ? `${user.title}. ` : ''}${
    user.fname ? `${user.fname} ` : ''
  }${user.lname ? `${user.lname}` : ''}`;
};

const useClinicUsers = () => {
  const queryClient = useQueryClient();
  const [isRemoving, setIsRemoving] = useState(false);
  const meContext = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const { data, isFetching, refetch } = useQuery<User[]>(
    ['clinicUsers', meContext.me.selectedClinic?.ID, meContext?.me.ID],
    getQuery(),
    {
      refetchOnWindowFocus: false,
      // staleTime = 24 hours
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  const save = async (val: Partial<User>) => {
    try {
      const previousUser = data?.find((user) => user.ID === val.ID);
      await clinicService.saveUser(
        meContext.me.selectedClinic?.ID || -1,
        val.ID as string,
        val,
      );
      queryClient.setQueryData(
        'clinicUsers',
        data?.map((user) => (user.ID === val.ID ? { ...user, ...val } : user)),
      );
      const previousClinicians = [
        ...(meContext.me.selectedClinic?.clinicians || []),
      ];
      const clinicianToUpdate = previousClinicians.findIndex(
        (clinician) => clinician.ID === val.ID,
      );
      const wasAProvider = !!(clinicianToUpdate && clinicianToUpdate > -1);
      const isProvider = val?.role?.includes(UserRoles.Provider);
      if (isProvider && !wasAProvider) {
        previousClinicians.push({ ...previousUser, ...val } as Clinician);
      } else if (!isProvider && wasAProvider) {
        previousClinicians.splice(clinicianToUpdate, 1);
      }

      meContext.updateMe(
        'selectedClinic',
        meContext.me.selectedClinic
          ? {
              ...meContext.me.selectedClinic,
              clinicians: previousClinicians,
            }
          : undefined,
      );

      createToast({
        title: 'Success!',
        description: <>Successfully saved user!</>,
        type: ToastTypes.Success,
        duration: 5000,
      });
    } catch (err: any) {
      createToast({
        title: 'Error!',
        description: (
          <span>
            {err?.response?.data?.[0]?.message || `Failed to save user!`}
          </span>
        ),
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
  };

  const remove = async (val: User) => {
    try {
      setIsRemoving(true);
      await clinicService.removeUser(meContext.me.selectedClinic?.ID, val.ID);
      queryClient.setQueryData(
        'clinicUsers',
        data?.filter((user) => user.ID !== val.ID),
      );
      meContext.updateMe(
        'selectedClinic',
        meContext.me.selectedClinic
          ? {
              ...meContext.me.selectedClinic,
              clinicians: meContext.me.selectedClinic.clinicians.filter(
                (user) => user.ID !== val.ID,
              ),
            }
          : undefined,
      );
      createToast({
        title: 'Success!',
        description: <>Successfully removed user!</>,
        type: ToastTypes.Success,
        duration: 5000,
      });
      setIsRemoving(false);
    } catch (err) {
      createToast({
        title: 'Error!',
        description: <>Failed to remove user!</>,
        type: ToastTypes.Fail,
        duration: 5000,
      });
      setIsRemoving(false);
    }
  };

  const add = async (val: Partial<NewUserForm>) => {
    if (!meContext.me.selectedClinic?.ID || !meContext.me.ID || !val.email) {
      return;
    }
    if (val.emailCheck) {
      const findUserRes = await clinicService.findUserByEmail(
        meContext.me.selectedClinic.ID,
        meContext.me.ID,
        val.email,
      );
      if (findUserRes) {
        return addUser({ ...val, ...findUserRes });
      } else {
        throw new Error('User not found');
      }
    } else {
      return addUser({ ...val, ID: null });
    }
  };

  const addUser = async (val: Partial<NewUserForm & { ID: string | null }>) => {
    if (!meContext.me.selectedClinic?.ID || !meContext.me.ID || !val.email) {
      return;
    }
    const res = await clinicService.addUser(
      meContext.me.selectedClinic.ID,
      meContext.me.ID,
      val as NewUserForm & { ID: string | null },
    );
    queryClient.setQueryData('clinicUsers', [
      ...(data || []),
      { ...val, ...res, name: createName(val) },
    ]);
  };

  return { isFetching, data, save, remove, isRemoving, add, refetch };
};

export default useClinicUsers;
