import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import clinicService from '../../../services/clinic.service';
import { EpayDevice } from '@chiroup/core/types/PatientBillling.type';

const query = (clinicId = -1) => {
  return async (context: QueryFunctionContext) => {
    return clinicService.getCreditDevices(clinicId);
  };
};

const useCreditDevices = () => {
  const meContext = useContext(MeContext);
  const queryClient = useQueryClient();
  const [removingDevice, setRemovingDevice] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<EpayDevice | null>();
  const { data, isFetching, refetch } = useQuery<any>(
    ['ccDevices'],
    query(meContext?.me.selectedClinic?.ID),
    {
      refetchOnWindowFocus: false,
    },
  );

  const addDevice = async (name: string, standAlone: boolean) => {
    const res = await clinicService.addCreditDevice(
      name,
      standAlone,
      meContext?.me?.selectedClinic?.ID ?? -1,
    );
    return res;
    // setSelectedDevice(res);
    // queryClient.setQueryData(['ccDevice'], (data || []).concat(res));
  };

  const removeDevice = async (key: string) => {
    try {
      setRemovingDevice(true);
      const res = await clinicService.removeCreditDevice(
        meContext?.me?.selectedClinic?.ID ?? -1,
        key,
      );
      if (res) {
        queryClient.setQueryData(
          ['ccDevices'],
          data?.data?.filter((device: EpayDevice) => device.key !== key),
        );
        setSelectedDevice(null);
      }
      setRemovingDevice(false);
    } catch (err) {
      console.error({ err });
      setRemovingDevice(false);
    }
  };

  return {
    data,
    isFetching,
    addDevice,
    selectedDevice,
    setSelectedDevice,
    removeDevice,
    removingDevice,
    refetch,
  };
};

export default useCreditDevices;
