import { Currency } from '@chiroup/components';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { PatientInvoiceLineItem } from '@chiroup/core/types/PatientInvoice.type';
import { TransactionItemSubtypeEnum } from '@chiroup/core/types/PatientTransaction.type';

type InvoiceTableRowProps = {
  item: PatientInvoiceLineItem;
};
const PrintInvoiceLineItemTableRow: React.FC<InvoiceTableRowProps> = ({
  item,
}) => {
  const getAmount = (
    amount: number,
    units: number,
    subtype: TransactionItemSubtypeEnum,
  ) => {
    return createDecimal(amount || 0)
      .times(
        subtype === TransactionItemSubtypeEnum.Adjustment
          ? (units || 1) * -1
          : units || 1,
      )
      .toNumber()
      .toFixed(2);
  };

  return (
    <tr
      className={`text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800 ${
        item.hidden ? 'hidden' : ''
      }`}
    >
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.description}
      </td>
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.detail}
      </td>
      {!!item.amount && (
        <td className="whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
          <Currency
            value={getAmount(
              item.amount,
              item.units ?? 1,
              item.type as TransactionItemSubtypeEnum,
            )}
          />
        </td>
      )}
    </tr>
  );
};

export default PrintInvoiceLineItemTableRow;
