/**
 * [2024-01-18.0934 by Brian] This is the best guess for EDI at this
 * point. MIME types are not mentioned in the Trizetto docs at this
 * point, just the file extensions.
 */

import { STRING_STRING_HASH } from '../constants/globals';

const X12_MIME_TYPE = 'application/EDI-X12';
const DEFAULT_MIME_TYPE = 'text/plain';

const supportedMimeTypes: STRING_STRING_HASH = {
  '27c': X12_MIME_TYPE,
  txt: X12_MIME_TYPE,
  edi: X12_MIME_TYPE,
  x12: X12_MIME_TYPE,
  rmt: X12_MIME_TYPE,
  '999': X12_MIME_TYPE,
};

export const isSupportedTextExtension = (extension: string | undefined) => {
  if (!extension) return false;
  return !!supportedMimeTypes[extension.toLowerCase()];
};

export const mimeTypeByExtension = (extension: string | undefined) => {
  if (!extension) return DEFAULT_MIME_TYPE;
  return supportedMimeTypes[extension] ?? DEFAULT_MIME_TYPE;
};
