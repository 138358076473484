import { clog } from '@chiroup/core/functions/clog';

type Props = {
  text?: string | undefined | null;
  content?: string | undefined | null;
  fetch?: () => Promise<string>;
  iconClassName?: string;
  tipClassName?: string;
  containerClassName?: string;
  svgClassName?: string;
  onDownload?: () => void;
  type?: string;
  id?: string;
  filename?: string;
  iconStyle?: React.CSSProperties;
};

/**
 * Copied the 'download' from the chiroup app. This is at the top so
 * it can be used for other apps and available at @chiroup/components.
 * It also follows the pattern of passing an object with arguments.
 *
 * Seemed to make sense to put it with a component that uses it or
 * you can have the raw function.
 *
 * @param param0
 */
export const trivialDownload = ({
  content = 'Just a test.',
  name = 'download.txt',
  mimeType = 'text/plain;encoding:utf-8',
}: {
  content?: string | Uint8Array | null;
  name?: string;
  mimeType?: string;
}) => {
  if (!content) {
    clog('Nothing to download.');
    return;
  }
  const a = document.createElement('a');

  const nav = navigator as any;

  if (nav.msSaveBlob) {
    nav.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      name,
    );
  } else if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      }),
    );
    a.setAttribute('download', name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.open(
      'data:application/octet-stream,' + encodeURIComponent(content as string),
      '_blank',
    );
    // location.href =
    //   "data:application/octet-stream," + encodeURIComponent(content);
  }
};

/**
 * [2024-01-10.1410 by Brian]
 *
 * Examples:
 *
 *   <TrivialDownload text={tooltip} />
 *
 *   <TrivialDownload text={tooltip} type="info" />
 *
 *   <TrivialDownload text={tooltip} type="info" id="main-tooltip" />
 *
 * TODO: The parameters `text` and `content` are synonmys.
 *
 * @param param0
 * @returns
 */
export const TrivialDownload: React.FC<Props> = ({
  text,
  content,
  iconClassName = 'h-8 w-8 text-gray-800 inline-block ml-2 align-text-top hover:text-primary-600',
  tipClassName = 'w-56',
  containerClassName = 'inline-block cursor-pointer w-6 h-6',
  svgClassName,
  type = 'question',
  id,
  iconStyle,
  filename = 'download.txt',
  onDownload = () => {
    console.warn('TrivialDownload: onDownload not implemented.');
  },
  fetch,
}) => {
  if (!text) text = content;
  if (!text) return null;

  const icon = () => {
    switch (type) {
      case 'solid': {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />
            <path
              fillRule="evenodd"
              d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087ZM12 10.5a.75.75 0 0 1 .75.75v4.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72v-4.94a.75.75 0 0 1 .75-.75Z"
              clipRule="evenodd"
            />
          </svg>
        );
      }
      case 'circle': {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 inline-block"
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"
              clipRule="evenodd"
            />
          </svg>
        );
      }
      default: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>
        );
      }
    }
  };

  return (
    <div
      className={containerClassName}
      onClick={() => {
        if (fetch) {
          fetch().then((text) => {
            trivialDownload({ content: text, name: filename });
            onDownload && onDownload();
          });
          return;
        } else {
          trivialDownload({ content: text, name: 'edi.x12' });
          onDownload && onDownload();
        }
      }}
    >
      <div className={iconClassName}>{icon()}</div>
    </div>
  );
};
