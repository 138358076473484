import { InfoItemHtml, LoadingPage } from '@chiroup/components';
import { ArrowPathIcon, PencilIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import useTransactionActivity from './useTransactionActivity';
import { classNames } from '@chiroup/core/functions/classNames';
import { TransactionActivityType } from '@chiroup/core/types/PatientTransaction.type';

dayjs.extend(relativeTime);

const activityIcons: {
  [key in TransactionActivityType]: React.ReactNode;
} = {
  [TransactionActivityType.Created]: (
    <ArrowPathIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [TransactionActivityType.Updated]: (
    <PencilIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
  ),
  [TransactionActivityType.PayorInvoiced]: (
    <ArrowPathIcon className="h-4 w-4 text-gray-500 transform rotate-180" />
  ),
};

type Props = {
  billingKey: string | null | undefined;
  containerClassName?: string;
};

export const TabActivity: React.FC<Props> = ({
  billingKey,
  containerClassName = 'w-full p-4 flex flex-col justify-center',
}) => {
  const { isFetching, data } = useTransactionActivity({
    billingKey: billingKey as string,
  });

  if (!billingKey) {
    return (
      <div
        className={classNames(
          'text-gray-400 italic text-md flex justify-center px-4 py-2',
          containerClassName,
        )}
      >
        No billing key was provided.
      </div>
    );
  }

  if (!isFetching && !data) {
    return (
      <div
        className={classNames(
          'text-gray-400 italic text-md flex justify-center px-4 py-2',
          containerClassName,
        )}
      >
        'No activity found.''
      </div>
    );
  }
  return isFetching ? (
    <LoadingPage />
  ) : (
    <ul className="space-y-6">
      {data?.map((activityItem, activityItemIdx) => (
        <li key={activityItem.id} className="relative flex gap-x-4">
          <div
            className={classNames(
              activityItemIdx === data?.length - 1 ? 'h-6' : '-bottom-6',
              'absolute left-0 top-0 flex w-6 justify-center',
            )}
          >
            <div className="w-px bg-gray-300 dark:bg-darkGray-700" />
          </div>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-darkGray-900">
            {activityIcons[activityItem.type] ? (
              <div className="flex justify-center items-center h-6 w-6 rounded-full bg-gray-300 dark:bg-darkGray-700">
                {activityIcons[activityItem.type]}
              </div>
            ) : (
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
            )}
          </div>
          <div className="flex-auto flex flex-col gap-2">
            <div className="flex-auto flex">
              <InfoItemHtml
                className="flex-auto py-0.5 text-xs leading-5 text-gray-500"
                value={activityItem.description}
              />
              <span
                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                title={dayjs(activityItem.createdAt).format(
                  'MM/DD/YYYY [at] h:mm A',
                )}
              >
                {dayjs(activityItem.createdAt).fromNow()}
              </span>
            </div>
            {!!(
              activityItem.data?.strings?.length ||
              activityItem.data?.updates?.length
            ) && (
              <div className="w-full rounded-md p-3 ring-1 ring-inset ring-gray-300">
                <ul className="text-sm leading-6 text-gray-500">
                  {activityItem.data.strings?.map((str: any, idx) => (
                    <li key={`aids-${idx}`}>{str}</li>
                  ))}
                  {activityItem?.data?.updates?.map((update: any, idx) => (
                    <li key={`aids-rest-${idx}`}>
                      <span className="font-medium text-gray-900">
                        {update.field}
                      </span>{' '}
                      changed{update.from && update.to ? ' from ' : ' '}
                      {update.msg ? `- ${update.msg}` : ''}
                      <span className="font-medium text-gray-900">
                        {update.from}
                      </span>
                      {update.from && ' '}
                      {update.to ? (
                        <>
                          to{' '}
                          <span className="font-medium text-gray-900">
                            {update.to}
                          </span>
                        </>
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
