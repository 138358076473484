/**
 *  parentId
 *     The id of the parent object, probably an appointment id or a billingKey.
 *
 * parentIsa
 *     Used to know what the parent is, transaction, encounter and appointment
 *     are supported.
 *
 *  patientId
 *     Nothing special here either.
 *
 *  clinicId
 *     The usual.
 *
 *  readonly = false
 *     Should be no surprises what this does.
 *
 *  plan
 *     This is a plan from the encounter wizard. It will replace/build all the
 *     services from this plan if it exists. An import button is visible if
 *     this exists.
 *
 *  horizontal = false,
 *     Is the display horizontal or vertical?
 *
 *  omitTitle = false
 *     Keep the title from showing.
 *
 *  omitDiagnosisHeader = false
 *     Keep the header out of the left side.
 *
 *  lockButtonText = 'Lock'
 *     What the text on the lock button should be.
 *
 *  unlockButtonText = 'Unlock'
 *     What the text on the unlock button should be.
 *
 *  saveButtonText = 'Save'
 *     What the text on the save button should be.
 *
 *  saveCallback
 *     After a save is done, if this exists, it is called. Use this to do
 *     what you want after a save.
 *
 *  saveVisit
 *     So this component can save a visit.
 *
 *  visitForm
 *     The visit form from an encounter.
 *
 *  assessmentCodes
 *     Appears to be a list of codes that are used to build the list of
 *     diagnoses for the encounter. (Diagnosis do not exist on transactions
 *     except as attached to each service. This makes it look likey they
 *     are.)
 *
 *  triggerAppendItems
 *     A useEffect watches this and when it changes and is not null, it will
 *     append anything sitting in this object to the services. Basically, so
 *     a parent component to call this component and pass in a list of items
 *     to add.
 *
 *  absoluteServiceItems = null
 *     Similar to the above, a useEffect watches this guy and will completely
 *     replace the services for what is in this list. This is primarily for
 *     resetting the services when an edit was canceled, but can be used for
 *     other things.
 *
 *  patientTransaction = null
 *     Instead of saving twice, this is designed to save things as a transaction
 *     not as just services if it has a transaction that has been kept up-to-date.
 *
 *  setPatientTransaction = null
 *     This supports updating the insurances inside the transaction. Also passed to
 *     CodeEncounterDiagnosisServicesWithDiagnosis. This is necessary to make sure
 *     a save of the transaction will save the insurance correctly as well.
 *
 *  clickRemoteControl
 *     This has the concept of remote-control clicks. A useEffect watches this
 *     data structure. If it changes from null, its properties are checked and
 *     the right button on this componennt's constituents is clicked.
 *
 *  disciplineId = undefined
 *     Pretty sure this is unused at this point. Exists to pass the disciplineId
 *     down to the services.
 *
 *  persistDiagnosesList = false
 *     In the SOAP wizard, a diagnosis can be selected. The requested behavior
 *     was to keep diagnoses around _without_ having a service attached to them.
 *     This was added to allow the bahvior to be controlled as desired.
 *
 *  appointment = null
 *     Presumably to get the insurance information from the appointment.
 *
 *  lockButtonComponent = null [deprecated, use instanceComponents.lockButton]
 *     Allows a custom lock button to be passed in. (All in support of having
 *     one set of buttons whether this component does the save or some other
 *     component that includes this component does the saving -- transactions).
 *
 *  importDiagnosesAlways = false
 *     Makes for a diagnosis list when there are no services. Mostly for the
 *     encounter where a diagnosis can be associated with a plan and they want
 *     the diagnosis to appear as an option _before_ any services are added.
 *
 *  setVisitForm = undefined
 *     A way to update the visit form.
 *
 *  importButtonText = No default.
 *
 *  importOnlyMode = false
 *     Turns off the display of everything but the import button and saves the
 *     transaction after the import in one step.
 *
 *  saveSuccessMessage = undefined
 *     If this exists, it is used as the success message for the save.
 */
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { TransactionContext } from '../../../../../../contexts/transaction.context';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useId,
  useState,
} from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../../contexts/toast.context';
import { billingService } from '../../../../../../services/billing.service';
import modalityService from '../../../../../../services/modality.service';
import supplementService from '../../../../../../services/supplement.service';
import testService from '../../../../../../services/test.service';
import { databaseService } from '../../../../../settings/database/database.service';
import useInsuranceList from '../../../billing/hooks/useInsuranceList';
import CodeEncounterDiagnosisServicesList from './CodeEncounterDiagnosisServicesList';
import CodeEncounterDiagnosisServicesWithDiagnosis from './CodeEncounterDiagnosisServicesWithDiagnosis';
import businessRulesService from '../../../../../../services/businessRules.service';
import { TransactionMagicEvent } from '../../../../../../services/businessRules.service/transactionMagic';
import { MagicActionType } from '../../../../../../services/businessRules.service/commonMagic';
import { applicableCodes } from '../../../../../../services/businessRules.service/autoPopulateMedicareModifier';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  USE_STATE_PAIR,
  STRING_NUMBER_HASH,
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { classNames } from '@chiroup/core/functions/classNames';
import { clog } from '@chiroup/core/functions/clog';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { CodeSets } from '@chiroup/core/types/BillingCode.type';
import { ClinicCaseType } from '@chiroup/core/types/ClinicCaseType.type';
import {
  Insurance,
  DisciplineInsuranceBenefit,
} from '@chiroup/core/types/PatientInsurance.type';
import {
  PatientTransactionItemDiagnosisType,
  TransactionAppendItemsType,
  PatientTransactionItemType,
  PatientTransaction,
  TransactionItemTypeEnum,
  TransactionItemSubtypeEnum,
  isaServiceItem,
} from '@chiroup/core/types/PatientTransaction.type';
import { VisitPlanType, Visit } from '@chiroup/core/types/PatientVisit.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { InstanceComponents } from '../../../billing/transactions/ConsolidatedTransactionModal';

type SetOrdHashType = { [key: string]: number };
type _restActiveType = { loadPlan: boolean; save: boolean; lock: boolean };

export type InsuranceError = {
  index: number;
  field: keyof AppointmentInsuranceType;
  message: string;
};
export type DndServiceDiagnosisType = {
  data: PatientTransactionItemDiagnosisType;
  services?: string[];
  ord?: number;
};

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const generateSetOrdHash = (
  listofDiagnoses: DndServiceDiagnosisType[],
): SetOrdHashType => {
  return listofDiagnoses.reduce<SetOrdHashType>((acc, current, idx) => {
    acc[current.data.code] = idx === 0 ? 100 : (idx + 1) * 100;
    return acc;
  }, {});
};

const blockofIssues = (issues: string[]) => {
  return (
    <ul>
      {(issues || []).map((issue: string, i: number) => {
        return (
          <li
            className="bg-gray-100 rounded-md my-1 px-2 py-1"
            key={`boi-${i}`}
          >
            {issue}
          </li>
        );
      })}
    </ul>
  );
};

export type ClickRemoteControlType = {
  button: 'save' | 'lock' | 'refresh';
  callback?:
    | ((e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => void)
    | undefined;
  data?: any;
  e: React.MouseEvent<HTMLButtonElement> | null;
  disableToast?: boolean;
  trace?: boolean;
};
const priority: { [key: string]: number } = {
  '99202': 5500,
  '99203': 5400,
  '99204': 5300,
  '99212': 5200,
  '99213': 5100,
  '99214': 5000,
  '99211': 4900,
  '98942': 4800,
  '98941': 4700,
  '97813': 4600,
  '97810': 4500,
  '20561': 4400,
  '98940': 4300,
  '98943': 4200,
  '97140': 4100,
  '97124': 4000,
  '20560': 3900,
  '97112': 3800,
  '97110': 3700,
  '97530': 3600,
  modalities: 2000,
  imagingAndTests: 1000,
  supplementsAndSupplies: 100,
};

type Props = {
  absoluteServiceItems?: PatientTransactionItemType[] | null;
  activeCaseSubtype?: boolean;
  activeTabName?: string | null;
  afterImport?: () => void;
  assessmentCodes?: { code: string; codeSet: CodeSets; description: string }[];
  caseType?: number | null;
  caseTypes?: Partial<ClinicCaseType>[];
  clickRemoteControl?: ClickRemoteControlType | null;
  clinicId: number;
  consolidatedView?: boolean | null | undefined;
  disciplineId?: number;
  findThePackageCredit?: (params: {
    packageId?: number | null;
    items: PatientTransactionItemType[];
    insurances?: Partial<AppointmentInsuranceType>[];
  }) => any;
  hasPackage?: boolean;
  horizontal?: boolean;
  importButtonText?: string;
  importDiagnosesAlways?: boolean | null | undefined;
  importOnlyMode?: boolean;
  instanceComponents?: InstanceComponents | null;
  lockButtonText?: string;
  noCodesMessage?: string;
  omitDiagnosisHeader?: boolean;
  omitTitle?: boolean;
  onChangeConsolidatedTransaction?: (
    itemId: number,
  ) => (property: string | number) => (value: any) => void;
  parentId?: string | null | undefined;
  parentIsa?: string;
  patientId: string;
  patientTransaction?: PatientTransaction | null;
  persistDiagnosesList?: boolean | null | undefined;
  plan?: VisitPlanType;
  providerId?: string;
  readonly?: boolean;
  saveButtonText?: string;
  saveCallback?: (args: TransactionAppendItemsType) => void;
  saveSuccessMessage?: string;
  saveVisit?: (visit: Visit, canUpdateSigned?: boolean) => Promise<Visit>;
  setConsolidatedData?: Dispatch<SetStateAction<PatientTransaction[] | null>>;
  setDiagnosesOrder?: Dispatch<SetStateAction<string[]>>;
  setPatientTransaction?: React.Dispatch<
    React.SetStateAction<PatientTransaction | null>
  >;
  setVisitForm?: Dispatch<SetStateAction<Visit>>;
  transactionLineItemComponent?: React.ReactNode;
  triggerAppendItems?: TransactionAppendItemsType | null;
  unlockButtonText?: string;
  visitForm?: Visit;
  codeEncounterDiagnosisServicesListProps?: STRING_ANY_HASH;
};

const CodeEncounterDiagnosisServices: React.FC<Props> = ({
  absoluteServiceItems = null,
  activeCaseSubtype,
  activeTabName = null,
  afterImport,
  assessmentCodes,
  caseType,
  caseTypes,
  clickRemoteControl,
  clinicId,
  codeEncounterDiagnosisServicesListProps = {},
  consolidatedView,
  disciplineId,
  findThePackageCredit,
  hasPackage = false,
  horizontal = false,
  importButtonText,
  importDiagnosesAlways = false,
  importOnlyMode,
  instanceComponents = null,
  lockButtonText = 'Lock',
  noCodesMessage,
  omitDiagnosisHeader = false,
  omitTitle = false,
  onChangeConsolidatedTransaction,
  parentId,
  parentIsa,
  patientId,
  patientTransaction,
  persistDiagnosesList = false,
  plan,
  providerId,
  readonly = false,
  saveButtonText = 'Save',
  saveCallback,
  saveSuccessMessage,
  saveVisit,
  setConsolidatedData,
  setDiagnosesOrder,
  setPatientTransaction,
  setVisitForm,
  transactionLineItemComponent = null,
  triggerAppendItems = null,
  unlockButtonText = 'Unlock',
  visitForm,
}) => {
  const patientTransactionState = {
    value: patientTransaction,
    set: setPatientTransaction,
  } as USE_STATE_PAIR<PatientTransaction>;

  const { data: policies, isFetching: isFetchingPolicies } = useInsuranceList({
    patientId: patientId,
    providerId: patientTransaction?.provider?.id || '',
    billingKey: patientTransaction?.billingKey || '',
  });
  const dndId = useId();
  const { createToast } = useContext(ToastContext);
  const { hasAccess, hasRole } = useContext(MeContext),
    [isFetchingService, setIsFetchingService] = useState(false);
  const [listofDiagnoses, setListofDiagnoses] = useState<
    DndServiceDiagnosisType[]
  >([]);

  const [insuranceErrors, setInsuranceErrors] = useState<InsuranceError[]>([]);
  const {
    // snapshot,
    listofServices,
    setListofServices,
    insurances,
    payors,
    locked,
    allowBillingPriorityChange,
    courtesyBilling,
    superBill,
    setSnapshot,
    setInsurances,
    setPayors,
    setLocked,
    setCourtesyBilling,
    setSuperBill,
  } = useContext(TransactionContext);
  // return <>All good in the neighborhood</>;

  const buildInsuranceErrors = (ins: Partial<AppointmentInsuranceType>[]) => {
    interface InsuranceInfo {
      count: number;
      names: string[];
    }

    type BillingPriorityMap = Record<number, InsuranceInfo>;

    const billingPriorityMap: BillingPriorityMap = {};
    let hasMissingPriority = false;

    ins.forEach((insurance, idx) => {
      const { billingPriority, insuranceName } = insurance;

      if (!billingPriority) {
        hasMissingPriority = true;
        setInsuranceErrors((prev) => [
          ...ChiroUpJSON.clone(prev),
          {
            index: idx,
            field: 'billingPriority',
            message: 'Billing priority is required.',
          },
        ]);
      } else {
        billingPriorityMap[billingPriority] = billingPriorityMap[
          billingPriority
        ] || { count: 0, names: [] };
        billingPriorityMap[billingPriority].count++;
        billingPriorityMap[billingPriority].names.push(insuranceName as string);
      }
    });

    const duplicates = Object.entries(billingPriorityMap).filter(
      ([_, { count }]) => count > 1,
    );

    if (duplicates.length > 0) {
      setInsuranceErrors((prev) => {
        const newobj = ChiroUpJSON.clone(prev).filter(
          (i: InsuranceError) => i.field !== 'billingPriority',
        );
        duplicates.forEach(([priority, { names }]) => {
          newobj.push({
            field: 'billingPriority',
            message: `Duplicate billing priority ${priority} for insurances: ${names.join(
              ', ',
            )}.`,
          });
        });
        return newobj;
      });
    } else if (!hasMissingPriority) {
      setInsuranceErrors([]);
    }
  };

  const handleBillingProfileChange = async (e: number | null) => {
    if (parentIsa === 'transaction' || parentIsa === 'appointment') {
      setPatientTransaction?.((prev) => {
        const newobj = ChiroUpJSON.clone(prev);
        newobj.billingProfileId = e;
        return newobj;
      });
    }
  };

  const setCourtesyBillingMitm = useCallback(
    (cb: boolean) => {
      setCourtesyBilling(cb);
      if (typeof setPatientTransaction === 'function') {
        setPatientTransaction((prev) => {
          const newobj = ChiroUpJSON.clone(prev);
          newobj.courtesyBilling = cb;
          return newobj;
        });
      }
    },
    [setCourtesyBilling, setPatientTransaction],
  );

  const setSuperBillMitm = useCallback(
    (sb: boolean) => {
      setSuperBill(sb);
      if (typeof setPatientTransaction === 'function') {
        setPatientTransaction((prev) => {
          const newobj = ChiroUpJSON.clone(prev);
          newobj.superBill = sb;
          return newobj;
        });
      }
    },
    [setPatientTransaction, setSuperBill],
  );

  const setInsurancesMitm = useCallback(
    (ins: Partial<AppointmentInsuranceType>[]) => {
      console.log({ CodeEncounterDiagnosisServices_setInsurancesMitm: ins });
      let cb = ins.some((i) => i.inNetwork) ? false : courtesyBilling;
      const sb = superBill || false;
      if (ins?.length && payors?.length) {
        const prevPrimary = insurances.find((i) => i.billingPriority === 1);
        const newPrimary = ins.find((i) => i.billingPriority === 1);

        const primaryChanged =
          prevPrimary?.insuranceID !== newPrimary?.insuranceID;
        // This to meet the requirement to set courtesy billing to automatically set the courtesy billing to true if the new primary insurance has no signature on file and does not accept assignment
        if (primaryChanged) {
          if (!newPrimary) {
            cb = false;
          }
        }
      }

      let updatedServices: PatientTransactionItemType[] = [],
        actions: MagicActionType[] = [];
      if (typeof setPatientTransaction === 'function') {
        let updatedTransaction: PatientTransaction | null = null;

        setPatientTransaction((prev) => {
          const newobj = ChiroUpJSON.clone(prev || {});
          newobj.insurances = ins;
          const res = businessRulesService.transactionMagic({
            transaction: newobj,
            event: TransactionMagicEvent.onChangeInsurance,
          });
          const wasPackagePreviouslyApplied = res?.transaction?.items?.find(
            (item: PatientTransactionItemType) =>
              item.subtype === TransactionItemSubtypeEnum.Adjustment &&
              item?.packageId,
          );
          if (wasPackagePreviouslyApplied) {
            let newItems = [];
            //remove the package credit
            newItems = JSON.parse(
              JSON.stringify(
                res?.transaction?.items.filter(
                  (item) =>
                    !(
                      item.subtype === TransactionItemSubtypeEnum.Adjustment &&
                      item?.packageId
                    ),
                ),
              ),
            );

            const packageCredit = findThePackageCredit?.({
              packageId: wasPackagePreviouslyApplied?.packageId,
              items: newItems,
              insurances: res?.transaction?.insurances || [],
            });
            if (res?.transaction) {
              res.transaction.items = newItems.concat(packageCredit);
            }
          }
          // I know, side-effects, bad bad bad, but this is a tangled mess. Needs must.
          if (res?.transaction && res?.touched) {
            updatedServices = res?.transaction?.services ?? [];
          }
          if (res?.actions) {
            actions = res.actions;
          }
          ins = res?.transaction?.insurances ?? ins;
          return (updatedTransaction = res?.transaction ?? newobj);
        });

        if (updatedTransaction && setConsolidatedData) {
          setTimeout(() => {
            setConsolidatedData((prev) => {
              if (!prev) return prev;
              return prev.map((item) => {
                if (item.billingKey === updatedTransaction?.billingKey) {
                  return updatedTransaction;
                }
                return item;
              });
            });
          }, 0);
        }
      }

      if (updatedServices.length) {
        setListofServices(updatedServices);
      }

      if (actions?.length) {
        createToast({
          title: 'ChiroUp Bot',
          description: (
            <div>
              {actions.map((action, idx) =>
                action.message ? <p key={idx}>{action.message}</p> : null,
              )}
            </div>
          ),
          type: ToastTypes.Info,
          duration: 5000,
        });
      }

      setInsurances(ins);
      buildInsuranceErrors(ins);
      setCourtesyBillingMitm(cb);
      setSuperBillMitm(sb);
    },
    [
      courtesyBilling,
      createToast,
      findThePackageCredit,
      insurances,
      payors?.length,
      setConsolidatedData,
      setCourtesyBillingMitm,
      setInsurances,
      setListofServices,
      setPatientTransaction,
      setSuperBillMitm,
      superBill,
    ],
  );

  /**
   * A way to detect if we need to use a POST or a PUT and to detect
   * dirty.
   */
  const [isRestActive, setIsRestActive] = useState<_restActiveType>({
    loadPlan: false,
    save: false,
    lock: false,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  useEffect(() => {
    if (!setDiagnosesOrder) return;
    setDiagnosesOrder(() => {
      return listofDiagnoses.map((diag) => diag.data.code);
    });
  }, [listofDiagnoses, setDiagnosesOrder]);

  useEffect(() => {
    const services = triggerAppendItems?.services || [];
    const insurances = triggerAppendItems?.insurances || [];
    const cb = triggerAppendItems?.courtesyBilling || false;
    const sb = triggerAppendItems?.superBill || false;
    /**
     * This may appear more complicated than it needs to be but this
     * tries to keep the same service from appearing more than once.
     * It should detect if a service is already there and simply
     * increment the count.
     */
    if (!services || !Array.isArray(services) || services.length === 0) return;
    const triggerItemsByCode = services.reduce(
      (a: any, c: PatientTransactionItemType) => {
        if (!c.code) return a;
        if (!a[c.code]) {
          a[c.code] = c.units || 1;
          return a;
        }
        a[c.code] = a[c.code] + 1;
        return a;
      },
      {},
    );

    const newlist = JSON.parse(JSON.stringify(listofServices || []));
    const newListByCode = newlist.reduce((a: any, c: any) => {
      if (!c.code) return a;
      a[c.code] = c;
      return a;
    }, {});

    const addTheseServices = services.filter(
      (item) => !item.code || !triggerItemsByCode[item.code],
    );

    Object.keys(triggerItemsByCode).forEach((code) => {
      if (!newListByCode[code]) return;
      newListByCode[code].units =
        newListByCode[code].units + triggerItemsByCode[code];
    });

    addTheseServices.forEach((item) => {
      newlist.push(item);
    });
    // newlist.concat(addTheseServices); // CONCAT is not destructive.

    setListofServices(newlist);
    setInsurancesMitm(insurances);
    setCourtesyBillingMitm(cb);
    setSuperBillMitm(sb);
    if (typeof setPatientTransaction === 'function' && patientTransaction) {
      const newo = ChiroUpJSON.clone(patientTransaction);
      newo.services = newlist;
      newo.insurances = insurances;
      newo.courtesyBilling = cb;
      newo.superBill = sb;
      setPatientTransaction(newo);
    }
  }, [triggerAppendItems]);

  useEffect(() => {
    if (!absoluteServiceItems || !Array.isArray(absoluteServiceItems)) return;
    setListofServices(absoluteServiceItems);
  }, [absoluteServiceItems]);

  const checkBusinessRules = () => {
    const issues: string[] = [],
      maxDiagnoses = 12,
      countofServices = listofServices.length || 0;
    let hasDiagCount = 0,
      hasUnitsCount = 0,
      hasBilledAmount = 0;
    listofServices.forEach((service: PatientTransactionItemType) => {
      hasDiagCount += (service?.diagnoses || []).length > 0 ? 1 : 0;
      hasUnitsCount += (service?.units || 0) > 0 ? 1 : 0;
      hasBilledAmount += ((service?.amount as number) || 0) > 0 ? 1 : 0;
    });

    if (maxDiagnoses < listofDiagnoses.length) {
      issues.push(`The maximum number of diagnoses is ${maxDiagnoses}.`);
    }

    if (hasDiagCount !== countofServices) {
      issues.push(`Each service needs at least one diagnosis.`);
    }

    if (hasUnitsCount !== countofServices) {
      issues.push(
        `The units for each service must be a number between 1 and 100.`,
      );
    }

    if (hasBilledAmount !== countofServices) {
      issues.push(`Billed amounts are needed on each service.`);
    }

    return issues;
  };

  useEffect(() => {
    const diags: { [key: string]: DndServiceDiagnosisType } = {};
    const listof: DndServiceDiagnosisType[] = [];
    listofServices?.forEach((service: PatientTransactionItemType) => {
      if (!service || !service.diagnoses || service.diagnoses.length === 0) {
        return;
      }
      service.diagnoses?.forEach((diag) => {
        if (!diags[diag.code]) {
          diags[diag.code] = {
            data: diag,
            services: [],
            ord: diag.ord || 9999,
          };
          listof.push(diags[diag.code]);
        }
        diags?.[diag.code]?.services?.push(`${service.code}`);
      });
    });

    if (persistDiagnosesList) {
      setListofDiagnoses((prev) => {
        prev?.forEach((diag, idx) => {
          if (!diags[diag.data.code]) {
            diags[diag.data.code] = {
              data: diag.data,
              services: [],
              ord: diag.ord ?? idx + 1,
            };
            listof.push({
              ...diag,
              ord: diag.data.ord ?? (idx + 1) * 10,
              services: [],
            });
          }
        });
        if (assessmentCodes?.length && importDiagnosesAlways) {
          // Try to push the assessment codes to the end.
          assessmentCodes?.forEach((diag, idx) => {
            if (!diags[diag.code]) {
              listof.push({
                data: {
                  ...diag,
                  id: -1,
                  itemId: -1,
                  ord: 1000 + (prev.length + idx + 1),
                },
                services: [],
                ord: 1000 + (listofDiagnoses?.length + 1 + idx) * 100,
              });
              diags[diag.code] = listof[listof.length - 1];
            }
          });
        }
        return listof.sort((a, b) => (a.ord || 0) - (b.ord || 0));
      });
    } else {
      setListofDiagnoses(listof.sort((a, b) => (a.ord || 0) - (b.ord || 0)));
    }
  }, [listofServices, assessmentCodes]);

  /**
   * There are timing issues with updating the list of services and
   * then immediately calling the save. So, this was updated to allow
   * passing a transformed list of services.
   *
   * Note: This _is not_ the save that is called when a transaction and
   * a method to set the transaction is passed in. This one just handles
   * the services and the insurances. After the save of the transaction
   * happens, the remote control tells this component to refresh.
   *
   * @param param0
   * @returns
   */
  const saveButtonClicked = useCallback(
    async ({
      e = null,
      disableToast,
      services = null,
      insurancesToUse = null,
    }: {
      e: React.MouseEvent<HTMLButtonElement> | null;
      disableToast?: boolean;
      services?: PatientTransactionItemType[] | null;
      insurancesToUse?: AppointmentInsuranceType[] | null;
    }) => {
      if (e && e.preventDefault) e.preventDefault();
      if (!services) services = JSON.parse(JSON.stringify(listofServices));
      if (!insurancesToUse) {
        insurancesToUse = JSON.parse(JSON.stringify(insurances));
      }

      const newErrors: InsuranceError[] = [];
      let hasInsuranceErrors = false;

      insurancesToUse?.forEach((insurance, index) => {
        if (!insurance.billingPriority) {
          newErrors.push({
            index,
            field: 'billingPriority',
            message: 'Billing priority is required.',
          });
        }
      });
      setInsuranceErrors((prev) => {
        const filteredOldErrors = prev.filter(
          (error) => error.field !== 'billingPriority',
        );
        const mergedErrors = [...filteredOldErrors, ...newErrors];
        if (mergedErrors?.length) {
          hasInsuranceErrors = true;
        }

        return mergedErrors;
      });

      if (hasInsuranceErrors) {
        return;
      }

      // [2024-06-20.1329 by Brian] Took out check/error for changes/dirty UI.

      /**
       * This keeps the diagnoses in the order of how the user placed them
       * in the UI.
       */
      const setOrdHash = generateSetOrdHash(listofDiagnoses);
      services?.forEach((service) => {
        if (!service) return;
        if (!service.diagnoses || service.diagnoses.length === 0) return;
        service.diagnoses.forEach((diag, idx) => {
          diag.ord = setOrdHash[diag.code] || (idx + 1) * 10;
        });
      });

      try {
        setIsRestActive((p) => ({ ...p, save: true }));

        const changed: {
          services: PatientTransactionItemType[];
          insurances: AppointmentInsuranceType[];
        } = await billingService.encounterServices.set({
          clinicId,
          id: parentId as string,
          services: services || [],
          insurances: insurancesToUse || [],
          transaction: parentIsa === 'transaction' ? patientTransaction : null,
          courtesyBilling,
          superBill,
          billingProfileId: patientTransaction?.billingProfileId,
        });
        setListofServices(changed.services);
        setInsurancesMitm(changed.insurances);
        const json = JSON.stringify(changed);
        setSnapshot(json);
        setLocked(changed?.services?.[0]?.locked || false);

        if (!disableToast) {
          createToast({
            title: 'Success!',
            description: (
              <div>
                {saveSuccessMessage
                  ? saveSuccessMessage
                  : `${patientTransaction ? 'Transaction' : 'Services'} 
                      successfully ${json === '[]' ? 'removed' : 'saved'}.`}
              </div>
            ),
            type: ToastTypes.Success,
            duration: 5000,
          });
        }
      } catch (e: any) {
        console.error({ errorFromTheSave: e });
        createToast({
          title: 'Error!',
          description: e?.response?.data?.message ? (
            e?.response?.data?.message
          ) : e?.response?.data?.body?.message ? (
            e?.response?.data?.body?.message
          ) : (
            <>Services could not be saved.</>
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
      setIsRestActive((p) => ({ ...p, save: false }));
      if (typeof saveCallback === 'function') {
        saveCallback({
          services: listofServices,
          insurances: insurancesToUse || [],
          courtesyBilling,
          superBill,
        });
      }
      if (!visitForm?.signed) {
        await saveVisit?.({ ...visitForm } as Visit);
      }
    },
    [
      clinicId,
      courtesyBilling,
      createToast,
      insurances,
      listofDiagnoses,
      listofServices,
      parentId,
      parentIsa,
      patientTransaction,
      saveCallback,
      saveSuccessMessage,
      saveVisit,
      setInsurancesMitm,
      setListofServices,
      setLocked,
      setSnapshot,
      superBill,
      visitForm,
    ],
  );

  const loadFromPlanAndAssessment = useCallback(
    async (e: any) => {
      /**
       * All these take clinicId and an array of ids.
       */
      const getMethods: { [key: string]: any } = {
        supplementsAndSupplies: (ids: number[]) => {
          if (!ids || ids.length === 0) return Promise.resolve([]);
          return supplementService.getMany(clinicId, ids);
        },
        imagingAndTests: (ids: number[]) => {
          if (!ids || ids.length === 0) return Promise.resolve([]);
          return testService.getMany(clinicId, ids);
        },
        modalities: (ids: number[]) => {
          if (!ids || ids.length === 0) return Promise.resolve([]);
          return modalityService.getMany(clinicId, ids);
        },
        codes: (ids: string[]) => {
          if (!ids || ids.length === 0) return Promise.resolve({ data: [] });
          return databaseService.getCodes({
            clinicId: clinicId,
            codes: ids,
            payors: true,
          });
        },
      };

      e.preventDefault();
      const referencedServices: { [key: string]: PatientTransactionItemType } =
        {};
      const loadIds: { [key: string]: string[] } = {};
      const unitsByCode: { [key: string]: number } = {};

      /**
       * These need REST calls to go get the services that were associated
       * with them.
       */

      ['supplementsAndSupplies', 'imagingAndTests', 'modalities'].forEach(
        (key) => {
          Object.keys(plan?.[key] || {}).forEach((i) => {
            const units = plan?.[key]?.[i]?.units || 0;
            if (units > 0) {
              if (!loadIds[key]) {
                loadIds[key] = [];
              }
              loadIds[key].push('' + parseInt(i));
              unitsByCode[i] = units;
            }
          });
        },
      );

      const proms: Promise<any>[] = [];
      const categoryCodeHash: { [key: string]: string } = {};
      /**
       * Earlier, we saved unitsByCodes by the row ID, now we need to have
       * it by the actually code string. So, this _should_ look for reference
       * codes on anything that goes and gets records and load them into
       * unitsByCode as well for later.
       */
      Object.keys(loadIds).forEach((key) => {
        if (getMethods[key] && loadIds[key] && loadIds[key].length > 0) {
          proms.push(
            getMethods[key](loadIds[key]).then((r: any) => {
              if (r && Array.isArray(r)) {
                r.forEach((s: any) => {
                  const id = (s as any).ID || (s as any).id || 0,
                    units = unitsByCode[id] || 1,
                    codes = (s as any).referenceCodes || [];
                  if (codes.length > 0) {
                    codes.forEach((code: any) => {
                      categoryCodeHash[code.code] = key;
                      unitsByCode[code.code] = units;
                    });
                  }
                });
              } else if (r) {
                const id = (r as any).ID || (r as any).id || 0,
                  units = unitsByCode[id] || 1,
                  codes = (r as any).referenceCodes || [];
                if (codes.length > 0) {
                  codes.forEach((code: any) => {
                    categoryCodeHash[code.code] = key;
                    unitsByCode[code.code] = units;
                  });
                }
              }
              return r;
            }),
          );
        }
      });

      setIsRestActive((p) => ({ ...p, loadPlan: true }));
      const responses = await Promise.all(proms);
      if (responses && Array.isArray(responses)) {
        responses.forEach((r: any) => {
          if (r.data && Array.isArray(r.data)) {
            r.data.forEach((s: any) => {
              referencedServices[s.code] = s;
            });
          } else if (r.referenceCodes && Array.isArray(r.referenceCodes)) {
            r.referenceCodes.forEach((rc: any) => {
              referencedServices[rc.code] = rc;
            });
          } else if (r && Array.isArray(r)) {
            r.forEach((s: any) => {
              if (s.referenceCodes && Array.isArray(s.referenceCodes)) {
                s.referenceCodes.forEach((rc: any) => {
                  referencedServices[rc.code] = rc;
                });
              }
            });
          }
        });
      }

      let key = 'evaluationAndManagement';
      let temp = plan?.[key] || {};

      Object.keys(temp).forEach((i) => {
        const $ptr = temp[i] || {};
        const units = $ptr.units || 0;
        if (units > 0) {
          if ($ptr.unitServices) {
            Object.keys($ptr.unitServices).forEach((k) => {
              const $ptr2 = $ptr.unitServices[k] || [];
              $ptr2.forEach((code: string) => {
                if (!loadIds.codes) {
                  loadIds.codes = [];
                }
                loadIds.codes.push(code);
                /**
                 * This is where things that have multiple services for each additional
                 * unit are handled. This ONLY handles where the first is one value and
                 * each additional is another.
                 */
                if (k === '__') {
                  unitsByCode[code] = units - 1;
                } else {
                  unitsByCode[code] = 1;
                }
              });
            });
          }
          if ($ptr.services) {
            $ptr.services.forEach((code: string) => {
              if (!loadIds.codes) {
                loadIds.codes = [];
              }
              loadIds.codes.push(code);
              unitsByCode[code] = units || 1;
            });
          }
        }
      });

      key = 'treatments';
      temp = plan?.[key] || {};

      Object.keys(temp).forEach((i) => {
        const $ptr = temp[i] || {};
        Object.keys($ptr).forEach((j) => {
          const $ptr2 = $ptr[j] || {},
            units = $ptr2.units || 0;
          if (units > 0) {
            if ($ptr2.unitServices) {
              const usedServiceUnits: STRING_NUMBER_HASH = {};
              for (let i = 1; i <= units; i++) {
                const key = `${i}`,
                  service = $ptr2.unitServices[key] ?? $ptr2.unitServices.__;
                if (!usedServiceUnits[service]) {
                  usedServiceUnits[service] = 0;
                }
                usedServiceUnits[service]++;
              }

              const services = Object.keys(usedServiceUnits);
              services.forEach((code: string) => {
                if (!loadIds.codes) {
                  loadIds.codes = [];
                }
                loadIds.codes.push(code);
                unitsByCode[code] = usedServiceUnits[code];
              });
            } else if ($ptr2.services) {
              $ptr2.services.forEach((code: string) => {
                if (!loadIds.codes) {
                  loadIds.codes = [];
                }
                loadIds.codes.push(code);
                unitsByCode[code] = units || 1;
              });
            }
          }
        });
      });

      key = 'rehab';
      temp = plan?.[key] || {};

      Object.keys(temp).forEach((i) => {
        const $ptr = temp[i] || {},
          units = $ptr.units || 0,
          services = $ptr.services || [];
        if (units > 0 && services.length > 0) {
          services.forEach((code: string) => {
            if (!loadIds.codes) {
              loadIds.codes = [];
            }
            loadIds.codes.push(code);
            unitsByCode[code] = units || 1;
          });
        }
      });

      const allUsedServiceCodes: string[] = [
        ...(loadIds.codes || []),
        ...Object.keys(referencedServices || {}),
      ];

      let completeListofServices = [];
      try {
        const { data } = (await getMethods.codes(allUsedServiceCodes)) ?? {
          data: [],
        };
        completeListofServices = data;

        if (data?.payors?.length) {
          setPayors((prev: any) => [
            ...ChiroUpJSON.clone(prev),
            ...data.payors,
          ]);
        }
      } catch (e) {
        console.error({ e });
        createToast({
          title: 'Fetch Error!',
          description: <>The network request failed.</>,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }

      /**
       * Not sure it was a good choice, but the service can return an array
       * if there are multiple services, otherwise just a singleton. Need
       * to detect that.
       */

      const sorted = Array.isArray(completeListofServices)
        ? (completeListofServices || [])
            .map((c: any, index: number) => {
              return {
                ...c,
                units: unitsByCode[c.code],
                diagnosis: c.diagnosis || [],
                type: TransactionItemTypeEnum.Debit,
                subtype: TransactionItemSubtypeEnum.Service,
                weight:
                  priority?.[c.code] ||
                  priority?.[categoryCodeHash[c.code]] + index ||
                  0,
              };
            })
            .sort((a, b) => (b.weight || 0) - (a.weight || 0))
        : [
            {
              ...completeListofServices,
              type: TransactionItemTypeEnum.Debit,
              subtype: TransactionItemSubtypeEnum.Service,
              units:
                completeListofServices && completeListofServices.code
                  ? unitsByCode[completeListofServices.code]
                  : 1,
            },
          ];

      sorted?.forEach((service) => {
        const defaultModifiers = [
          service.modifier1,
          service.modifier2,
          service.modifier3,
          service.modifier4,
        ].filter((m) => !!m);

        const newModifiers = insurances?.reduce((arr, insurance) => {
          if (arr.length >= 4) return arr;

          const payorInfo = service.payors.find(
            (payor: any) => payor.payorIntPk === insurance.payorID,
          );

          if (!payorInfo) return arr;

          if (payorInfo.modifier1) {
            const modifierOneExists = arr.includes(payorInfo.modifier1);
            if (!modifierOneExists) arr.push(payorInfo.modifier1);
          }

          if (payorInfo.modifier2) {
            const modifierTwoExists = arr.includes(payorInfo.modifier2);
            if (!modifierTwoExists) arr.push(payorInfo.modifier2);
          }

          if (payorInfo.modifier3) {
            const modifierThreeExists = arr.includes(payorInfo.modifier3);
            if (!modifierThreeExists) arr.push(payorInfo.modifier3);
          }

          if (payorInfo.modifier4) {
            const modifierFourExists = arr.includes(payorInfo.modifier4);
            if (!modifierFourExists) arr.push(payorInfo.modifier4);
          }
          // Limit to four modifiers
          if (arr.length > 4) {
            arr = arr.slice(0, 4);
          }

          return arr;
        }, defaultModifiers || []);

        newModifiers?.forEach((modifier, i) => {
          service[`modifier${i + 1}`] = modifier;
        });
      });

      const diags: { [key: string]: DndServiceDiagnosisType } = {};
      const listof: DndServiceDiagnosisType[] = [];

      const medicareInsurance = insurances?.some(
        (i: any) =>
          (i?.insuranceProgram ?? '').indexOf('mcare') === 0 ||
          (i?.insuranceProgram ?? '').indexOf('mcaid') === 0 ||
          (i?.insuranceProgram ?? '').indexOf('hmomedicare') === 0,
      );
      //this is a hack, we should not need to map and add the modifier here,
      //but I couldn't get the transactionMagic function to work correctly when loading from plan.
      const finalServices = sorted
        .filter((s) => !!s && !!s.code)
        .map((service: PatientTransactionItemType) => {
          if (
            service?.code &&
            applicableCodes?.[service?.code] &&
            medicareInsurance &&
            activeCaseSubtype
          ) {
            return {
              ...service,
              modifier1: 'AT',
            };
          }
          return service;
        });

      setListofServices(finalServices);
      // console.log({ assessmentCodes });
      if (assessmentCodes?.length) {
        assessmentCodes?.forEach((diag, idx) => {
          if (!diags[diag.code]) {
            diags[diag.code] = {
              data: {
                ...diag,
                id: -1,
                itemId: -1,
                ord: 1000 + 1 + idx,
              },
              services: [],
              ord: 1000 + (listofDiagnoses?.length + 1 + idx) * 100,
            };
            listof.push(diags[diag.code]);
          }
        });

        if (persistDiagnosesList) {
          const listOfDiagnosesNew = listofDiagnoses || [];
          listOfDiagnosesNew.forEach((diag, idx) => {
            if (!diags[diag.data.code]) {
              diags[diag.data.code] = diag;
              diag.ord = diag?.services?.length ? (idx + 1) * 10 : 1001 + idx; // try and sort unused diags at the end
              listof.push(diag);
            }
          });
          setListofDiagnoses(listof);

          setVisitForm?.((prev) => ({
            ...prev,
            importDiagnosesAlways: true,
          }));
        } else {
          setListofDiagnoses(listof);
        }
      }
      // console.log({ diags, listof });
      const newInsurances = JSON.parse(JSON.stringify(insurances));
      for (const insurance of newInsurances) {
        for (const service of sorted) {
          if (!insurance.serviceAllowedAmounts?.[service.code]) {
            const servicePayor = service.payors.find(
              (payor: any) => payor.payorIntPk === insurance.payorID,
            );
            insurance.serviceAllowedAmounts = {
              ...(insurance.serviceAllowedAmounts || {}),
              [String(service.code)]: {
                allowedAmount:
                  servicePayor?.allowedAmount || service?.billedAmount || 0,
                payorID: servicePayor?.payorIntPk,
              },
            };
          }
        }
      }

      setInsurancesMitm(newInsurances);

      setIsRestActive((p) => ({ ...p, loadPlan: false }));

      return {
        services: finalServices,
        insurances: newInsurances,
      };
    },
    [
      assessmentCodes,
      clinicId,
      createToast,
      insurances,
      listofDiagnoses,
      persistDiagnosesList,
      plan,
      setInsurancesMitm,
      setListofServices,
      setPayors,
      setVisitForm,
      activeCaseSubtype,
    ],
  );

  const lockButtonClicked = async (
    e: React.MouseEvent<HTMLButtonElement> | null,
  ) => {
    console.log(
      [
        'You should not be seeing this with the advent of super appointments.',
        'If you are seeing this, we need to find out why. If we NEVER see this -- I know --',
        'then we can remove this code AND the endpoint it uses.',
      ].join(' '),
    );
    if (e && e.preventDefault) e.preventDefault();
    return;
    const issues = checkBusinessRules();

    if (!locked) {
      if (listofDiagnoses.length === 0) {
        issues.push(`At least one diagnosis is required.`);
      }
      if (listofServices.length === 0) {
        issues.push(`At least one service is required.`);
      }

      if (issues.length > 0) {
        createToast({
          title: 'Business-rule Exception!',
          description: blockofIssues(issues),
          type: ToastTypes.Info,
          duration: 5000,
        });
        return;
      }
    }

    const clone = ChiroUpJSON.clone(
      listofServices,
    ) as PatientTransactionItemType[];
    clone?.forEach((service) => (service.locked = !locked));
    await saveButtonClicked({ e, services: clone });
    let res = null;
    if (!locked) {
      try {
        res = await billingService.encounterServices.afterLock({
          clinicId,
          billingKey: patientTransaction?.billingKey,
        });
        if (res && res.message) {
          createToast({
            title: 'Tests!',
            description: res,
            type: ToastTypes.Info,
            duration: 5000,
          });
        }
      } catch (e: any) {
        createToast({
          title: 'Tests!',
          description:
            e?.response?.data?.message ?? 'After-lock tests could not run.',
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
    }
  };
  const handleDragOver = (event: DragOverEvent) => {
    if (locked || readonly) return;
    const { active, over } = event;
    if (!over || !active) return;
    const { id: activeId } = active;
    const { id: overId } = over;
    const isDraggingService = active.data.current?.type === 'service';
    const isDraggingDiagnosis = active.data.current?.type === 'diagnosis';
    if (!isDraggingService && !isDraggingDiagnosis) return;

    if (isDraggingService) {
      const activeIndex = listofServices.findIndex(
        (i: any) => i.code === activeId,
      );
      const overIndex = listofServices.findIndex((i: any) => i.code === overId);
      setListofServices((prev: any) => arrayMove(prev, activeIndex, overIndex));
    } else if (isDraggingDiagnosis) {
      const isOverService = over.data.current?.type === 'service';
      const diagnosesWithoutServices = listofDiagnoses.reduce((a, c) => {
        if (!c.services || c.services.length === 0) {
          a[c.data.code] = c;
        }
        return a;
      }, {} as STRING_ANY_HASH);

      if (!isOverService && !diagnosesWithoutServices[activeId]) {
        const activeIndex = listofDiagnoses.findIndex(
          (i) => i.data.code === activeId,
        );
        const overIndex = listofDiagnoses.findIndex(
          (i) => i.data.code === overId,
        );

        const moved = arrayMove(listofDiagnoses, activeIndex, overIndex),
          weights: STRING_NUMBER_HASH = {};
        moved.forEach((diag: any, idx: number) => {
          if (!diag) return;
          if (!diag.data) return;
          diag.ord = // Unused diags at the end
            diag?.services?.length
              ? (weights[diag?.data?.code] = (idx + 1) * 10)
              : 1001 + idx;
          diag.data.ord = diag.ord;
        });
        setListofDiagnoses(moved);
        setListofServices((prev: any) => {
          const newServices = [...(prev || [])];
          newServices.forEach((service) => {
            if (!service.diagnoses) service.diagnoses = [];
            service.diagnoses = service.diagnoses.sort(
              (a: any, b: any) =>
                (weights[a.code] || 0) - (weights[b.code] || 0),
            );
          });
          return newServices;
        });
      }
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (locked || readonly) {
      createToast({
        title: 'Locked!',
        description: <>Locked services may not be changed.</>,
        type: ToastTypes.Info,
        duration: 5000,
      });
      return;
    }
    const { active, over } = event;

    if (!over || !active) return;
    const isDraggingDiagnosis = active.data.current?.type === 'diagnosis';
    const isOverService = over.data.current?.type === 'service';

    if (isDraggingDiagnosis && typeof setPatientTransaction === 'function') {
      const items = event?.active?.data?.current?.sortable?.items || [];
      const weights = items.reduce((obj: any, i: string, idx: number) => {
        obj[i] = (idx + 1) * 20;
        return obj;
      }, {});

      const diagsByCode: STRING_ANY_HASH = listofDiagnoses.reduce((a, c) => {
        a[c.data.code] = c;
        return a;
      }, {} as STRING_ANY_HASH);

      setListofServices((prev: any) => {
        const newServices = [...(prev || [])];
        newServices.forEach((service) => {
          if (!service.diagnoses) service.diagnoses = [];
          const usedDiags: STRING_BOOLEAN_HASH = {};
          service.diagnoses = service.diagnoses.map((d: any) => {
            const weight = weights[d.code];
            d.ord = weight;
            usedDiags[d.code] = true;
            return d;
          });
          if (service.code === over?.id) {
            if (!usedDiags[active?.id]) {
              const data = diagsByCode[active?.id].data;
              service.diagnoses.push({
                code: data.code,
                codeSet: data.codeSet,
                description: data.description,
                ord: weights[active?.id],
              });
            }
          }
        });
        return newServices;
      });
      setPatientTransaction((prev) => {
        const newobj = ChiroUpJSON.clone(prev || {});
        newobj.items = newobj.items?.map((item: any) => {
          if (
            item.subtype === TransactionItemSubtypeEnum.Service ||
            item.subtype === TransactionItemSubtypeEnum.PatientService
          ) {
            item.diagnoses = item.diagnoses ?? [];
            const usedDiags: STRING_BOOLEAN_HASH = {};
            item.diagnoses = item.diagnoses.map((d: any) => {
              const weight = weights[d.code];
              d.ord = weight;
              usedDiags[d.code] = true;
              return d;
            });
            if (item.code === over?.id) {
              if (!usedDiags[active?.id]) {
                const data = diagsByCode[active?.id].data;
                item.diagnoses.push({
                  code: data.code,
                  codeSet: data.codeSet,
                  description: data.description,
                  ord: weights[active?.id],
                });
              }
            }
          }
          return item;
        });
        return newobj;
      });
      return;
    } else if (
      !isDraggingDiagnosis &&
      typeof setPatientTransaction === 'function'
    ) {
      setPatientTransaction((prev) => {
        const newobj = ChiroUpJSON.clone(prev || {});
        newobj.items =
          newobj.items?.filter((item: any) => !isaServiceItem(item)) || [];
        newobj.items = newobj.items.concat(listofServices);
        return newobj;
      });
      return;
    }
    if (!isDraggingDiagnosis || !isOverService) return;
    const { id: activeId } = active;
    const { id: overId } = over;
    const activeIndex = listofDiagnoses.findIndex(
      (i) => i.data.code === activeId,
    );
    const overIndex = listofServices.findIndex((i: any) => i.code === overId);
    const diagnosisDragging = listofDiagnoses[activeIndex];
    const serviceOver = listofServices[overIndex];
    const diagnosisAlreadyThere = serviceOver.diagnoses?.some(
      (d: any) => d.code === diagnosisDragging.data.code,
    );
    if (diagnosisAlreadyThere) return;

    setListofServices((prev: any) =>
      prev.map((s: any) => {
        if (s.code !== serviceOver.code) return s;
        return {
          ...s,
          diagnoses: (s.diagnoses || []).concat(diagnosisDragging.data),
        };
      }),
    );
  };

  const addDiagnosisToAllServices = (
    _: React.MouseEvent<HTMLDivElement>,
    diagnosis: PatientTransactionItemDiagnosisType,
  ) => {
    // TODO: They are going to want to be able to add the diagnosis across all services in any time slot.
    const newServices = [...(listofServices || [])];
    newServices.forEach((service) => {
      if (!service.diagnoses) service.diagnoses = [];
      if (
        service.diagnoses.length === 0 ||
        (!service.diagnoses?.some((d: any) => d.code === diagnosis.code) &&
          service.diagnoses.length !== 4)
      ) {
        service.diagnoses.push(diagnosis);
      }
    });
    setListofServices(newServices);
    patientTransactionState?.set?.((prev) => {
      const clone = ChiroUpJSON.clone(prev) as PatientTransaction;
      const itemsWithoutServices =
        clone?.items?.filter((item) => !isaServiceItem(item)) || [];
      clone.services = newServices;
      clone.items = [...itemsWithoutServices, ...newServices];

      // Stick an update-the-parent at the end of the event loop.
      // See comment in updateTransactionWhenNeeded for why.
      if (setConsolidatedData) {
        setTimeout(() => {
          setConsolidatedData((prev: PatientTransaction[] | null) => {
            if (!prev) return null;
            const newdata = ChiroUpJSON.clone(prev);
            for (let i = 0; i < newdata.length; i++) {
              if (newdata[i].billingKey === clone.billingKey) {
                newdata[i] = clone;
              }
            }
            return newdata;
          });
        }, 0);
      }
      return clone;
    });
  };

  const handlePolicyChange = useCallback(
    (selectedPolicy: Insurance) => {
      if (!selectedPolicy) return;
      console.log({ selectedPolicy, disciplineId });
      const disciplineBenefits = selectedPolicy.disciplineBenefits?.find(
        (db: DisciplineInsuranceBenefit) => db.disciplineId === disciplineId,
      );
      console.log({ disciplineBenefits });
      const serviceAllowedAmounts = listofServices.reduce(
        (obj: any, service: any) => {
          const serviceCode = service.code;
          if (!serviceCode) return obj;
          let payorForThisPolicyServiceCombo = service.payors
            ? service.payors?.find(
                (payor: any) =>
                  (payor.payorID || payor.payorIntPk) === selectedPolicy.payor,
              )
            : payors?.find(
                (payor: any) =>
                  (payor.payorID || payor.payorIntPk) ===
                    selectedPolicy.payor && payor.code === serviceCode,
              );
          if (!payorForThisPolicyServiceCombo) {
            payorForThisPolicyServiceCombo = payors?.find(
              (payor: any) =>
                payor.payorId === selectedPolicy.payor &&
                payor.code === serviceCode,
            );
          }
          const hasAllowedAmount =
            payorForThisPolicyServiceCombo?.allowedAmount &&
            Number(payorForThisPolicyServiceCombo?.allowedAmount) !== 0;
          obj[serviceCode] = {
            allowedAmount: hasAllowedAmount
              ? payorForThisPolicyServiceCombo?.allowedAmount
              : service.billedAmount || 0,
          };
          return obj;
        },
        {},
      );
      const newi = ChiroUpJSON.clone(insurances);

      const newInsurance = {
        insuranceID: selectedPolicy.id,
        insuranceName: selectedPolicy?.name,
        insuranceProgram: selectedPolicy?.insuranceProgram,
        coPay: disciplineBenefits?.coPay || 0,
        coInsurance: disciplineBenefits?.coInsurance || 0,
        deductible: selectedPolicy.deductible || 0,
        billingPriority: newi.length + 1,
        payorID: selectedPolicy.payor,
        inNetwork: selectedPolicy.inNetwork,
        billable: selectedPolicy.billable,
        serviceAllowedAmounts,
        maxPerVisit: selectedPolicy.maxPerVisit || null,
      };

      if (!selectedPolicy.billable) {
        setInsurancesMitm([newInsurance]);
      } else {
        setInsurancesMitm([...newi, newInsurance]);
      }
    },
    [disciplineId, insurances, listofServices, payors, setInsurancesMitm],
  );

  const handleCourtesyBillingChange = (e: boolean) => {
    setCourtesyBilling(e);
    setCourtesyBillingMitm(e);
    if (e) {
      setSuperBill(false);
      setSuperBillMitm(false);
    }
  };

  const handleSuperBillChange = (e: boolean) => {
    setSuperBill(e);
    setSuperBillMitm(e);
    if (e) {
      setCourtesyBilling(false);
      setCourtesyBillingMitm(false);
    }
  };

  if (
    !hasAccess(FeatureFlags.ehr) ||
    !hasRole([UserRoles.Admin, UserRoles.Provider, UserRoles.Biller])
  ) {
    return (
      <div className="ml-5 text-gray-400">
        <cite>You do not have access to this feature.</cite>
      </div>
    );
  }

  return (
    <DndContext
      id={dndId}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <div
        data-id="code-encounter-diagnosis-services"
        className={classNames(
          'w-full flex gap-4',
          horizontal ? 'flex-row' : 'flex-col',
        )}
      >
        {!consolidatedView && (
          <CodeEncounterDiagnosisServicesList
            omitDiagnosisHeader={omitDiagnosisHeader}
            listofDiagnoses={listofDiagnoses}
            addDiagnosisToAllServices={addDiagnosisToAllServices}
            isBillingStarted={patientTransaction?.isBillingStarted}
            readonly={readonly}
            locked={locked}
            importOnlyMode={importOnlyMode}
          />
        )}
        <CodeEncounterDiagnosisServicesWithDiagnosis
          afterImport={afterImport}
          allowBillingPriorityChange={allowBillingPriorityChange}
          billingProfileId={patientTransaction?.billingProfileId}
          caseType={caseType}
          caseTypes={caseTypes}
          consolidatedView={!!consolidatedView}
          courtesyBilling={courtesyBilling}
          createToast={createToast}
          diagnosticCodeComponent={
            <CodeEncounterDiagnosisServicesList
              omitDiagnosisHeader={omitDiagnosisHeader}
              listofDiagnoses={listofDiagnoses}
              addDiagnosisToAllServices={addDiagnosisToAllServices}
              isBillingStarted={patientTransaction?.isBillingStarted}
              readonly={readonly}
              locked={locked}
              importOnlyMode={importOnlyMode}
              {...codeEncounterDiagnosisServicesListProps}
            />
          }
          disciplineId={disciplineId}
          findThePackageCredit={findThePackageCredit}
          handleBillingProfileChange={handleBillingProfileChange}
          handleCourtesyBillingChange={handleCourtesyBillingChange}
          handlePolicyChange={handlePolicyChange}
          handleSuperBillChange={handleSuperBillChange}
          hasPackage={hasPackage}
          horizontal={horizontal}
          importButtonText={importButtonText}
          importOnlyMode={importOnlyMode}
          insuranceErrors={insuranceErrors}
          isBillingStarted={patientTransaction?.isBillingStarted}
          isFetchingPolicies={isFetchingPolicies}
          isFetchingService={isFetchingService}
          isRestActive={isRestActive}
          listofServices={listofServices}
          loadFromPlanAndAssessment={loadFromPlanAndAssessment}
          lockButtonClicked={lockButtonClicked}
          instanceComponents={instanceComponents}
          lockButtonText={lockButtonText}
          locked={locked}
          noCodesMessage={noCodesMessage}
          omitTitle={omitTitle}
          parentIsa={parentIsa}
          patientId={patientId}
          patientTransactionState={patientTransactionState}
          plan={plan}
          policies={policies?.data || []}
          providerId={providerId || visitForm?.clinician?.id || ''}
          readonly={readonly}
          remoteControl={parentIsa === 'transaction' ? true : false}
          saveButtonClicked={saveButtonClicked}
          saveButtonText={saveButtonText}
          saveCallback={saveCallback}
          services={listofServices}
          setConsolidatedData={setConsolidatedData}
          setInsurances={setInsurancesMitm}
          setIsFetchingService={setIsFetchingService}
          setListofServices={setListofServices}
          setPayors={setPayors}
          superBill={superBill}
          transactionLineItemComponent={transactionLineItemComponent}
          unlockButtonText={unlockButtonText}
        />
        {/* <pre>{ChiroUpJSON.pretty({ listofServices })}</pre> */}
      </div>
    </DndContext>
  );
};

export default CodeEncounterDiagnosisServices;
