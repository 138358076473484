import {
  CCPaymentFields,
  PaymentMethodType,
} from '@chiroup/core/types/PatientBillling.type';
import { MeContext } from '../../../../../contexts/me.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import {
  PatientPayment,
  PaymentType,
} from '@chiroup/core/types/PatientPayment.type';
import { PatientTransaction } from '../../../../../../../../libs/core/src/lib/types/PatientTransaction.type';

const getQuery = function () {
  return async (context: QueryFunctionContext) => {
    const { queryKey } = context;
    const clinicId = queryKey[1] as number;
    const paymentId = queryKey[2] as string;
    if (paymentId === 'new') {
      return {} as any;
    }
    return patientBillingService.getPatientPayment({
      clinicId,
      paymentId,
    });
  };
};

const usePatientPayment = ({
  patientId,
  paymentId,
  setIsFetchingPayment,
  bulkResponse = false,
  setConsolidatedData,
  setTransactionData,
}: {
  patientId: string;
  paymentId: string;
  setIsFetchingPayment?: (isFetching: string | null) => void;
  bulkResponse?: boolean;
  setConsolidatedData?: React.Dispatch<
    React.SetStateAction<PatientTransaction[] | null>
  >;
  setTransactionData?: React.Dispatch<
    React.SetStateAction<PatientTransaction | null>
  >;
}) => {
  const queryClient = useQueryClient();
  const { me } = useContext(MeContext);
  const [selectedPaymentCard, setSelectedPaymentCard] =
    useState<PaymentMethodType | null>(null);
  const { status, data, error, isFetching, refetch } = useQuery<PatientPayment>(
    ['paymentsBilling', me?.selectedClinic?.ID || -1, paymentId],
    getQuery(),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setIsFetchingPayment?.(isFetching ? paymentId : null);
  }, [isFetching, paymentId, setIsFetchingPayment]);

  const makePayment = async ({
    value,
    paymentKey,
    payMethodKey,
  }: {
    value: Partial<PatientPayment & CCPaymentFields>;
    paymentKey?: string;
    payMethodKey?: string;
  }) => {
    const payment: Partial<PatientPayment> = {
      ...value,
      patientId,
      clinicId: me.selectedClinic?.ID ?? -1,
      locationId: me?.selectedLocation,
      type: PaymentType.CreditCard,
      ePayData: {
        streetAddress: value?.streetAddress ?? '',
        postalCode: value?.postalCode ?? '',
        fname: value?.fname ?? '',
        lname: value?.lname ?? '',
        saveCard: value?.saveCard ?? false,
        paymentKey: paymentKey ?? '',
        payMethodKey: payMethodKey ?? '',
      },
    };

    console.log({ bulkResponse });
    throw new Error('Not implemented');

    const result = await patientBillingService.savePatientPayment({
      clinicId: me.selectedClinic?.ID ?? -1,
      payment,
      bulkResponse,
    });

    clearPatientBillingBalances();
    return result;
  };

  const updatePayRequest = async (val: {
    billingKey: string;
    payload: PatientPayment & CCPaymentFields;
    clinicId: number;
    patientId: string;
    deviceKey: string;
  }) => {
    const result = await patientBillingService.updatePayRequest(val);

    //updating query cache is needed for the credit modal edits to show up if reopened after edit.
    queryClient.setQueryData(
      ['paymentsBilling', me?.selectedClinic?.ID || -1, paymentId],
      (prev: any) => {
        return val.payload;
      },
    );

    clearPatientBillingBalances();
    return result;
  };

  const save = async (val: Partial<PatientPayment & CCPaymentFields>) => {
    console.log({ IAmHere: val, bulkResponse });
    const amount = val.amount
      ? Number(String(val.amount || '').replace(/,/g, ''))
      : 0;
    const creditAmount = val.creditAmount
      ? Number(String(val.creditAmount || '').replace(/,/g, ''))
      : 0;
    if (val.type === PaymentType.CreditCard && val.deviceKey === 'manual') {
      if ((window as any).usaepay) {
        const client = new (window as any).usaepay.Client(val.epayPublicKey);
        const paymentCard = client.createPaymentCardEntry();

        if (!selectedPaymentCard?.key) {
          const result = await client.getPaymentKey(paymentCard);
          if (result.error) {
            console.warn('CCerror', result.error);
            throw new Error(result.error);
          } else {
            return makePayment({
              value: {
                ...val,
                amount,
                creditAmount,
              },
              paymentKey: result.key,
            });
          }
        } else {
          return makePayment({
            value: {
              ...val,
              amount,
              creditAmount,
            },
            payMethodKey: selectedPaymentCard.key,
          });
        }
      }
    }

    const result = await patientBillingService.savePatientPayment({
      clinicId: me?.selectedClinic?.ID || -1,
      payment: {
        ...val,
        amount,
        creditAmount,
        patientId,
        locationId: me?.selectedLocation,
      },
      bulkResponse,
    });

    if (result?.bulkResponse?.billing) {
      console.log('....... setting the balance from bulk response ......');
      await queryClient.setQueryData(
        ['patientsBillingBalances', patientId],
        (p: any) => ({ ...p, ...result.bulkResponse.billing }),
      );
    } else {
      await clearPatientBillingBalances();
    }

    if (result?.bulkResponse?.items?.length) {
      console.log('....... setting the transaction from bulk response ......');
      // This is not the most efficient technique, but it should be okay for
      // the #s that we're talking and it is just on the client.
      for (const item of result.bulkResponse.items) {
        if (!item) continue;
        const u = item.purchase as PatientTransaction;
        if (!u || !u.billingKey) continue;
        console.log({ usePatientPayment_tsx: u });

        if (setTransactionData) {
          setTransactionData((p) => {
            if (p?.billingKey === u.billingKey) {
              return u;
            }
            return p;
          });
          const currentTransaction =
            queryClient.getQueryData<PatientTransaction>([
              'transaction',
              u.billingKey,
            ]);
          if (currentTransaction) {
            queryClient.setQueryData(
              ['transaction', u.billingKey],
              (p: any) => {
                if (currentTransaction.billingKey === u.billingKey) {
                  console.log(
                    `....... setting the transaction from bulk response ${u.billingKey}......`,
                  );
                  return u;
                }
                return undefined; // Should not set anything.
              },
            );
            const state = queryClient.getQueryState([
              'transaction',
              u.billingKey,
            ]);
            console.log({ QueryClientStateForTransaction: state });
          }
        }
        if (setConsolidatedData) {
          setConsolidatedData((p) => {
            if (p) {
              return p.map((x) => {
                if (x.billingKey === u.billingKey) {
                  console.log(
                    '....... setting the consolidated responses ......',
                  );

                  return u;
                }
                return x;
              });
            }
            return p;
          });
        }

        const currentConsolidated = queryClient.getQueryData<
          PatientTransaction[]
        >(['consolidated-transaction', u.billingKey]);
        if (currentConsolidated) {
          queryClient.setQueryData(
            ['consolidated-transaction', u.billingKey],
            currentConsolidated.map((x) => {
              if (x.billingKey === u.billingKey) {
                console.log(
                  `....... setting the consolidated responses for ${u.billingKey} ......`,
                );
                return u;
              }
              return x;
            }),
          );
        }
      }
    } else {
      // If we're not getting the bulk response, we have to clear all the
      // transactions as they may have been updated.s
      queryClient.invalidateQueries(['transaction']);
    }

    return result;
  };

  /**
   * This was called for the specific balance and then another call was
   * made to clear the cache for them all. Unknown why this is this way.
   */
  const clearPatientBillingBalances = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['patientsBillingBalances', patientId],
      exact: true,
    });
    // await queryClient.invalidateQueries(['paymentsBilling']); // [BWM] ??? why clear it specifically and then all ?
  };

  const clear = () => {
    queryClient.invalidateQueries({
      queryKey: ['paymentsBilling', me?.selectedClinic?.ID || -1, paymentId],
      exact: true,
    });
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    save,
    selectedPaymentCard,
    setSelectedPaymentCard,
    clear,
    updatePayRequest,
  };
};

export default usePatientPayment;
