import { Select, SelectOption } from '@chiroup/components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import InvoiceItemTableRow from './InvoiceItemTableRow';
import InvoiceLineItemTableRow from './InvoiceLineItemTableRow';
import InvoicePayorTableRow from './InvoicePayorTableRow';
import InvoiceTableHeader from './InvoiceTableHeader';
import useProviderLicenses from './hooks/useProviderLicenses';
import {
  PatientInvoiceItem,
  PatientInvoiceLineItem,
  InvoicePayor,
  PatientInvoice,
} from '@chiroup/core/types/PatientInvoice.type';
import { TransactionItemSubtypeEnum } from '@chiroup/core/types/PatientTransaction.type';
import {
  ProviderLicense,
  UserSettingsBillingType,
} from '@chiroup/core/types/UserSettingsBilling.type';

type InvoiceBreakdownProps = {
  purchaseDate: any;
  provider: string;
  items: PatientInvoiceItem[];
  lineItems: PatientInvoiceLineItem[];
  payors: Record<string, InvoicePayor>;
  setInvoiceData: React.Dispatch<
    React.SetStateAction<PatientInvoice | undefined>
  >;
  providerId: string;
  license?: string | null;
  patientResponsibility: number;
  userSettingsBilling?: UserSettingsBillingType;
  skipUseQuery?: boolean;
};

const InvoiceBreakdown: React.FC<InvoiceBreakdownProps> = ({
  purchaseDate,
  provider,
  items = [],
  lineItems = [],
  payors = [],
  setInvoiceData,
  providerId,
  license,
  userSettingsBilling,
  skipUseQuery,
}) => {
  const { data, isFetching } = useProviderLicenses(providerId, skipUseQuery);
  const [licenses, setLicenses] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (!providerId || providerId === '- n. a. -') return;
    const localData = userSettingsBilling ?? data;
    if (!localData) return;
    setLicenses(
      localData?.providerLicenses?.map((license: ProviderLicense) => ({
        text: license.licenseNumber || '',
        value: license.licenseNumber || '',
      })) || [],
    );
    setInvoiceData((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        invoiceBreakdown: {
          ...prev.invoiceBreakdown,
          purchaseDate:
            prev.invoiceBreakdown?.purchaseDate ||
            dayjs(new Date()).format('MM/DD/YYYY'),
          providerLicense:
            prev.invoiceBreakdown?.providerLicense ||
            localData?.providerLicenses?.[0]?.licenseNumber ||
            undefined,
        },
      };
    });
  }, [data, userSettingsBilling, providerId, setInvoiceData]);

  const showItemAmounts = !lineItems.some(
    (item) =>
      item.type === TransactionItemSubtypeEnum.Service ||
      item.type === TransactionItemSubtypeEnum.PatientService ||
      item.type === TransactionItemSubtypeEnum.AdditionalFee,
  );

  const getItemHeaders = (showItemAmounts: boolean) => {
    if (showItemAmounts) {
      return ['Item Purchased', 'Item Type', 'Item Units', 'Item Total'];
    } else return ['Item Purchased', 'Item Type'];
  };

  return (
    <div className="p-4 text-sm avoid-page-break mt-4 ">
      {/* <h3 className="font-bold pb-2 print:hidden">Invoice Breakdown</h3> */}
      <div className="pb-3">
        <div className="flex items-center print:text-xs ">
          <label className="font-normal mr-2">Purchase Date:</label>
          <p className="block">{purchaseDate}</p>
        </div>
        <div className="grid grid-cols-3 pt-2 print:p-0  items-center">
          <p className="font-normal text-xs   print:col-span-2">
            {`Rendering Provider: ${provider}`}
          </p>
          <p className="font-normal text-xs print:p-0 hidden print:block print:col-span-2">
            {`License Number: ${license}`}
          </p>

          {!!providerId && providerId !== '- n. a. -' && (
            <Select
              label="License:"
              loading={isFetching}
              inlineSelect
              inlineInputWidth="w-3/4"
              disabled={isFetching}
              labelClassName={'text-sm font-normal mt-2'}
              className="col-span-1 print:hidden"
              options={licenses}
              name="licenses"
              value={license}
              onChange={(e) =>
                setInvoiceData((prev) => {
                  if (!prev) return prev;
                  return {
                    ...prev,
                    invoiceBreakdown: {
                      ...prev.invoiceBreakdown,
                      providerLicense: e,
                    },
                  };
                })
              }
              limit={1}
              clearable
            />
          )}
        </div>
      </div>
      <div className="mb-2 inline-block rounded-lg border w-full">
        <table className="min-w-full divide-y divide-gray-300">
          <InvoiceTableHeader
            headers={getItemHeaders(showItemAmounts)}
            textAlign={{ 'Item Total': 'text-right' }}
          />
          <tbody>
            {items?.map((item, index) => (
              <InvoiceItemTableRow
                key={`${index}-${item.description}`}
                item={item}
                showItemAmounts={showItemAmounts}
              />
            ))}
          </tbody>
        </table>
      </div>
      {!!lineItems.length && (
        <div className="mb-2 inline-block rounded-lg border w-full">
          <table className="min-w-full divide-y divide-gray-300">
            <InvoiceTableHeader
              headers={['Line Item', 'Detail', 'Amount']}
              textAlign={{
                Amount: 'text-right',
              }}
            />
            <tbody>
              {lineItems.map((lineItem, index) => (
                <InvoiceLineItemTableRow
                  key={lineItem.transactionItemId}
                  index={index}
                  item={lineItem}
                  setInvoiceData={setInvoiceData}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!!Object.keys(payors).length && (
        <div className="inline-block rounded-lg border w-full mb-2">
          <table className="min-w-full divide-y divide-gray-300">
            <InvoiceTableHeader
              headers={['Payor Coverage', 'Detail', 'Responsibility']}
              textAlign={{
                Responsibility: 'text-right',
              }}
            />
            <tbody>
              {Object.entries(payors)
                ?.sort((a, b) => {
                  return a[1].billingPriority - b[1].billingPriority;
                })
                ?.map(([key, value], index) => (
                  <InvoicePayorTableRow
                    item={value}
                    key={key}
                    setInvoiceData={setInvoiceData}
                    index={index}
                    lastIndex={Object.keys(payors).length - 1}
                    payorId={key}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default InvoiceBreakdown;
