import { useContext, useState } from 'react';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';

import patientBillingService from '../../../../../services/patientBilling.service';

const useClaimAllocation = ({
  clinicId,
  billingKey,
}: {
  clinicId?: number;
  billingKey?: string;
}) => {
  const { createToast } = useContext(ToastContext);
  const [isRestActive, setIsRestActive] = useState(false);

  if (!clinicId || !billingKey) {
    throw new Error('clinicId and billingKey are required.');
  }

  const allocateClaims = async ({
    clinicId,
    billingKey,
    amount,
  }: {
    clinicId: number;
    billingKey: string;
    amount: number;
  }) => {
    setIsRestActive(true);
    try {
      await patientBillingService.allocateTransactionClaims({
        clinicId,
        billingKey,
        body: { amount },
      });
    } catch (error: any) {
      createToast({
        title: `Allocate Claim Balance`,
        description: `${
          error?.response?.data?.message ?? error?.message ?? 'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
    setIsRestActive(false);
  };

  return {
    allocateClaims,
    isRestActive,
  };
};

export default useClaimAllocation;
