import patientBillingService from '../../../../../services/patientBilling.service';
import { MeContext } from '../../../../../contexts/me.context';
import { useContext, useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
// import useServices from '../../visits/notes/services/useServices';

const getQuery = function (clinicId: number) {
  return async (context: QueryFunctionContext) => {
    const billingKey = context.queryKey[1] as string;
    if (!billingKey) return;
    if (billingKey === 'new') return {} as any;
    return patientBillingService.getConsolidatedTransaction(
      clinicId,
      billingKey,
    );
  };
};

export const useConsolidatedTransaction = function ({
  billingKey,
  onFetchFail,
}: {
  billingKey?: string | null;
  onFetchFail?: (e: any) => void;
}) {
  const queryClient = useQueryClient();
  const QUERY_KEY_ARRAY = ['consolidated-transaction', billingKey];
  const { me } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const [snapshot, setSnapshot] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const [failed, setFailed] = useState(false);
  const { status, data, isFetching, refetch, isError } = useQuery(
    QUERY_KEY_ARRAY,
    getQuery(me?.selectedClinic?.ID || -1),
    {
      onError: (error: any) => {
        createToast({
          title: `Request Failed`,
          description: `${
            error?.response?.data?.message ??
            error?.response?.data?.error ??
            error?.message ??
            'Unknown error.'
          }`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
        onFetchFail?.(error);
        setIsSaving(false);
        // console.log({ isFetching });
        queryClient.setQueryData(QUERY_KEY_ARRAY, undefined);
        setFailed(true);
      },
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000,
      keepPreviousData: true,
    },
  );

  const onCloseCleanup = () => {
    // queryClient.invalidateQueries(QUERY_KEY_ARRAY);
    setSnapshot(undefined);
  };

  const save = async ({
    clinicId,
    payload,
  }: {
    clinicId: number | null | undefined;
    payload: PatientTransaction[] | null | undefined;
  }) => {
    setIsSaving(true);
    try {
      const res = await patientBillingService.saveConsolidatedTransaction({
        clinicId,
        payload,
      });
      // console.log({ ResultFromSaveOnUseConsolidatedTransaction: res });
      if (res && res.items && res.issues) {
        queryClient.setQueryData(QUERY_KEY_ARRAY, res.items);
        for (const item of res.items) {
          queryClient.setQueryData(
            ['transaction', item.billingKey],
            item as PatientTransaction,
          );
        }
      }
      setSnapshot(res?.items ? JSON.stringify(res.items) : undefined);
      return res;
    } catch (e: any) {
      createToast({
        title: `Failed to save transaction`,
        description: `${
          e?.response?.data?.message ??
          e?.response?.data?.error ??
          e?.message ??
          'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  // When the data changes, we need to take a new snapshot AND
  // update the queryClient with the new data to prevent an
  // unnecessary REST call.
  useEffect(() => {
    if (data && Array.isArray(data)) {
      // console.log({ data });
      if (!snapshot) setSnapshot(JSON.stringify(data));
      for (const o of data ?? []) {
        queryClient.setQueryData(['transaction', o.billingKey], o);
      }
    }
  }, [data, queryClient, snapshot]);

  return {
    data,
    failed,
    isError,
    isFetching,
    isSaving,
    onCloseCleanup,
    refetch,
    save,
    setSnapshot,
    snapshot,
    status,
  };
};
