import {
  CancelConfirmModal,
  ConfirmModal,
  Loading,
  MakeBrowserWait,
  OpenClosedStates,
  Select,
  getNearest,
} from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  PhoneIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import qs from 'query-string';
import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { InstanceKeys } from '@chiroup/core/constants/DatabaseFeatureCommon';
import {
  NUMBER_BOOLEAN_HASH,
  STRING_ANY_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  Appointment,
  AppointmentForUI,
  AppointmentStatuses,
  NoShowCancelFees,
  RecurringAvailability,
} from '@chiroup/core/types/Appointment.type';
import { ClinicCaseType } from '@chiroup/core/types/ClinicCaseType.type';
import {
  Discipline,
  DisciplineTreatment,
} from '@chiroup/core/types/Discipline.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { TransactionItemSubtypeEnum } from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import useLocationRooms from '../../hooks/useLocationRooms';
import { useQueryParams } from '../../hooks/useQueryParams';
import patientService from '../../services/patient.service';
import DisclosureButton from '../common/DisclosureButton';
import ButtonGroup, { ButtonGroupType } from '../common/buttons/ButtonGroup';
import { getCaseTypeIcon } from '../common/icons/helpers';
import PatientSendSurveyModal from '../patients/detail/PatientSendSurveyModal';
import { SendSurveysFormValues } from '../patients/hooks/usePatientCommunications';
import useDatabase from '../settings/database/useDatabase';
import ScheduleAppointmentActivityLog from './ScheduleAppointmentActivityLog';
import ScheduleAppointmentCheckIn from './ScheduleAppointmentCheckIn';
import ScheduleAppointmentForm from './ScheduleAppointmentForm';
import SchedulePatientHistory from './SchedulePatientHistory';
import ScheduleRecurringAppointments from './ScheduleRecurringAppointments';
import useAppointment from './hooks/useAppointment';
import { RoomStatus } from '@chiroup/core/types/RoomStatus.type';
import ConsolidatedTransactionModal from '../patients/detail/billing/transactions/ConsolidatedTransactionModal';

interface InnerProps {
  selectedAppointmentId: string | null;
  sessionId: string;
  onClose: (afterClose?: () => void) => void;
  timezone: string;
  recurringAppointment?: boolean;
  recurringData: RecurringAvailability;
  setRecurringData: React.Dispatch<React.SetStateAction<RecurringAvailability>>;
  disciplines?: Discipline[];
  hide?: boolean;
  patient?: Patient;
  dontNavigate?: boolean;
}

/**
 * 2024-10-22.1452 by Brian
 *   Moved the validation somewhere else.
 */
const validation = {
  // patientId: { required: { message: 'Required.' } },
  // clinicianId: { required: { message: 'Clinician is required' } },
  // treatmentId: { required: { message: 'Treatment is required' } },
  // startTime: { required: { message: 'Start time is required' } },
  // duration: { required: { message: 'Duration is required' } },
};

export const ScheduleAppointmentInner: React.FC<InnerProps> = ({
  onClose,
  sessionId,
  selectedAppointmentId,
  timezone,
  recurringAppointment,
  recurringData,
  setRecurringData,
  disciplines,
  hide = false,
  patient,
  dontNavigate = false,
}) => {
  const navigate = useNavigate();
  const { me, hasRole } = useContext(MeContext);
  const { isFetching: isFetchingRooms, options: roomSelectOptions } =
    useLocationRooms({
      clinicId: me?.selectedClinic?.ID,
      locationId: me?.selectedLocation,
      includeArchivedInSelect: false,
    });
  const { data: caseTypes } = useDatabase<ClinicCaseType>({
    instanceKey: InstanceKeys.caseTypes,
    allCaseTypes: true,
  });

  const roomSelectComponents = useMemo(() => {
    return roomSelectOptions.map((room) => ({
      ...room,
      component: (
        <div className="w-48 flex flex-row rounded-md overflow-hidden">
          <div
            className={classNames(
              'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
              room?.data?.color
                ? room?.value === '-1'
                  ? 'bg-gray-100'
                  : `bg-${room.data.color}-500`
                : '',
            )}
          >
            &nbsp;
          </div>
          <div
            className={classNames(
              'flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white',
            )}
          >
            <div className="flex-1 truncate px-4 py-2 text-sm">
              <div className="font-medium text-gray-900 hover:text-gray-600">
                {room.text}
              </div>
            </div>
          </div>
        </div>
      ),
    }));
  }, [roomSelectOptions]);

  const { addQueryParams } = useQueryParams();
  const { search } = useLocation();
  const queryParams = useMemo(() => {
    const d = dayjs(),
      res = qs.parse(search),
      s = res.date ? String(res.date) : d.format('YYYY-MM-DD'),
      pcs = s.split('-');
    res.pickedDate = [pcs[1], pcs[2], pcs[0]].join('/');
    return res;
  }, [search]);

  const [viewPurchaseModalOpen, setViewPurchaseModalOpen] = useState(false);
  const [sendSurveyModalState, setSendSurveyModalState] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [noShowModalOpen, setNoShowModalOpen] = useState(false);
  const [doubleBookErrorMessage, setDoubleBookErrorMessage] = useState<
    RoomStatus[]
  >([]);
  const [deleteRecurringModalOpen, setDeleteRecurringModalOpen] =
    useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { createToast } = useContext(ToastContext);

  const {
    data: appointment,
    isFetching,
    saveAppointment: hookSaveAppointment,
    checkRecurringAvailability,
    saveRecurringAppointments,
    checkIn,
    markAsScheduled,
    isSubmitting: isSavingAppointment,
    isChangingStatus,
    isCheckingIn,
    markAsNoShow,
    approvePatient,
    error,
    deleteRecurringAppointments,
    updateRecurringAppointments,
  } = useAppointment({
    appointmentId: selectedAppointmentId,
    sessionId,
  });

  const nextEncounterDateDayjs = useMemo(() => {
    if (!appointment) return undefined;
    return typeof appointment?.displayValues?.nextRequestedEncounterDate ===
      'number'
      ? dayjs(appointment?.displayValues?.nextRequestedEncounterDate * 1000)
      : undefined;
  }, [appointment]);

  const apptSettings = useMemo(() => {
    return me?.selectedClinic?.settings?.find(
      (setting) => setting.setting === 'Appointment Settings',
    );
  }, [me?.selectedClinic?.settings]);

  const clinicTz = useMemo(() => {
    return me?.selectedClinic?.settings?.find(
      (setting) => setting.setting === 'Timezone',
    )?.jsonValue;
  }, [me?.selectedClinic?.settings]);

  const defaultSlotValue = useMemo(() => {
    return [
      {
        startTime: queryParams.startTime
          ? dayjs(
              queryParams.startTime
                ? Number(queryParams.startTime)
                : Date.now(),
            )
              .tz(clinicTz)
              .valueOf()
          : getNearest(appointment?.tz as string),
        clinicianId: queryParams.clinician,
        createTrackCCSSubmission: false,
        treatmentId: Number(
          queryParams?.treatment ??
            apptSettings?.jsonValue?.appointmentDefaultTreatment,
        ),
        duration:
          appointment?.duration ??
          apptSettings?.jsonValue?.appointmentDefaultDuration,
      } as Appointment,
    ];
  }, [
    appointment?.duration,
    appointment?.tz,
    apptSettings?.jsonValue?.appointmentDefaultDuration,
    apptSettings?.jsonValue?.appointmentDefaultTreatment,
    clinicTz,
    queryParams.clinician,
    queryParams.startTime,
    queryParams?.treatment,
  ]);

  const {
    value,
    onChange,
    errors,
    isDirty,
    isSubmitting,
    setValue,
    registerSubmit,
    patchValue,
    markAsDirty,
    // setErrors,
  } = useForm<AppointmentForUI>(
    {
      surveys: [],
      slots: defaultSlotValue,
    },
    validation,
  );

  const addNewSlot = useCallback(() => {
    if (!value) return;
    const newv = ChiroUpJSON.clone(value) as AppointmentForUI;
    newv.slots = newv.slots || [];
    const newSlot = ChiroUpJSON.clone(
      newv.slots[newv.slots.length - 1] ?? {},
    ) as any;
    // When we edit, we need to clean things up a bit.
    delete newSlot.id;
    delete newSlot.clinicianId;
    delete newSlot.treatmentId;
    delete newSlot.disciplineId;
    delete newSlot.roomId;
    newSlot.duration =
      newSlot.duration ?? apptSettings?.jsonValue?.appointmentDefaultDuration;
    newSlot.startTime = newSlot.startTime + 1000 * 60 * newSlot.duration;
    newSlot.createTrackCCSSubmission = false;
    newv.slots.push(newSlot);
    setValue(newv);
  }, [apptSettings?.jsonValue?.appointmentDefaultDuration, setValue, value]);

  const treatmentHash = useMemo(() => {
    const disciplineOptions: any[] = [],
      reduceResult = disciplines?.reduce(
        (arr: any, discipline) => {
          const newOption = {
            text: discipline.name,
            value: discipline.ID,
            treatments: {} as NUMBER_BOOLEAN_HASH,
          } as any;
          const options = discipline?.treatments?.map((treatment) => ({
            text: treatment.name,
            value: treatment.ID,
          }));

          const durations = discipline?.treatments?.map(
            (treatment) => treatment.treatmentDuration,
          );
          const treatments = discipline?.treatments?.reduce(
            (
              res: { [key: number]: DisciplineTreatment },
              treatment: DisciplineTreatment,
            ) => {
              newOption.treatments[treatment.ID] = true;
              return {
                ...res,
                [treatment.ID]: treatment,
              };
            },
            {},
          );

          if (Object.keys(newOption.treatments).length) {
            disciplineOptions.push(newOption);
          }

          return {
            options: [...arr.options, ...options],
            objs: { ...arr.objs, ...treatments },
            durations: [
              ...new Set([...arr.durations, ...durations, value.duration]),
            ],
          };
        },
        { options: [], objs: {}, durations: [] },
      );
    return {
      ...reduceResult,
      durations: reduceResult?.durations.sort((a: any, b: any) => a - b),
      disciplineOptions,
      roomSelectComponents,
    };
  }, [disciplines, roomSelectComponents, value.duration]);

  const disciplineOptions = useMemo(() => {
    if (!treatmentHash?.disciplineOptions) {
      return [{ text: '- no disciplines -', value: '' }];
    }
    return treatmentHash.disciplineOptions;
  }, [treatmentHash?.disciplineOptions]);

  const updateMySlot = useCallback(
    ({
      ord,
      key,
      value: replacementValue,
    }: {
      ord: number;
      key: string;
      value: any;
    }) => {
      console.log({ updateMySlot: { ord, key, replacementValue } });
      if (!replacementValue) return;
      const newv = ChiroUpJSON.clone(value) as AppointmentForUI;
      newv.slots = newv.slots || [];
      const slot = (newv.slots[ord] ?? {}) as STRING_ANY_HASH;
      slot[key] = replacementValue;
      if (key === 'treatmentId') {
        const discipline = disciplineOptions.find(
            (option: any) => option.treatments[slot.treatmentId],
          ),
          treatmentSpecificDuration =
            treatmentHash?.objs?.[replacementValue]?.treatmentDuration,
          isPrincipalUsed = newv.slots.reduce(
            (acc: boolean, slot: Appointment) => {
              return slot.treatmentId === newv.treatmentId || acc;
            },
            false,
          );
        if (treatmentSpecificDuration) {
          slot.duration = treatmentSpecificDuration;
        }
        if (!isPrincipalUsed) {
          newv.treatmentId = replacementValue;
        }
        if (discipline && discipline.value) {
          slot.disciplineId = discipline.value;
        }
      }
      if (newv.slots.length === 1) {
        newv.disciplineId = disciplineOptions.find(
          (option: any) => option.treatments[slot.treatmentId],
        )?.value;
        newv.treatmentId = slot.treatmentId;
        newv.clinicianId = slot.clinicianId;
      }

      // Make sure we still have a primary discipline id.
      const isDisciplineUsed = newv?.slots?.find((slot) => {
        return slot.treatmentId === newv.treatmentId;
      });
      if (!isDisciplineUsed) {
        const disciplineId = disciplineOptions.find(
          (option: any) => option.treatments[slot.treatmentId],
        )?.value;
        newv.disciplineId = disciplineId;
      }

      // Since clinician id is optional on a slot, we need to go
      // find one.
      const isClinicianUsed = newv?.slots?.find((slot) => {
        return slot.clinicianId && slot.clinicianId === newv.clinicianId;
      });
      console.log({ isClinicianUsed });
      if (!isClinicianUsed) {
        const slotWithClinician = newv?.slots?.find(
          (slot) => slot.clinicianId && slot.clinicianId !== '-1',
        );
        console.log({ slotWithClinician });
        if (slotWithClinician) {
          newv.clinicianId = slotWithClinician.clinicianId;
        } else {
          newv.clinicianId = '-1';
        }
      }

      console.log({ slotSetTo: slot });
      setValue(newv);
    },
    [disciplineOptions, setValue, treatmentHash?.objs, value],
  );

  const onCheckCreateTrackCCSSubmission = useCallback(
    (val: boolean, ord: number) => {
      console.log({ ord, val });
      const newValue = JSON.parse(JSON.stringify(value));
      const updatedSlots = (newValue?.slots || [])?.map(
        (slot: Appointment, idx: number) => {
          if (idx === ord) {
            console.log({ idx, ord });
            return {
              ...slot,
              createTrackCCSSubmission: val,
              trackId: val ? null : slot?.trackId,
            };
          }
          return slot;
        },
      );
      newValue.slots = updatedSlots;
      setValue({ ...newValue });
    },
    [setValue, value],
  );

  const deleteMySlot = useCallback(
    (slot: number, options: any) => {
      if (typeof slot !== 'number') return;
      console.log({ deleteMySlot: slot, options });

      const newv = ChiroUpJSON.clone(value) as AppointmentForUI;
      if (newv && newv.slots && newv.slots[slot]) {
        newv.slots.splice(slot, 1);
      }

      /**
       * If we only have one slot, there can be only one choice
       * for the discipline. So, if a discipline had been chosen
       * for one of the slots that was just deleted, we need to
       * figure out what the disciplineId should be.
       */
      const firstSlot = newv?.slots?.[0] ?? {
          treatmentId: -1,
          clinicianId: '-1',
        },
        disciplineId = disciplineOptions.find(
          (option: any) => option.treatments[firstSlot.treatmentId],
        )?.value;

      if (newv?.slots?.length === 1) {
        const treatmentId = firstSlot.treatmentId,
          clinicianId = firstSlot.clinicianId;
        console.log({ firstSlot, disciplineId, treatmentId });

        if (disciplineId) {
          newv.disciplineId = disciplineId;
        }
        if (treatmentId) {
          newv.treatmentId = treatmentId;
        }
        if (clinicianId) {
          newv.clinicianId = clinicianId;
        }
      }

      // Since clinician id is optional on a slot, we need to go
      // find one.
      const isClinicianUsed = newv?.slots?.find((slot) => {
        return slot.clinicianId && slot.clinicianId === newv.clinicianId;
      });
      console.log({ isClinicianUsed });
      if (!isClinicianUsed) {
        const slotWithClinician = newv?.slots?.find(
          (slot) => slot.clinicianId && slot.clinicianId !== '-1',
        );
        console.log({ slotWithClinician });
        if (slotWithClinician) {
          newv.clinicianId = slotWithClinician.clinicianId;
        } else {
          newv.clinicianId = options?.clinicians?.[0]?.value;
        }
      }

      // Make sure we still have a primary discipline id.
      const isDisciplineUsed = newv?.slots?.find((slot) => {
        return slot.treatmentId && slot.treatmentId === newv.treatmentId;
      });
      if (!isDisciplineUsed) {
        newv.disciplineId = disciplineId;
      }

      const isTreatmentUsed = newv?.slots?.find((slot) => {
        return slot.treatmentId && slot.treatmentId === newv.treatmentId;
      });
      if (!isTreatmentUsed) {
        newv.treatmentId = newv?.slots?.[0]?.treatmentId ?? -1;
      }

      // Clean out the slots...screen blinks...put them back. Could not find
      // a cleaner way to make React stop using the old objects.
      setValue({ ...newv, slots: [] });
      setTimeout(() => {
        setValue(newv);
      }, 0);
    },
    [disciplineOptions, setValue, value],
  );

  const {
    value: noShowValue,
    onChange: noShowOnChange,
    errors: noShowErrors,
    registerSubmit: noShowSubmit,
    patchValue: patchNoShowValue,
  } = useForm<{ ID: number }>(
    {},
    { ID: { required: { message: 'No show fee choice is required.' } } },
  );

  const [selectedOption, setSelectedOption] = useState('deleteAppointment');
  const [editType, setEditType] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);

  // const {
  //   status,
  //   data: transaction,
  //   refetch: refetchTransaction,
  //   insurances,
  //   payors,
  //   snapshot,
  //   locked,
  //   services,
  //   setServices,
  //   setSnapshot,
  //   setInsurances,
  //   setLocked,
  //   setPayors,
  //   setCourtesyBilling,
  //   setSuperBill,
  //   allowBillingPriorityChange,
  //   courtesyBilling,
  //   superBill,
  //   transactionReadonly,
  //   listofServices,
  //   setListofServices,
  // } = useTransaction(selectedAppointmentId || '');
  // const [workingTransaction, setWorkingTransaction] =
  //   useState<PatientTransaction | null>(null);

  // const {
  //   patientResponsibility,
  //   insurancesToUse,
  //   responsibilities,
  //   payorResponsibilityTotal,
  //   balanceAllocatedToPatient,
  // } = useResponsibilities({
  //   insurances,
  //   workingTransaction,
  //   courtesyBilling,
  //   superBill,
  // });

  const disabled =
    appointment?.status === AppointmentStatuses.Canceled ||
    isFetching ||
    isSubmitting ||
    isCanceling ||
    isCheckingIn ||
    isDeleting ||
    isChangingStatus ||
    isSavingAppointment;

  // const [remoteControl, setRemoteControl] =
  //   React.useState<ClickRemoteControlType | null>(null);

  /**
   * A passthrough so we can also refetch the transaction when the appointment
   * is saved successfully.
   *
   * @param args
   */
  const saveAppointment = async ({
    appointment,
    notify,
    removeRecurringId,
    fee,
    doubleBook,
  }: {
    appointment: Partial<AppointmentForUI>;
    notify?: boolean | undefined;
    removeRecurringId?: boolean | undefined;
    fee?: {
      amount: number;
      subtype: string;
      description: string;
      name: string;
    };
    doubleBook?: boolean;
  }) => {
    try {
      const res = await hookSaveAppointment({
        appointment,
        notify,
        removeRecurringId,
        fee,
        doubleBook,
      });
      if (res?.message === 'alreadyBooked' && !doubleBook) {
        setDoubleBookErrorMessage(res?.data);
        delete res.message;
        return res;
      }
      // if (!appointment?.deleted) refetchTransaction();
      return res;
    } catch (err: any) {
      createToast({
        title: 'Error!',
        description: (
          <span>
            {err?.response?.data?.message ?? 'Could not save appointment.'}
          </span>
        ),
        type: ToastTypes.Fail,
        duration: 5000,
      });

      console.error(err);
    }
  };

  // useEffect(() => {
  //   if (transaction) {
  //     setWorkingTransaction(transaction);
  //     setRemoteControl({
  //       button: 'refresh',
  //       e: null,
  //       callback: () => {},
  //       data: transaction,
  //     });
  //   }
  // }, [transaction]);

  // useEffect(() => {
  //   if (!selectedAppointmentId && value?.id !== undefined) {
  //     setValue({ ...value, id: undefined, slots: defaultSlotValue });
  //   }
  // }, [defaultSlotValue, selectedAppointmentId, setValue, value]);

  const [formEditState, setFormEditState] = useState<boolean | null>(null);
  const [formEditStateCallback, setFormEditStateCallback] = useState<
    (() => void) | null
  >(null);

  const tryCatchCheckIn = async () => {
    try {
      setFormEditState(false);
      setFormEditStateCallback(() => {
        return () => {
          setFormEditState(null);
          setFormEditStateCallback(null);
        };
      });
      await checkIn();
      // refetchTransaction();
    } catch (err: any) {
      createToast({
        title: 'Error!',
        description: (
          <div>{err?.response?.data?.message ?? 'The check-in failed.'}</div>
        ),
        type: ToastTypes.Fail,
        duration: 5000,
      });
      console.error(err);
    }
  };

  const sendSurveys = async (values: SendSurveysFormValues) => {
    if (!selectedAppointmentId) return;
    return patientService.sendSurvey(
      { ...values, appointmentId: selectedAppointmentId },
      me?.selectedClinic?.ID,
    );
  };

  const { data: noShowCancelFees } = useDatabase<NoShowCancelFees>({
    instanceKey: InstanceKeys.noShowCancelFees,
    limit: 9999,
  });

  const priceOfAllTreatments = useMemo(() => {
    if (!disciplines) return undefined;
    return appointment?.slots?.reduce((acc, slot: Appointment) => {
      for (const discipline of disciplines) {
        const treatment = discipline.treatments.find(
          (treat) => treat?.ID === slot?.treatmentId,
        );

        if (treatment) {
          acc += Number(treatment.priceToCalculateFees);
        }
      }
      return acc;
    }, 0);
  }, [disciplines, appointment?.slots]);

  const noShowFeesOptions = noShowCancelFees?.reduce(
    (arr: { text: string; value?: number; onClick: boolean }[], fee) => {
      //@Jeremy I think the wrong fix was applied here, I think it should have been to make
      // Premiere's fees in database match the enum types below.
      if (fee.type === TransactionItemSubtypeEnum.NoShow) {
        arr.push({
          text: fee.name + ' ' + fee.value + ' ' + fee.structure,
          value: fee.ID,
          onClick:
            !!priceOfAllTreatments ||
            fee.structure.includes(TransactionItemSubtypeEnum.Override),
        });
      }
      return arr;
    },
    [],
  );

  const deleteAppointmentConfirm = async () => {
    if (!selectedAppointmentId) return;
    try {
      setIsDeleting(true);
      const ids = await saveAppointment({
        appointment: {
          id: selectedAppointmentId,
          patientId: appointment?.patientId,
          deleted: true,
        },
      });
      setIsDeleting(false);
      setDeleteModalOpen(false);
      console.log({ DeleteTheseIdsFromTheBoard: ids });
      onClose();
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
    }
  };

  const deleteAllRecurringAppointmentsConfirm = async () => {
    if (
      !selectedAppointmentId ||
      !appointment?.recurringAppointmentId ||
      !appointment?.patientId ||
      !appointment?.startTime
    ) {
      return;
    }
    if (selectedOption === 'deleteAppointment') {
      return deleteAppointmentConfirm();
    }
    try {
      setIsDeleting(true);
      await deleteRecurringAppointments(appointment);
      setIsDeleting(false);
      setDeleteModalOpen(false);
      onClose();
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
    }
  };

  const copyAppointment = () => {
    return onClose(() => {
      setValue({});
      const params: {
        [key: string]: string | number;
      } = {
        open: 'add',
      };
      if (appointment?.slots?.length) {
        const slotsObj = appointment.slots.reduce<
          {
            clinicianId: string | undefined;
            treatmentId: number;
            duration: number;
            startTime: number;
            roomId: number | null | undefined;
          }[]
        >((arr, slot) => {
          arr.push({
            clinicianId: slot?.clinicianId,
            treatmentId: slot.treatmentId,
            duration: slot.duration,
            startTime: slot.startTime,
            roomId: slot.roomId,
          });
          return arr;
        }, []);
        params.slots = encodeURIComponent(JSON.stringify(slotsObj));
      }
      if (appointment?.disciplineId) {
        params.discipline = appointment.disciplineId;
      }
      if (appointment?.treatmentId) {
        params.treatment = appointment.treatmentId;
      }
      if (appointment?.patientId) {
        params.patient = appointment.patientId;
      }
      if (appointment?.clinicianId) {
        params.clinician = appointment.clinicianId;
      }
      if (appointment?.displayValues) {
        params.patientName = appointment.displayValues.patientName;
      }
      if (appointment?.duration) {
        params.duration = appointment.duration;
      }
      if (appointment?.startTime) {
        params.startTime = appointment.startTime.valueOf();
      }
      addQueryParams(params).navigate();
    });
  };

  const scheduleRecurringAppointment = () => {
    if (appointment?.slots && appointment?.slots?.length !== 1) {
      return;
    }
    return onClose(() => {
      const params: {
        [key: string]: string | number;
      } = {
        mode: 'recurring',
      };
      if (appointment?.id) {
        params.open = appointment.id;
      }
      if (appointment?.treatmentId) {
        params.treatment = appointment.treatmentId;
      }
      if (appointment?.patientId) {
        params.patient = appointment.patientId;
      }
      if (appointment?.clinicianId) {
        params.clinician = appointment.clinicianId;
      }
      if (appointment?.duration) {
        params.duration = appointment.duration;
      }
      if (appointment?.startTime) {
        params.startTime = appointment.startTime.valueOf();
      }
      if (appointment?.displayValues?.patientName) {
        params.patientName = appointment.displayValues.patientName;
      }
      addQueryParams(params).navigate();
    });
  };

  // const displayNoShowOrCancellationFee = useMemo(() => {
  //   return transaction?.items?.find(
  //     (item: { subtype: string }) =>
  //       item.subtype === TransactionItemSubtypeEnum.Canceled ||
  //       item.subtype === TransactionItemSubtypeEnum.NoShow,
  //   );
  // }, [transaction]);

  const markAndApplyFees = async () => {
    setIsCanceling(true);
    const id = noShowValue?.ID;
    if (id === -1 || !id) {
      await markAsNoShow();
      setNoShowModalOpen(false);
      setIsCanceling(false);
      patchNoShowValue({});
      return;
    }

    const fee = noShowCancelFees.find((fee) => fee.ID === id);
    const feeToSend = { amount: 0, name: '', description: '', subtype: '' };

    if (fee) {
      feeToSend.name = fee.name;
      feeToSend.subtype = fee.type;
      feeToSend.description = fee.name;
      if (fee.structure === '$ Override') {
        feeToSend.amount = Number(fee.value);
      } else if (priceOfAllTreatments && fee.structure === '$ Additional Fee') {
        feeToSend.amount = Number(fee.value) + Number(priceOfAllTreatments);
      } else if (priceOfAllTreatments && fee.structure === '% Additional Fee') {
        feeToSend.amount =
          (Number(fee.value) / 100) * Number(priceOfAllTreatments) +
          Number(priceOfAllTreatments);
      } else if (
        priceOfAllTreatments &&
        fee.structure === '$ Discount From Total'
      ) {
        feeToSend.amount = Number(priceOfAllTreatments) - Number(fee.value);
      } else if (
        priceOfAllTreatments &&
        fee.structure === '% Discount From Total'
      ) {
        feeToSend.amount =
          Number(priceOfAllTreatments) -
          (Number(fee.value) / 100) * Number(priceOfAllTreatments);
      }
    }

    await markAsNoShow(feeToSend);
    setIsCanceling(false);
    setNoShowModalOpen(false);
    patchNoShowValue({});
    return;
  };

  const getButtons = ({
    status,
  }: {
    status: AppointmentStatuses;
  }): ButtonGroupType[] => {
    const buttons: ButtonGroupType[] = [];
    if (status !== AppointmentStatuses.Canceled) {
      buttons.push({
        label: 'Request Info',
        onClick: () => {
          setSendSurveyModalState(OpenClosedStates.Open);
        },
      });
    }

    buttons.push({
      label: 'Purchase',
      // to: `/patients/${patientId}/billing/${billingKey}`,
      onClick: () => {
        // console.log({ onClickOnButtonGroup: 'Purchase' });
        setViewPurchaseModalOpen(true);
      },
    });

    // if (
    //   !!transaction?.provider?.displayName?.length &&
    //   !!transaction?.nextRequestedEncounterDate
    // ) {
    //   const url = [
    //     `/schedule?endDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
    //     `startDate=${nextEncounterDateDayjs?.format('YYYY-MM-DD')}`,
    //     `patient=${transaction?.patient?.id}`,
    //     `patientName=${transaction?.patient?.displayName}`,
    //     `clinician=${transaction?.provider?.id}`,
    //     `treatment=${transaction?.treatmentId}`,
    //     `open=add`,
    //   ].join('&');

    //   buttons.push({
    //     label: nextEncounterDateDayjs
    //       ? 'Schedule appointment on ' +
    //         nextEncounterDateDayjs?.format('M/DD/YYYY')
    //       : 'Next Appointment',
    //     onClick: () => {
    //       console.log({ notNavigatingTo: url });
    //       navigate('/schedule');
    //       setTimeout(() => {
    //         navigate(url);
    //       }, 750);
    //     },
    //   });
    // } else {
    const url = [
      `/schedule?patient=${appointment?.patientId}`,
      `patientName=${encodeURIComponent(
        appointment?.displayValues?.patientName ?? '- no name -',
      )}`,
      `clinician=${appointment?.clinicianId}`,
      `treatment=${appointment?.treatmentId}`,
      `open=add`,
    ].join('&');
    buttons.push({
      label: 'Next Appointment',
      onClick: () => {
        console.log({ notNavigatingTo: url });
        navigate('/schedule');
        setTimeout(() => {
          navigate(url);
        }, 750);
      },
    });
    // }

    return buttons;
  };

  const startTimeInterval = useMemo(() => {
    return (
      me?.selectedClinic?.settings?.find(
        (setting: { setting: string }) =>
          setting.setting === 'Appointment Settings',
      )?.jsonValue?.appointmentStartTimeInterval || 15
    );
  }, [me]);

  // const disableButtonGroup = useMemo(() => {
  //   return workingTransaction?.items?.length ? false : disabled;
  // }, [disabled, workingTransaction?.items?.length]);

  // console.log({ value });
  return (
    <div data-component="schedule-appointment">
      {recurringAppointment ? (
        <ScheduleRecurringAppointments
          appointment={appointment}
          onClose={onClose}
          checkRecurringAvailability={checkRecurringAvailability}
          saveRecurringAppointments={saveRecurringAppointments}
          recurringData={recurringData}
          setRecurringData={setRecurringData}
          sessionId={sessionId}
          timezone={timezone}
          passedDisciplines={disciplines}
          startTimeInterval={startTimeInterval}
        />
      ) : isFetching ||
        isFetchingRooms ||
        (selectedAppointmentId && !appointment) ? (
        <div className="flex flex-row justify-center">
          {error ? (
            <div
              className="relative w-full px-4 py-3 text-sm text-red-700 bg-red-100 border border-red-400 rounded"
              role="alert"
            >
              <span className="block sm:inline">
                The appointment could not be found.
              </span>
              <span className="hidden">{selectedAppointmentId}</span>
            </div>
          ) : (
            <Loading color="text-gray-400" />
          )}
        </div>
      ) : selectedAppointmentId && !recurringAppointment ? (
        <>
          <div className="pb-1 sm:pb-6">
            <div className="sm:flex sm:items-end">
              <div className="sm:flex-1">
                <div>
                  <div className="flex items-center justify-between">
                    <Link
                      to={`/patients/${appointment?.patientId}`}
                      onClick={(e) => {
                        /**
                         * Admittedly, this is kinda weird. The link exists so that the user can open in a new tab.
                         * However, if we don't prevent the default behavior, navigating to the patient page will
                         * close the slide over, which navigates to the schedule. So, we do that ourselves , then prevent the default behavior
                         * and navigate manually.
                         */
                        e.preventDefault();
                        e.stopPropagation();
                        navigate('/schedule');
                        setTimeout(() => {
                          navigate(`/patients/${appointment?.patientId}`);
                        }, 333);
                      }}
                    >
                      <span
                        className="flex flex-row gap-2 text-xl font-bold text-gray-900 cursor-pointer dark:text-white sm:text-2xl hover:underline"
                        title={
                          appointment?.pendingPatient
                            ? 'Pending patient.'
                            : appointment?.deniedPatient
                              ? 'Denied patient.'
                              : ''
                        }
                      >
                        <span className="text-xl">
                          {appointment?.displayValues?.patientName}{' '}
                          {getCaseTypeIcon({
                            caseType:
                              appointment?.displayValues?.caseType || -1,
                            caseTypes:
                              (caseTypes as Partial<ClinicCaseType>[]) || [],
                            hasActiveInsurance:
                              appointment?.displayValues?.hasActiveInsurance ||
                              false,
                          })}
                        </span>
                        {appointment?.pendingPatient ? (
                          <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500" />
                        ) : appointment?.deniedPatient ? (
                          <ExclamationTriangleIcon className="w-5 h-5 text-red-500" />
                        ) : null}
                      </span>
                    </Link>
                    {appointment?.status === AppointmentStatuses.Canceled ? (
                      <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-full bg-red-50 ring-1 ring-inset ring-red-600/50">
                        Canceled
                      </span>
                    ) : appointment?.status ===
                      AppointmentStatuses.CheckedIn ? (
                      <span className="inline-flex items-center px-2 py-1 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                        Checked In
                      </span>
                    ) : appointment?.status === AppointmentStatuses.NoShow ? (
                      <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-full bg-red-50 ring-1 ring-inset ring-red-600/50">
                        No Show
                      </span>
                    ) : appointment?.status ===
                      AppointmentStatuses.Scheduled ? (
                      <ScheduleAppointmentCheckIn
                        checkIn={tryCatchCheckIn}
                        disabled={disabled}
                        loading={isCheckingIn}
                      />
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-0 sm:flex-row sm:gap-6">
                    {appointment?.displayValues?.patientPhone && (
                      <a
                        href={`tel:${appointment.displayValues?.patientPhone}`}
                        className="flex flex-row items-center gap-2 text-sm text-gray-500"
                      >
                        <PhoneIcon className="w-4 h-4" />{' '}
                        {appointment.displayValues?.patientPhone}
                      </a>
                    )}
                    {appointment?.displayValues?.patientEmail && (
                      <a
                        href={`mailto:${appointment.displayValues?.patientEmail}`}
                        className="flex flex-row items-center gap-2 text-sm text-gray-500"
                      >
                        <EnvelopeIcon className="w-4 h-4" />{' '}
                        {appointment.displayValues?.patientEmail}
                      </a>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap justify-between mt-5 space-y-3 sm:space-x-3 sm:space-y-0">
                  <div>
                    <ButtonGroup
                      buttons={getButtons({
                        status: appointment?.status as AppointmentStatuses,
                      })}
                    />
                    <div className="flex flex-row gap-2">
                      {/* <Button
                        color={ButtonColors.plainWithBorder}
                        text="Request Info"
                        onClick={() => {
                          setSendSurveyModalState(OpenClosedStates.Open);
                        }}
                        disabled={disabled}
                      /> */}
                    </div>
                  </div>

                  {hasRole([
                    UserRoles.Admin,
                    UserRoles.Provider,
                    UserRoles.Staff,
                    UserRoles.ClinicalAssistant,
                  ]) && (
                    <div className="inline-flex ml-3 sm:ml-0">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <Menu.Button
                          className="inline-flex items-center p-2 text-gray-400 bg-white rounded-md shadow-sm dark:bg-darkGray-800 hover:dark:bg-darkGray-700 ring-1 ring-inset ring-gray-300 dark:ring-darkGray-600 hover:bg-gray-50"
                          disabled={disabled}
                        >
                          <span className="sr-only">Open options menu</span>
                          <EllipsisVerticalIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {appointment?.pendingPatient && (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={approvePatient}
                                    >
                                      Approve patient
                                    </span>
                                  )}
                                </Menu.Item>
                              )}
                              <Menu.Item>
                                {({ active }) => (
                                  <span
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                      'block px-4 py-2 text-sm cursor-pointer',
                                    )}
                                    onClick={copyAppointment}
                                  >
                                    Copy appointment
                                  </span>
                                )}
                              </Menu.Item>
                              {!appointment?.recurringAppointmentId &&
                                (!appointment?.slots ||
                                  appointment.slots.length === 1) &&
                                (!value?.slots || value.slots.length === 1) && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <span
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm cursor-pointer',
                                        )}
                                        onClick={scheduleRecurringAppointment}
                                      >
                                        Schedule recurring appointments
                                      </span>
                                    )}
                                  </Menu.Item>
                                )}
                              {appointment?.status !==
                                AppointmentStatuses.CheckedIn &&
                                appointment?.status !==
                                  AppointmentStatuses.NoShow && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <span
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm cursor-pointer',
                                        )}
                                        onClick={() => setCancelModalOpen(true)}
                                      >
                                        Cancel appointment
                                      </span>
                                    )}
                                  </Menu.Item>
                                )}
                              {appointment?.status ===
                              AppointmentStatuses.NoShow ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={markAsScheduled}
                                    >
                                      Cancel no show
                                    </span>
                                  )}
                                </Menu.Item>
                              ) : appointment?.status !==
                                AppointmentStatuses.CheckedIn ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={() => setNoShowModalOpen(true)}
                                    >
                                      No show
                                    </span>
                                  )}
                                </Menu.Item>
                              ) : null}

                              {appointment?.status ===
                              AppointmentStatuses.CheckedIn ? (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer',
                                      )}
                                      onClick={markAsScheduled}
                                    >
                                      Cancel Check In
                                    </span>
                                  )}
                                </Menu.Item>
                              ) : (
                                <div>
                                  {appointment?.recurringAppointmentId ? (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm cursor-pointer',
                                          )}
                                          onClick={() =>
                                            setDeleteRecurringModalOpen(true)
                                          }
                                        >
                                          Delete
                                        </span>
                                      )}
                                    </Menu.Item>
                                  ) : (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm cursor-pointer',
                                          )}
                                          onClick={() =>
                                            setDeleteModalOpen(true)
                                          }
                                        >
                                          Delete
                                        </span>
                                      )}
                                    </Menu.Item>
                                  )}
                                </div>
                              )}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </div>

                {/* 
                TODO: Put nextRequestedEncounterDate on the top super-appointment.
                TODO: !URGENT Need to handle cancellation and no-show fees on super appointments.
                {(!!displayNoShowOrCancellationFee ||
                  appointment?.cancellationReason) && (
                  <p className="mt-8 inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/50">
                    <span>
                      {appointment?.cancellationReason && (
                        <p>
                          Reason for cancellation:{' '}
                          {appointment?.cancellationReason}
                        </p>
                      )}
                      {displayNoShowOrCancellationFee?.description && (
                        <p>
                          Fee applied:{' '}
                          {displayNoShowOrCancellationFee?.description}
                        </p>
                      )}
                    </span>
                  </p>
                )} */}
              </div>
            </div>
          </div>
          {viewPurchaseModalOpen ? (
            <ConsolidatedTransactionModal
              id={appointment?.id}
              isOpen={true}
              onCloseView={() => {
                setViewPurchaseModalOpen(false);
              }}
            />
          ) : null}

          <PatientSendSurveyModal
            setState={setSendSurveyModalState}
            state={sendSurveyModalState}
            patientId={appointment?.patientId ?? ''}
            patientPhone={appointment?.displayValues?.patientPhone}
            patientEmail={appointment?.displayValues?.patientEmail}
            sendSurveys={sendSurveys}
            optOutSMS={!!appointment?.optOutSMS}
          />
          <CancelConfirmModal
            isOpen={cancelModalOpen}
            close={() => setCancelModalOpen(false)}
            title="Cancel appointment"
            description="Are you sure you want to cancel this appointment?"
            confirmText="Cancel Appointment"
            cancelText="Keep Appointment"
            loading={isCanceling}
            appointment={appointment}
            priceOfTreatment={priceOfAllTreatments}
            selectedAppointmentId={selectedAppointmentId}
            noShowCancelFees={noShowCancelFees}
            setCancelModalOpen={setCancelModalOpen}
            saveAppointment={saveAppointment}
            // refetchTransaction={refetchTransaction}
            fromChiroUp
          />
          <ConfirmModal
            isOpen={deleteModalOpen}
            close={() => setDeleteModalOpen(false)}
            title="Delete appointment"
            description="Are you sure you want to delete this appointment? This cannot be undone."
            confirmText="Delete"
            confirm={deleteAppointmentConfirm}
            loading={isDeleting}
          />
          <ConfirmModal
            isOpen={noShowModalOpen}
            close={() => setNoShowModalOpen(false)}
            title="No show"
            description="Mark patient as a no show?"
            confirmText="Confirm"
            confirm={noShowSubmit(markAndApplyFees, {})}
            loading={isCanceling}
          >
            <form>
              <Select
                label="No show fee"
                name="ID"
                value={noShowValue?.ID}
                onChange={noShowOnChange('ID')}
                limit={1}
                errors={noShowErrors?.fieldErrors?.ID}
                options={[
                  {
                    text: 'No Show - No fee',
                    value: -1,
                    onClick: true,
                  },
                  ...noShowFeesOptions,
                ]}
              />
            </form>
          </ConfirmModal>
          <ConfirmModal
            isOpen={deleteRecurringModalOpen}
            close={() => setDeleteRecurringModalOpen(false)}
            title="Delete appointment"
            description="Do you want to delete this appointment or this appointment and all future recurring appointments? This cannot be undone."
            confirmText="Delete"
            confirm={deleteAllRecurringAppointmentsConfirm}
            loading={isDeleting}
          >
            <div className="m-2">
              <label>
                <input
                  type="radio"
                  value="deleteAppointment"
                  checked={selectedOption === 'deleteAppointment'}
                  onChange={() => setSelectedOption('deleteAppointment')}
                  className="form-radio h-4 w-4 text-sm text-primary-600 transition duration-150 ease-in-out m-2"
                />
                <span className="text-sm text-gray-500">
                  Delete this appointment
                </span>
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="deleteAllFutureRecurring"
                  checked={selectedOption === 'deleteAllFutureRecurring'}
                  onChange={() => setSelectedOption('deleteAllFutureRecurring')}
                  className="form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out m-2"
                />
                <span className="text-sm text-gray-500">
                  Delete this appointment and all future recurring appointments
                </span>
              </label>
            </div>
          </ConfirmModal>
          <dl className="space-y-6 divide-y divide-gray/10 dark:divide-white/10">
            {appointment?.recurringAppointmentId ? (
              <Disclosure as="div" className="pt-6" defaultOpen>
                {({ open }) => (
                  <>
                    <DisclosureButton open={open} title="Summary" />
                    <Disclosure.Panel as="dd" className="mt-4 w-full">
                      {(editType === 'editAllFutureRecurring' ||
                        editType === '' ||
                        (!showForm &&
                          editType === 'editOnlyThisAppointment')) && (
                        <ScheduleRecurringAppointments
                          appointment={appointment}
                          onClose={onClose}
                          checkRecurringAvailability={
                            checkRecurringAvailability
                          }
                          saveRecurringAppointments={saveRecurringAppointments}
                          updateRecurringAppointments={
                            updateRecurringAppointments
                          }
                          recurringData={recurringData}
                          setRecurringData={setRecurringData}
                          sessionId={sessionId}
                          editType={editType}
                          setEditType={setEditType}
                          showForm={showForm}
                          setShowForm={setShowForm}
                          timezone={timezone}
                          passedDisciplines={disciplines}
                        />
                      )}
                      {showForm && editType === 'editOnlyThisAppointment' && (
                        <ScheduleAppointmentForm
                          appointment={appointment}
                          onClose={onClose}
                          saveAppointment={saveAppointment}
                          timezone={timezone}
                          disabled={disabled}
                          editingOneOfRecurring={true}
                          setEditType={setEditType}
                          setShowForm={setShowForm}
                          value={value}
                          onChange={onChange}
                          errors={errors}
                          isDirty={isDirty}
                          // disableButtonGroup={disableButtonGroup}
                          isSubmitting={isSubmitting}
                          setValue={setValue}
                          registerSubmit={registerSubmit}
                          patchValue={patchValue}
                          patient={patient}
                          treatmentHash={treatmentHash}
                          disciplineOptions={disciplineOptions}
                          startTimeInterval={startTimeInterval}
                          dontNavigate={dontNavigate}
                          addNewSlot={addNewSlot}
                          deleteMySlot={deleteMySlot}
                          queryParams={queryParams}
                          updateMySlot={updateMySlot}
                          markAsDirty={markAsDirty}
                          formEditState={formEditState}
                          formEditStateCallback={formEditStateCallback}
                          doubleBookErrorMessage={doubleBookErrorMessage}
                          setDoubleBookErrorMessage={setDoubleBookErrorMessage}
                          defaultSlotValue={defaultSlotValue}
                          onCheckCreateTrackCCSSubmission={
                            onCheckCreateTrackCCSSubmission
                          }
                        />
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ) : (
              <Disclosure as="div" className="pt-6" defaultOpen>
                {({ open }) => (
                  <>
                    <DisclosureButton open={open} title="Summary" />
                    <Disclosure.Panel as="dd" className="mt-4 w-full">
                      <ScheduleAppointmentForm
                        appointment={appointment}
                        onClose={onClose}
                        saveAppointment={saveAppointment}
                        timezone={timezone}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        errors={errors}
                        isDirty={isDirty}
                        isSubmitting={isSubmitting}
                        setValue={setValue}
                        registerSubmit={registerSubmit}
                        // disableButtonGroup={disableButtonGroup}
                        patchValue={patchValue}
                        patient={patient}
                        treatmentHash={treatmentHash}
                        disciplineOptions={disciplineOptions}
                        startTimeInterval={startTimeInterval}
                        dontNavigate={dontNavigate}
                        addNewSlot={addNewSlot}
                        deleteMySlot={deleteMySlot}
                        queryParams={queryParams}
                        updateMySlot={updateMySlot}
                        markAsDirty={markAsDirty}
                        formEditState={formEditState}
                        formEditStateCallback={formEditStateCallback}
                        doubleBookErrorMessage={doubleBookErrorMessage}
                        setDoubleBookErrorMessage={setDoubleBookErrorMessage}
                        defaultSlotValue={defaultSlotValue}
                        onCheckCreateTrackCCSSubmission={
                          onCheckCreateTrackCCSSubmission
                        }
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}
            {!hide && (
              <>
                <Disclosure as="div" className="pt-6">
                  {({ open }) => (
                    <>
                      <DisclosureButton open={open} title="Activity Log" />
                      <Disclosure.Panel as="dd" className="w-full mt-4">
                        <ScheduleAppointmentActivityLog
                          appointmentId={selectedAppointmentId}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="pt-6">
                  {({ open }) => (
                    <>
                      <DisclosureButton
                        open={open}
                        title="Patient Appointment History"
                      />
                      <Disclosure.Panel as="dd" className="w-full mt-4">
                        <SchedulePatientHistory
                          patientId={appointment?.patientId || ''}
                          // locationsById={locationsById}
                          me={me}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>{' '}
              </>
            )}
          </dl>
        </>
      ) : (
        <ScheduleAppointmentForm
          appointment={appointment}
          onClose={onClose}
          saveAppointment={saveAppointment}
          timezone={timezone}
          newAppointment
          value={value}
          onChange={onChange}
          errors={errors}
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          setValue={setValue}
          // disableButtonGroup={disableButtonGroup}
          registerSubmit={registerSubmit}
          patchValue={patchValue}
          patient={patient}
          treatmentHash={treatmentHash}
          disciplineOptions={disciplineOptions}
          startTimeInterval={startTimeInterval}
          dontNavigate={dontNavigate}
          addNewSlot={addNewSlot}
          deleteMySlot={deleteMySlot}
          queryParams={queryParams}
          updateMySlot={updateMySlot}
          markAsDirty={markAsDirty}
          formEditState={formEditState}
          formEditStateCallback={formEditStateCallback}
          doubleBookErrorMessage={doubleBookErrorMessage}
          setDoubleBookErrorMessage={setDoubleBookErrorMessage}
          defaultSlotValue={defaultSlotValue}
          onCheckCreateTrackCCSSubmission={onCheckCreateTrackCCSSubmission}
        />
      )}

      <MakeBrowserWait
        isWaiting={isSavingAppointment || isChangingStatus || isCheckingIn}
      />
      {/* <pre>
        {ChiroUpJSON.pretty({
          foobar: {
            value,
            selectedAppointmentId,
            // roomSelectOptions,
            // roomSelectComponents,
          },
        })}
      </pre> */}
    </div>
  );
};

interface Props extends InnerProps {
  state: OpenClosedStates;
  recurringAppointment?: boolean;
  recurringData: RecurringAvailability;
  setRecurringData: React.Dispatch<React.SetStateAction<RecurringAvailability>>;
  disciplines?: Discipline[];
  patient?: Patient;
  dontNavigate?: boolean;
}

const ScheduleAppointment: React.FC<Props> = ({
  state,
  onClose,
  sessionId,
  selectedAppointmentId,
  timezone,
  recurringAppointment,
  recurringData,
  setRecurringData,
  disciplines,
  hide,
  patient,
  dontNavigate = false,
}) => {
  return (
    <div className="inset-y-0 right-0 flex border-l border-gray-300 max-w-xl w-full fixed sm:relative z-30">
      <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-darkGray-900 shadow-xl w-full">
        <div className="py-6 px-4 sm:px-6 bg-gray-50 dark:bg-darkGray-700">
          <div className="flex items-start justify-between">
            <div className="flex flex-col">
              <div className="text-lg font-medium text-gray-900 dark:text-white">
                {recurringAppointment
                  ? 'Recurring appointments'
                  : 'Appointment'}
              </div>
            </div>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => {
                  onClose();
                }}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative my-6 flex-1 px-4 sm:px-6">
          <ScheduleAppointmentInner
            onClose={onClose}
            sessionId={sessionId}
            selectedAppointmentId={selectedAppointmentId}
            timezone={timezone}
            recurringAppointment={recurringAppointment}
            recurringData={recurringData}
            setRecurringData={setRecurringData}
            disciplines={disciplines}
            hide={hide}
            patient={patient}
            dontNavigate={dontNavigate}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleAppointment;
