import {
  Button,
  ButtonColors,
  Currency,
  InputMasked,
} from '@chiroup/components';
import Modal from '../../../../common/Modal';
import { useContext } from 'react';
import patientBillingService from '../../../../../services/patientBilling.service';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import { useForm } from '@chiroup/hooks';
import { createDecimal } from '@chiroup/core/functions/createDecimal';

type Props = {
  amount: number | null;
  billingKey: string;
  isOpen: boolean;
  refetch: () => void;
  close: () => void;
  patientId: string;
  isProcessingWriteOff: boolean;
  setIsProcessingWriteOff: (isProcessingWriteOff: boolean) => void;
};

const WriteOffNonBillableBalanceModal: React.FC<Props> = ({
  amount: max,
  billingKey,
  isOpen,
  refetch,
  close,
  patientId,
  isProcessingWriteOff,
  setIsProcessingWriteOff,
}) => {
  const { me } = useContext(MeContext);
  const { value, errors, onChange, registerSubmit } = useForm<{
    writeOffAmount: number;
  }>(
    {
      writeOffAmount: 0,
    },
    {
      writeOffAmount: {
        function: {
          value: (value: any) => {
            const { writeOffAmount } = value;
            const valueToCheck = createDecimal(writeOffAmount || 0);
            if (valueToCheck.lessThanOrEqualTo(0)) {
              return 'Amount must be greater than 0';
            } else if (valueToCheck.greaterThan(max || 0)) {
              return 'Amount must be less than or equal to the balance';
            }
            return false;
          },
        },
      },
    },
  );

  const { createToast } = useContext(ToastContext);

  const onFail = () => {
    createToast({
      type: ToastTypes.Fail,
      title: 'Error',
      description: 'There was an error writing off this invoice.',
    });
  };

  const writeOff = async () => {
    try {
      setIsProcessingWriteOff(true);
      await patientBillingService.writeOffNonBillablePayor({
        clinicId: me.selectedClinic?.ID || -1,
        billingKey: billingKey,
        amount: value.writeOffAmount || 0,
        patientId,
      });
      close();
      refetch();
    } catch (error: any) {
      onFail();
    } finally {
      setIsProcessingWriteOff(false);
    }
  };
  return (
    <Modal isOpen={isOpen} close={close}>
      <div>
        <div className="mt-3 sm:mt-5 flex flex-col gap-6">
          <div className="flex flex-col">
            <h3
              className="text-lg text-center font-medium leading-6 text-gray-900 dark:text-darkGray-100"
              id="modal-headline"
            >
              Write-off Non-Billable Balance
            </h3>
          </div>
          <form className="space-y-6">
            <InputMasked
              className="w-full"
              label={`Write-off Amount`}
              name="amount"
              placeholder="0.00"
              value={value.writeOffAmount}
              onChange={onChange('writeOffAmount')}
              numericOptions={{
                decimalScale: 2,
                fixedDecimalScale: true,
              }}
              hint={
                <>
                  Maximum:{' '}
                  <Currency value={max || 0} className="inline-block" />
                </>
              }
              errors={errors?.fieldErrors?.writeOffAmount}
            />
          </form>
        </div>
        <div className={'mt-6 flex flex-row gap-2'}>
          <Button
            text={'Write-off'}
            fullWidth
            onClick={registerSubmit(writeOff, {})}
            loading={isProcessingWriteOff}
          />
          <Button
            text="Close"
            fullWidth
            color={ButtonColors.plain}
            onClick={close}
            className="border border-gray-300 dark:border-darkGray-600"
          />
        </div>
      </div>
    </Modal>
  );
};

export default WriteOffNonBillableBalanceModal;
