import { useMemo } from 'react';
import Modal from '../../../../common/Modal';
import Button, { ButtonColors } from '../../../../common/Button';
import Header from '../../../../layout/Header';
import { PatientPackage } from '@chiroup/core/types/Packages.type';

type PackageSupplementsModalProps = {
  isOpen: boolean;
  close: () => void;
  applyPackage: (id: number) => void;
  canApplyPackages?: number[];
  packages?: PatientPackage[];
};

const ChoosePackageModal = ({
  isOpen,
  applyPackage,
  canApplyPackages,
  close,
  packages,
}: PackageSupplementsModalProps) => {
  const patientPackages = useMemo(() => {
    return (
      packages?.filter((pck) => canApplyPackages?.includes(pck?.ID || -1)) || []
    );
  }, [canApplyPackages, packages]);

  return (
    <Modal isOpen={isOpen} close={() => close()} addClasses="h-4/5">
      <>
        <Header title="Packages" />
        <ul className="list-none gap-2 mt-4">
          {patientPackages?.map((pkg) => {
            return (
              <li
                key={pkg.ID}
                className={`mb-2 flex shadow-sm rounded-md select-none cursor-pointer col-span-1 hover:opacity-100`}
                onClick={() => {
                  if (!pkg.ID) return;
                  applyPackage(pkg.ID);
                  close();
                }}
              >
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-800 rounded-r-md">
                  <div className="flex-1 px-4 py-2 text-sm">
                    <div className="flex gap-4 justify-between">
                      <div className="flex gap-4">
                        <span title={pkg.packageName}>{pkg.packageName}</span>
                        <span>${pkg.price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <Button
          text="Close"
          color={ButtonColors.plainWithBorder}
          className="mt-4"
          onClick={() => {
            close();
          }}
        />
      </>
    </Modal>
  );
};

export default ChoosePackageModal;
