import {
  ButtonColors,
  Checkbox,
  ConfirmModal,
  NewButton,
  OpenClosedStates,
  Select,
} from '@chiroup/components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import SubContainer from '../../../../../../components/layout/SubContainer';
import { MeContext } from '../../../../../../contexts/me.context';
import usePatient from '../../../../hooks/usePatient';
import { InsuranceError } from '../services/CodeEncounterDiagnosisServices';
import EncounterInsuranceModal from './EncounterInsuranceModal';
import EncounterInsurancePolicy from './EncounterInsurancePolicy';
import useUserBillingProfiles from '../../../../../../hooks/useUserBillingProfiles';
import { classNames } from '@chiroup/core/functions/classNames';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import {
  Insurance,
  DisciplineInsuranceBenefit,
} from '@chiroup/core/types/PatientInsurance.type';
import { PatientTransactionItemType } from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';

type Props = {
  insurances: Partial<AppointmentInsuranceType>[];
  setInsurances: (ins: Partial<AppointmentInsuranceType>[]) => void;
  patientId: string;
  readOnly: boolean;
  isBillingStarted?: boolean;
  insuranceErrors: InsuranceError[];
  services: PatientTransactionItemType[];
  disciplineId?: number;
  policies: any[];
  isFetchingPolicies: boolean;
  handlePolicyChange: (e: any) => void;
  handleCourtesyBillingChange: (e: boolean) => void;
  handleSuperBillChange?: (e: boolean) => void;
  activePolicies: any[];
  onRemoveInsurance: (insurance: Partial<AppointmentInsuranceType>) => void;
  allowBillingPriorityChange: boolean;
  courtesyBilling?: boolean;
  superBill?: boolean;
  parentIsa?: string;
  providerId?: string;
  handleBillingProfileChange?: (e: number | null) => void;
  billingProfileId?: number;
  billingKey: string;
  consolidatedView?: boolean;
  horizontal?: boolean;
};

const EncounterInsurance: React.FC<Props> = ({
  insurances,
  setInsurances,
  patientId,
  readOnly = false,
  isBillingStarted = false,
  insuranceErrors,
  services,
  disciplineId,
  policies,
  isFetchingPolicies,
  handlePolicyChange,
  activePolicies,
  onRemoveInsurance,
  allowBillingPriorityChange = false,
  handleCourtesyBillingChange,
  handleSuperBillChange,
  courtesyBilling = false,
  superBill = false,
  parentIsa,
  providerId,
  handleBillingProfileChange,
  billingProfileId,
  billingKey,
  consolidatedView = false,
  horizontal = false,
}) => {
  const { hasRole } = useContext(MeContext);
  const { data: patient, isFetching: isFetchingPatient } =
    usePatient(patientId);
  const [insuranceModalOpen, setInsuranceModalOpen] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedInsurance, setSelectedInsurance] = useState<any>(null);

  const {
    data: userBillingProfiles,
    isFetching: isFetchingUserBillingProfiles,
    refetch: refetchUserBillingProfiles,
  } = useUserBillingProfiles({ userId: providerId || '' });

  useEffect(() => {
    if (providerId) refetchUserBillingProfiles();
  }, [providerId, refetchUserBillingProfiles]);

  const billingProfileOptions = useMemo(() => {
    if (!userBillingProfiles) {
      return [];
    }
    return userBillingProfiles.map((p) => ({
      text: p.description,
      value: p.billingProfileId,
    }));
  }, [userBillingProfiles]);

  const hasBillingProfiles = useMemo(() => {
    if (!billingProfileOptions || !providerId) return false;
    return billingProfileOptions?.length > 0;
  }, [billingProfileOptions, providerId]);

  const onChangePolicy = (e: any) => {
    if (e?.billable) {
      handlePolicyChange(e);
    } else if (!e?.billable && !isBillingStarted) {
      setConfirmModalOpen(true);
      setSelectedInsurance(e);
    }
  };

  const onConfirmNonBillableInsurance = () => {
    handlePolicyChange(selectedInsurance);
    handleBillingProfileChange?.(null);
    setConfirmModalOpen(false);
    setSelectedInsurance(null);
  };

  return (readOnly && insurances?.length) || !readOnly ? (
    <div className={classNames(horizontal ? 'flex flex-col w-full' : 'mt-4')}>
      {consolidatedView ? null : <hr className="my-4" />}
      <div
        className={classNames(
          'relative flex items-center mb-4',
          horizontal ? 'flex-col' : 'flex-row justify-between', // Counterintuitive, but true
        )}
      >
        <div
          id="insurance-bar"
          className={classNames(
            'text-lg font-medium leading-5',
            horizontal
              ? 'mb-2.5 relative sm:mt-px sm:pt-2 flex justify-start w-full mt-4'
              : 'block mt-4',
            isBillingStarted ? 'text-accent-600' : 'text-primary-600',
          )}
        >
          Insurance
        </div>
        {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) &&
          !readOnly &&
          !policies.some((p) => !p.billable) && (
            <div
              className={classNames(
                'flex flex-row gap-2',
                horizontal ? 'w-full justify-end' : undefined,
              )}
            >
              {consolidatedView ? (
                <div className="flex flex-row">
                  <div className="text-sm italic font-light pt-4 pr-2">
                    {readOnly || insurances.some((i) => !i.billable)
                      ? ''
                      : activePolicies?.length
                        ? 'Select a policy to add'
                        : 'No more active policies'}
                  </div>
                  <div className="flex-grow flex">
                    {!readOnly &&
                      !insurances.some((i) => !i.billable) &&
                      !!activePolicies.length && (
                        <Select
                          name="activePolicies"
                          label=""
                          onChange={onChangePolicy}
                          options={activePolicies}
                          limit={1}
                          className="w-48 mb-4"
                          disabled={activePolicies.length === 0 || readOnly}
                        />
                      )}
                  </div>
                </div>
              ) : null}
              <div className="whitespace-nowrap pt-2">
                <NewButton
                  text="Insurance"
                  onClick={() => setInsuranceModalOpen(OpenClosedStates.Open)}
                  color={
                    isBillingStarted
                      ? ButtonColors.accent
                      : ButtonColors.primary
                  }
                />
              </div>
            </div>
          )}
      </div>
      {insurances?.some((p) => !p.billable) && (
        <cite className={'text-sm text-gray-400 col-span-10 p-2 ml-2'}>
          No other policies can be added with a non-billable policy.
        </cite>
      )}
      {!policies?.some((p: Insurance) => p.active) && (
        <cite className={'text-sm text-gray-400 col-span-10 p-2 ml-2'}>
          Patient has no active policies.
        </cite>
      )}
      {patientId &&
        (!!policies?.some((p: Insurance) => p.active) ||
          !!insurances?.length) && (
          <SubContainer
            title={
              consolidatedView ? '' : !readOnly ? 'Active Policies' : 'Policies'
            }
            subtitle={
              consolidatedView
                ? null
                : `${
                    readOnly || insurances.some((i) => !i.billable)
                      ? ''
                      : activePolicies?.length
                        ? 'Select a policy to add.'
                        : 'No more active policies.'
                  }`
            }
            rightSide={
              !consolidatedView &&
              !readOnly &&
              !insurances.some((i) => !i.billable) && (
                <div className="flex flex-col items-end">
                  {!!activePolicies.length && (
                    <Select
                      name="activePolicies"
                      label="Policies"
                      onChange={onChangePolicy}
                      options={activePolicies}
                      limit={1}
                      className="w-64 mb-4"
                      disabled={activePolicies.length === 0 || readOnly}
                    />
                  )}
                </div>
              )
            }
            divider={false}
          >
            <div className="flex flex-col gap-1">
              {insurances?.map((p, i) => {
                const disciplineBenefit = policies
                  ?.find(
                    (insurance: Insurance) => insurance.id === p.insuranceID,
                  )
                  ?.disciplineBenefits?.find(
                    (db: DisciplineInsuranceBenefit) =>
                      db.disciplineId === disciplineId,
                  );

                return (
                  <EncounterInsurancePolicy
                    insurances={insurances}
                    disciplineBenefit={disciplineBenefit}
                    allowBillingPriorityChange={allowBillingPriorityChange}
                    key={p.insuranceID}
                    readOnly={readOnly}
                    appointmentInsuranceIndex={i}
                    appointmentInsurance={p}
                    setInsurances={setInsurances}
                    services={services}
                    insuranceErrors={insuranceErrors}
                    onRemoveInsurance={onRemoveInsurance}
                  />
                );
              })}
            </div>

            {consolidatedView ? null : parentIsa ===
              'encounter' ? null : !!insurances.length &&
              hasBillingProfiles &&
              !insurances.some((i) => !i.billable) ? (
              <Select
                name="billingProfile"
                options={billingProfileOptions}
                onChange={(e) => handleBillingProfileChange?.(e)}
                value={billingProfileId}
                limit={1}
                className="col-span-4 pt-2"
                label="Billing Profile"
                disabled={
                  readOnly ||
                  isFetchingUserBillingProfiles ||
                  !hasBillingProfiles
                }
              />
            ) : isFetchingUserBillingProfiles ? null : !userBillingProfiles?.length ? (
              <div
                className="mt-4 p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <span className="font-medium">Warning!</span>
                {`   `}
                No billing profiles are associated with this clinician.
              </div>
            ) : null}
          </SubContainer>
        )}
      {parentIsa !== 'encounter' && !consolidatedView && (
        <div className="flex justify-between">
          {!!insurances.length && (
            <Checkbox
              className="py-4"
              label="Courtesy billing"
              tooltip={
                insurances.some((i) => i.inNetwork)
                  ? `All insurances must be out of network to use courtesy billing.`
                  : insurances.some((i) => !i.billable)
                    ? `All insurances must be billable to use courtesy billing.`
                    : superBill
                      ? `Courtesy billing cannot be enabled if a superbill is generated.`
                      : isBillingStarted
                        ? `A claim has already been submitted to a payor.`
                        : `Submit claim to insurance(s) as a courtesy for the patient.`
              }
              value={courtesyBilling}
              onChange={handleCourtesyBillingChange}
              disabled={
                readOnly ||
                insurances.some((i) => i.inNetwork) ||
                superBill ||
                isBillingStarted ||
                insurances.some((i) => !i.billable)
              }
            />
          )}
          <Checkbox
            className="py-4"
            label="Generate Superbill"
            tooltip={
              courtesyBilling
                ? `A superbill cannot be generated if courtesy billing is enabled.`
                : isBillingStarted
                  ? `A claim has already been submitted to a payor.`
                  : `Create a comprehensive superbill for the patient to submit to their insurance provider.`
            }
            value={superBill}
            onChange={handleSuperBillChange}
            disabled={readOnly || courtesyBilling || isBillingStarted}
          />
        </div>
      )}
      {!isFetchingPatient && patient?.ID && (
        <EncounterInsuranceModal
          isOpen={insuranceModalOpen}
          close={() => setInsuranceModalOpen(OpenClosedStates.Closed)}
          patient={patient}
          billingKey={billingKey}
        />
      )}
      <ConfirmModal
        isOpen={confirmModalOpen}
        close={() => setConfirmModalOpen(false)}
        confirm={onConfirmNonBillableInsurance}
        icon={<ExclamationTriangleIcon className="h-6 w-6 text-orange-300" />}
        title="Non-billable insurance"
        description="This insurance policy is not billable. If you add this policy, all others will be removed and no further policies may be added. Are you sure you want to add this policy?"
      />
    </div>
  ) : !isFetchingPolicies ? null /* Instructions were here. */ : null;
};

export default EncounterInsurance;
