import { useContext, useState } from 'react';
import patientBillingService from '../../../../../../../services/patientBilling.service';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../../../../../contexts/me.context';
import { InvoiceUseEnum } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { Invoice } from '@chiroup/core/types/Invoice.type';
import { PatientInvoice } from '@chiroup/core/types/PatientInvoice.type';

const getQuery = function (clinicId: number) {
  return async (context: QueryFunctionContext) => {
    const invoiceId = context.queryKey[1] as number,
      use = context.queryKey[2] as InvoiceUseEnum;
    return patientBillingService.getInvoice({
      clinicId,
      invoiceId,
      use,
    });
  };
};

const useInvoice = ({
  invoiceId,
  use,
}: {
  invoiceId: number;
  use?: InvoiceUseEnum;
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();
  const { status, data, error, isFetching, refetch } = useQuery<Invoice>(
    ['invoice', invoiceId, use],
    getQuery(me?.selectedClinic?.ID || -1),
    {
      refetchOnWindowFocus: false,
      enabled: invoiceId !== -1,
    },
  );

  const save = async (
    invoiceData: PatientInvoice | undefined,
    patientId: string,
    dispatched?: boolean,
  ) => {
    if (!invoiceData) {
      return;
    }
    setIsSaving(true);
    try {
      const res = await patientBillingService.updatePatientInvoice({
        invoiceData,
        invoiceId,
        patientId,
        clinicId: me?.selectedClinic?.ID || -1,
        dispatched,
      });
      queryClient.setQueryData<Invoice>(['invoice', invoiceId], res);

      const invoiceBreakdownItemsByItemId = (
        res?.invoiceData?.invoiceBreakdown?.lineItems || []
      ).reduce((obj: any, item: any) => {
        obj[item.transactionItemId] = {
          detail: item.detail,
          description: item.description,
          hidden: item.hidden,
        };
        return obj;
      }, {});
      queryClient.setQueryData(['transaction', res.billingKey], (prev: any) => {
        const newData = ChiroUpJSON.clone(prev);
        newData.items = newData.items?.map((item: any) => {
          if (invoiceBreakdownItemsByItemId[item.id]) {
            const parsedDisplayData = JSON.parse(item.displayData || '{}');
            parsedDisplayData.invoiceData =
              invoiceBreakdownItemsByItemId[item.id];
            item.displayData = JSON.stringify(parsedDisplayData);
          }
          return item;
        });
        return newData;
      });
      queryClient.invalidateQueries(['invoices']);
      setIsSaving(false);
      return res;
    } catch (e) {
      setIsSaving(false);
    }
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    save,
    isSaving,
  };
};

export default useInvoice;
