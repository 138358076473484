import { classNames } from '@chiroup/core/functions/classNames';
import {
  PatientTransactionItemType,
  TransactionItemTypeEnum,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import Loading from '../../../../common/Loading';
import { TransactionItem } from './TransactionItem';
import { TransactionItemAdjustment } from './TransactionItemAdjustment';

type Props = {
  items: PatientTransactionItemType[];
  onRemove?: (ord: number) => void;
  isLoading?: boolean;
  readonly?: boolean;
  onChange: (ord: number) => (property: string) => (value: string) => void;
  hiddenWhenEmpty?: boolean;
  editing?: boolean;
  hideUnits?: boolean;
  hasServices?: boolean;
  patientName?: string;
  patientId?: string;
  billingKey?: string;
};

export const TransactionItems: React.FC<Props> = ({
  items,
  isLoading = false,
  onRemove,
  readonly = false,
  onChange,
  hiddenWhenEmpty = false,
  editing,
  hideUnits = false,
  hasServices = false,
  patientName,
  patientId,
  billingKey,
}) => {
  if (!items || !items.length) {
    if (hiddenWhenEmpty) return null;

    return (
      <div
        className={classNames(
          'text-gray-400 italic text-md flex justify-center px-4 py-2 flex-col',
        )}
      >
        {isLoading ? (
          <div className="flex justify-end">
            <Loading color="text-gray-400" />
          </div>
        ) : (
          <div className="w-full flex justify-center">No items found.</div>
        )}
      </div>
    );
  }

  const overrideApplied = items?.some(
    (item) =>
      item?.type &&
      TransactionItemTypeEnum.Debit &&
      item?.subtype === TransactionItemSubtypeEnum.Override,
  );

  const itemsToShow = items?.filter(
    (item) =>
      item?.subtype !== TransactionItemSubtypeEnum.Service &&
      item?.subtype !== TransactionItemSubtypeEnum.PatientService &&
      item?.subtype !== TransactionItemSubtypeEnum.Tax,
  );

  /**
   * Keeps an empty box from appearing if nothing but services exist.
   */
  if (itemsToShow?.length === 0) {
    return null;
  }
  const showPaymentWarning =
    editing && itemsToShow.some((item) => item.subtype === 'Payment');

  return (
    <div>
      {/* <div>
        itemsToShowLength={itemsToShow.length}; itemsLength={items.length}
      </div> */}
      <div className="relative space-y-2 flex flex-col rounded-lg border border-gray-300 overflow-hidden bg-white shadow-sm focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:border-gray-400 items-center">
        {itemsToShow.map((item, index) => {
          return item?.subtype === TransactionItemSubtypeEnum.Adjustment ||
            item?.subtype === TransactionItemSubtypeEnum.Override ||
            item?.subtype === TransactionItemSubtypeEnum.AdditionalFee ? (
            <TransactionItemAdjustment
              index={index}
              value={item}
              key={`teti-${index}`}
              onRemove={onRemove}
              readonly={readonly}
              onChange={onChange(item.id)}
              percentDiscountApplied={
                item?.adjustment?.structure === '% Discount'
              }
            />
          ) : (
            <TransactionItem
              index={index}
              value={item}
              key={`teti-${index}`}
              onRemove={onRemove}
              readonly={readonly}
              onChange={onChange(item.id)}
              overrideApplied={overrideApplied}
              hideUnits={hideUnits}
              hasServices={hasServices}
              patientName={patientName}
              patientId={patientId}
              billingKey={billingKey}
              clickableRows={
                item.subtype === TransactionItemSubtypeEnum.Payment
              }
            />
          );
        })}
      </div>
      {showPaymentWarning && (
        <p className="mt-8 inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/50">
          <span>
            Alert: Payment has been Received! Please note that any modifications
            to this Purchase could affect the associated invoice and applied
            payments. Exercise caution while making changes.
          </span>
        </p>
      )}
    </div>
  );
};
