import { STRING_STRING_HASH } from '@chiroup/core/constants/globals';
import { classNames } from '@chiroup/core/functions/classNames';

type InvoiceTableHeaderProps = {
  headers: string[];
  className?: STRING_STRING_HASH;
  textAlign?: STRING_STRING_HASH;
  widthClasses?: STRING_STRING_HASH;
};

const InvoiceTableHeader: React.FC<InvoiceTableHeaderProps> = ({
  headers,
  className = {},
  textAlign = {},
  widthClasses = {},
}) => (
  <thead className="bg-gray-300 text-xs">
    <tr>
      {headers.map((header, index) => (
        <th
          key={index}
          scope="col"
          className={
            className[header] ??
            classNames(
              'py-1 pl-4 pr-3 font-semibold text-gray-900 sm:pl-6',
              textAlign[header] ?? 'text-left',
              widthClasses[header] ?? '',
            )
          }
        >
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

export default InvoiceTableHeader;
